<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JIDOTEATE.HAIGUSYA.TITLE_HAIGUSYA' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-kakunin">
        <div class="box-kakunin-message">
          <div class="box-kakunin-message-col1">
            <div class="kakunin-area">
              <input type="checkbox" id="haiguFlag" name="haiguFlag"
                [formControl]="pageForm.controls.haiguFlag" (change)="onHaigu()">
              <label for="haiguFlag">
              </label>
            </div>
          </div>
          <div class="box-kakunin-message-col2">
            {{ 'JIDOTEATE.HAIGUSYA.HAVE_HAIGU' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="box-koumoku" [hidden]="!pageForm.controls.haiguFlag.value">
      <div class="box-koumoku">
        <div class="box-required">
          <img class="required" src="/assets/required.png">
          <label>{{ 'COMMON.HAIGUSYA' | translate }}</label>
        </div>

        <form [formGroup]="pageForm">
          <app-shimei title="{{ 'JIDOTEATE.HAIGUSYA.HAIGUSYA' | translate }}"
            buttonTitle="{{ 'JIDOTEATE.SEI_COPY' | translate }}" [buttonVisible]="true" [copyShimei]="uji"
            fgName={{fgNameShimei}} ckName={{ckNameShimei}} defaltKbn="1">
          </app-shimei>
        </form>
      </div>

      <div class="box-koumoku">
        <div class="box-required">
          <img class="required" src="/assets/required.png">
          <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
        </div>
        <form [formGroup]="pageForm">
          <app-datepicker fgName={{fgNameDatepickerBirthday}}></app-datepicker>
        </form>
      </div>

      <div class="box-koumoku">
        <div class="box-required">
          <img class="required" src="/assets/required.png">
          <label> {{ 'COMMON.JOB' | translate }}</label>
        </div>
        <ng-container
          *ngIf="pageForm.controls.haiguJob.hasError('required') && (pageForm.controls.haiguJob.touched || pageForm.controls.haiguJob.dirty)">
          <div class="err-msg">
            <a>
              <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
              {{ 'COMMON.JOB_ERROR_REQUIRED' | translate }}
            </a>
          </div>
        </ng-container>
        <div class="box-control">
          <div class="select"
            [class.input-invalid]="pageForm.controls.haiguJob.invalid && (pageForm.controls.haiguJob.touched || pageForm.controls.haiguJob.dirty)">
            <select name="haiguJob" [formControl]="pageForm.controls.haiguJob">
              <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
              <option *ngFor="let job_val of job" [ngValue]="job_val">{{job_val}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="box-koumoku">
        <div class="box-kakunin">
          <div class="box-kakunin-message">
            <div class="box-kakunin-message-col1">
              <div class="kakunin-area">
                <input type="checkbox" id="bekkyoFlag" name="bekkyoFlag"
                  [formControl]="pageForm.controls.bekkyoFlag" (change)="onBekkyo()">
                <label for="bekkyoFlag">
                </label>
              </div>
            </div>
            <div class="box-kakunin-message-col2">
              {{ 'JIDOTEATE.HAIGUSYA.ADDR_SETUMEI' | translate }}
            </div>
          </div>
        </div>

        <div class="box-sub-block" [hidden]="!pageForm.controls.bekkyoFlag.value">
          <div class="box-block-dairi-inner">

            <div class="box-koumoku">
              <div class="box-required">
                <img class="required" src="/assets/required.png">
                <label>{{ 'COMMON.ADDR' | translate }}</label>
              </div>

              <form [formGroup]="pageForm">
                <app-zipcode fgName={{fgNameZipcode}} ckName={{ckNameZipcode}} defaltKbn="3"></app-zipcode>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="box-koumoku">
        <div class="box-kakunin">
          <div class="box-kakunin-message">
            <div class="box-kakunin-message-col1">
              <div class="kakunin-area">
                <input type="checkbox" id="differentaddressFlag" name="differentaddressFlag"
                  [formControl]="pageForm.controls.differentaddressFlag" (change)="on11DiffAddr()">
                <label for="differentaddressFlag">
                </label>
              </div>
            </div>
            <div class="box-kakunin-message-col2">
              {{ 'JIDOTEATE.HAIGUSYA.11_ADDR_SETUMEI' | translate }}
            </div>
          </div>
        </div>

        <div class="box-sub-block" [hidden]="!pageForm.controls.differentaddressFlag.value">
          <div class="box-block-dairi-inner">

            <div class="box-koumoku">
              <div class="box-required">
                <img class="required" src="/assets/required.png">
                <label>{{ 'COMMON.ADDR' | translate }}</label>
              </div>

              <form [formGroup]="pageForm">
                <app-zipcode fgName={{fgName11jitenZipcode}} ckName={{ckName11jitenZipcode}} defaltKbn="3"></app-zipcode>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>