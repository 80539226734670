import { Injectable } from '@angular/core';
import { Jyuminhyou } from './item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class JyuminhyouService {

  item: Jyuminhyou;
  steps = []

  constructor(public translate: TranslateService) {

    interface StepObj {
      step: number;
      title: string;
    }

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event)
      const stepArray = ret.translations.JYUMINHYOU.STEP;
      this.steps.splice(0)

      for (let i = 0; i < stepArray.length; i++) {
        const stepCol: StepObj = {
          step: i + 1,
          title: stepArray[i],
        };
        this.steps.push(stepCol)
      }

    });
  }

}
