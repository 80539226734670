<div class="box-control">
    <div class="select yyyy">
        <select [formControl]="controlName('year')" (ngModelChange)="onChange()" (ngModelChange)="onChange()" (ngModelChange)="onChangeValue()">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let year of years" [ngValue]="year.year">{{year.wareki + ' （' + year.year + '）'}}</option>
        </select>
    </div>

    <div class="box-lbl-datepicker">
        <a class="lbl-datepicker">{{ 'DATEPICKER.YEAR' | translate }}</a>
    </div>

    <div class="select mm">
        <select [formControl]="controlName('month')" (ngModelChange)="onChange()" (ngModelChange)="onChangeValue()">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let month of months" [ngValue]="month">{{month}}</option>
        </select>
    </div>

    <div class="box-lbl-datepicker">
        <a class="lbl-datepicker">{{ 'DATEPICKER.MONTH' | translate }}</a>
    </div>

    <div class="select dd">
        <select [formControl]="controlName('day')" (ngModelChange)="onChangeValue()">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let day of days" [ngValue]="day">{{day}}</option>
        </select>
    </div>

    <div class="box-lbl-datepicker">
        <a class="lbl-datepicker">{{ 'DATEPICKER.DAY' | translate }}</a>
    </div>

</div>