<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'IRYOKODOMO.SHINSEI.TITLE_SHINSEI' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'IRYOKODOMO.SHINSEI.SETUMEI_SHINSEI' | translate }}
        </a>
      </div>
    </div>

    <!--
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.SHINSEIDAY' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerShinsei}}></app-datepicker>
      </form>
    </div>
-->

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.SHINSEISYA' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false"
          fgName={{fgNameShimeiShinsei}} ckName={{ckNameShimeiShinsei}} defaltKbn="1"></app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcodeShinsei}} ckName={{ckNameZipcodeShinsei}} defaltKbn="3"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.snsi_home_tel_no.hasError('numeric') && (pageForm.controls.snsi_home_tel_no.touched || pageForm.controls.snsi_home_tel_no.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>

      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.snsi_home_tel_no.invalid && (pageForm.controls.snsi_home_tel_no.touched || pageForm.controls.snsi_home_tel_no.dirty)"
          class="input-text input-tel" type="text" maxlength="11" name="snsi_home_tel_no"
          [formControl]="pageForm.controls.snsi_home_tel_no" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerBirthday}}></app-datepicker>
      </form>
    </div>

    <!--続柄-->
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <div class="label-msg"><label>{{ 'COMMON.ZOKUGARA' | translate }}</label></div>
      </div>
      <ng-container
        *ngIf="pageForm.controls.snsi_zokugara.hasError('required') && (pageForm.controls.snsi_zokugara.touched || pageForm.controls.snsi_zokugara.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.ZOKUGARA_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <div class="select box-zokugara"
          [class.input-invalid]="pageForm.controls.snsi_zokugara.invalid && (pageForm.controls.snsi_zokugara.touched || pageForm.controls.snsi_zokugara.dirty)">
          <select name="snsi_zokugara" [formControl]="pageForm.controls.snsi_zokugara" (change)="onZokugaraChange()">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let zokugara_val of zokugara" [ngValue]="zokugara_val.value">{{zokugara_val.label | translate}}</option>
          </select>
        </div>
      </div>
    </div>

    <ng-container *ngIf="pageForm.controls.snsi_zokugara.value=='99'">
      <div class="box-koumoku">
        <div class="label-msg"><label>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA' | translate }}</label></div>
        <div class="box-control">
            <input class="input-text" type="text" [formControl]="pageForm.controls.snsi_zokugara_sonota" maxlength="10">
        </div>
        <div class="box-setumei">
            <a>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA_SETUMEI' | translate }}</a>
        </div>
      </div>
    </ng-container>

    <!--加入年金-->
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.NENKIN' | translate }}</label>
      </div>
      <ng-container
        *ngIf="pageForm.controls.snsi_nenkin.hasError('required') && (pageForm.controls.snsi_nenkin.touched || pageForm.controls.snsi_nenkin.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.NENKIN_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <div class="select box-nenkin"
          [class.input-invalid]="pageForm.controls.snsi_nenkin.invalid && (pageForm.controls.snsi_nenkin.touched || pageForm.controls.snsi_nenkin.dirty)">
          <select name="snsi_nenkin" [formControl]="pageForm.controls.snsi_nenkin">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let nenkin_val of nenkin" [ngValue]="nenkin_val">{{nenkin_val}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>

</div>