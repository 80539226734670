<div class="box-bankcode">

    <div class="box-koumoku">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'BANKCODE.BANK.TITLE' | translate }}</label></div>
        </div>
        <ng-container
            *ngIf="getControlByName(frmBankCode).hasError('required') && (getControlByName(frmBankCode).touched || getControlByName(frmBankCode).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.BANK.ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <div class="box-control">
            <div class="select"
                [class.input-invalid]="getControlByName(frmBankCode).invalid && (getControlByName(frmBankCode).touched || getControlByName(frmBankCode).dirty)">
                <ng-select #selectBank [items]="banks" [searchable]="false" bindValue="code" bindLabel="name"
                    [virtualScroll]="true" (scroll)="onScrollBanks($event)" (scrollToEnd)="onScrollToEndBanks()"
                    (change)="onChangeBanks()" [formControl]="getControlByName(frmBankCode)"
                    [dropdownPosition]="'bottom'" notFoundText="{{ 'COMMON.NOT_FOUND' | translate }}">
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 30px" type="text"
                            (input)="selectBank.filter($event.target.value)" />
                        <small class="form-text text-muted">{{banksBuffer.length}} ( {{banks.length}} )
                            {{ 'COMMON.LOADED' | translate }}</small>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <span class="ng-value-label" *ngIf="item.name.length <= 10"> {{item.name}}</span>
                        <span class="ng-value-label" *ngIf="item.name.length > 10"> {{item.name.slice(0,10)}}...</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        {{item.name}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'BANKCODE.BRANCH.TITLE' | translate }}</label></div>
        </div>
        <ng-container
            *ngIf="getControlByName(frmBranchCode).hasError('required') && (getControlByName(frmBranchCode).touched || getControlByName(frmBranchCode).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.BRANCH.ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>

        <div class="box-control">
            <div class="select"
                [class.input-invalid]="getControlByName(frmBranchCode).invalid && (getControlByName(frmBranchCode).touched || getControlByName(frmBranchCode).dirty)">
                <ng-select #selectShiten [items]="shitens" [searchable]="false" bindValue="code" bindLabel="name"
                    [virtualScroll]="true" (scroll)="onScrollShitens($event)" (scrollToEnd)="onScrollToEndShitens()"
                    [formControl]="getControlByName(frmBranchCode)" (change)="onChangeShitens()"
                    [dropdownPosition]="'bottom'" notFoundText="{{ 'COMMON.NOT_FOUND' | translate }}">
                    <ng-template ng-header-tmp>
                        <input style="width: 100%; line-height: 30px" type="text"
                            (input)="selectShiten.filter($event.target.value)" />
                        <small class="form-text text-muted">{{shitensBuffer.length}} ( {{shitens.length}} )
                            {{ 'COMMON.LOADED' | translate }}</small>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-index="index">
                        <span class="ng-value-label" *ngIf="item.name.length <= 10"> {{item.name}}</span>
                        <span class="ng-value-label" *ngIf="item.name.length > 10"> {{item.name.slice(0,10)}}...</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        {{item.name}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'BANKCODE.ACCOUNT.TYPE_TITLE' | translate }}</label></div>
        </div>
        <ng-container
            *ngIf="getControlByName(frmAccountType).hasError('required') && (getControlByName(frmAccountType).touched || getControlByName(frmAccountType).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.TYPE_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <div class="box-control">
            <div class="select"
                [class.input-invalid]="getControlByName(frmAccountType).invalid && (getControlByName(frmAccountType).touched || getControlByName(frmAccountType).dirty)">
                <select [formControl]="getControlByName(frmAccountType)">
                    <option disabled="disabled" [value]="null">
                        {{ 'COMMON.SELECT' | translate }}</option>
                    <option *ngFor="let type of accountTypes" value={{type}}>
                        {{type}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="box-koumoku">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'BANKCODE.ACCOUNT.NUMBER_TITLE' | translate }}</label>
            </div>
        </div>
        <ng-container
            *ngIf="getControlByName(frmAccountNumber).hasError('required') && (getControlByName(frmAccountNumber).touched || getControlByName(frmAccountNumber).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.NUMBER_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="getControlByName(frmAccountNumber).hasError('numeric') && (getControlByName(frmAccountNumber).touched || getControlByName(frmAccountNumber).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.NUMBER_ERROR_TYPE' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="getControlByName(frmAccountNumber).hasError('minlength') && (getControlByName(frmAccountNumber).touched || getControlByName(frmAccountNumber).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.NUMBER_ERROR_LENGTH' | translate }}
                </a>
            </div>
        </ng-container>
        <div class="box-control">
            <input
                [class.input-invalid]="getControlByName(frmAccountNumber).invalid && (getControlByName(frmAccountNumber).touched || getControlByName(frmAccountNumber).dirty)"
                type="tel" maxlength="7" class="input-text input-bankcode" name={{frmAccountNumber}}
                [formControl]="getControlByName(frmAccountNumber)">
        </div>
    </div>
    <div class="box-koumoku no-margin-bottom">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'BANKCODE.ACCOUNT.OWNER_TITLE' | translate }}</label>
            </div>
        </div>
        <ng-container
            *ngIf="getControlByName(frmAccountOwner).hasError('required') && (getControlByName(frmAccountOwner).touched || getControlByName(frmAccountOwner).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.OWNER_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="getControlByName(frmAccountOwner).hasError('katakana') && (getControlByName(frmAccountOwner).touched || getControlByName(frmAccountOwner).dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'BANKCODE.ACCOUNT.OWNER_ERROR_TYPE' | translate }}
                </a>
            </div>
        </ng-container>
        <div class="box-control">
            <input [class.input-invalid]="getControlByName(frmAccountOwner).hasError('katakana') ||
                   (getControlByName(frmAccountOwner).invalid && 
                     (getControlByName(frmAccountOwner).touched || getControlByName(frmAccountOwner).dirty))"
                class="input-text" type="text" name={{frmAccountOwner}}
                [formControl]="getControlByName(frmAccountOwner)" maxlength="20">
        </div>
        <div class="box-setumei">
            <a>{{ 'BANKCODE.ACCOUNT.OWNER_SETUMEI' | translate }}</a>
        </div>
    </div>

</div>