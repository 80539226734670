import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterKbn',
  pure: false
})
export class FilterKbnPipe implements PipeTransform {

  transform(items: any[], filter: any[]): any {
    if (!items || !filter) {
      return items;
    }

    const wItems: Array<{ value: string; label: string; }> = [];
    filter.forEach((kbn) => {
      const w = items.find((v) => v.value == kbn);
      wItems.push(w);
    });
    return wItems;
  }

}