<form [formGroup]="pageForm">
  <div formArrayName="cyouhyos">
    <div class="box-block" *ngFor="let cyouhyo of cyouhyos.controls; let i = index">

      <div class="box-cyouhyo-title">
        <div class="box-koumoku">
          <h4>{{ 'JYUMINHYOU.CYOUHYO.TITLE_CYOUHYO' | translate }}（{{ i+1 }}/{{ pageForm.controls.cyouhyos.length }}）
          </h4>
        </div>
        <ng-container *ngIf="i!=0">
          <a class="btn-delete" (click)="removeCyouhyo(i)">
            <fa-icon [icon]="faTrashAlt"></fa-icon>
          </a>
        </ng-container>
      </div>

      <div class="box-block-inner">

        <div class="box-koumoku">
          <div class="box-help">
            <a>
              {{ 'JYUMINHYOU.CYOUHYO.SETUMEI_CYOUHYO' | translate }}
            </a>
          </div>
        </div>

        <div class="box-koumoku">

          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'JYUMINHYOU.CYOUHYO.CYOUHYO' | translate }}</label>
          </div>

          <div class="box-cyouhyo-common">
            <div [formGroupName]="i">
              <div class="box-koumoku">

                <div class="box-required">
                  <img class="required" src="/assets/required.png">
                  <div class="label-msg"><label>{{ 'JYUMINHYOU.CYOUHYO.NAME' | translate }}</label></div>
                </div>
                <ng-container
                  *ngIf="pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.hasError('required') && (pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.touched || pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.dirty)">
                  <div class="err-msg">
                    <a>
                      <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                      {{ 'JYUMINHYOU.CYOUHYO.NAME_ERROR_REQUIRED' | translate }}
                    </a>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.hasError('duplex') && (pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.touched || pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.dirty)">
                  <div class="err-msg">
                    <a>
                      <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                      {{ 'JYUMINHYOU.CYOUHYO.NAME_ERROR_DUPLEX' | translate }}
                    </a>
                  </div>
                </ng-container>
                <div class="box-control">
                  <div class="select box-cyouhyoname"
                    [class.input-invalid]="pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.invalid && (pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.touched || pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.dirty)">
                    <select formControlName="cyouhyo_cd" >
                      <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                      <option *ngFor="let cx101_val of cx101" [ngValue]="cx101_val.cd">
                        {{ cx101_val.name | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <ng-container
              *ngIf="pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.value=='112' || pageForm.controls.cyouhyos.controls[i].controls.cyouhyo_cd.value=='132'">
              <div class="box-koumoku">
                <div class="inf-msg">
                  <a>
                    <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                    {{ 'JYUMINHYOU.CYOUHYO.ICHIBU_INFOMATION' | translate }}
                  </a>
                </div>
              </div>
            </ng-container>

              <div class="box-koumoku no-margin-bottom">
                <div class="box-required">
                  <img class="required" src="/assets/required.png">
                  <div class="label-msg"><label>{{ 'COMMON.MAISU' | translate }}</label></div>
                </div>
                <ng-container
                  *ngIf="pageForm.controls.cyouhyos.controls[i].controls.maisu.hasError('required') && (pageForm.controls.cyouhyos.controls[i].controls.maisu.touched || pageForm.controls.cyouhyos.controls[i].controls.maisu.dirty)">
                  <div class="err-msg">
                    <a>
                      <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                      {{ 'COMMON.MAISU_ERROR_REQUIRED' | translate }}
                    </a>
                  </div>
                </ng-container>

                <div class="box-control">
                  <div class="select box-maisu"
                    [class.input-invalid]="pageForm.controls.cyouhyos.controls[i].controls.maisu.invalid && (pageForm.controls.cyouhyos.controls[i].controls.maisu.touched || pageForm.controls.cyouhyos.controls[i].controls.maisu.dirty)">
                    <select formControlName="maisu">
                      <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                      <option *ngFor="let maisu_val of maisu" [ngValue]="maisu_val">{{maisu_val}}</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="pageForm.controls.cyouhyos.value.length < cx101.length">
  <div class="box-add-cyouhyo">
    <a class="btn-add-cyouhyo" (click)="addCyouhyo()">{{ 'JYUMINHYOU.CYOUHYO.ADD_CYOUHYO' | translate }}</a>
  </div>
</ng-container>

<!-- Debug用の表示 
<h3>Debug</h3>
<ng-container *ngIf="form.value">
  <pre>{{ form.value | json }}</pre>
</ng-container>
-->