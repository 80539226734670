import { Directive, ElementRef, HostListener } from "@angular/core";
import { hankaku2zenkaku } from '@utility/string';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[appHan2zen]'
})
export class Han2zenDirective {

  constructor(private elemRef: ElementRef<HTMLInputElement>, private control: NgControl) {}

  @HostListener("input") onInput(): void {
    const os = window.navigator.userAgent.toLowerCase();
    if (os.indexOf("mac os x") !== -1) {
      return;
    }
    const initialValue = this.elemRef.nativeElement.value;
    const zenValue = hankaku2zenkaku(initialValue);
    // this.elemRef.nativeElement.value = zenValue;
    this.control.control.setValue(zenValue);
  }
}
