import { Component, OnInit, Input } from '@angular/core';
import { JidoTeate } from '@jidoteate/services/item';

@Component({
  selector: 'app-jidoteate-kakunin',
  templateUrl: './jidoteate-kakunin.component.html',
  styleUrls: ['./jidoteate-kakunin.component.css']
})
export class JidoteateKakuninComponent implements OnInit {
  @Input() item: JidoTeate;

  constructor() { }

  ngOnInit(): void {
  }

  getJidoCount(): number {
    return this.item.fk202s.length;
  }

  get hasHaigu(): boolean {
    return !!this.item.fk201_haigu_name_kj;
  }

  get has11Addr(): boolean {
    return !!this.item.fk201_snsi_11_yubinno;
  }

  get isHaiguBekkyo(): boolean {
    return !!this.item.fk201_haigu_yubinno;
  }

  get haiguHas11Addr(): boolean {
    return !!this.item.fk201_haigu_11_yubinno;
  }
}
