import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Inkansyoumei, Shinsei } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { InkansyoumeiDouiComponent } from '@inkansyoumei/components/inkansyoumei-doui/inkansyoumei-doui.component';
import { InkansyoumeiShinseiComponent } from '@inkansyoumei/components/inkansyoumei-shinsei/inkansyoumei-shinsei.component';
import { InkansyoumeiKakuninComponent } from '@inkansyoumei/components/inkansyoumei-kakunin/inkansyoumei-kakunin.component';
import { InkansyoumeiQrComponent } from '@inkansyoumei/components/inkansyoumei-qr/inkansyoumei-qr.component';
import { InkansyoumeiService } from '@inkansyoumei/services/inkansyoumei.service';
import { Step } from '@components/stepver/stepver.component';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-inkansyoumei',
  templateUrl: './inkansyoumei.component.html',
  styleUrls: ['./inkansyoumei.component.css']
})
export class InkansyoumeiComponent implements OnInit {
  @ViewChild(InkansyoumeiDouiComponent) douiComponent: InkansyoumeiDouiComponent;
  @ViewChild(InkansyoumeiShinseiComponent) shinseiComponent: InkansyoumeiShinseiComponent;
  @ViewChild(InkansyoumeiKakuninComponent) kakuninComponent: InkansyoumeiKakuninComponent;
  @ViewChild(InkansyoumeiQrComponent) qrComponent: InkansyoumeiQrComponent;

  item: Inkansyoumei;
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;
  
  constructor(
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private inkansyoumeiService: InkansyoumeiService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    //初期化
    this.item = {
      "sj111_snsi_bng": 0,
      "sj111_snsi_ymd": this.today,
      "sj111_touroku_no": null,
      "sj111_maisuu": null,
      "sj111_snsi_yubinno": "",
      "sj111_snsi_addr_ken": "",
      "sj111_snsi_addr_city": "",
      "sj111_snsi_addr_town": "",
      "sj111_snsi_addr_banti": "",
      "sj111_snsi_addr_ktgk": "",
      "sj111_snsi_name_kj": "",
      "sj111_snsi_name_kn": "",
      "sj111_snsi_telno": "",
      "sj111_snsi_umare_ymd": "",
      "sj111_dairi_yubinno": "",
      "sj111_dairi_addr_ken": "",
      "sj111_dairi_addr_city": "",
      "sj111_dairi_addr_town": "",
      "sj111_dairi_addr_banti": "",
      "sj111_dairi_addr_ktgk": "",
      "sj111_dairi_name_kj": "",
      "sj111_dairi_name_kn": "",
      "sj111_dairi_telno": "",
      "sj111_sign_img": "",
      "sj111_zumi_flg": false,
      "sj111_sys_user_id": "",
    };

    this.steps = this.inkansyoumeiService.steps;

    //ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.shinseiComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      //toutchしたことにする
      this.douiComponent.setTouched();
      if (environment.production && this.douiComponent.pageForm.invalid) return;

      //次の画面のステータスをセット
      this.isChildFormValid = this.shinseiComponent.pageForm.valid;
    }

    if (this.currentSteper == 2) {
      //toutchしたことにする
      this.shinseiComponent.setTouched();
      if (environment.production && this.shinseiComponent.pageForm.invalid) return;
      const shinsei = this.shinseiComponent.getShinsei();
      this.setShinsei(shinsei);

      this.isChildFormValid = this.qrComponent.createQRText();
    }
    if (this.currentSteper == 3) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;
    this.scrollTop();
  }

  onBack() {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;

    //同意に戻ったらチェックを外す
    if (this.currentSteper == 1) {
      this.douiComponent.clearDouiFlg();
    }
  }

  hiddenBack() {
    if ([2, 3, 4].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setShinsei(data: Shinsei) {
    this.item.sj111_touroku_no = this.replaceNullZero(data.touroku_no);
    this.item.sj111_maisuu = this.replaceNullZero(data.maisuu);
    this.item.sj111_snsi_yubinno = this.replaceNullEmpty(data.snsi_address.zipcode);
    this.item.sj111_snsi_addr_ken = this.replaceNullEmpty(data.snsi_address.address.ken);
    this.item.sj111_snsi_addr_city = this.replaceNullEmpty(data.snsi_address.address.city);
    this.item.sj111_snsi_addr_town = this.replaceNullEmpty(data.snsi_address.address.town);
    this.item.sj111_snsi_addr_banti = this.replaceNullEmpty(data.snsi_address.banti);
    this.item.sj111_snsi_addr_ktgk = this.replaceNullEmpty(data.snsi_address.ktgk);
    this.item.sj111_snsi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.snsi_smi));
    this.item.sj111_snsi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.snsi_smi));
    this.item.sj111_snsi_telno = this.replaceNullEmpty(data.snsi_telno);
    this.item.sj111_snsi_umare_ymd = this.replaceNullEmpty(data.snsi_umare_ymd);
    this.item.sj111_dairi_yubinno = this.replaceNullEmpty(data.dairi_address.zipcode);
    this.item.sj111_dairi_addr_ken = this.replaceNullEmpty(data.dairi_address.address.ken);
    this.item.sj111_dairi_addr_city = this.replaceNullEmpty(data.dairi_address.address.city);
    this.item.sj111_dairi_addr_town = this.replaceNullEmpty(data.dairi_address.address.town);
    this.item.sj111_dairi_addr_banti = this.replaceNullEmpty(data.dairi_address.banti);
    this.item.sj111_dairi_addr_ktgk = this.replaceNullEmpty(data.dairi_address.ktgk);
    this.item.sj111_dairi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.dairi_smi));
    this.item.sj111_dairi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.dairi_smi));
    this.item.sj111_dairi_telno = this.replaceNullEmpty(data.dairi_telno);
  }

  replaceNullZero(value) {
    return value == null ? 0 : value;
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

}
