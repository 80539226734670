<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JIDOTEATE.SEIKYUSYA.TITLE_SEIKYUSYA' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.SEIKYUSYA' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false" fgName={{fgNameShimei}} ckName={{ckNameShimei}} defaltKbn="1">
        </app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcode}} ckName={{ckNameZipcode}} defaltKbn="3"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-kakunin">
        <div class="box-kakunin-message">
          <div class="box-kakunin-message-col1">
            <div class="kakunin-area">
              <input type="checkbox" id="addr11flag" name="addr11flag"
                [formControl]="pageForm.controls.addr11flag" (change)="onAddr11Checked()">
              <label for="addr11flag">
              </label>
            </div>
          </div>
          <div class="box-kakunin-message-col2">
            {{ 'JIDOTEATE.SEIKYUSYA.11_ADDR_SETUMEI' | translate }} </div>
        </div>
      </div>

      <div class="box-sub-block" [hidden]="!pageForm.controls.addr11flag.value">
        <div class="box-block-dairi-inner">

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <label>{{ 'COMMON.ADDR' | translate }}</label>
            </div>

            <form [formGroup]="pageForm">
              <app-zipcode fgName={{fgName11jitenZipcode}} ckName={{ckName11jitenZipcode}} defaltKbn="3"></app-zipcode>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.snsi_telno.hasError('numeric') && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.snsi_telno.invalid && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)"
          class="input-text input-tel" type="text" maxlength="11" name="snsi_telno"
          [formControl]="pageForm.controls.snsi_telno" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <div class="label-msg"><label>{{ 'COMMON.SEX' | translate }}</label></div>
      </div>
      <form [formGroup]="pageForm">
        <app-sex fgName={{fgNameSex}} rdName={{rdNameSex}}></app-sex>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <div class="label-msg"><label>{{ 'COMMON.ZOKUGARA' | translate }}</label></div>
      </div>
      <ng-container
        *ngIf="pageForm.controls.snsi_zokugara.hasError('required') && (pageForm.controls.snsi_zokugara.touched || pageForm.controls.snsi_zokugara.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.ZOKUGARA_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <div class="select box-col-md"
          [class.input-invalid]="pageForm.controls.snsi_zokugara.invalid && (pageForm.controls.snsi_zokugara.touched || pageForm.controls.snsi_zokugara.dirty)">
          <select name="snsi_zokugara" [formControl]="pageForm.controls.snsi_zokugara" (change)="onZokugaraChange()">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let zokugara_val of zokugara" [ngValue]="zokugara_val.value">{{zokugara_val.label | translate}}</option>
          </select>
        </div>
      </div>
    </div>

    <ng-container *ngIf="pageForm.controls.snsi_zokugara.value=='99'">
      <div class="box-koumoku">
        <div class="label-msg"><label>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA' | translate }}</label></div>
        <div class="box-control">
          <input class="input-text" type="text" [formControl]="pageForm.controls.snsi_zokugara_sonota" maxlength="10">
        </div>
        <div class="box-setumei">
          <a>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA_SETUMEI' | translate }}</a>
        </div>
      </div>
    </ng-container>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerBirthday}}></app-datepicker>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label> {{ 'COMMON.NENKIN' | translate }}</label>
      </div>
      <ng-container
        *ngIf="pageForm.controls.snsi_nenkin.hasError('required') && (pageForm.controls.snsi_nenkin.touched || pageForm.controls.snsi_nenkin.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.NENKIN_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <div class="select"
          [class.input-invalid]="pageForm.controls.snsi_nenkin.invalid && (pageForm.controls.snsi_nenkin.touched || pageForm.controls.snsi_nenkin.dirty)">
          <select name="snsi_nenkin" [formControl]="pageForm.controls.snsi_nenkin">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let nenkin_val of nenkin" [ngValue]="nenkin_val">{{nenkin_val}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label> {{ 'COMMON.JOB' | translate }}</label>
      </div>
      <ng-container
        *ngIf="pageForm.controls.snsi_job.hasError('required') && (pageForm.controls.snsi_job.touched || pageForm.controls.snsi_job.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.JOB_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <div class="select"
          [class.input-invalid]="pageForm.controls.snsi_job.invalid && (pageForm.controls.snsi_job.touched || pageForm.controls.snsi_job.dirty)">
          <select name="snsi_job" [formControl]="pageForm.controls.snsi_job">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let job_val of job" [ngValue]="job_val">{{job_val}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JIDOTEATE.SEIKYUSYA.TITLE_TEISYUTUBI' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'JIDOTEATE.SEIKYUSYA.TEISYUTUBI_SETUMEI' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'JIDOTEATE.SEIKYUSYA.TEISYUTUBI' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerSeikyu}}></app-datepicker>
      </form>
    </div>
  </div>
</div>