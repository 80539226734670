import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, AfterViewChecked, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Inkantouroku, Shinsei, Hosho } from '@inkantouroku/services/item';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { ShimeiComponent, Shimei } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inkantouroku-hosho',
  templateUrl: './inkantouroku-hosho.component.html',
  styleUrls: ['./inkantouroku-hosho.component.css', '../../inkantouroku.component.css']
})
export class InkantourokuHoshoComponent implements OnInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  fgNameShimeiHosho = 'shimeiHosho';
  ckNameShimeiHosho = 'hosho-shimeiHosho';
  fgNameZipcodeHosho = 'zipcodeHosho';
  ckNameZipcodeHosho = 'hosho-zipcodeHosho';
  faExclamationTriangle = faExclamationTriangle;

  constructor(fb: FormBuilder) {
    this.pageForm = fb.group({
      //shimeiHosho（shimei コンポーネントで追加）
      //zipcodeHosho（zipcode コンポーネントで追加）
      hosho_telno: [null, [CustomValidator.numeric]],
      hosho_touroku_no: [null, [Validators.required, CustomValidator.numeric]],
    });
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  getHosho(): Hosho {
    const shimeiHosho = new Shimei(this.pageForm.controls.shimeiHosho.get('sei_kj').value, this.pageForm.controls.shimeiHosho.get('mei_kj').value, this.pageForm.controls.shimeiHosho.get('sei_kn').value, this.pageForm.controls.shimeiHosho.get('mei_kn').value);
    const addressHosho = new Address(this.pageForm.controls.zipcodeHosho.get('address').value.ken, this.pageForm.controls.zipcodeHosho.get('address').value.city, this.pageForm.controls.zipcodeHosho.get('address').value.town);
    const zipcodeHosho = new ZipcodeAddress(this.pageForm.controls.zipcodeHosho.get('zipcode').value, addressHosho, this.pageForm.controls.zipcodeHosho.get('banti').value, this.pageForm.controls.zipcodeHosho.get('ktgk').value);

    const hosho = new Hosho(
      shimeiHosho,
      zipcodeHosho,
      this.pageForm.controls.hosho_telno.value,
      this.pageForm.controls.hosho_touroku_no.value,
    );
    return hosho;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }
}
