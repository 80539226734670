import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JyukiidoComponent } from './jyukiido/jyukiido.component';
import { CyouhyouModalComponent } from './jyukiido/components/cyouhyou-modal/cyouhyou-modal.component';
import { JyukiidoShinseiComponent } from './jyukiido/components/jyukiido-shinsei/jyukiido-shinsei.component';
import { JyukiidoZenjyusyoComponent } from './jyukiido/components/jyukiido-zenjyusyo/jyukiido-zenjyusyo.component';
import { JyukiidoShinjyusyoComponent } from './jyukiido/components/jyukiido-shinjyusyo/jyukiido-shinjyusyo.component';
import { JyukiidoIdosyaComponent } from './jyukiido/components/jyukiido-idosya/jyukiido-idosya.component';
import { JyukiidoKakuninComponent } from './jyukiido/components/jyukiido-kakunin/jyukiido-kakunin.component';
import { JyukiidoQrComponent } from './jyukiido/components/jyukiido-qr/jyukiido-qr.component';
import { InkansyoumeiComponent } from './inkansyoumei/inkansyoumei.component';
import { InkansyoumeiShinseiComponent } from './inkansyoumei/components/inkansyoumei-shinsei/inkansyoumei-shinsei.component';
import { InkansyoumeiKakuninComponent } from './inkansyoumei/components/inkansyoumei-kakunin/inkansyoumei-kakunin.component';
import { InkansyoumeiQrComponent } from './inkansyoumei/components/inkansyoumei-qr/inkansyoumei-qr.component';
import { StepverComponent } from './common/components/stepver/stepver.component';
import { ShimeiComponent } from './common/components/shimei/shimei.component';
import { DatepickerComponent } from './common/components/datepicker/datepicker.component';
import { SexComponent } from './common/components/sex/sex.component';
import { ConfigService } from './common/services/config/config.service';
import { ZipcodeComponent } from './common/components/zipcode/zipcode.component';
import { BankcodeComponent } from './common/components/bankcode/bankcode.component';
import { IryokodomoComponent } from './iryokodomo/iryokodomo.component';
import { IryokodomoShinseiComponent } from './iryokodomo/components/iryokodomo-shinsei/iryokodomo-shinsei.component';
import { IryokodomoTaisyoujidouComponent } from './iryokodomo/components/iryokodomo-taisyoujidou/iryokodomo-taisyoujidou.component';
import { IryokodomoHokenComponent } from './iryokodomo/components/iryokodomo-hoken/iryokodomo-hoken.component';
import { IryokodomoQrComponent } from './iryokodomo/components/iryokodomo-qr/iryokodomo-qr.component';
import { IryokodomoKakuninComponent } from './iryokodomo/components/iryokodomo-kakunin/iryokodomo-kakunin.component';
import { JidoteateComponent } from './jidoteate/jidoteate.component';
import { JidoteateSeikyushaComponent } from './jidoteate/components/jidoteate-seikyusha/jidoteate-seikyusha.component';
import { JidoteateHaigushaComponent } from './jidoteate/components/jidoteate-haigusha/jidoteate-haigusha.component';
import { JidoteateJidosComponent } from './jidoteate/components/jidoteate-jidos/jidoteate-jidos.component';
import { JidoteateKozaComponent } from './jidoteate/components/jidoteate-koza/jidoteate-koza.component';
import { JidoteateKakuninComponent } from './jidoteate/components/jidoteate-kakunin/jidoteate-kakunin.component';
import { JidoteateQrComponent } from './jidoteate/components/jidoteate-qr/jidoteate-qr.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JyuminhyouComponent } from './jyuminhyou/jyuminhyou.component';
import { JyuminhyouShinseiComponent } from './jyuminhyou/components/jyuminhyou-shinsei/jyuminhyou-shinsei.component';
import { JyuminhyouCyouhyoComponent } from './jyuminhyou/components/jyuminhyou-cyouhyo/jyuminhyou-cyouhyo.component';
import { JyuminhyouOptionComponent } from './jyuminhyou/components/jyuminhyou-option/jyuminhyou-option.component';
import { JyuminhyouKakuninComponent } from './jyuminhyou/components/jyuminhyou-kakunin/jyuminhyou-kakunin.component';
import { JyuminhyouQrComponent } from './jyuminhyou/components/jyuminhyou-qr/jyuminhyou-qr.component';
import { InkantourokuComponent } from './inkantouroku/inkantouroku.component';
import { InkantourokuShinseiComponent } from './inkantouroku/components/inkantouroku-shinsei/inkantouroku-shinsei.component';
import { InkantourokuHoshoComponent } from './inkantouroku/components/inkantouroku-hosho/inkantouroku-hosho.component';
import { InkantourokuKakuninComponent } from './inkantouroku/components/inkantouroku-kakunin/inkantouroku-kakunin.component';
import { InkantourokuQrComponent } from './inkantouroku/components/inkantouroku-qr/inkantouroku-qr.component';
import { JyukiidoMenuComponent } from './jyukiido/components/jyukiido-menu/jyukiido-menu.component';
import { FilterKbnPipe } from './common/pipe/filter-kbn.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InkansyoumeiDouiComponent } from './inkansyoumei/components/inkansyoumei-doui/inkansyoumei-doui.component';
import { InkantourokuDouiComponent } from './inkantouroku/components/inkantouroku-doui/inkantouroku-doui.component';
import { JyuminhyouDouiComponent } from './jyuminhyou/components/jyuminhyou-doui/jyuminhyou-doui.component';
import { JyukiidoDouiComponent } from './jyukiido/components/jyukiido-doui/jyukiido-doui.component';
import { Han2zenDirective } from './common/utility/han2zen.directive';
import { Zen2hanDirective } from './common/utility/zen2han.directive';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    JyukiidoComponent,
    JyukiidoShinseiComponent,
    JyukiidoZenjyusyoComponent,
    JyukiidoShinjyusyoComponent,
    JyukiidoIdosyaComponent,
    JyukiidoKakuninComponent,
    JyukiidoQrComponent,
    InkansyoumeiComponent,
    InkansyoumeiShinseiComponent,
    InkansyoumeiKakuninComponent,
    InkansyoumeiQrComponent,
    StepverComponent,
    ShimeiComponent,
    DatepickerComponent,
    SexComponent,
    ZipcodeComponent,
    BankcodeComponent,
    IryokodomoComponent,
    IryokodomoShinseiComponent,
    IryokodomoTaisyoujidouComponent,
    IryokodomoHokenComponent,
    IryokodomoQrComponent,
    IryokodomoKakuninComponent,
    JyuminhyouComponent,
    JyuminhyouShinseiComponent,
    JyuminhyouCyouhyoComponent,
    JyuminhyouOptionComponent,
    JyuminhyouKakuninComponent,
    JyuminhyouQrComponent,
    JidoteateComponent,
    JidoteateSeikyushaComponent,
    JidoteateHaigushaComponent,
    JidoteateJidosComponent,
    JidoteateKozaComponent,
    JidoteateKakuninComponent,
    JidoteateQrComponent,
    InkantourokuComponent,
    InkantourokuShinseiComponent,
    InkantourokuHoshoComponent,
    InkantourokuKakuninComponent,
    InkantourokuQrComponent,
    JyukiidoMenuComponent,
    FilterKbnPipe,
    PageNotFoundComponent,
    InkansyoumeiDouiComponent,
    InkantourokuDouiComponent,
    JyuminhyouDouiComponent,
    JyukiidoDouiComponent,
    CyouhyouModalComponent,
    Han2zenDirective,
    Zen2hanDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule,
    NgSelectModule
  ],
  providers: [
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.load(), deps: [ConfigService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
