import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export class Inkansyoumei {
    sj111_snsi_bng: number;
    sj111_snsi_ymd: string;
    sj111_touroku_no: number;
    sj111_maisuu: number;
    sj111_snsi_yubinno: string;
    sj111_snsi_addr_ken: string;
    sj111_snsi_addr_city: string;
    sj111_snsi_addr_town: string;
    sj111_snsi_addr_banti: string;
    sj111_snsi_addr_ktgk: string;
    sj111_snsi_name_kj: string;
    sj111_snsi_name_kn: string;
    sj111_snsi_telno: string;
    sj111_snsi_umare_ymd: string;
    sj111_dairi_yubinno: string;
    sj111_dairi_addr_ken: string;
    sj111_dairi_addr_city: string;
    sj111_dairi_addr_town: string;
    sj111_dairi_addr_banti: string;
    sj111_dairi_addr_ktgk: string;
    sj111_dairi_name_kj: string;
    sj111_dairi_name_kn: string;
    sj111_dairi_telno: string;
    sj111_sign_img: String;
    sj111_zumi_flg: boolean;
    sj111_sys_user_id: String;
}


export class Shinsei {
    touroku_no: number;
    maisuu: number;
    snsi_smi: Shimei;
    snsi_address: ZipcodeAddress;
    snsi_telno: string;
    snsi_umare_ymd: string;
    dairiFlg: boolean;
    dairi_smi: Shimei;
    dairi_address: ZipcodeAddress;
    dairi_telno: string;
    constructor(touroku_no: number, maisuu:number, snsi_smi: Shimei, snsi_address: ZipcodeAddress, snsi_telno: string, snsi_umare_ymd: string, dairiFlg: boolean, dairi_smi: Shimei, dairi_address: ZipcodeAddress, dairi_telno: string,) {
        this.touroku_no = touroku_no;
        this.maisuu = maisuu;
        this.snsi_smi = snsi_smi;
        this.snsi_address = snsi_address;
        this.snsi_telno = snsi_telno;
        this.snsi_umare_ymd = snsi_umare_ymd;
        this.dairiFlg = dairiFlg;
        this.dairi_smi = dairi_smi;
        this.dairi_address = dairi_address;
        this.dairi_telno = dairi_telno;
    }
}