import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@services/config/config.service';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GeoApiService {

  url: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.url = this.config.get('URL_SPUTNIK');
   }

  // 郵便番号検索
  getAddressByPostal(postal:string):any{
    if (!postal) { return Promise.resolve(null); }
    const params = {postal, method: "postal"}

    return lastValueFrom(this.http.get(this.url,{params})).then(result =>{
      const res: any = result;
      return res.location;
    })
  }
}
