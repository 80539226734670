import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { Jyuminhyou, Syoumeisyo } from '@jyuminhyou/services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle, faUserSlash, faTrashAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from '@services/config/config.service';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-jyuminhyou-cyouhyo',
  templateUrl: './jyuminhyou-cyouhyo.component.html',
  styleUrls: ['./jyuminhyou-cyouhyo.component.css', '../../jyuminhyou.component.css']
})
export class JyuminhyouCyouhyoComponent implements OnInit {
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageForm: FormGroup;
  cx101: Array<{ cd: number; name: string; }>;
  maisu = [];
  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;
  faTrashAlt = faTrashAlt;
  keys = []

  constructor(private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
    });

    for (var i = 1; i < 100; i++) {
      this.maisu.push(i);
    }

    this.cx101 = Object(config.get('cx101'));
  }

  ngOnInit(): void {
    this.pageForm = this.createForm();
    //一帳票は入力させる
    this.addCyouhyo();

    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      cyouhyos: this.fb.array([])
    });
  }

  ValidDuplex() {
    return (control: AbstractControl) => {
      if (this.isEmptyInputValue(control.value)) {
        return null;
      }

      this.keys.splice(0);
      this.pageForm.get('cyouhyos')['controls'].forEach((child, i) => {
        this.keys.push(this.pageForm.controls.cyouhyos.value[i].cyouhyo_cd);
      });

      var cnt = 0;
      for (var i = 0; i < this.keys.length; i++) {
        if (this.keys[i] == control.value) {
          cnt++;
        }
      }
      return cnt == 0 ? null : { 'duplex': true };
    }
  }

  isEmptyInputValue(val: any) {
    return val == null || val.length === 0;
  }

  existsSameValue(a) {
    var s = new Set(a);
    return s.size != a.length;
  }

  get cyouhyoForm(): FormGroup {
    return this.fb.group({
      cyouhyo_cd: [null, [Validators.required, this.ValidDuplex()]],
      maisu: [null, [Validators.required]],
      ichibu_name: [null, []]
    });
  }

  get cyouhyos(): FormArray {
    return this.pageForm.get('cyouhyos') as FormArray;
  }

  addCyouhyo() {
    this.cyouhyos.push(this.cyouhyoForm);
  }

  removeCyouhyo(index: number) {
    this.cyouhyos.removeAt(index);
  }

  getCyouhyo(): Syoumeisyo[] {
    const syoumeisyos = [];

    this.pageForm.get('cyouhyos')['controls'].forEach((child, i) => {
      const syoumeisyo = new Syoumeisyo(i + 1, this.pageForm.controls.cyouhyos.value[i].cyouhyo_cd, this.pageForm.controls.cyouhyos.value[i].maisu, this.pageForm.controls.cyouhyos.value[i].ichibu_name);
      syoumeisyos.push(syoumeisyo);
    });

    return syoumeisyos;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

}
