import { FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { GeoApiService } from '@services/geo-api/geo-api.service';
import { ConfigService } from '@services/config/config.service';

const ZENKAKU_REGEXP = /^[^\x01-\x7E\xA1-\xDF]+$/;
const ZENKAKU_ALL_REGEXP = /(^[^\x01-\x7E\xA1-\xDF]+$)|(^[a-zA-Zｦ-ﾝﾞﾟー－]+$)/;
const KATAKANA_REGEXP = /^[ァ-ヶー－　]+$/;
const KATAKANA_ALL_REGEXP = /^[ｦ-ﾝﾞﾟァ-ヶー－　]+$/;
const NUMERIC_REGEXP = /^[0-9]+$/;


// ハイフンの全角として長音記号 (U+30FC)と全角ハイフンマイナス (U+FF0D) を許容する。
// 半角ハイフンと呼ばれている記号は Unicode 上ではハイフンマイナスと呼ばれている。
const BANTI_REGEXP = /^[0-9\-０-９ー－]+$/;

function isEmptyInputValue(val: any) {
    return val == null || val.length === 0;
}

export class CustomValidator {
    static zenkaku(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return ZENKAKU_ALL_REGEXP.test(control.value) ? null : { 'zenkaku': true };
    }

    static katakana(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return KATAKANA_ALL_REGEXP.test(control.value) ? null : { 'katakana': true };
    }

    static banti(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return BANTI_REGEXP.test(control.value) ? null : { 'banti': true };
    }

    static numeric(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return NUMERIC_REGEXP.test(control.value) ? null : { 'numeric': true };
    }

    static minvalue(val): ValidationErrors | null {
        return (control: AbstractControl) => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            if (!NUMERIC_REGEXP.test(control.value)) {
                return null;
            }

            if (val > control.value) {
                return { 'minvalue': true };
            }
        };
    }

    static kannai(config: ConfigService): ValidationErrors | null {
        return (control: AbstractControl) => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            var zipcode: Array<string>;
            zipcode = config.get('zipcode');

            return (zipcode.includes(control.value)) ? null : { 'kannai': true };
        };
    }

    static doui(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return control.value ? null : { 'doui': true };
    }
}

export function ValidZipcode(
    geoApiService: GeoApiService,
    configService: ConfigService
) {
    return (formGroup: FormGroup) => {
        const zipcodeControl = formGroup.controls['zipcode'];
        const addr_kbnControl = formGroup.controls['addr_kbn'];

        if (isEmptyInputValue(zipcodeControl.value)) {
            return null;
        }
        const zipcode = zipcodeControl.value;
        if (zipcode.length != 7) {
            return null;
        }
        if (!NUMERIC_REGEXP.test(zipcode)) {
            return null;
        }

        var kannai_zipcode: Array<string>;
        kannai_zipcode = configService.get('zipcode');
        const addr_kbn = addr_kbnControl.value;
        if (addr_kbn == '1') {
            //管内
            if (!kannai_zipcode.includes(zipcode)) {
                zipcodeControl.setErrors({ 'kannai': true });
                return null;
            }
        }
        else if (addr_kbn == '2') {
            //管外
            if (kannai_zipcode.includes(zipcode)) {
                zipcodeControl.setErrors({ 'kangai': true });
                return null;
            }
        }
    };
}
