import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { CustomValidator, ValidZipcode } from '@utility/custom-validator';
import { alphanum2zenkaku, isDigit, hira2kata } from '@utility/string';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { BankCodeApiService, Bank as BankApiBank, Shiten as BankApiShiten } from '@services/bankcode-api/bankcode-api.service';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

type formNames =
  'bankCode' | 'bankName' |
  'branchCode' | 'branchName' |
  'accountType' | 'accountNumber' | 'accountOwner';
type formGroupType = { [P in formNames]: any[] };

@Component({
  selector: 'app-bankcode',
  templateUrl: './bankcode.component.html',
  styleUrls: ['./bankcode.component.css']
})


export class BankcodeComponent implements OnInit {
  @Input() fgName: string;
  parentForm: FormGroup;
  faExclamationTriangle = faExclamationTriangle;
  frmBankCode = 'bankCode' as const;
  frmBankName = 'bankName' as const;
  frmBranchCode = 'branchCode' as const;
  frmBranchName = 'branchName' as const;
  frmAccountNumber = 'accountNumber' as const;
  frmAccountOwner = 'accountOwner' as const;
  frmAccountType = 'accountType' as const;
  accountTypes: string[] = [];
  banks = [];
  banksBuffer = [];
  loadingBanks = false;
  shitens = [];
  shitensBuffer = [];
  loadingShitens = false;
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;

  constructor(private fgd: FormGroupDirective, private bankCodeApiService: BankCodeApiService, private fb: FormBuilder, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.accountTypes = ret.translations.MASTER.KOZASYUBETU;
    });
  }

  ngOnInit(): void {
    this.parentForm = this.fgd.form;

    const formGroup: formGroupType = {
      [this.frmBankCode]: [null, [Validators.required]],
      [this.frmBankName]: [null, [Validators.required]],
      [this.frmBranchCode]: [null, [Validators.required]],
      [this.frmBranchName]: [null, [Validators.required]],
      [this.frmAccountNumber]: [null, [Validators.required, CustomValidator.numeric, Validators.minLength(7)]],
      [this.frmAccountOwner]: [null, [Validators.required, CustomValidator.katakana]],
      [this.frmAccountType]: [null, [Validators.required]],
    };

    this.parentForm.addControl(this.fgName, this.fb.group(formGroup));

    this.bankCodeApiService.getBanks()
      .then(value => {
        this.banks = value;
        this.banksBuffer = this.banks.slice(0, this.bufferSize);
      });

    this.getControlByName(this.frmBranchCode).disable();
  }

  onScrollToEndBanks() {
    this.fetchMoreBanks();
  }

  onScrollBanks({ end }) {
    if (this.loadingBanks || this.banks.length <= this.banksBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.banksBuffer.length) {
      this.fetchMoreBanks();
    }
  }

  private fetchMoreBanks() {
    const len = this.banksBuffer.length;
    const more = this.banks.slice(len, this.bufferSize + len);
    this.loadingBanks = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loadingBanks = false;
      this.banksBuffer = this.banksBuffer.concat(more);
    }, 200)
  }

  onChangeBanks() {
    if (!this.getControlByName(this.frmBankCode).value) {
      this.getControlByName(this.frmBranchCode).patchValue([]);
      this.shitens = [];
      this.getControlByName(this.frmBranchCode).disable();
    }
    else {
      const bankName = this.banks.find((v) => v.code === this.getControlByName(this.frmBankCode).value);
      this.getControlByName(this.frmBankName).setValue(bankName.name);

      this.bankCodeApiService.getShitens(this.getControlByName(this.frmBankCode).value)
        .then(value => {
          this.shitens = value;
          this.shitensBuffer = this.shitens.slice(0, this.bufferSize);
          this.getControlByName(this.frmBranchCode).enable();
        });
    }
  }

  onChangeShitens() {
    if (this.getControlByName(this.frmBranchCode).value) {
      const branchName = this.shitens.find((v) => v.code === this.getControlByName(this.frmBranchCode).value);
      this.getControlByName(this.frmBranchName).setValue(branchName.name);
    }
  }

  onScrollToEndShitens() {
    this.fetchMoreShitens();
  }

  onScrollShitens({ end }) {
    if (this.loadingShitens || this.shitens.length <= this.shitensBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.shitensBuffer.length) {
      this.fetchMoreShitens();
    }
  }

  private fetchMoreShitens() {
    const len = this.shitensBuffer.length;
    const more = this.shitens.slice(len, this.bufferSize + len);
    this.loadingShitens = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loadingShitens = false;
      this.shitensBuffer = this.shitensBuffer.concat(more);
    }, 200)
  }

  getControlByName(key: formNames): AbstractControl {
    return (this.parentForm.get(this.fgName).get(key));
  }

}
