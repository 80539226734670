import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { SeikyuOption } from '@jyuminhyou/services/item';
import { CustomValidator } from '@utility/custom-validator';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from '@services/config/config.service';
import { Jyuminhyou } from '@jyuminhyou/services/item';

@Component({
  selector: 'app-jyuminhyou-option',
  templateUrl: './jyuminhyou-option.component.html',
  styleUrls: ['./jyuminhyou-option.component.css']
})
export class JyuminhyouOptionComponent implements OnInit, AfterViewInit {
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() item: Jyuminhyou;
  pageForm: FormGroup;
  youto = [];
  gaijinkisai = [];
  hideSonota = true;
  sonota: string;

  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;

  constructor(fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.pageForm = fb.group({
      youto: [null, [Validators.required]],//注意：JSON対応のため文字列での取扱
      zokugara: [null, [Validators.required]],//注意：JSON対応のため文字列での取扱
      mynumber: [null, [Validators.required]],//注意：JSON対応のため文字列での取扱
      honseki: [null, []],
      gaijinkisaiObjects: [null, []], //Object形式
      gaijinFlg: false,
      sonota: [null, []]
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.youto = ret.translations.JYUMINHYOU.OPTION.SEL_YOUTO;
      this.gaijinkisai = ret.translations.JYUMINHYOU.OPTION.SEL_GAIJINKISAI;
      this.sonota = ret.translations.JYUMINHYOU.OPTION.SONOTA;
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit() {
    //はじめは外国人はチェックしない
    this.pageForm.controls.gaijinkisaiObjects.disable();

    this.pageForm.controls.zokugara.setValue("false");
    this.pageForm.controls.mynumber.setValue("false");
    this.pageForm.controls.honseki.setValue("false");
  }

  getOption(): SeikyuOption {

    const youtoData = this.pageForm.controls.youto.value.concat();
    const sonotaVal = this.pageForm.controls.sonota.value == null ? "" : this.pageForm.controls.sonota.value;
    if (sonotaVal != "")
      youtoData.forEach((element, index) => {
        if (element == this.sonota) {
          youtoData[index] = youtoData[index] + "(" + sonotaVal + ")";
        }
      });

    const hide = this.isHide();
    const zokugara = hide ? "false" : this.pageForm.controls.zokugara.value;
    const honseki = hide ? "false" : this.pageForm.controls.honseki.value;
    const gaijinkisaiObjects = hide ? [] : this.pageForm.controls.gaijinkisaiObjects.value;

    const seikyuOption = new SeikyuOption(
      youtoData,
      zokugara,
      this.pageForm.controls.mynumber.value,
      honseki,
      gaijinkisaiObjects,
    );

    return seikyuOption;
  }

  onGaijin() {
    if (this.pageForm.controls.gaijinFlg.value) {
      //外国人の場合
      this.pageForm.controls.gaijinkisaiObjects.enable();
    }
    else {
      //外国人で無い場合
      this.pageForm.controls.gaijinkisaiObjects.disable();
    }

    //値をクリア
    this.pageForm.controls.gaijinkisaiObjects.setValue('');
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  isHide() {
    return this.item.sj102s.filter((element) => !((element.sj102_cyouhyo_cd == '131') || (element.sj102_cyouhyo_cd == '132'))).length == 0 ? true : false;
  }

  onChangeYouto() {
    if (this.pageForm.controls.youto.value == null) {
      return true;
    };
    this.hideSonota = this.pageForm.controls.youto.value.filter(youto => youto === this.sonota).length == 0 ? true : false;
    if (this.hideSonota) {
      this.pageForm.controls.sonota.setValue('');
    }
  }

  selectAll() {
    this.pageForm.controls.gaijinkisaiObjects.setValue(this.gaijinkisai);
  }

  unselectAll() {
    this.pageForm.controls.gaijinkisaiObjects.setValue([]);
  }

}
