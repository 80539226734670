<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'INKANTOUROKU.HOSHO.TITLE_HOSHO' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'INKANTOUROKU.HOSHO.SETUMEI_HOSHO' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'INKANTOUROKU.HOSHO.HOSHONIN' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiHosho}} ckName={{ckNameShimeiHosho}} defaltKbn="1"></app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcodeHosho}} ckName={{ckNameZipcodeHosho}} defaltKbn="3"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.hosho_telno.hasError('numeric') && (pageForm.controls.hosho_telno.touched || pageForm.controls.hosho_telno.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.hosho_telno.invalid && (pageForm.controls.hosho_telno.touched || pageForm.controls.hosho_telno.dirty)"
          class="input-text input-tel" type="text" maxlength="11" name="hosho_telno"
          [formControl]="pageForm.controls.hosho_telno" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'INKANTOUROKU.HOSHO.HOSHO_TOUROKUNO' | translate }}</label>
      </div>
      <ng-container
        *ngIf="pageForm.controls.hosho_touroku_no.hasError('numeric') && (pageForm.controls.hosho_touroku_no.touched || pageForm.controls.hosho_touroku_no.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'INKANTOUROKU.HOSHO.HOSHO_TOUROKUNO_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <ng-container
        *ngIf="pageForm.controls.hosho_touroku_no.hasError('required') && (pageForm.controls.hosho_touroku_no.touched || pageForm.controls.hosho_touroku_no.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'INKANTOUROKU.HOSHO.HOSHO_TOUROKUNO_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.hosho_touroku_no.invalid && (pageForm.controls.hosho_touroku_no.touched || pageForm.controls.hosho_touroku_no.dirty)"
          class="input-text input-inkanno" type="text" name="hosho_touroku_no" [formControl]="pageForm.controls.hosho_touroku_no" maxlength="10">
      </div>
      <div class="box-setumei">
        <a>{{ 'INKANTOUROKU.HOSHO.HOSHO_TOUROKUNO_SETUMEI' | translate }}</a>
      </div>
    </div>

  </div>
</div>