import { Injectable, ViewContainerRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  viewContainerRef: ViewContainerRef;
  subject: Subject<any>;
  currentComponent = null;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public openModal(componetTemplate: any, viewContainerRef: ViewContainerRef, param: any) {

    this.subject = new Subject();

    if (this.viewContainerRef) this.viewContainerRef.clear();
    if (this.currentComponent) this.currentComponent.destroy();

    this.viewContainerRef = viewContainerRef;

    let componentRef = this.createComponent(componetTemplate, viewContainerRef);

    componentRef.instance.data = param;
    param.click = this.retPublish();

    this.currentComponent = componentRef;

    return this.subject.asObservable();
  }
  private createComponent(componentTemplate: any, viewContainerRef: ViewContainerRef): ComponentRef<any> {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentTemplate);
    return viewContainerRef.createComponent(componentFactory);
  }

  private retPublish() {
    let subject = this.subject;
    return (retVal: string) => {
      try {
        subject.next(retVal);
        subject.complete();
      } catch (err) {
        subject.error(err);
      }
    }
  }

  public closeModal(){
    if (this.viewContainerRef) this.viewContainerRef.clear();
    if (this.currentComponent) this.currentComponent.destroy();
  }
}