import { Component, OnInit, Input } from '@angular/core';
import { EncryptService } from '@services/encrypt/encrypt.service';
import { ConfigService } from '@services/config/config.service';
import * as QRCode from '../../../../../node_modules/qrcode';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faPlusCircle, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { JidoTeate, Jido } from '@jidoteate/services/item';

@Component({
  selector: 'app-jidoteate-qr',
  templateUrl: './jidoteate-qr.component.html',
  styleUrls: ['./jidoteate-qr.component.css']
})
export class JidoteateQrComponent implements OnInit {
  @Input() item: JidoTeate;
  mochimono: any;
  mochimono_file_header: string;
  title: string;
  image_url;
  original_qr_size = 200;
  font_size = 10;
  margin = 30;
  filename: string;
  filenameMochimono: string;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faDownload = faDownload;
  isWindows: boolean;
  qr_error: string;

  constructor(private encryptService: EncryptService, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.mochimono = ret.translations.JIDOTEATE.QR.MOCHIMONO_LIST;
      this.title = ret.translations.JIDOTEATE.TITLE_MAIN;
      this.mochimono_file_header = ret.translations.JIDOTEATE.QR.MOCHIMONO_FILE_HEADER;
      this.qr_error = ret.translations.COMMON.QR_ERROR;
    });
  }

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    this.isWindows = userAgent.toLowerCase().includes('windows');
  }

  createQRText(): boolean {
    const qrStr =
      // 帳票番号
      'fk201'
      // 請求者
      + ',' + this.item.fk201_snsi_ymd
      + ',' + this.item.fk201_snsi_name_kj
      + ',' + this.item.fk201_snsi_name_kn
      + ',' + this.item.fk201_snsi_umare_ymd
      + ',' + this.item.fk201_snsi_yubinno
      + ',' + this.item.fk201_snsi_addr_ken
      + ',' + this.item.fk201_snsi_addr_city
      + ',' + this.item.fk201_snsi_addr_town
      + ',' + this.item.fk201_snsi_addr_banti
      + ',' + this.item.fk201_snsi_addr_ktgk
      + ',' + this.item.fk201_snsi_11_yubinno
      + ',' + this.item.fk201_snsi_11_addr_ken
      + ',' + this.item.fk201_snsi_11_addr_city
      + ',' + this.item.fk201_snsi_11_addr_town
      + ',' + this.item.fk201_snsi_11_addr_banti
      + ',' + this.item.fk201_snsi_11_addr_ktgk
      + ',' + this.item.fk201_snsi_job
      + ',' + this.item.fk201_snsi_telno
      + ',' + this.item.fk201_snsi_sex
      + ',' + this.item.fk201_snsi_zokugara
      + ',' + this.item.fk201_snsi_nenkin
      + ',' + this.item.fk201_snsi_haigu

      // 配偶者
      + ',' + this.item.fk201_haigu_name_kj
      + ',' + this.item.fk201_haigu_name_kn
      + ',' + this.item.fk201_haigu_umare_ymd
      + ',' + this.item.fk201_haigu_yubinno
      + ',' + this.item.fk201_haigu_addr_ken
      + ',' + this.item.fk201_haigu_addr_city
      + ',' + this.item.fk201_haigu_addr_town
      + ',' + this.item.fk201_haigu_addr_banti
      + ',' + this.item.fk201_haigu_addr_ktgk
      + ',' + this.item.fk201_haigu_11_yubinno
      + ',' + this.item.fk201_haigu_11_addr_ken
      + ',' + this.item.fk201_haigu_11_addr_city
      + ',' + this.item.fk201_haigu_11_addr_town
      + ',' + this.item.fk201_haigu_11_addr_banti
      + ',' + this.item.fk201_haigu_11_addr_ktgk
      + ',' + this.item.fk201_haigu_job
      + ',' + this.item.fk201_haigu_dokyo

      // 振込口座
      + ',' + this.item.fk201_koza_bank_cd
      + ',' + this.item.fk201_koza_bank_kj
      + ',' + this.item.fk201_koza_siten_cd
      + ',' + this.item.fk201_koza_siten_kj
      + ',' + this.item.fk201_koza_syubetu
      + ',' + this.item.fk201_koza_no
      + ',' + this.item.fk201_koza_meigi_kn

      // 児童
      + ',' + this.createQRJido();

    const qrText = this.encryptService.getQREncrypt(qrStr);
    if (qrText.length > 2000) {
      alert(this.qr_error);
      return false;
    }

    QRCode.toDataURL(qrText, { scale: 2, errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      var img = document.getElementById('qr-image');
      (img as any).src = url;
      (img as any).alt = "jidoteate-" + getFormattedCurrentDateJST('yyyyMMddHHmmss');
    });

    this.filename = "jidoteate-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".png";
    this.filenameMochimono = "jidoteate-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".txt";
    return true;
  }

  downloadCanvas() {
    var img = document.getElementById('qr-image');
    let link = document.getElementById('hiddenLink');
    (link as any).href = (img as any).src;
    link.click();
  }

  createQRJido(): string {
    const values: Array<string> = [];
    values.push(this.item.fk202s.length.toString());

    this.item.fk202s.forEach((jido) => values.push(
      jido.fk202_name_kj,
      jido.fk202_name_kn,
      jido.fk202_umare_ymd,
      jido.fk202_zokugara,
      jido.fk202_dokyo,
      jido.fk202_job,
      jido.fk202_kango,
      jido.fk202_seikei,
    ));

    return values.join(',');
  }

  downloadMochimono(): void {
    const lines: Array<string> = [];
    lines.push(this.mochimono_file_header);
    lines.push('');
    const mochimonoList: Array<{ title: string; meisai: Array<string>; }> = this.mochimono;
    mochimonoList.forEach(u => {
      lines.push(`・${u.title}`);
      u.meisai.forEach(v => lines.push(v));
      lines.push('');
    });

    const textdata = lines.join(this.isWindows ? '\r\n' : '\n');

    const link = document.getElementById('hiddenMochimonoLink') as HTMLAnchorElement;
    link.href = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(textdata)));
    link.click();
  }
}
