import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Iryokodomo, Shinsei } from '@iryokodomo/services/item';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { ShimeiComponent, Shimei } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'app-iryokodomo-shinsei',
  templateUrl: './iryokodomo-shinsei.component.html',
  styleUrls: ['./iryokodomo-shinsei.component.css', '../../iryokodomo.component.css']
})
export class IryokodomoShinseiComponent implements OnInit, AfterViewInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChildren(DatepickerComponent) datepickerComponents: QueryList<DatepickerComponent>;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;

  fgNameDatepickerShinsei = 'datepickerShinsei';
  fgNameShimeiShinsei = 'shimeiShinsei';
  ckNameShimeiShinsei = 'shinsei-shimeiShinsei';
  fgNameZipcodeShinsei = 'zipcodeShinsei';
  ckNameZipcodeShinsei = 'shinsei-zipcodeShinsei';
  fgNameDatepickerBirthday = 'datepickerBirthday';

  zokugara: Array<{ value: string; label: string; }>;
  zokugaraMaster: any;
  nenkin: string[];
  faExclamationTriangle = faExclamationTriangle;

  constructor(fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.pageForm = fb.group({
      //datepickerShinsei（datepicker コンポーネントで追加）
      //shimeiShinsei（shimei コンポーネントで追加）
      //zipcodeShinsei（zipcode コンポーネントで追加）
      snsi_home_tel_no: [null, [CustomValidator.numeric]],
      //snsi_cell_tel_no: [null, [CustomValidator.numeric]],
      //datepickerBirthday（datepicker コンポーネントで追加）
      snsi_zokugara: [null, [Validators.required]],
      snsi_zokugara_sonota: [null, []],
      //snsi_name_tusho: [null, []],
      snsi_nenkin: [null, [Validators.required]],
    });
    this.zokugara = Object(config.get("ZOKUGARA"));

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.zokugaraMaster = ret.translations.MASTER.ZOKUGARA;
      this.nenkin = ret.translations.MASTER.NENKIN;
    });
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit() {
  }

  getShinsei(): Shinsei {
    //const datepickerShinsei = String(this.pageForm.controls.datepickerShinsei.get('year').value) + '-' + String(('0' + (this.pageForm.controls.datepickerShinsei.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.datepickerShinsei.get('day').value)).slice(-2));
    const shimeiShinsei = new Shimei(this.pageForm.controls.shimeiShinsei.get('sei_kj').value, this.pageForm.controls.shimeiShinsei.get('mei_kj').value, this.pageForm.controls.shimeiShinsei.get('sei_kn').value, this.pageForm.controls.shimeiShinsei.get('mei_kn').value);
    const addressShinsei = new Address(this.pageForm.controls.zipcodeShinsei.get('address').value.ken, this.pageForm.controls.zipcodeShinsei.get('address').value.city, this.pageForm.controls.zipcodeShinsei.get('address').value.town);
    const zipcodeShinsei = new ZipcodeAddress(this.pageForm.controls.zipcodeShinsei.get('zipcode').value, addressShinsei, this.pageForm.controls.zipcodeShinsei.get('banti').value, this.pageForm.controls.zipcodeShinsei.get('ktgk').value);
    const datepickerBirthday = String(this.pageForm.controls.datepickerBirthday.get('year').value) + '-' + String(('0' + (this.pageForm.controls.datepickerBirthday.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.datepickerBirthday.get('day').value)).slice(-2));
    const zokugara = this.pageForm.controls.snsi_zokugara_sonota.value != "" && this.pageForm.controls.snsi_zokugara_sonota.value != null ?  this.pageForm.controls.snsi_zokugara_sonota.value
    : this.zokugaraMaster[this.pageForm.controls.snsi_zokugara.value];

    const shinsei = new Shinsei(
      null, //datepickerShinsei
      zipcodeShinsei,
      this.pageForm.controls.snsi_home_tel_no.value,
      null, //this.pageForm.controls.cell_home_tel_no.value,
      shimeiShinsei,
      datepickerBirthday,
      zokugara,
      null, //this.pageForm.controls.snsi_name_tusho.value,
      this.pageForm.controls.snsi_nenkin.value,
    );
    return shinsei;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  onZokugaraChange(){
    if (this.pageForm.controls.snsi_zokugara.value!=='99'){
      this.pageForm.controls.snsi_zokugara_sonota.setValue('');
    }
  }

}
