import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Idosya, Zenjyusyo } from '@jyukiido/services/item';
import { Shimei } from '@components/shimei/shimei.component';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { ShimeiComponent } from '@components/shimei/shimei.component';
import { faExclamationTriangle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { concatSeiMei } from '@utility/format';

@Component({
  selector: 'app-jyukiido-zenjyusyo',
  templateUrl: './jyukiido-zenjyusyo.component.html',
  styleUrls: ['./jyukiido-zenjyusyo.component.css', '../../jyukiido.component.css']
})
export class JyukiidoZenjyusyoComponent implements OnInit {
  @ViewChild(ShimeiComponent) shimeiComponent: ShimeiComponent;
  @ViewChild(ZipcodeComponent) zipcodeComponent: ZipcodeComponent;
  @Input() idosyas: Idosya[];
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  shimei: Shimei;
  pageForm: FormGroup;
  fgNameShimei = 'shimei';
  ckNameShimei = 'zenjyusyo-shimei';
  fgNameZipcode = 'zipcode';
  ckNameZipcode = 'zenjyusyo-zipcode';
  faExclamationTriangle = faExclamationTriangle;
  faEdit = faEdit;

  constructor(private fb: FormBuilder) {
    this.pageForm = fb.group({
      setainushi: [null, [Validators.required]],
      editFlg: false
      //shimei（shimei コンポーネントで追加）
      //zipcode（zipcode コンポーネントで追加）
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  getZenjyusyo(): Zenjyusyo {
    const shimeiData = new Shimei(this.pageForm.controls.shimei.get('sei_kj').value, this.pageForm.controls.shimei.get('mei_kj').value, this.pageForm.controls.shimei.get('sei_kn').value, this.pageForm.controls.shimei.get('mei_kn').value)
    const address = new Address(this.pageForm.controls.zipcode.get('address').value.ken, this.pageForm.controls.zipcode.get('address').value.city, this.pageForm.controls.zipcode.get('address').value.town)
    const zipcodeAddress = new ZipcodeAddress(this.pageForm.controls.zipcode.get('zipcode').value, address, this.pageForm.controls.zipcode.get('banti').value, this.pageForm.controls.zipcode.get('ktgk').value)
    const zenjyusyo = new Zenjyusyo(zipcodeAddress, shimeiData)

    return zenjyusyo;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  setDefaltKbn(addr_kbn) {
    this.zipcodeComponent.setDefaltKbn(addr_kbn);
  }

  filterAddrKbns(addr_kbns) {
    this.zipcodeComponent.showKbns = addr_kbns;
  }

  clearSetaiin() {
    this.pageForm.controls.setainushi.setValue(null);
    this.pageForm.controls.shimei.get('sei_kj').setValue('');
    this.pageForm.controls.shimei.get('mei_kj').setValue('');
    this.pageForm.controls.shimei.get('sei_kn').setValue('');
    this.pageForm.controls.shimei.get('mei_kn').setValue('');
    this.pageForm.controls.shimei.get('shimei_kj_all').setValue('');
    this.pageForm.controls.shimei.get('shimei_kn_all').setValue('');
  }

  onChangeSetainushi() {
    const shimei_kbn = this.pageForm.controls.setainushi.value.ido_smi.mei_kj != "" ? '1' : '2';
    this.pageForm.controls.shimei.get('shimei_kbn').setValue(shimei_kbn);
    this.shimeiComponent.setValidate();
    if (this.pageForm.controls.shimei.get('shimei_kbn').value == '1') {
      //日本人
      this.pageForm.controls.shimei.get('sei_kj').setValue(this.pageForm.controls.setainushi.value.ido_smi.sei_kj);
      this.pageForm.controls.shimei.get('mei_kj').setValue(this.pageForm.controls.setainushi.value.ido_smi.mei_kj);
      this.pageForm.controls.shimei.get('sei_kn').setValue(this.pageForm.controls.setainushi.value.ido_smi.sei_kn);
      this.pageForm.controls.shimei.get('mei_kn').setValue(this.pageForm.controls.setainushi.value.ido_smi.mei_kn);
    }
    else if (this.pageForm.controls.shimei.get('shimei_kbn').value == '2') {
      //外国人
      this.pageForm.controls.shimei.get('shimei_kj_all').setValue(concatSeiMei(this.pageForm.controls.setainushi.value.ido_smi.sei_kj, this.pageForm.controls.setainushi.value.ido_smi.mei_kj));
      this.pageForm.controls.shimei.get('shimei_kn_all').setValue(concatSeiMei(this.pageForm.controls.setainushi.value.ido_smi.sei_kn, this.pageForm.controls.setainushi.value.ido_smi.mei_kn));
    }
  }

  onEdit() {
    if (this.pageForm.controls.editFlg.value) {
      this.pageForm.controls.setainushi.disable();
    }
    else {
      this.pageForm.controls.setainushi.enable();
    }
    //クリア
    this.clearSetaiin();
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix;
  }

}
