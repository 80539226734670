import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { Shimei, ShimeiComponent } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SexComponent } from '@components/sex/sex.component';
import { Seikyusha } from '@jidoteate/services/item';
import { getShimeiFromShimeiForm, getFormattedyyMMddFromDatepicker, getZipcodeAddressFromZipcodeForm } from '@utility/format';
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'app-jidoteate-seikyusha',
  templateUrl: './jidoteate-seikyusha.component.html',
  styleUrls: ['./jidoteate-seikyusha.component.css', '../../jidoteate.component.css']
})
export class JidoteateSeikyushaComponent implements OnInit, AfterViewInit {
  @ViewChild(ShimeiComponent) shimeiComponent: ShimeiComponent;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @ViewChild(SexComponent) sexComponent: SexComponent;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  kankei: string[];
  nenkin: string[];
  job: string[];
  zokugara: Array<{ value: string; label: string; }>;
  zokugaraMaster: any;
  fgNameShimei = 'shimei';
  ckNameShimei = 'seikyusha-shimei';
  fgNameDatepickerSeikyu = 'seikyuDatepicker';
  fgNameDatepickerBirthday = 'birthDatepicker';
  fgNameZipcode = 'zipcode';
  ckNameZipcode = 'seikyusha-zipcode';
  fgName11jitenZipcode = 'zipcode11';
  ckName11jitenZipcode = 'seikyusha-zipcode11';
  fgNameSex = 'sex';
  rdNameSex = 'seikyusha-sex';
  faExclamationTriangle = faExclamationTriangle;
  hideSonota = false;

  constructor(private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.pageForm = fb.group({
      snsi_telno: [null, [CustomValidator.numeric]],
      addr11flag: false,
      snsi_nenkin: [null, [Validators.required]],
      snsi_job: [null, [Validators.required]],
      snsi_zokugara: [null, [Validators.required]],
      snsi_zokugara_sonota: [null, []],
    });
    this.zokugara = Object(config.get("ZOKUGARA"));

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.nenkin = ret.translations.MASTER.NENKIN;
      this.job = ret.translations.MASTER.JOB;
      this.zokugaraMaster = ret.translations.MASTER.ZOKUGARA;
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit(): void {
    this.pageForm.controls.zipcode11.disable();
  }

  getSeikyusha(): Seikyusha {
    const seikyuDatepicker = String(this.pageForm.controls.seikyuDatepicker.get('year').value) + '-' + String(('0' + (this.pageForm.controls.seikyuDatepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.seikyuDatepicker.get('day').value)).slice(-2));
    const shimeiData = new Shimei(this.pageForm.controls.shimei.get('sei_kj').value, this.pageForm.controls.shimei.get('mei_kj').value, this.pageForm.controls.shimei.get('sei_kn').value, this.pageForm.controls.shimei.get('mei_kn').value);
    const birthDatepicker = String(this.pageForm.controls.birthDatepicker.get('year').value) + '-' + String(('0' + (this.pageForm.controls.birthDatepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.birthDatepicker.get('day').value)).slice(-2));
    const address = new Address(this.pageForm.controls.zipcode.get('address').value.ken, this.pageForm.controls.zipcode.get('address').value.city, this.pageForm.controls.zipcode.get('address').value.town);
    const zipcodeAddress = new ZipcodeAddress(this.pageForm.controls.zipcode.get('zipcode').value, address, this.pageForm.controls.zipcode.get('banti').value, this.pageForm.controls.zipcode.get('ktgk').value);
    const address11 = new Address(this.pageForm.controls.zipcode11.get('address').value.ken, this.pageForm.controls.zipcode11.get('address').value.city, this.pageForm.controls.zipcode11.get('address').value.town);
    const zipcodeAddress11 = new ZipcodeAddress(this.pageForm.controls.zipcode11.get('zipcode').value, address11, this.pageForm.controls.zipcode11.get('banti').value, this.pageForm.controls.zipcode11.get('ktgk').value);
    const zokugara = this.pageForm.controls.snsi_zokugara_sonota.value != "" && this.pageForm.controls.snsi_zokugara_sonota.value != null ?  this.pageForm.controls.snsi_zokugara_sonota.value
    : this.zokugaraMaster[this.pageForm.controls.snsi_zokugara.value];

    const seikyusha = new Seikyusha(
      seikyuDatepicker,
      shimeiData,
      birthDatepicker,
      zipcodeAddress,
      zipcodeAddress11,
      this.pageForm.controls.snsi_job.value,
      this.pageForm.controls.snsi_telno.value,
      this.pageForm.controls.sex.get('cd').value,
      zokugara,
      this.pageForm.controls.snsi_nenkin.value
    );

    return seikyusha;
  }

  onAddr11Checked(): void {
    if (this.pageForm.controls.addr11flag.value) {
      this.pageForm.controls.zipcode11.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).setValidate();
    }
    else {
      this.pageForm.controls.zipcode11.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).setDisable();
    }

    // 値をクリア
    this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).clear();
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  onZokugaraChange(){
    if (this.pageForm.controls.snsi_zokugara.value!=='99'){
      this.pageForm.controls.snsi_zokugara_sonota.setValue('');
    }
  }

}
