<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JIDOTEATE.KOZA.TITLE_KOZA' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <div class="label-msg"><label>{{ 'COMMON.KOZA_FURIKOMI' | translate }}</label></div>
      </div>

      <form [formGroup]="pageForm">
        <app-bankcode fgName={{fgNameBankcode}}></app-bankcode>
      </form>
    </div>

  </div>
</div>