<div class="box-kakunin-title">
    <h4>{{ 'COMMON.KAKUNIN.TITLE_KAKUNIN' | translate }}</h4>
</div>
<div class="box-kakunin">
    <div class="box-koumoku">
        <h4>{{ 'INKANSYOUMEI.SHINSEI.TITLE_KISAI' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.sj111_touroku_no}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.MAISU' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.sj111_maisuu}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.SHIMEI' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.sj111_snsi_name_kj}}</a></div>
                    <div class="is-hidden"><a>/</a></div>
                    <div><a>{{item.sj111_snsi_name_kn}}</a></div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.ADDR' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.sj111_snsi_addr_ken}}{{item.sj111_snsi_addr_city}}{{item.sj111_snsi_addr_town}}{{item.sj111_snsi_addr_banti}}
                {{item.sj111_snsi_addr_ktgk}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.TEL' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.sj111_snsi_telno}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.BIRTHDAY' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.sj111_snsi_umare_ymd}}
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <!--来庁者-->
        <h4>{{ 'COMMON.RAICYOUSYA' | translate }}</h4>
    </div>
    <div class="meisai">
        <ng-container *ngIf="item.sj111_dairi_addr_ken==''">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj111_snsi_name_kj}}</a></div>
                        <ng-container *ngIf="item.sj111_snsi_name_kn">
                            <div class="is-hidden"><a>/</a></div>
                            <div><a>{{item.sj111_snsi_name_kn}}</a></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.sj111_dairi_addr_ken">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj111_dairi_name_kj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.sj111_dairi_name_kn}}</a></div>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div>
                        <a>{{item.sj111_dairi_addr_ken}}{{item.sj111_dairi_addr_city}}{{item.sj111_dairi_addr_town}}{{item.sj111_dairi_addr_banti}}
                            {{item.sj111_dairi_addr_ktgk}}</a>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj111_dairi_telno}}</a></div>
                </div>
            </div>
        </ng-container>
    </div>
</div>