import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export type JidoTeate = {
    fk201_snsi_bng: number;
    fk201_snsi_ymd: string;
    fk201_snsi_name_kj: string;
    fk201_snsi_name_kn: string;
    fk201_snsi_umare_ymd: string;
    fk201_snsi_yubinno: string;
    fk201_snsi_addr_ken: string;
    fk201_snsi_addr_city: string;
    fk201_snsi_addr_town: string;
    fk201_snsi_addr_banti: string;
    fk201_snsi_addr_ktgk: string;
    fk201_snsi_11_yubinno: string;
    fk201_snsi_11_addr_ken: string;
    fk201_snsi_11_addr_city: string;
    fk201_snsi_11_addr_town: string;
    fk201_snsi_11_addr_banti: string;
    fk201_snsi_11_addr_ktgk: string;
    fk201_snsi_job: string;
    fk201_snsi_telno: string;
    fk201_snsi_sex: string;
    fk201_snsi_zokugara: string;
    fk201_snsi_nenkin: string;
    fk201_snsi_haigu: string;
    fk201_haigu_name_kj: string;
    fk201_haigu_name_kn: string;
    fk201_haigu_umare_ymd: string;
    fk201_haigu_yubinno: string;
    fk201_haigu_addr_ken: string;
    fk201_haigu_addr_city: string;
    fk201_haigu_addr_town: string;
    fk201_haigu_addr_banti: string;
    fk201_haigu_addr_ktgk: string;
    fk201_haigu_11_yubinno: string;
    fk201_haigu_11_addr_ken: string;
    fk201_haigu_11_addr_city: string;
    fk201_haigu_11_addr_town: string;
    fk201_haigu_11_addr_banti: string;
    fk201_haigu_11_addr_ktgk: string;
    fk201_haigu_job: string;
    fk201_haigu_dokyo: string;
    fk201_koza_bank_cd: string;
    fk201_koza_bank_kj: string;
    fk201_koza_siten_cd: string;
    fk201_koza_siten_kj: string;
    fk201_koza_syubetu: string;
    fk201_koza_no: string;
    fk201_koza_meigi_kn: string;
    fk202s: Jido[];
};

export type Jido = {
    fk202_id: number;
    fk202_name_kj: string;
    fk202_name_kn: string;
    fk202_umare_ymd: string;
    fk202_zokugara: string;
    fk202_dokyo: string;
    fk202_job: string;
    fk202_kango: string;
    fk202_seikei: string;
};

export class Seikyusha {
    snsi_ymd: string;
    snsi_smi: Shimei;
    snsi_umare_ymd: string;
    snsi_address: ZipcodeAddress;
    snsi_11_address: ZipcodeAddress;
    snsi_job: string;
    snsi_telno: string;
    snsi_sex: string;
    snsi_zokugara: string;
    snsi_nenkin: string;
    constructor(snsi_ymd: string,
        snsi_smi: Shimei,
        snsi_umare_ymd: string,
        snsi_address: ZipcodeAddress,
        snsi_11_address: ZipcodeAddress,
        snsi_job: string,
        snsi_telno: string,
        snsi_sex: string,
        snsi_zokugara: string,
        snsi_nenkin: string
    ) {
        this.snsi_ymd = snsi_ymd;
        this.snsi_smi = snsi_smi;
        this.snsi_umare_ymd = snsi_umare_ymd;
        this.snsi_address = snsi_address
        this.snsi_11_address = snsi_11_address;
        this.snsi_job = snsi_job;
        this.snsi_telno = snsi_telno;
        this.snsi_sex = snsi_sex;
        this.snsi_zokugara = snsi_zokugara;
        this.snsi_nenkin = snsi_nenkin;
    }
}

export class Haigusha {
    snsi_haigu: string;
    haigu_smi: Shimei;
    haigu_umare_ymd: string;
    haigu_address: ZipcodeAddress;
    haigu_11_address: ZipcodeAddress;
    haigu_job: string;
    haigu_dokyo: string;
    constructor(snsi_haigu: string,
        haigu_smi: Shimei,
        haigu_umare_ymd: string,
        haigu_address: ZipcodeAddress,
        haigu_11_address: ZipcodeAddress,
        haigu_job: string,
        haigu_dokyo: string
    ) {
        this.snsi_haigu = snsi_haigu;
        this.haigu_smi = haigu_smi;
        this.haigu_umare_ymd = haigu_umare_ymd;
        this.haigu_address = haigu_address;
        this.haigu_11_address = haigu_11_address;
        this.haigu_job = haigu_job;
        this.haigu_dokyo = haigu_dokyo;
    }
};

export class JidoMeisai {
    id: number;
    shimei: Shimei;
    umare_ymd: string;
    zokugara: string;
    doukyo: string;
    job: string;
    kango: string;
    seikei: string;
    constructor(id: number,
        shimei: Shimei,
        umare_ymd: string,
        zokugara: string,
        doukyo: string,
        job: string,
        kango: string,
        seikei: string
    ) {
        this.id = id;
        this.shimei = shimei;
        this.umare_ymd = umare_ymd;
        this.zokugara = zokugara;
        this.doukyo = doukyo;
        this.job = job;
        this.kango = kango;
        this.seikei = seikei;
    }
};

export class Koza {
    koza_bank_cd: string;
    koza_bank_kj: string;
    koza_siten_cd: string;
    koza_siten_kj: string;
    koza_syubetu: string;
    koza_no: string;
    koza_meigi_kn: string;
    constructor(koza_bank_cd: string,
        koza_bank_kj: string,
        koza_siten_cd: string,
        koza_siten_kj: string,
        koza_syubetu: string,
        koza_no: string,
        koza_meigi_kn: string
    ) {
        this.koza_bank_cd = koza_bank_cd;
        this.koza_bank_kj = koza_bank_kj;
        this.koza_siten_cd = koza_siten_cd;
        this.koza_siten_kj = koza_siten_kj;
        this.koza_syubetu = koza_syubetu;
        this.koza_no = koza_no;
        this.koza_meigi_kn = koza_meigi_kn;
    }
};