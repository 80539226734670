import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Inkansyoumei, Shinsei } from '@inkansyoumei/services/item';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { ShimeiComponent, Shimei } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inkansyoumei-shinsei',
  templateUrl: './inkansyoumei-shinsei.component.html',
  styleUrls: ['./inkansyoumei-shinsei.component.css', '../../inkansyoumei.component.css']
})
export class InkansyoumeiShinseiComponent implements OnInit, AfterViewInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  fgNameShimeiTouroku = 'shimeiTouroku';
  ckNameShimeiTouroku = 'shinsei-shimeiTouroku';
  fgNameDatepickerTouroku = 'datepickerTouroku';
  fgNameZipcodeTouroku = 'zipcodeTouroku';
  ckNameZipcodeTouroku = 'shinsei-zipcodeTouroku';
  fgNameShimeiDairi = 'shimeiDairi';
  ckNameShimeiDairi = 'shinsei-shimeiDairi';
  fgNameZipcodeDairi = 'zipcodeDairi';
  ckNameZipcodeDairi = 'shinsei-zipcodeDairi';
  maisu = [];
  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;
  rdName = 'honnin_dairi';

  constructor(fb: FormBuilder) {
    this.pageForm = fb.group({
      touroku_no: [null, [Validators.required, CustomValidator.numeric]],
      maisuu: [null, [Validators.required, CustomValidator.numeric, , CustomValidator.minvalue(1)]],
      //shimeiTouroku（shimei コンポーネントで追加）
      //zipcodeTouroku（zipcode コンポーネントで追加）
      //datepickerTouroku（datepicker コンポーネントで追加）
      snsi_telno: [null, [CustomValidator.numeric]],
      dairiFlg: [null, [Validators.required]],
      //shimeiDairi（shimei コンポーネントで追加）
      //zipcodeDairi（zipcode コンポーネントで追加）
      dairi_telno: [null, [CustomValidator.numeric]],
    });

    for (var i = 1; i < 100; i++) {
      this.maisu.push(i);
    }
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit() {
    //はじめは代理はチェックしない
    this.pageForm.controls.dairi_telno.disable();
    this.pageForm.controls.shimeiDairi.disable();
    this.pageForm.controls.zipcodeDairi.disable();
  }

  getShinsei(): Shinsei {
    const shimeiTouroku = new Shimei(this.pageForm.controls.shimeiTouroku.get('sei_kj').value, this.pageForm.controls.shimeiTouroku.get('mei_kj').value, this.pageForm.controls.shimeiTouroku.get('sei_kn').value, this.pageForm.controls.shimeiTouroku.get('mei_kn').value);
    const addressTouroku = new Address(this.pageForm.controls.zipcodeTouroku.get('address').value.ken, this.pageForm.controls.zipcodeTouroku.get('address').value.city, this.pageForm.controls.zipcodeTouroku.get('address').value.town);
    const zipcodeTouroku = new ZipcodeAddress(this.pageForm.controls.zipcodeTouroku.get('zipcode').value, addressTouroku, this.pageForm.controls.zipcodeTouroku.get('banti').value, this.pageForm.controls.zipcodeTouroku.get('ktgk').value);
    const datepickerTouroku = String(this.pageForm.controls.datepickerTouroku.get('year').value) + '-' + String(('0' + (this.pageForm.controls.datepickerTouroku.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.datepickerTouroku.get('day').value)).slice(-2));
    const shimeiDairi = new Shimei(this.pageForm.controls.shimeiDairi.get('sei_kj').value, this.pageForm.controls.shimeiDairi.get('mei_kj').value, this.pageForm.controls.shimeiDairi.get('sei_kn').value, this.pageForm.controls.shimeiDairi.get('mei_kn').value);
    const addressDairi = new Address(this.pageForm.controls.zipcodeDairi.get('address').value.ken, this.pageForm.controls.zipcodeDairi.get('address').value.city, this.pageForm.controls.zipcodeDairi.get('address').value.town);
    const zipcodeDairi = new ZipcodeAddress(this.pageForm.controls.zipcodeDairi.get('zipcode').value, addressDairi, this.pageForm.controls.zipcodeDairi.get('banti').value, this.pageForm.controls.zipcodeDairi.get('ktgk').value);

    const shinsei = new Shinsei(
      this.pageForm.controls.touroku_no.value,
      this.pageForm.controls.maisuu.value,
      shimeiTouroku,
      zipcodeTouroku,
      this.pageForm.controls.snsi_telno.value,
      datepickerTouroku,
      this.pageForm.controls.dairiFlg.value,
      shimeiDairi,
      zipcodeDairi,
      this.pageForm.controls.dairi_telno.value
    );

    return shinsei;
  }

  onDairi() {
    if (this.pageForm.controls.dairiFlg.value) {
      //代理の場合
      this.pageForm.controls.shimeiDairi.enable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setValidate();
      this.pageForm.controls.zipcodeDairi.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setValidate();
      this.pageForm.controls.dairi_telno.enable();
    }
    else {
      //代理で無い場合
      this.pageForm.controls.shimeiDairi.disable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setDisable();
      this.pageForm.controls.zipcodeDairi.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setDisable();
      this.pageForm.controls.dairi_telno.disable();
    }

    //値をクリア
    this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).clear();
    this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).clear();
    this.pageForm.controls.dairi_telno.setValue('');
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix;
  }

}
