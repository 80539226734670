import { Component, OnInit, Input } from '@angular/core';
import { Jyukiido } from '@jyukiido/services/item';
import { EncryptService } from '@services/encrypt/encrypt.service';
import { ConfigService } from '@services/config/config.service';
import * as QRCode from '../../../../../node_modules/qrcode';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faPlusCircle, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-jyukiido-qr',
  templateUrl: './jyukiido-qr.component.html',
  styleUrls: ['./jyukiido-qr.component.css']
})
export class JyukiidoQrComponent implements OnInit {
  @Input() item: Jyukiido;
  mochimono_01: any;
  mochimono_02: any;
  mochimono_03: any;
  sonota_01: any;
  sonota_02: any;
  sonota_03: any;
  mochimono_file_header: string;
  title: string;
  uketuke: any;
  sonota: any;
  image_url;
  original_qr_size = 200;
  font_size = 10;
  margin = 30;
  filename: string;
  filenameMochimono: string;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faDownload = faDownload;
  isWindows: boolean;
  qr_error: string;

  constructor(private encryptService: EncryptService, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);

      this.mochimono_01 = ret.translations.JYUKIIDO.QR.MOCHIMONO_LIST_01;
      this.mochimono_02 = ret.translations.JYUKIIDO.QR.MOCHIMONO_LIST_02;
      this.mochimono_03 = ret.translations.JYUKIIDO.QR.MOCHIMONO_LIST_03;

      this.sonota_01 = ret.translations.JYUKIIDO.QR.SONOTA_LIST_01;
      this.sonota_02 = ret.translations.JYUKIIDO.QR.SONOTA_LIST_02;
      this.sonota_03 = ret.translations.JYUKIIDO.QR.SONOTA_LIST_03;

      this.title = ret.translations.JYUKIIDO.TITLE_MAIN;
      this.mochimono_file_header = ret.translations.JYUKIIDO.QR.MOCHIMONO_FILE_HEADER;
      this.uketuke = ret.translations.JYUKIIDO.QR.UKETUKE_LIST;
      this.sonota = ret.translations.JYUKIIDO.QR.SONOTA_LIST;
      this.qr_error = ret.translations.COMMON.QR_ERROR;
    });
  }

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    this.isWindows = userAgent.toLowerCase().includes('windows');
  }

  createQRText(): boolean {
    const qrStr =
      //帳票番号
      'sj201'
      //申請
      + "," + this.item.sj201_snsi_syurui
      + "," + this.item.sj201_snsi_name_kj
      + "," + this.item.sj201_snsi_name_kn
      + "," + this.item.sj201_snsi_telno
      + "," + this.item.sj201_dairi_yubinno
      + "," + this.item.sj201_dairi_addr_ken
      + "," + this.item.sj201_dairi_addr_city
      + "," + this.item.sj201_dairi_addr_town
      + "," + this.item.sj201_dairi_addr_banti
      + "," + this.item.sj201_dairi_addr_ktgk
      + "," + this.item.sj201_dairi_knki
      + "," + this.item.sj201_ido_ymd
      + "," + btoa(unescape(encodeURIComponent(this.item.sj201_cyouhyo_cd))) //base64

      //前住所
      + "," + this.item.sj201_old_yubinno
      + "," + this.item.sj201_old_addr_ken
      + "," + this.item.sj201_old_addr_city
      + "," + this.item.sj201_old_addr_town
      + "," + this.item.sj201_old_addr_banti
      + "," + this.item.sj201_old_addr_ktgk
      + "," + this.item.sj201_old_setai_name_kj
      + "," + this.item.sj201_old_setai_name_kn

      //新住所
      + "," + this.item.sj201_new_yubinno
      + "," + this.item.sj201_new_addr_ken
      + "," + this.item.sj201_new_addr_city
      + "," + this.item.sj201_new_addr_town
      + "," + this.item.sj201_new_addr_banti
      + "," + this.item.sj201_new_addr_ktgk
      + "," + this.item.sj201_new_setai_name_kj
      + "," + this.item.sj201_new_setai_name_kn
      + "," + this.createQRIdosya();

    const qrText = this.encryptService.getQREncrypt(qrStr);
    if (qrText.length > 2000) {
      alert(this.qr_error);
      return false;
    }

    QRCode.toDataURL(qrText, { scale: 2, errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      var img = document.getElementById('qr-image');
      (img as any).src = url;
      (img as any).alt = "jyusyohenkou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss');
    });

    this.filename = "jyusyohenkou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".png";
    this.filenameMochimono = "jyusyohenkou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".txt";
    return true;
  }

  createQRIdosya() {
    let textTransfer = String(this.item.sj202s.length);
    for (let i = 0; i < this.item.sj202s.length; i++) {
      const transfer = this.item.sj202s[i];
      textTransfer = textTransfer
        + "," + transfer.sj202_name_kj
        + "," + transfer.sj202_name_kn
        + "," + transfer.sj202_umare_ymd
        + "," + transfer.sj202_sex
        + "," + transfer.sj202_zokugara
        + "," + transfer.sj202_zokugara_sonota;
    }

    return textTransfer;
  }

  downloadCanvas() {
    var img = document.getElementById('qr-image');
    let link = document.getElementById('hiddenLink');
    (link as any).href = (img as any).src;
    link.click();
  }

  downloadMochimono(): void {
    let lines: Array<string> = [];
    
    var mochimono = null;
    var sonota = null;
    if (this.item.sj201_snsi_syurui == '01') {
      mochimono = this.mochimono_01;
      sonota = this.sonota_01;
    }
    else if (this.item.sj201_snsi_syurui == '02') {
      mochimono = this.mochimono_02;
      sonota = this.sonota_02;
    }
    else if (this.item.sj201_snsi_syurui == '03') {
      mochimono = this.mochimono_03;
      sonota = this.sonota_03;
    }


    lines = lines.concat(this.getDownLoadList(mochimono));
    lines = lines.concat(this.getDownLoadList(this.uketuke));
    lines = lines.concat(this.getDownLoadList(sonota));
    
    const textdata = lines.join(this.isWindows ? '\r\n' : '\n');

    const link = document.getElementById('hiddenMochimonoLink') as HTMLAnchorElement;
    link.href = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(textdata)));
    link.click();
  }

  getDownLoadList(itemList): Array<string> {
    const lines: Array<string> = [];
    
    itemList.forEach(u => {
      lines.push(`・${u.title}`);
      u.meisai.forEach(v => lines.push(v));
      if (u.hasOwnProperty('link')) lines.push(u.link.url);
      lines.push('');
    });
    
    return lines;
  }

  getMultiSelectedValue(objItems: object[]) {
    if (!objItems) return;

    var values = [];
    Object.keys(objItems).forEach((key) => {
      values.push(objItems[key].value);
    })
    return JSON.stringify(values);
  }

}
