<div class="box-kakunin-title">
    <h4>{{ 'COMMON.KAKUNIN.TITLE_KAKUNIN' | translate }}</h4>
</div>

<div class="box-kakunin">
    <!--「対象者」画面 STEP 2-->
    <div *ngFor="let transfer of item.sj202s; let tindex = index">
        <div class="box-koumoku">
            <h4>{{ 'JYUKIIDO.IDOSYA.TITLE_IDOSYA' | translate }}（{{tindex + 1}}/{{getMaxTransfer()}}）</h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{transfer.sj202_name_kj}}</a></div>
                        <ng-container *ngIf="transfer.sj202_name_kn">
                            <div class="is-hidden"><a>/</a></div>
                            <div><a>{{transfer.sj202_name_kn}}</a></div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.BIRTHDAY' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{transfer.sj202_umare_ymd}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SEX' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <ng-container *ngIf="transfer.sj202_sex === '1';else second">
                        <a>{{ 'SEX_WITH_JA.MALE' | translate }}</a>
                    </ng-container>
                    <ng-template #second>
                        <ng-container *ngIf="transfer.sj202_sex === '2';else third">
                            <a>{{ 'SEX_WITH_JA.FEMALE' | translate }}</a>
                        </ng-container>
                    </ng-template>
                    <ng-template #third>
                        <a>{{ 'SEX_WITH_JA.UNKNOWN' | translate }}</a>
                    </ng-template>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.ZOKUGARA' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{getZokugaraLabel(transfer)}}</a></div>
                </div>
            </div>
        </div>
    </div>

    <!--「申請」画面 STEP 3-->
    <div class="box-koumoku">
        <!--来庁者-->
        <h4>{{ 'JYUKIIDO.SHINSEI.TITLE_DAIRI' | translate }}</h4>
    </div>
    <div class="meisai">
        <ng-container *ngIf="item.sj201_dairi_addr_ken==''">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj201_snsi_name_kj}}</a></div>
                        <ng-container *ngIf="item.sj201_snsi_name_kn">
                            <div class="is-hidden"><a>/</a></div>
                            <div><a>{{item.sj201_snsi_name_kn}}</a></div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj201_snsi_telno}}</a></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.sj201_dairi_addr_ken">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj201_snsi_name_kj}}</a></div>
                        <ng-container *ngIf="item.sj201_snsi_name_kn">
                            <div class="is-hidden"><a>/</a></div>
                            <div><a>{{item.sj201_snsi_name_kn}}</a></div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div>
                        <a>{{item.sj201_dairi_addr_ken}}{{item.sj201_dairi_addr_city}}{{item.sj201_dairi_addr_town}}{{item.sj201_dairi_addr_banti}}
                            {{item.sj201_dairi_addr_ktgk}}</a>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj201_snsi_telno}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.DAIRI_KANKEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj201_dairi_knki}}</a></div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="box-koumoku">
        <!--引っ越しの日付-->
        <h4>{{ 'JYUKIIDO.SHINSEI.TITLE_HIKKOSHI' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="box-koumoku">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JYUKIIDO.SHINSEI.DATE_HIKKOSHI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj201_ido_ymd}}</a></div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="item.sj201_cyouhyo_cd">
        <div class="box-koumoku">
            <h4>{{ 'JYUKIIDO.SHINSEI.TITLE_CYOUHYO' | translate }}</h4>
        </div>
        <div class="meisai" >
            <div class="meisai-row" *ngFor="let cyouhyoSyurui of cyouhyoList">
                <div class="meisai-col-title">
                    <div><a>{{ cyouhyoSyurui.label | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="meisai-col-naiyou-row" >
                        <div *ngFor="let cyouhyoName of cyouhyoSyurui.cyouhyo"><a>{{cyouhyoName}}</a></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!--「前住所」画面 STEP 4-->
    <div class="box-koumoku">
        <!--前住所-->
        <h4>{{ 'JYUKIIDO.ZENJYUSYO.TITLE_ZENJYUSYO' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div class="meisai-col-title-naiyo">
                    <!--引っ越し前の情報-->
                    <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                </div>
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-addr">
                    <div>
                        <a>{{item.sj201_old_addr_ken}}{{item.sj201_old_addr_city}}{{item.sj201_old_addr_town}}{{item.sj201_old_addr_banti}}
                            {{item.sj201_old_addr_ktgk}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.SETAINUSHI' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.sj201_old_setai_name_kj}}</a></div>
                    <ng-container *ngIf="item.sj201_old_setai_name_kn">
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.sj201_old_setai_name_kn}}</a></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--「新住所」画面 STEP 5-->
    <div class="box-koumoku">
        <!--新住所-->
        <h4>{{ 'JYUKIIDO.SHINJYUSYO.TITLE_SHINJYUSYO' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div class="meisai-col-title-naiyo">
                    <!--引っ越し先の情報-->
                    <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                </div>
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-addr">
                    <div>
                        <a>{{item.sj201_new_addr_ken}}{{item.sj201_new_addr_city}}{{item.sj201_new_addr_town}}{{item.sj201_new_addr_banti}}
                            {{item.sj201_new_addr_ktgk}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.SETAINUSHI' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.sj201_new_setai_name_kj}}</a></div>
                    <ng-container *ngIf="item.sj201_new_setai_name_kn">
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.sj201_new_setai_name_kn}}</a></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
