import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Menu } from '@jyukiido/services/item';
import { CustomValidator } from '@utility/custom-validator';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-jyukiido-menu',
  templateUrl: './jyukiido-menu.component.html',
  styleUrls: ['./jyukiido-menu.component.css']
})
export class JyukiidoMenuComponent implements OnInit, AfterViewInit {
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageForm: FormGroup;
  faExclamationTriangle = faExclamationTriangle;

  constructor(private fb: FormBuilder) {
    this.pageForm = fb.group({
      zenInFlg: [null, [Validators.required]],
      shinInFlg: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit() {
    this.pageForm.controls.shinInFlg.disable();
  }

  onZenIn() {
    if (this.pageForm.controls.zenInFlg.value) {
      //前住所が管内の場合
      this.pageForm.controls.shinInFlg.enable();
    }
    else {
      //前住所が管外の場合
      this.pageForm.controls.shinInFlg.disable();
    }

    //値をクリア
    this.pageForm.controls.shinInFlg.setValue(null);
  }

  getMenu() {
    if (!this.pageForm.controls.zenInFlg.value) {
      //転入
      return new Menu('01');
    }
    else if (this.pageForm.controls.shinInFlg.value) {
      //転居
      return new Menu('03');
    }
    else {
      //転出
      return new Menu('02');
    }
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

}
