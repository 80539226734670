import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Jyuminhyou, Shinsei} from '@jyuminhyou/services/item';
import { ConfigService } from '@services/config/config.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-jyuminhyou-kakunin',
  templateUrl: './jyuminhyou-kakunin.component.html',
  styleUrls: ['./jyuminhyou-kakunin.component.css']
})
export class JyuminhyouKakuninComponent implements OnInit {
  @Input() item: Jyuminhyou;
  @Input() shinsei: Shinsei;
  cx101: Array<{ cd: number; name: string; }>;
  zokugara;
  mynumber;
  honseki;
  gaijinkisai;

  constructor(private config: ConfigService, public translate: TranslateService) {
    this.cx101 = Object(config.get('cx101'));
    
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.gaijinkisai = ret.translations.JYUMINHYOU.OPTION.SEL_GAIJINKISAI;
    });
  }

  ngOnInit() {
  }

  getMaxCyouhyo() {
    return this.item.sj102s.length;
  }

  getCyouhyoName(cyouhyo_cd) {
    let ret = null;

    for (let i in this.cx101) {
      if (this.cx101[i].cd == cyouhyo_cd) {
        ret = this.cx101[i].name;
        break;
      }
    }

    return ret;
  }

  getZokugara() {
    return !this.item.sj101_zokusei ? null : JSON.parse(this.item.sj101_zokusei).zokugara;
  }

  getMynumber() {
    return !this.item.sj101_zokusei ? null : JSON.parse(this.item.sj101_zokusei).kojinno;
  }

  getHonseki() {
    return !this.item.sj101_zokusei ? null : JSON.parse(this.item.sj101_zokusei).honseki;
  }

  getYouto() {
    if (!this.item.sj101_youto) return;

    const jsonObject = JSON.parse(this.item.sj101_youto);
    return jsonObject.join(', ');
  }

  displayMultiSelectedLabel() {
    if (!this.item.sj101_zokusei) return;
    const zokusei = JSON.parse(this.item.sj101_zokusei);

    var labels = [];
    this.gaijinkisai.forEach((key) => {
      if(Object.keys(zokusei).indexOf(key.value) !== -1)
      labels.push(key.label);
    })
    return labels.join(', ');
  }
  
  isHide() {
    return this.item.sj102s.filter((element) => !((element.sj102_cyouhyo_cd == '131') || (element.sj102_cyouhyo_cd == '132'))).length == 0 ? true : false;
  }

}
