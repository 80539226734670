import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, AfterViewChecked, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Iryokodomo, Hoken } from '@iryokodomo/services/item';
import { CustomValidator } from '@utility/custom-validator';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-iryokodomo-hoken',
  templateUrl: './iryokodomo-hoken.component.html',
  styleUrls: ['./iryokodomo-hoken.component.css']
})
export class IryokodomoHokenComponent implements OnInit {
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageForm: FormGroup;
  hoken: string[];
  faExclamationTriangle = faExclamationTriangle;

  constructor(fb: FormBuilder, public translate: TranslateService) {
    this.pageForm = fb.group({
      hoken_hhs_kigo: [null, []],
      hoken_hhs_bango: [null, []],
      hoken_hhs_name: [null, [CustomValidator.zenkaku]],
      hoken_kodomoskk_date: [null, []],
      hoken_hoken_name: [null, [CustomValidator.zenkaku]],
      hoken_hoken_bango: [null, [CustomValidator.numeric]],
      hoken_hoken_syurui: [null, []],
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.hoken = ret.translations.MASTER.HOKEN;
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  getHoken(): Hoken {
    const hoken = new Hoken(
      this.pageForm.controls.hoken_hhs_kigo.value,
      this.pageForm.controls.hoken_hhs_bango.value,
      this.pageForm.controls.hoken_hhs_name.value,
      this.pageForm.controls.hoken_kodomoskk_date.value,
      this.pageForm.controls.hoken_hoken_name.value,
      this.pageForm.controls.hoken_hoken_bango.value,
      this.pageForm.controls.hoken_hoken_syurui.value,
    );
    return hoken;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

}
