<div id="modal-container" class="modal-main" [@openClose]="isOpen ? 'open' :'closed' ">
    <h5>{{ 'JYUKIIDO.CYOUHYO.TITLE_CYOUHYO' | translate }}</h5>
    <form [formGroup]="cyouhyouForm">
    <p>{{ 'JYUKIIDO.CYOUHYO.SETUMEI_CYOUHYO' | translate }}</p>
    <div class="box-jyouken">
      <label formArrayName ="selectedCyouhyou"  *ngFor="let cyouhyou of data.item; let cindex = index">
        <input type="checkbox" class="checkbox"  [formControlName]="cindex">
        <span class="radio-fontas"></span>
        {{ cyouhyou.name | translate }}
      </label>
    </div>
    <div class="box-item">
        <a class="btn-cancel" (click)="data.click(getCyouhyouId()); closeModal()">{{ 'JYUKIIDO.CYOUHYO.CLOSE' | translate }}</a>
    </div>
    </form>
</div>

<div id="overlay" [@openClose]="isOpen ? 'open' :'closed'"></div>