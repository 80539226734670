import { Component, OnInit, Input } from '@angular/core';
import { Jyuminhyou } from '@jyuminhyou/services/item';
import { EncryptService } from '@services/encrypt/encrypt.service';
import { ConfigService } from '@services/config/config.service';
import * as QRCode from 'qrcode';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faPlusCircle, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-jyuminhyou-qr',
  templateUrl: './jyuminhyou-qr.component.html',
  styleUrls: ['./jyuminhyou-qr.component.css']
})
export class JyuminhyouQrComponent implements OnInit {
  @Input() item: Jyuminhyou;
  mochimono: any;
  mochimono_file_header: string;
  title: string;
  uketuke: any;
  image_url;
  original_qr_size = 200;
  font_size = 10;
  margin = 30;
  filename: string;
  filenameMochimono: string;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faDownload = faDownload;
  isWindows: boolean;
  qr_error: string;

  constructor(private encryptService: EncryptService, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      const isDairi = !!this.item?.sj101_dairi_addr_ken;
      this.title = ret.translations.JYUMINHYOU.TITLE_MAIN;
      this.mochimono = isDairi
        ? ret.translations.JYUMINHYOU.QR.MOCHIMONO_LIST_DAIRI
        : ret.translations.JYUMINHYOU.QR.MOCHIMONO_LIST;
      this.mochimono_file_header = isDairi
        ? ret.translations.JYUMINHYOU.QR.MOCHIMONO_FILE_HEADER_DAIRI
        : ret.translations.JYUMINHYOU.QR.MOCHIMONO_FILE_HEADER;
      this.uketuke = ret.translations.JYUMINHYOU.QR.UKETUKE_LIST;
      this.qr_error = ret.translations.COMMON.QR_ERROR;
    });
  }

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    this.isWindows = userAgent.toLowerCase().includes('windows');
  }

  createQRText(): boolean {
    const isDairi = !!this.item?.sj101_dairi_addr_ken;
    this.translate
      .get(
        isDairi
          ? "JYUMINHYOU.QR.MOCHIMONO_LIST_DAIRI"
          : "JYUMINHYOU.QR.MOCHIMONO_LIST"
      )
      .subscribe((mochimono: any) => (this.mochimono = mochimono));
    this.translate
      .get(
        isDairi
          ? "JYUMINHYOU.QR.MOCHIMONO_FILE_HEADER_DAIRI"
          : "JYUMINHYOU.QR.MOCHIMONO_FILE_HEADER"
      )
      .subscribe(
        (mochimono_file_header: string) =>
          (this.mochimono_file_header = mochimono_file_header)
      );

    const sj101_ichibu_name_base64 = btoa(unescape(encodeURIComponent(this.item.sj101_ichibu_name))); //base64
    const sj101_youto_base64 = btoa(unescape(encodeURIComponent(this.item.sj101_youto))); //base64
    const sj101_zokusei_base64 = btoa(unescape(encodeURIComponent(this.item.sj101_zokusei))); //base64
    const sj101_mynumber_taisyosya_base64 = btoa(unescape(encodeURIComponent(this.item.sj101_mynumber_taisyosya))); //base64

    const qrStr =
      //帳票番号
      'sj101'
      + "," + this.item.sj101_tisy_name_kj
      + "," + this.item.sj101_tisy_name_kn
      + "," + this.item.sj101_tisy_umare_ymd
      + "," + this.item.sj101_tisy_yubinno
      + "," + this.item.sj101_tisy_addr_ken
      + "," + this.item.sj101_tisy_addr_city
      + "," + this.item.sj101_tisy_addr_town
      + "," + this.item.sj101_tisy_addr_banti
      + "," + this.item.sj101_tisy_addr_ktgk
      + "," + this.item.sj101_tisy_telno
      + "," + this.item.sj101_dairi_name_kj
      + "," + this.item.sj101_dairi_name_kn
      + "," + this.item.sj101_dairi_yubinno
      + "," + this.item.sj101_dairi_addr_ken
      + "," + this.item.sj101_dairi_addr_city
      + "," + this.item.sj101_dairi_addr_town
      + "," + this.item.sj101_dairi_addr_banti
      + "," + this.item.sj101_dairi_addr_ktgk
      + "," + this.item.sj101_dairi_knki
      + "," + this.item.sj101_dairi_telno
      + "," + this.item.sj101_siky_name_kj
      + "," + this.item.sj101_siky_name_kn
      + "," + this.item.sj101_siky_yubinno
      + "," + this.item.sj101_siky_addr_ken
      + "," + this.item.sj101_siky_addr_city
      + "," + this.item.sj101_siky_addr_town
      + "," + this.item.sj101_siky_addr_banti
      + "," + this.item.sj101_siky_addr_ktgk
      + "," + this.item.sj101_siky_telno
      + "," + sj101_ichibu_name_base64
      + "," + sj101_youto_base64
      + "," + sj101_zokusei_base64
      + "," + sj101_mynumber_taisyosya_base64
      + "," + this.createQRCyouhyo();

    const qrText = this.encryptService.getQREncrypt(qrStr);
    if (qrText.length > 2000) {
      alert(this.qr_error);
      return false;
    }

    QRCode.toDataURL(qrText, { scale: 2, errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      var img = document.getElementById('qr-image');
      (img as any).src = url;
      (img as any).alt = "jyuminhyou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss');
    });

    this.filename = "jyuminhyou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".png";
    this.filenameMochimono = "jyuminhyou-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".txt";
    return true;
  }

  createQRCyouhyo() {

    let textTransfer = String(this.item.sj102s.length);
    for (let i = 0; i < this.item.sj102s.length; i++) {
      const transfer = this.item.sj102s[i];
      textTransfer = textTransfer
        + "," + transfer.sj102_cyouhyo_cd
        + "," + transfer.sj102_maisuu;
        + "," + transfer.sj102_ichibu_name;
    }

    return textTransfer;

  }

  downloadCanvas() {
    var img = document.getElementById('qr-image');
    let link = document.getElementById('hiddenLink');
    (link as any).href = (img as any).src;
    link.click();
  }

  downloadMochimono(): void {
    const lines: Array<string> = [];
    lines.push(this.mochimono_file_header);
    lines.push('');
    const mochimonoList: Array<{ title: string; meisai: Array<string>; }> = this.mochimono;
    mochimonoList.forEach(u => {
      lines.push(`・${u.title}`);
      u.meisai.forEach(v => lines.push(v));
      lines.push('');
    });

    const textdata = lines.join(this.isWindows ? '\r\n' : '\n');

    const link = document.getElementById('hiddenMochimonoLink') as HTMLAnchorElement;
    link.href = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(textdata)));
    link.click();
  }
}
