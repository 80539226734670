import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { Shimei, ShimeiComponent } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SexComponent } from '@components/sex/sex.component';
import { Haigusha, Seikyusha } from '@jidoteate/services/item';
import { getShimeiFromShimeiForm, getFormattedyyMMddFromDatepicker, getZipcodeAddressFromZipcodeForm } from '@utility/format';

@Component({
  selector: 'app-jidoteate-haigusha',
  templateUrl: './jidoteate-haigusha.component.html',
  styleUrls: ['./jidoteate-haigusha.component.css', '../../jidoteate.component.css']
})
export class JidoteateHaigushaComponent implements OnInit, AfterViewInit {
  @ViewChild(ShimeiComponent) shimeiComponent: ShimeiComponent;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  shimei: Shimei;
  uji: Shimei;
  kankei: string[];
  nenkin: string[];
  job: string[];
  umu: [];
  bekkyo: [];
  fgNameShimei = 'shimei';
  ckNameShimei = 'haigusha-shimei';
  fgNameDatepickerBirthday = 'birthDatepicker';
  fgNameZipcode = 'zipcode';
  ckNameZipcode = 'haigusha-zipcode';
  fgName11jitenZipcode = 'zipcode11';
  ckName11jitenZipcode = 'haigusha-zipcode11';
  faExclamationTriangle = faExclamationTriangle;

  constructor(private fb: FormBuilder, public translate: TranslateService) {
    this.pageForm = fb.group({
      // shimei（shimei コンポーネントで追加 this.fgNameShimei）
      // datepicker（datepicker コンポーネントで追加 this.fgNameDatePicker）
      // zipcode（zipcode コンポーネントで追加 this.fgNameZipcode）
      bekkyoFlag: false,
      differentaddressFlag: false,
      haiguFlag: false,
      haiguJob: [null, [Validators.required]],
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.kankei = ret.translations.MASTER.KANKEI;
      this.nenkin = ret.translations.MASTER.NENKIN;
      this.job = ret.translations.MASTER.JOB;
      this.umu = ret.translations.JIDOTEATE.HAIGUSYA.UMU;
      this.bekkyo = ret.translations.JIDOTEATE.HAIGUSYA.BEKKYO;
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit(): void {
    this.pageForm.controls.zipcode.disable();
    this.pageForm.controls.zipcode11.disable();
    this.pageForm.controls.shimei.disable();
    this.pageForm.controls.birthDatepicker.disable();
    this.pageForm.controls.haiguJob.disable();
  }

  getHaigusha(): Haigusha {
    const shimeiData = new Shimei(this.pageForm.controls.shimei.get('sei_kj').value, this.pageForm.controls.shimei.get('mei_kj').value, this.pageForm.controls.shimei.get('sei_kn').value, this.pageForm.controls.shimei.get('mei_kn').value);
    const birthDatepicker = String(this.pageForm.controls.birthDatepicker.get('year').value) + '-' + String(('0' + (this.pageForm.controls.birthDatepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.birthDatepicker.get('day').value)).slice(-2));
    const address = new Address(this.pageForm.controls.zipcode.get('address').value.ken, this.pageForm.controls.zipcode.get('address').value.city, this.pageForm.controls.zipcode.get('address').value.town);
    const zipcodeAddress = new ZipcodeAddress(this.pageForm.controls.zipcode.get('zipcode').value, address, this.pageForm.controls.zipcode.get('banti').value, this.pageForm.controls.zipcode.get('ktgk').value);
    const address11 = new Address(this.pageForm.controls.zipcode11.get('address').value.ken, this.pageForm.controls.zipcode11.get('address').value.city, this.pageForm.controls.zipcode11.get('address').value.town);
    const zipcodeAddress11 = new ZipcodeAddress(this.pageForm.controls.zipcode11.get('zipcode').value, address11, this.pageForm.controls.zipcode11.get('banti').value, this.pageForm.controls.zipcode11.get('ktgk').value);

    const haigusha = new Haigusha(
      this.pageForm.controls.haiguFlag.value ? this.umu["TRUE"] : this.umu["FALSE"],
      shimeiData,
      birthDatepicker,
      zipcodeAddress,
      zipcodeAddress11,
      this.pageForm.controls.haiguJob.value,
      this.pageForm.controls.bekkyoFlag.value ? this.bekkyo["TRUE"] : this.bekkyo["FALSE"],
    );

    return haigusha;
  }

  on11DiffAddr(): void {
    if (this.pageForm.controls.differentaddressFlag.value) {
      this.pageForm.controls.zipcode11.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).setValidate();
    }
    else {
      this.pageForm.controls.zipcode11.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).setDisable();
    }

    // 値をクリア
    this.zipcodeComponents.find((element) => element.fgName === this.fgName11jitenZipcode).clear();
  }

  onBekkyo(): void {
    if (this.pageForm.controls.bekkyoFlag.value) {
      this.pageForm.controls.zipcode.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcode).setValidate();
    }
    else {
      this.pageForm.controls.zipcode.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcode).setDisable();
    }

    // 値をクリア
    this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcode).clear();
  }

  onHaigu(): void {
    if (this.pageForm.controls.haiguFlag.value) {
      this.pageForm.controls.zipcode.enable();
      this.pageForm.controls.zipcode11.enable();
      this.pageForm.controls.shimei.enable();
      this.shimeiComponent.setValidate();
      this.pageForm.controls.birthDatepicker.enable();
      this.pageForm.controls.haiguJob.enable();

      this.pageForm.controls.bekkyoFlag.setValue(false);
      this.onBekkyo();
      this.pageForm.controls.differentaddressFlag.setValue(false);
      this.on11DiffAddr();
    }
    else {
      this.shimeiComponent.clear();
      //this.pageForm.controls.haiguFlag.setValue(false);
      this.pageForm.controls.zipcode.disable();
      this.pageForm.controls.zipcode11.disable();
      this.pageForm.controls.shimei.disable();
      this.pageForm.controls.birthDatepicker.disable();
      this.pageForm.controls.haiguJob.disable();
    }

    this.pageForm.controls.haiguJob.setValue(null);
  }

  setCopyShimei(name_kj, name_kn) {
    const shimei_kj = name_kj.split("　");
    const shimei_kn = name_kn.split("　");
    this.shimei = new Shimei(shimei_kj[0], shimei_kj[1], shimei_kn[0], shimei_kn[1]);
    this.uji = new Shimei(shimei_kj[0], null, shimei_kn[0], null);
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

}
