<div class="box-kakunin-title">
    <h4>{{ 'COMMON.KAKUNIN.TITLE_KAKUNIN' | translate }}</h4>
</div>
<div class="box-kakunin">
    <div class="box-koumoku">
        <h4>{{ 'IRYOKODOMO.SHINSEI.TITLE_SHINSEI' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.SHINSEISYA' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.fk101_snsi_namekj}}</a></div>
                    <div class="is-hidden"><a>/</a></div>
                    <div><a>{{item.fk101_snsi_namekn}}</a></div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.ADDR' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_snsi_addr_ken}}{{item.fk101_snsi_addr_city}}{{item.fk101_snsi_addr_town}}{{item.fk101_snsi_addr_banti}} {{item.fk101_snsi_addr_ktgk}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.TEL' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_snsi_home_tel_no}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.BIRTHDAY' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_snsi_birthday}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.ZOKUGARA' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_snsi_zokugara}}
            </div>
        </div>
    </div>

    <div *ngFor="let transfer of item.fk102s; let tindex = index">
        <div class="box-koumoku">
            <h4>{{ 'IRYOKODOMO.TAISYOUJIDOU.TITLE_TAISYOUJIDOU' | translate }}（{{tindex + 1}}/{{getMaxTransfer()}}）</h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{transfer.fk102_jido_namekj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{transfer.fk102_jido_namekn}}</a></div>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.BIRTHDAY' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{transfer.fk102_jido_birthday}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SEX' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <ng-container *ngIf="transfer.fk102_jido_sex === '1';else second">
                        <a>{{ 'SEX_WITH_JA.MALE' | translate }}</a>
                    </ng-container>
                    <ng-template #second>
                        <ng-container *ngIf="transfer.fk102_jido_sex === '2';else third">
                            <a>{{ 'SEX_WITH_JA.FEMALE' | translate }}</a>
                        </ng-container>
                    </ng-template>
                    <ng-template #third>
                        <a>{{ 'SEX_WITH_JA.UNKNOWN' | translate }}</a>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <h4>{{ 'IRYOKODOMO.HOKEN.TITLE_HOKEN' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.HKNNAME' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_hoken_hoken_name}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.HKNSYURUI' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_hoken_hoken_syurui}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.HHSNAME' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk101_hoken_hhs_name}}
            </div>
        </div>
    </div>
</div>