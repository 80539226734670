<div class="box-block">
    <div class="box-koumoku">
        <h4>{{ 'JYUMINHYOU.OPTION.TITLE_OPTION' | translate }}</h4>
    </div>
    <div class="box-block-inner">

        <div class="box-koumoku">
            <div class="box-help">
                <a>
                    {{ 'JYUMINHYOU.OPTION.SETUMEI_OPTION' | translate }}
                </a>
            </div>
        </div>

        <div class="box-koumoku">
            <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.OPTION' | translate }}</label></div>
            <div class="box-option">

                <!--用途-->
                <div class="box-koumoku">
                    <div class="box-required">
                        <img class="required" src="/assets/required.png">
                        <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.YOUTO' | translate }}</label></div>
                    </div>
                    <ng-container
                        *ngIf="pageForm.controls.youto.hasError('required') && (pageForm.controls.youto.touched || pageForm.controls.youto.dirty)">
                        <div class="err-msg">
                            <a>
                                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                {{ 'JYUMINHYOU.OPTION.YOUTO_ERROR_REQUIRED' | translate }}
                            </a>
                        </div>
                    </ng-container>
                    <div class="box-control-multi">
                        <div class="select"
                            [class.input-invalid]="pageForm.controls.youto.invalid && (pageForm.controls.youto.touched || pageForm.controls.youto.dirty)">
                            <ng-select class="ng-select-multi" [items]="youto" [multiple]="true"
                                [formControl]="pageForm.controls.youto" [clearable]="false" (change)="onChangeYouto()">
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items">
                                        <span class="ng-value-label" *ngIf="item.length <= 10"> {{item}}</span>
                                        <span class="ng-value-label" *ngIf="item.length > 10">
                                            {{item.slice(0,10)}}...</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="box-koumoku" [hidden]="hideSonota">
                    <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.TITLE_SONOTA' | translate }}</label></div>
                    <div class="box-control">
                        <input class="input-text" type="text" [formControl]="pageForm.controls.sonota" maxlength="10">
                    </div>

                    <div class="box-setumei">
                        <a>{{ 'JYUMINHYOU.OPTION.SONOTA_SETUMEI' | translate }}</a>
                    </div>
                </div>

                <!--続柄-->
                <div [hidden]="isHide()">
                    <div class="box-koumoku">
                        <div class="box-required">
                            <img class="required" src="/assets/required.png">
                            <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.TITLE_ZOKUGARA' | translate }}</label>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="pageForm.controls.zokugara.hasError('required') && (pageForm.controls.zokugara.touched || pageForm.controls.zokugara.dirty)">
                            <div class="err-msg">
                                <a>
                                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                    {{ 'JYUMINHYOU.OPTION.ZOKUGARA_ERROR_REQUIRED' | translate }}
                                </a>
                            </div>
                        </ng-container>
                        <div class="radio-tile-group">
                            <div class="input-container">
                                <label for="zokugara-yes">
                                    <input id="zokugara-yes" class="radio-button" type="radio" name="zokugara"
                                        [formControl]="pageForm.controls.zokugara" [value]="'true'" hidden />
                                    <div class="radio-tile"
                                        [class.input-invalid]="pageForm.controls.zokugara.invalid && (pageForm.controls.zokugara.touched || pageForm.controls.zokugara.dirty)">
                                        <div class="icon zokugara-yes-icon">
                                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                                <style type="text/css">
                                                
                                                    .st0{fill:#4B4B4B;}
                                                
                                                </style>
                                                <g>
                                                    <path class="st0" d="M342.906,0H69.063c-6.578,0-11.906,5.344-11.906,11.906v488.188c0,6.578,5.328,11.906,11.906,11.906h373.875
                                                        c6.563,0,11.906-5.328,11.906-11.906V111.938L342.906,0z M339.344,36.844l81.031,81.031h-81.031V36.844z M426.266,483.422H85.734
                                                        V28.578h229.797v89.938c0,6.359,2.625,12.234,6.797,16.391c4.141,4.172,10.016,6.781,16.391,6.781h87.547V483.422z" style="fill: rgb(255, 215, 0);"></path>
                                                    <rect x="145.281" y="185.75" class="st0" width="209.563" height="23.813" style="fill: rgb(255, 215, 0);"></rect>
                                                    <rect x="145.281" y="269.094" class="st0" width="208.359" height="23.813" style="fill: rgb(255, 215, 0);"></rect>
                                                    <rect x="145.281" y="352.438" class="st0" width="111.906" height="23.828" style="fill: rgb(255, 215, 0);"></rect>
                                                    <path class="st0" d="M311.031,340.219c-8.594,8.563-13.938,20.563-13.938,33.656s5.344,25.094,13.938,33.672l0.125,0.125
                                                        l-20.031,32.906l22.156,0.297l10.469,19.563l20.953-34.469l20.953,34.469l10.5-19.563l22.141-0.297l-20.047-32.906l0.141-0.125
                                                        c8.578-8.578,13.938-20.578,13.938-33.672s-5.359-25.094-13.938-33.656c-8.578-8.609-20.578-13.969-33.688-13.969
                                                        S319.594,331.609,311.031,340.219z M363.75,373.875c0,3.672-1,7.047-2.797,9.938c-0.766,1.281-1.703,2.453-2.766,3.547
                                                        c-3.188,3.141-7.344,5.188-12.094,5.516c-0.453,0.031-0.906,0.063-1.391,0.063s-0.922-0.031-1.359-0.063
                                                        c-4.75-0.328-8.938-2.375-12.125-5.516c-1.063-1.094-2-2.266-2.75-3.547c-1.813-2.891-2.813-6.266-2.813-9.938
                                                        c0-5.313,2.094-9.969,5.563-13.469c3.5-3.469,8.188-5.563,13.484-5.563c5.313,0,9.984,2.094,13.484,5.563
                                                        C361.641,363.906,363.75,368.563,363.75,373.875z" style="fill: rgb(255, 215, 0);"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <label for="zokugara-yes" class="radio-tile-label">{{
                                            'JYUMINHYOU.OPTION.PRINT_YES' | translate }}
                                        </label>
                                    </div>
                                </label>
                            </div>
                            <div class="input-container">
                                <label for="zokugara-no">
                                    <input id="zokugara-no" class="radio-button" type="radio" name="zokugara"
                                        [formControl]="pageForm.controls.zokugara" [value]="'false'" hidden />
                                    <div class="radio-tile"
                                        [class.input-invalid]="pageForm.controls.zokugara.invalid && (pageForm.controls.zokugara.touched || pageForm.controls.zokugara.dirty)">
                                        <div class="icon zokugara-no-icon">
                                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                                <style type="text/css">
                                                    .st0{fill:#4B4B4B;}
                                                </style>
                                                <g>
                                                    <path class="st0" d="M411.626,0H222.754c-13.552,0-26.56,5.39-36.148,14.969L68.794,132.788
                                                        c-9.594,9.587-14.976,22.596-14.976,36.156v296.5c0,25.67,20.892,46.556,46.564,46.556h311.243
                                                        c25.664,0,46.556-20.886,46.556-46.556V46.542C458.182,20.878,437.289,0,411.626,0z M206.248,32.349v104.652
                                                        c0,11.313-4.402,15.722-15.715,15.722H85.881L206.248,32.349z M431.998,465.444c0,11.237-9.142,20.372-20.372,20.372H100.382
                                                        c-11.245,0-20.38-9.135-20.38-20.372V175.998h124.55c13.77,0,24.978-11.207,24.978-24.985V26.184h182.096
                                                        c11.23,0,20.372,9.127,20.372,20.358V465.444z" style="fill: rgb(255, 215, 0);"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <label for="zokugara-no" class="radio-tile-label">{{
                                            'JYUMINHYOU.OPTION.PRINT_NO' | translate }}
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>



                <!--本籍-->
                <div [hidden]="isHide()">
                    <div class="box-koumoku no-margin-bottom">
                        <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.TITLE_HONSEKI' | translate }}</label></div>
                        <ng-container
                            *ngIf="pageForm.controls.honseki.hasError('required') && (pageForm.controls.honseki.touched || pageForm.controls.honseki.dirty)">
                            <div class="err-msg">
                                <a>
                                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                    {{ 'JYUMINHYOU.OPTION.HONSEKI_ERROR_REQUIRED' | translate }}
                                </a>
                            </div>
                        </ng-container>
                        <div class="radio-tile-group no-margin-bottom">
                            <div class="input-container">
                                <label for="honseki-yes">
                                    <input id="honseki-yes" class="radio-button" type="radio" name="honseki"
                                        [formControl]="pageForm.controls.honseki" [value]="'true'" hidden />
                                    <div class="radio-tile"
                                        [class.input-invalid]="pageForm.controls.honseki.invalid && (pageForm.controls.honseki.touched || pageForm.controls.honseki.dirty)">
                                        <div class="icon honseki-yes-icon">
                                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                                <style type="text/css">
                                                
                                                    .st0{fill:#4B4B4B;}
                                                
                                                </style>
                                                <g>
                                                    <path class="st0" d="M342.906,0H69.063c-6.578,0-11.906,5.344-11.906,11.906v488.188c0,6.578,5.328,11.906,11.906,11.906h373.875
                                                        c6.563,0,11.906-5.328,11.906-11.906V111.938L342.906,0z M339.344,36.844l81.031,81.031h-81.031V36.844z M426.266,483.422H85.734
                                                        V28.578h229.797v89.938c0,6.359,2.625,12.234,6.797,16.391c4.141,4.172,10.016,6.781,16.391,6.781h87.547V483.422z" style="fill: rgb(255, 215, 0);"></path>
                                                    <rect x="145.281" y="185.75" class="st0" width="209.563" height="23.813" style="fill: rgb(255, 215, 0);"></rect>
                                                    <rect x="145.281" y="269.094" class="st0" width="208.359" height="23.813" style="fill: rgb(255, 215, 0);"></rect>
                                                    <rect x="145.281" y="352.438" class="st0" width="111.906" height="23.828" style="fill: rgb(255, 215, 0);"></rect>
                                                    <path class="st0" d="M311.031,340.219c-8.594,8.563-13.938,20.563-13.938,33.656s5.344,25.094,13.938,33.672l0.125,0.125
                                                        l-20.031,32.906l22.156,0.297l10.469,19.563l20.953-34.469l20.953,34.469l10.5-19.563l22.141-0.297l-20.047-32.906l0.141-0.125
                                                        c8.578-8.578,13.938-20.578,13.938-33.672s-5.359-25.094-13.938-33.656c-8.578-8.609-20.578-13.969-33.688-13.969
                                                        S319.594,331.609,311.031,340.219z M363.75,373.875c0,3.672-1,7.047-2.797,9.938c-0.766,1.281-1.703,2.453-2.766,3.547
                                                        c-3.188,3.141-7.344,5.188-12.094,5.516c-0.453,0.031-0.906,0.063-1.391,0.063s-0.922-0.031-1.359-0.063
                                                        c-4.75-0.328-8.938-2.375-12.125-5.516c-1.063-1.094-2-2.266-2.75-3.547c-1.813-2.891-2.813-6.266-2.813-9.938
                                                        c0-5.313,2.094-9.969,5.563-13.469c3.5-3.469,8.188-5.563,13.484-5.563c5.313,0,9.984,2.094,13.484,5.563
                                                        C361.641,363.906,363.75,368.563,363.75,373.875z" style="fill: rgb(255, 215, 0);"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <label for="honseki-yes" class="radio-tile-label">{{
                                            'JYUMINHYOU.OPTION.PRINT_YES' | translate }}
                                        </label>
                                    </div>
                                </label>
                            </div>
                            <div class="input-container">
                                <label for="honseki-no">
                                    <input id="honseki-no" class="radio-button" type="radio" name="honseki"
                                        [formControl]="pageForm.controls.honseki" [value]="'false'" hidden />
                                    <div class="radio-tile"
                                        [class.input-invalid]="pageForm.controls.honseki.invalid && (pageForm.controls.honseki.touched || pageForm.controls.honseki.dirty)">
                                        <div class="icon honseki-no-icon">
                                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                                <style type="text/css">
                                                    .st0{fill:#4B4B4B;}
                                                </style>
                                                <g>
                                                    <path class="st0" d="M411.626,0H222.754c-13.552,0-26.56,5.39-36.148,14.969L68.794,132.788
                                                        c-9.594,9.587-14.976,22.596-14.976,36.156v296.5c0,25.67,20.892,46.556,46.564,46.556h311.243
                                                        c25.664,0,46.556-20.886,46.556-46.556V46.542C458.182,20.878,437.289,0,411.626,0z M206.248,32.349v104.652
                                                        c0,11.313-4.402,15.722-15.715,15.722H85.881L206.248,32.349z M431.998,465.444c0,11.237-9.142,20.372-20.372,20.372H100.382
                                                        c-11.245,0-20.38-9.135-20.38-20.372V175.998h124.55c13.77,0,24.978-11.207,24.978-24.985V26.184h182.096
                                                        c11.23,0,20.372,9.127,20.372,20.358V465.444z" style="fill: rgb(255, 215, 0);"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <label for="honseki-no" class="radio-tile-label">{{ 'JYUMINHYOU.OPTION.PRINT_NO'
                                            | translate }}
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div class="box-setumei">
                            <a>{{ 'JYUMINHYOU.OPTION.HONSEKI_SETUMEI' | translate }}</a>
                        </div>

                    </div>
                </div>

                <!--マイナンバー-->
                <div class="box-koumoku">
                    <div class="box-required">
                        <img class="required" src="/assets/required.png">
                        <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.TITLE_MYNUMBER' | translate }}</label></div>
                    </div>

                    <ng-container
                        *ngIf="pageForm.controls.mynumber.hasError('required') && (pageForm.controls.mynumber.touched || pageForm.controls.mynumber.dirty)">
                        <div class="err-msg">
                            <a>
                                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                {{ 'JYUMINHYOU.OPTION.MYNUMBER_ERROR_REQUIRED' | translate }}
                            </a>
                        </div>
                    </ng-container>
                    <div class="radio-tile-group">
                        <div class="input-container">
                            <label for="mynumber-all">
                                <input id="mynumber-all" class="radio-button" type="radio" name="mynumber"
                                    [formControl]="pageForm.controls.mynumber" [value]="'all'" hidden />
                                <div class="radio-tile"
                                    [class.input-invalid]="pageForm.controls.mynumber.invalid && (pageForm.controls.mynumber.touched || pageForm.controls.mynumber.dirty)">
                                    <div class="icon mynumber-all-icon">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                            <style type="text/css">
                                                .st0{fill:#4B4B4B;}
                                            </style>
                                            <g>
                                                <path class="st0" d="M256.495,96.434c26.632,0,48.213-21.597,48.213-48.205C304.708,21.58,283.128,0,256.495,0
                                                    c-26.65,0-48.222,21.58-48.222,48.229C208.274,74.837,229.846,96.434,256.495,96.434z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M298.267,119.279h-42.271h-42.271c-23.362,0-48.779,25.418-48.779,48.788v162.058
                                                    c0,11.685,9.463,21.156,21.148,21.156c5.743,0,0,0,14.756,0l8.048,138.206c0,12.434,10.078,22.513,22.513,22.513
                                                    c5.244,0,14.923,0,24.585,0c9.671,0,19.35,0,24.593,0c12.434,0,22.513-10.078,22.513-22.513l8.04-138.206
                                                    c14.764,0,9.013,0,14.764,0c11.676,0,21.148-9.471,21.148-21.156V168.067C347.054,144.697,321.636,119.279,298.267,119.279z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M104.141,149.083c23.262,0,42.105-18.85,42.105-42.104c0-23.262-18.843-42.112-42.105-42.112
                                                    c-23.27,0-42.104,18.851-42.104,42.112C62.037,130.232,80.871,149.083,104.141,149.083z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M408.716,149.083c23.27,0,42.104-18.85,42.104-42.104c0-23.262-18.834-42.112-42.104-42.112
                                                    c-23.253,0-42.104,18.851-42.104,42.112C366.612,130.232,385.463,149.083,408.716,149.083z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M137.257,169.024h-33.548h-36.92c-20.398,0-42.595,22.213-42.595,42.612v141.526
                                                    c0,10.212,8.264,18.476,18.468,18.476c5.018,0,0,0,12.884,0l7.024,120.704c0,10.852,8.805,19.658,19.666,19.658
                                                    c4.577,0,13.024,0,21.473,0c8.439,0,16.895,0,21.472,0c10.861,0,19.666-8.805,19.666-19.658l7.016-120.704v-6.849
                                                    c-8.98-8.856-14.606-21.082-14.606-34.664V169.024z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M445.211,169.024h-36.928h-33.54v161.101c0,13.582-5.626,25.808-14.615,34.664v6.849l7.017,120.704
                                                    c0,10.852,8.814,19.658,19.674,19.658c4.578,0,13.025,0,21.464,0c8.456,0,16.904,0,21.481,0c10.862,0,19.659-8.805,19.659-19.658
                                                    l7.032-120.704c12.883,0,7.865,0,12.883,0c10.204,0,18.468-8.265,18.468-18.476V211.636
                                                    C487.806,191.237,465.61,169.024,445.211,169.024z" style="fill: rgb(255, 215, 75);"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <label for="mynumber-all" class="radio-tile-label">{{ 'JYUMINHYOU.OPTION.MYNUMBER_ALL'
                                        | translate }}
                                    </label>
                                </div>
                            </label>
                        </div>
                        <div class="input-container">
                            <label for="mynumber-some">
                                <input id="mynumber-some" class="radio-button" type="radio" name="mynumber"
                                    [formControl]="pageForm.controls.mynumber" [value]="'some'" hidden />
                                <div class="radio-tile"
                                    [class.input-invalid]="pageForm.controls.mynumber.invalid && (pageForm.controls.mynumber.touched || pageForm.controls.mynumber.dirty)">
                                    <div class="icon mynumber-some-icon">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                            <style type="text/css">
                                                .st0{fill:#4B4B4B;}
                                            </style>
                                            <g>
                                                <path class="st0" d="M256.496,96.435c26.634,0,48.213-21.596,48.213-48.212C304.709,21.578,283.131,0,256.496,0
                                                    c-26.644,0-48.222,21.578-48.222,48.223C208.274,74.839,229.852,96.435,256.496,96.435z" style="fill: rgb(255, 215, 75);"></path>
                                                <path class="st0" d="M298.273,119.275h-42.272h-42.273c-23.364,0-48.781,25.418-48.781,48.79v162.062
                                                    c0,11.681,9.464,21.155,21.145,21.155c5.751,0,0,0,14.764,0l8.04,138.212c0,12.43,10.086,22.507,22.516,22.507
                                                    c5.246,0,14.917,0,24.589,0c9.671,0,19.343,0,24.588,0c12.43,0,22.516-10.077,22.516-22.507l8.04-138.212
                                                    c14.764,0,9.013,0,14.764,0c11.681,0,21.145-9.474,21.145-21.155V168.065C347.054,144.693,321.636,119.275,298.273,119.275z" style="fill: rgb(255, 215, 75);"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <label for="mynumber-some" class="radio-tile-label">{{ 'JYUMINHYOU.OPTION.MYNUMBER_SOME'
                                        | translate }}
                                    </label>
                                </div>
                            </label>
                        </div>
                        <div class="input-container">
                            <label for="mynumber-no">
                                <input id="mynumber-no" class="radio-button" type="radio" name="mynumber"
                                    [formControl]="pageForm.controls.mynumber" [value]="'false'" hidden />
                                <div class="radio-tile"
                                    [class.input-invalid]="pageForm.controls.mynumber.invalid && (pageForm.controls.mynumber.touched || pageForm.controls.mynumber.dirty)">
                                    <div class="icon mynumber-no-icon">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                            <style type="text/css">
                                                .st0{fill:#4B4B4B;}
                                            </style>
                                            <g>
                                                <path class="st0" d="M411.626,0H222.754c-13.552,0-26.56,5.39-36.148,14.969L68.794,132.788
                                                    c-9.594,9.587-14.976,22.596-14.976,36.156v296.5c0,25.67,20.892,46.556,46.564,46.556h311.243
                                                    c25.664,0,46.556-20.886,46.556-46.556V46.542C458.182,20.878,437.289,0,411.626,0z M206.248,32.349v104.652
                                                    c0,11.313-4.402,15.722-15.715,15.722H85.881L206.248,32.349z M431.998,465.444c0,11.237-9.142,20.372-20.372,20.372H100.382
                                                    c-11.245,0-20.38-9.135-20.38-20.372V175.998h124.55c13.77,0,24.978-11.207,24.978-24.985V26.184h182.096
                                                    c11.23,0,20.372,9.127,20.372,20.358V465.444z" style="fill: rgb(255, 215, 0);"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <label for="mynumber-no" class="radio-tile-label">{{ 'JYUMINHYOU.OPTION.PRINT_NO' |
                                        translate }}
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="cau-msg">
                    <a>
                        <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                        {{ 'JYUMINHYOU.OPTION.MYNUMBER_CAUTION' | translate }}
                    </a>
                </div>
                <ng-container *ngIf="pageForm.controls.mynumber.value=='some'">
                    <div class="inf-msg">
                        <a>
                          <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                          {{ 'JYUMINHYOU.OPTION.MYNUMBER_INFOMATION' | translate }}
                        </a>
                      </div>
                </ng-container>
            </div>
        </div>

        <div [hidden]="isHide()">
            <div class="box-koumoku">
                <div class="box-kakunin">
                    <div class="box-kakunin-message">
                        <div class="box-kakunin-message-col1">
                            <div class="kakunin-area">
                                <input type="checkbox" id="kakunin-check-gaijin" name="gaijinFlg"
                                    [formControl]="pageForm.controls.gaijinFlg" (change)="onGaijin()">
                                <label for="kakunin-check-gaijin">
                                </label>
                            </div>
                        </div>
                        <div class="box-kakunin-message-col2">
                            {{ 'JYUMINHYOU.OPTION.GAIJIN' | translate }}
                        </div>
                    </div>
                </div>
    
                <div class="box-sub-block" [hidden]="!pageForm.controls.gaijinFlg.value">
                    <div class="box-block-dairi-inner">
                        <!--記載事項-->
                        <div class="box-koumoku">
                            <div class="label-msg"><label>{{ 'JYUMINHYOU.OPTION.GAIJINKISAI' | translate }}</label></div>
                            <div class="box-control-multi">
                                <div class="select box-gaijinkisai">
                                    <ng-select class="ng-select-multi" [items]="gaijinkisai" [multiple]="true"
                                        [formControl]="pageForm.controls.gaijinkisaiObjects" [clearable]="false">
                                        <ng-template ng-header-tmp>
                                            <button (click)="selectAll()" class="ngselect">{{ 'COMMON.SELECT_ALL' |
                                                translate }}</button>
                                            <button (click)="unselectAll()" class="ngselect">{{ 'COMMON.CLEAR_ALL' |
                                                translate }}</button>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items">
                                                <span class="ng-value-label" *ngIf="item.label.length <= 20">
                                                    {{item.label}}</span>
                                                <span class="ng-value-label" *ngIf="item.label.length > 20">
                                                    {{item.label.slice(0,20)}}...</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            
        </div>

        <div class="box-koumoku">
            <div class="inf-msg">
                <a>
                    <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                    {{ 'JYUMINHYOU.OPTION.MULTIPLE_OPTION' | translate }}
                </a>
            </div>
        </div>

    </div>
</div>