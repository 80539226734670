import { Component, OnInit, Input } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

export class Step {
  step: number;
  title: string;
}

@Component({
  selector: 'app-stepver',
  templateUrl: './stepver.component.html',
  styleUrls: ['./stepver.component.css']
})
export class StepverComponent implements OnInit {
  @Input() currentStep: number;
  @Input()steps : Step[];
  fontCSS

  constructor(public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event)


      if (ret.lang=='en' || ret.lang=='vi'){
        this.fontCSS = 'stepfont-small';
      }
      else
      {
        if (this.steps.length > 7) {
          this.fontCSS = 'stepfont-midium';
        }
        else{
          this.fontCSS = 'stepfont-big';
        }
      }
    });
  }

  ngOnInit(): void {
  }

  getStepState(step: Step){
    const state = this.currentStep === step.step 
      ? "is-active"
      : "";
    return state;
  }

}
