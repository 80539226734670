<div class="box-block">
    <div class="box-koumoku">
        <h4>{{ 'IRYOKODOMO.HOKEN.TITLE_HOKEN' | translate }}</h4>
    </div>
    <div class="box-block-inner">

        <div class="box-koumoku">
            <div class="box-help">
                <a>
                    {{ 'IRYOKODOMO.HOKEN.SETUMEI_HOKEN' | translate }}
                </a>
            </div>
        </div>

        <div class="box-koumoku">
            <div class="label-msg"><label>{{ 'IRYOKODOMO.HOKEN.HOKEN' | translate }}</label></div>
            <div class="box-hoken">

                <div class="box-koumoku">
                    <div class="label-msg"><label>{{ 'COMMON.HKNNAME' | translate }}</label></div>
                    <ng-container
                        *ngIf="pageForm.controls.hoken_hoken_name.hasError('zenkaku') && (pageForm.controls.hoken_hoken_name.touched || pageForm.controls.hoken_hoken_name.dirty)">
                        <div class="err-msg">
                            <a>
                                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                {{ 'COMMON.HKNNAME_ERROR_TYPE' | translate }}
                            </a>
                        </div>
                    </ng-container>
                    <div class="box-control">
                        <input class="input-text" type="text" [formControl]="pageForm.controls.hoken_hoken_name" maxlength="20">
                    </div>
                </div>

                <!--保険の種類-->
                <div class="box-koumoku">
                    <div class="label-msg"><label>{{ 'COMMON.HKNSYURUI' | translate }}</label></div>
                    <div class="box-control">
                        <div class="select box-hokensyurui"
                            [class.input-invalid]="pageForm.controls.hoken_hoken_syurui.invalid && (pageForm.controls.hoken_hoken_syurui.touched || pageForm.controls.hoken_hoken_syurui.dirty)">
                            <select name="hoken_hoken_syurui" [formControl]="pageForm.controls.hoken_hoken_syurui">
                                <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}
                                </option>
                                <option *ngFor="let hoken_val of hoken" [ngValue]="hoken_val">{{hoken_val}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="box-koumoku">
                    <div class="label-msg"><label>{{ 'COMMON.HHSNAME' | translate }}</label></div>
                    <ng-container
                        *ngIf="pageForm.controls.hoken_hhs_name.hasError('zenkaku') && (pageForm.controls.hoken_hhs_name.touched || pageForm.controls.hoken_hhs_name.dirty)">
                        <div class="err-msg">
                            <a>
                                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                                {{ 'COMMON.HHSNAME_ERROR_TYPE' | translate }}
                            </a>
                        </div>
                    </ng-container>
                    <div class="box-control">
                        <input class="input-text" type="text" [formControl]="pageForm.controls.hoken_hhs_name" maxlength="20">
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>