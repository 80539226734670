import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { getGengoStringFromYear, toJstTime, formatyyMMdd } from '@utility/date-functions';
import { addMonths } from 'date-fns';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DatepickerComponent implements OnInit {
  @Input() fgName: string;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  parentForm: FormGroup;
  years: Array<{ year: number; wareki: string; }>;
  months: Array<number>;
  days: Array<number>;

  // 通常年の日数/月
  private daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // うるう年の日数/月
  private daysOfMonthOfLeap = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  constructor(private fgd: FormGroupDirective, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    const today = toJstTime(new Date());
    this.years = this.getYears();
    this.months = this.getMonths();
    this.days = this.getDays(today.getFullYear(), today.getMonth() + 1);

    this.parentForm = this.fgd.form;
    this.parentForm.addControl(this.fgName, this.fb.group({
      year: [today.getFullYear(), [Validators.required]],
      month: [today.getMonth() + 1, [Validators.required]],
      day: [today.getDate(), [Validators.required]],
    },
    ));
  }

  getYears(): Array<{ year: number; wareki: string; }> {
    // 年の最大は1ヶ月後にする
    const maxYear: number = addMonths(toJstTime(new Date()), 1).getFullYear();

    const years = new Array<{ year: number; wareki: string; }>();
    for (let i = 1900; i <= maxYear; i++) {
      years.push(
        {
          year: i,
          wareki: getGengoStringFromYear(i)
        });
    }
    return years;
  }

  getMonths(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  getDays(year: number, month: number): number[] {
    const days = [];
    const monthIndex = month - 1;

    const maxDay = (this.isLeapYear(year)
      ? this.daysOfMonthOfLeap
      : this.daysOfMonth)[monthIndex];

    for (let i = 1; i <= maxDay; i++) {
      days.push(i);
    }
    return days;
  }

  private isLeapYear(year: number): boolean {
    // 4で割り切れて、100で割り切れなければうるう年
    // ただし400で割り切れればうるう年
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  onChange() {
    this.days = this.getDays(this.controlName('year').value, this.controlName('month').value);

    if (!this.days.includes(this.controlName('day').value)){
      //無い場合、最終日
      this.controlName('day').setValue(this.days.slice(-1)[0]);
    }
  }

  onChangeValue() {
    this.valueChange.emit(toJstTime(new Date(formatyyMMdd(this.controlName('year').value, this.controlName('month').value, this.controlName('day').value))));
  }

  controlName(key) {
    return (this.parentForm.get(this.fgName).get(key));
  }

}
