import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({ 
  selector: 'app-cyouhyou-modal',
  templateUrl: './cyouhyou-modal.component.html',
  styleUrls: ['./cyouhyou-modal.component.css'],//,'../../../common/css/rigel.css'],
  animations: [
    trigger('openClose', [
          state('open', style({
             opacity: 1,
          })),
          state('closed', style({
             display: 'none',
             opacity: 0,
          })),
          transition('open => closed', [
             animate('0.2s')
          ]),
          transition('closed => open', [
             animate('0.2s')
          ]),
          transition('* => void', [
             animate('0.2s')
          ]),
          transition('void => *', [
             animate('0.2s')
          ]),
    ])
 ]
})
export class CyouhyouModalComponent implements OnInit {

  @Input() data: any;

  isOpen: boolean;

  cyouhyouForm: FormGroup;
  
  get selectedCyouhyouFormArray(){
     return this.cyouhyouForm.controls.selectedCyouhyou as FormArray;
  }

  constructor(private fb: FormBuilder) {
     this.isOpen = true;
     this.cyouhyouForm = fb.group({
      selectedCyouhyou: new FormArray([])
     })
  }

  ngOnInit(): void {
   for (let i= 0;i< this.data.item.length;i++){
     this.selectedCyouhyouFormArray.push(new FormControl(false));
   };
  }

  closeModal() {
     this.isOpen = false;
  }

  getCyouhyouId(){
   const chyouhyouId = this.cyouhyouForm.value.selectedCyouhyou
   .map((checked, i) => checked ? this.data.item[i].cd : null)
   .filter(v => v !== null);
   
   return chyouhyouId

   }

}
