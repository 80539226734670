<div class="box-main-error">
    <div class="box-top">
        <div class="box-title">
            <div>
                <h1>ページが見つかりません。</h1>
            </div>
        </div>
    </div>

    <div class="box-contents-background">
        <div class="box-contents">
            <div class="box-block">
                <div class="box-koumoku">
                </div>
                <div class="box-block-inner">
                    <p class="resizeimage">
                        <img class="page-not-found" src="/assets/404.png">
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>