<form [formGroup]="pageForm">
  <div formArrayName="setaiins">
    <div class="box-block" *ngFor="let setaiin of setaiins.controls; let i = index">

      <div class="box-idosya-title">
        <div class="box-koumoku">
          <h4>{{ 'JYUKIIDO.IDOSYA.TITLE_IDOSYA' | translate }}（{{ i+1 }}/{{ pageForm.controls.setaiins.length }}）</h4>
        </div>
        <ng-container *ngIf="i!=0">
          <a class="btn-delete" (click)="removeSetaiin(i)">
            <fa-icon [icon]="faUserSlash"></fa-icon>
          </a>
        </ng-container>
      </div>

      <div class="box-block-inner">

        <div [formGroupName]="i">

          <ng-container *ngIf="i==0">
            <form [formGroup]="setaiin">
              <div class="box-koumoku">
                <div class="box-required">
                  <img class="required" src="/assets/required.png">
                  <label>{{ 'COMMON.SHIMEI' | translate }}</label>
                </div>

                <app-shimei title="{{ 'COMMON.SETAIIN' | translate }}" [buttonVisible]="false" fgName={{fgNameShimei}}
                  ckName="{{ckNameShimei + i}}" defaltKbn="1">
                </app-shimei>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="i!=0">
            <form [formGroup]="setaiin">
              <div class="box-koumoku">
                <app-shimei title="{{ 'COMMON.SETAIIN' | translate }}" [buttonVisible]="true"
                  buttonTitle="{{ 'JYUKIIDO.IDOSYA.PRE_SEI_COPY' | translate }}" [copyShimei]="getUji(i-1)"
                  fgName={{fgNameShimei}} ckName="{{ckNameShimei + i}}" defaltKbn="1">
                </app-shimei>
              </div>
            </form>
          </ng-container>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.BIRTHDAY' | translate }}</label></div>
            </div>
            <form [formGroup]="setaiin">
              <app-datepicker fgName={{fgNameDatepicker}}></app-datepicker>
            </form>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.SEX' | translate }}</label></div>
            </div>
            <form [formGroup]="setaiin">
              <!-- ckNameはjyukiidoの中で一意になるようにする -->
              <app-sex fgName={{fgNameSex}} rdName="{{getRadioName(i)}}"></app-sex>
            </form>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.ZOKUGARA' | translate }}</label></div>
            </div>
            <ng-container
              *ngIf="pageForm.controls.setaiins.controls[i].controls.zokugara.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.zokugara.touched || pageForm.controls.setaiins.controls[i].controls.zokugara.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.ZOKUGARA_ERROR_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <div class="select box-zokugara"
                [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.zokugara.invalid && (pageForm.controls.setaiins.controls[i].controls.zokugara.touched || pageForm.controls.setaiins.controls[i].controls.zokugara.dirty)">
                <select formControlName="zokugara" (change)="onZokugaraChange(i)">
                  <option disabled="disabled" [value]="null" selected="true">{{ 'COMMON.SELECT' | translate }}</option>
                  <option *ngFor="let zokugara_val of zokugara" [ngValue]="zokugara_val.value">
                    {{zokugara_val.label | translate}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="box-koumoku">
            <div class="inf-msg">
              <a>
                <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                <ng-container *ngIf="item.sj201_snsi_syurui=='01'">
                  {{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SETUMEI_01' | translate }}
                </ng-container>
                <ng-container *ngIf="item.sj201_snsi_syurui=='02'">
                  {{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SETUMEI_02' | translate }}
                </ng-container>
                <ng-container *ngIf="item.sj201_snsi_syurui=='03'">
                  {{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SETUMEI_03' | translate }}
                </ng-container>
              </a>
            </div>
          </div>

          <ng-container *ngIf="pageForm.controls.setaiins.controls[i].controls.zokugara.value=='99'">
            <div class="box-koumoku">
              <div class="label-msg"><label>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA' | translate }}</label></div>
              <div class="box-control">
                <input class="input-text" type="text" formControlName="zokugara_sonota" maxlength="10">
              </div>
              <div class="box-setumei">
                <a>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA_SETUMEI' | translate }}</a>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="pageForm.controls.setaiins.value.length < 6">
  <div class="box-add-setaiin">
    <a class="btn-add-setaiin" (click)="addSetaiin()">{{ 'JYUKIIDO.IDOSYA.ADD_MEMBER' | translate }}</a>
  </div>
</ng-container>

<!-- Debug用の表示 
<h3>Debug</h3>
<ng-container *ngIf="form.value">
  <pre>{{ form.value | json }}</pre>
</ng-container>
-->