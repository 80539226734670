<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JYUMINHYOU.SHINSEI.TITLE_TAISYOUSYA' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'JYUMINHYOU.SHINSEI.SETUMEI_TAISYOUSYA' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.TAISYOUSYA' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiTisy}} ckName={{ckNameShimeiTisy}} defaltKbn="1">
        </app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerTisyUmare}}></app-datepicker>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcodeTisy}} ckName={{ckNameZipcodeTisy}} defaltKbn="1"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.tisy_telno.hasError('numeric') && (pageForm.controls.tisy_telno.touched || pageForm.controls.tisy_telno.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.tisy_telno.invalid && (pageForm.controls.tisy_telno.touched || pageForm.controls.tisy_telno.dirty)"
          class="input-text input-tel" type="tel" maxlength="11" name="tisy_telno"
          [formControl]="pageForm.controls.tisy_telno" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>



  </div>
</div>



<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'COMMON.TITLE_DAIRI' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'JYUMINHYOU.SHINSEI.SETUMEI_DAIRI' | translate }}
        </a>
      </div>
    </div>



    <div class="box-required">
      <img class="required" src="/assets/required.png">
      <label>{{ 'COMMON.RAICYOUSYA' | translate }}</label>
    </div>

    <ng-container
      *ngIf="pageForm.controls.dairiKbn.hasError('required') && (pageForm.controls.dairiKbn.touched || pageForm.controls.dairiKbn.dirty)">
      <div class="err-msg">
        <a>
          <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
          {{ 'COMMON.RAICYOUSYA_ERROR_REQUIRED' | translate }}
        </a>
      </div>
    </ng-container>

    <div class="box-koumoku">
      <div class="dairi-radio-tile-group">
        <div class="input-container input-container-left">
          <label for="{{getId('honnin')}}">
            <input id="{{getId('honnin')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiKbn"
              (change)="onDairi()" [value]="1" name={{rdName}} hidden />
            <div class="radio-tile"
              [class.input-invalid]="pageForm.controls.dairiKbn.invalid && (pageForm.controls.dairiKbn.touched || pageForm.controls.dairiKbn.dirty)">
              <div class="icon honnin-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                  viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #4B4B4B;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M449.688,422.344c-3.375-9.109-8.094-16.906-13.703-23.5c-4.906-5.766-10.484-10.625-16.391-14.781
                      c-10.344-7.297-21.719-12.484-32.875-16.922c-8.359-3.328-16.625-6.203-24.266-9.109c-5.734-2.172-11.109-4.344-15.891-6.688
                      c-3.594-1.734-6.844-3.563-9.688-5.5c-2.125-1.469-4.016-2.969-5.656-4.547c-2.469-2.391-4.406-4.891-5.813-7.703
                      c-1.438-2.828-2.406-5.984-2.813-9.844c-0.313-3.063-0.469-6.188-0.469-9.281c0-9.125,1.266-18.094,2.781-25.484
                      c4.594-5.188,8.797-11.359,12.766-18.75c3.984-7.438,7.766-16.109,11.547-26.391c2.422-1.094,4.844-2.422,7.25-4.031
                      c5.422-3.641,10.578-8.734,14.875-15.516c4.281-6.781,7.734-15.203,10.094-25.609c0.641-2.875,0.953-5.766,0.953-8.578
                      c0-5.906-1.391-11.531-3.734-16.563c-1.625-3.484-3.688-6.672-6.094-9.578c1.234-4.531,2.719-10.641,4.047-17.781
                      c1.828-9.938,3.359-21.844,3.359-34.375c0-8.078-0.641-16.438-2.281-24.672c-1.234-6.172-3.031-12.297-5.594-18.203
                      c-3.844-8.813-9.391-17.109-17.156-23.969c-7.156-6.359-16.156-11.422-27-14.672c-3.828-4.578-7.688-8.516-11.641-11.828
                      c-3.391-2.844-6.891-5.25-10.469-7.219c-5.359-2.969-10.922-4.969-16.625-6.156c-5.703-1.203-11.5-1.625-17.516-1.625
                      c-8.313,0-17.047,0.813-26.703,1.781c-3.641,0.359-6.703,0.813-9.359,1.281c-3.953,0.703-6.922,1.438-9.313,1.906
                      c-1.188,0.25-2.219,0.438-3.25,0.563c-1.016,0.109-2.016,0.188-3.156,0.188c-1.5,0-3.281-0.125-5.563-0.469
                      c-7.031-1-13.453-2.609-18.5-4.156c-2.531-0.766-4.688-1.516-6.406-2.156c-0.844-0.328-1.594-0.625-2.188-0.891
                      c-0.594-0.25-1.063-0.453-1.313-0.578l0,0c-0.766-0.391-1.5-0.625-2.188-0.766C183.094,0.031,182.5,0,182,0
                      c-1.078,0-1.828,0.172-2.438,0.313c-0.609,0.156-1.078,0.328-1.5,0.516c-0.797,0.328-1.438,0.672-2.047,1.047
                      c-1.141,0.656-2.203,1.438-3.359,2.313c-2.016,1.563-4.219,3.5-6.563,5.75c-3.5,3.391-7.25,7.469-10.672,11.938
                      c-1.703,2.25-3.313,4.563-4.75,6.969c-1.453,2.406-2.719,4.875-3.734,7.469c-4.922,12.844-8.188,24.656-10.219,35.578
                      s-2.813,20.922-2.813,30.078c0,15.016,2.109,27.75,4.125,38.5c0,0.516,0.031,1,0.047,1.5c0.078,1.016,0.172,2.063,0.266,3.141
                      c0.172,1.625,0.375,3.297,0.531,4.688c0.063,0.688,0.125,1.313,0.156,1.797c0.031,0.25,0.031,0.438,0.031,0.594l0.031,0.156v0.063
                      l0,0v0.922l1.969,8.781c-2.844,3.109-5.313,6.578-7.219,10.391c-2.656,5.313-4.234,11.297-4.234,17.625
                      c0,2.813,0.313,5.688,0.953,8.563c1.578,6.938,3.625,13,6.109,18.281c3.688,7.922,8.359,14.094,13.563,18.719
                      c4.016,3.594,8.313,6.219,12.563,8.156c3.766,10.281,7.547,18.938,11.547,26.375c3.969,7.391,8.156,13.563,12.75,18.75
                      c1.5,7.375,2.781,16.375,2.781,25.516c0,3.094-0.156,6.203-0.469,9.266c-0.313,3.031-0.984,5.625-1.938,7.984
                      c-0.906,2.203-2.078,4.172-3.516,6.047c-2.703,3.516-6.453,6.672-11.203,9.594c-4.156,2.563-9.063,4.938-14.469,7.188
                      c-9.438,3.984-20.406,7.641-31.578,12.016c-8.391,3.281-16.938,6.984-25.109,11.641c-6.125,3.5-12.031,7.547-17.469,12.375
                      c-8.172,7.25-15.25,16.313-20.156,27.625c-4.906,11.297-7.656,24.75-7.641,40.688c0,2.188,0.453,4.313,1.219,6.25
                      c0.719,1.813,1.672,3.484,2.828,5.063c2.188,2.938,4.969,5.594,8.406,8.141c6.016,4.422,14.031,8.594,24.375,12.5
                      c15.531,5.859,36.328,11.109,63.219,14.938C183.281,509.625,216.25,512,256,512c34.453,0,63.828-1.797,88.547-4.75
                      c18.547-2.234,34.484-5.125,48.016-8.422c10.156-2.484,18.938-5.188,26.5-8.047c5.656-2.156,10.609-4.375,14.922-6.688
                      c3.234-1.719,6.094-3.484,8.625-5.297c3.781-2.734,6.828-5.578,9.141-8.781c1.156-1.609,2.125-3.313,2.813-5.172
                      c0.688-1.844,1.109-3.844,1.109-5.938C455.672,444.875,453.563,432.766,449.688,422.344z M189,262.344
                      c-3.906-7.25-7.781-16.297-11.781-27.563l-1.313-3.688l-3.656-1.344c-2.547-0.938-5.031-2.141-7.406-3.734
                      c-3.578-2.422-7-5.703-10.109-10.609c-3.109-4.922-5.922-11.5-7.922-20.391c-0.391-1.688-0.563-3.297-0.563-4.891
                      c0-3.563,0.875-6.938,2.484-10.188c1.328-2.656,3.203-5.219,5.469-7.5c3.828,5.781,6.984,10.344,8.141,11.5
                      c3.75,3.75,4.281-5.063,2.5-14.969c-3.281-18.234,16.688-39.875,18.719-82.375C228.5,96.563,316.344,77.5,316.344,77.5
                      s0.938,21.813,15.906,41.766c14.219,18.953,22.297,63.422,22.297,63.422s1.953-3.672,4.828-8.578
                      c1.719,2.016,3.141,4.188,4.188,6.453c1.438,3.063,2.188,6.219,2.188,9.547c0,1.594-0.172,3.219-0.563,4.906
                      c-1.328,5.922-3.031,10.828-4.922,14.891c-2.859,6.094-6.141,10.281-9.578,13.359c-3.453,3.078-7.094,5.078-10.906,6.469
                      l-3.672,1.359l-1.313,3.688c-3.984,11.266-7.891,20.313-11.797,27.578c-3.891,7.266-7.781,12.703-11.813,16.984l-1.594,1.672
                      l-0.5,2.266c-1.875,8.563-3.594,19.5-3.594,31.188c0,3.609,0.156,7.297,0.531,11c0.469,4.5,1.531,8.703,3.109,12.578
                      c1.484,3.609,3.422,6.922,5.719,9.891c0.563,0.719,1.141,1.438,1.734,2.125c-0.625,1.156-1.297,2.266-2.047,3.375
                      c-2.891,4.188-6.75,8.031-11.297,11.406c-6.813,5.063-15.156,9.031-23.5,11.641c-4.188,1.328-8.344,2.297-12.313,2.953
                      s-7.719,0.969-11.031,0.969c-5.031,0-11.094-0.734-17.375-2.25c-9.438-2.281-19.359-6.344-27.453-11.906
                      c-4.063-2.75-7.656-5.891-10.578-9.313c-2.031-2.359-3.719-4.859-5.094-7.484c2.125-2.531,4-5.297,5.547-8.344
                      c2.375-4.656,3.922-9.922,4.516-15.641c0.375-3.703,0.531-7.375,0.531-10.984c0-11.703-1.719-22.641-3.594-31.234l-0.5-2.25
                      l-1.594-1.688C196.781,275.063,192.891,269.594,189,262.344z M438.594,459.75c-0.563,0.953-1.859,2.516-4.063,4.281
                      c-1.906,1.531-4.484,3.266-7.734,5.031c-5.688,3.125-13.391,6.375-23.188,9.438c-14.688,4.625-34.078,8.844-58.531,11.906
                      c-24.469,3.063-54,4.953-89.078,4.953c-33.875,0-62.594-1.766-86.563-4.641c-17.984-2.156-33.281-4.938-46.063-8.063
                      c-9.563-2.344-17.734-4.875-24.531-7.438c-5.094-1.938-9.422-3.891-12.984-5.797c-2.672-1.422-4.922-2.828-6.75-4.141
                      c-2.734-1.969-4.484-3.75-5.344-4.969c-0.438-0.594-0.656-1.047-0.734-1.281c-0.047-0.109-0.063-0.156-0.063-0.188
                      c0-12.359,1.859-22.375,4.938-30.719c2.719-7.281,6.375-13.281,10.781-18.5c3.875-4.547,8.344-8.469,13.297-11.969
                      c8.672-6.125,18.828-10.844,29.422-15.047c7.938-3.156,16.109-6,24.047-9.016c5.953-2.25,11.766-4.594,17.266-7.266
                      c3.594-1.766,7.063-3.672,10.344-5.797c0.484,0.781,1,1.563,1.516,2.328c4.109,6,9.328,11.078,15.094,15.359
                      c8.656,6.406,18.578,11.031,28.422,14.156c4.922,1.547,9.844,2.719,14.609,3.5c4.766,0.766,9.375,1.188,13.703,1.188
                      c6.625-0.016,13.875-0.938,21.313-2.75c11.156-2.703,22.781-7.375,32.938-14.313c5.063-3.469,9.781-7.516,13.813-12.219
                      c1.844-2.156,3.531-4.438,5.063-6.875c0.328,0.219,0.656,0.438,1,0.656c5.219,3.188,10.844,5.875,16.734,8.344
                      c10.266,4.313,21.297,7.984,31.969,12.172c8,3.125,15.797,6.547,22.922,10.594c5.313,3.047,10.266,6.453,14.672,10.375
                      c6.609,5.875,12.031,12.844,15.938,21.797c3.875,8.938,6.25,19.953,6.266,34C439,458.938,438.906,459.266,438.594,459.75z"
                      style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('honnin')}}" class="radio-tile-label">{{ 'COMMON.HONNIN' | translate }}</label>

            </div>
          </label>
        </div>

        <div class="input-container input-container-middle">
          <label for="{{getId('kazoku')}}">
            <input id="{{getId('kazoku')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiKbn"
              (change)="onDairi()" [value]="2" name={{rdName}} hidden />
            <div class="radio-tile"
              [class.input-invalid]="pageForm.controls.dairiKbn.invalid && (pageForm.controls.dairiKbn.touched || pageForm.controls.dairiKbn.dirty)">
              <div class="icon kazoku-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                  viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #4B4B4B;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M453.5,429.797c-1.984-7.484-4.781-14.203-8.25-20.188c-6.031-10.516-14.063-18.703-22.938-25.203
                      c-6.656-4.906-13.781-8.891-21.016-12.313C390.438,366.938,379.281,363,369,359.203c-10.313-3.797-19.797-7.484-27.234-11.766
                      l-0.828-0.469c-2.688-1.563-5.063-3.219-7.156-4.938c-3.156-2.625-5.563-5.406-7.344-8.516c-1.75-3.109-2.953-6.609-3.406-10.984
                      c-0.328-3.094-0.469-2.891-0.469-6c0-9.188,1.266-18.25,2.797-25.656c4.625-5.219,8.844-11.438,12.844-18.875
                      c4.016-7.469,7.828-16.219,11.625-26.563c2.422-1.125,4.891-2.438,7.297-4.063c5.469-3.656,10.656-8.781,14.969-15.625
                      c4.328-6.813,7.781-15.297,10.156-25.781c0.656-2.875,0.969-5.781,0.969-8.625c0.016-5.938-1.391-11.594-3.766-16.688
                      c-1.609-3.469-3.703-6.688-6.125-9.625c1.234-4.563,2.734-10.688,4.063-17.906c1.859-9.969,3.391-21.969,3.391-34.563
                      c0-8.156-0.641-16.563-2.297-24.844c-1.234-6.219-3.047-12.406-5.625-18.313c-3.859-8.875-9.453-17.25-17.266-24.156
                      c-7.219-6.406-16.25-11.484-27.188-14.75c-3.844-4.625-7.719-8.563-11.719-11.906c-3.422-2.875-6.938-5.313-10.531-7.281
                      c-5.391-3-11-4.969-16.734-6.188s-11.578-1.656-17.641-1.656c-8.359,0.031-17.156,0.844-26.875,1.797
                      c-3.656,0.375-6.75,0.828-9.406,1.297c-4,0.719-6.969,1.469-9.375,1.938c-1.188,0.25-2.234,0.438-3.281,0.531
                      c-1.016,0.156-2.031,0.219-3.156,0.219c-1.531,0-3.313-0.125-5.609-0.469C207,7.75,200.531,6.156,195.469,4.594
                      c-2.547-0.781-4.75-1.531-6.469-2.188c-0.859-0.313-1.594-0.625-2.188-0.875c-0.609-0.25-1.078-0.469-1.344-0.594
                      c-0.75-0.406-1.5-0.625-2.188-0.766C182.609,0.031,182.016,0,181.5,0c-1.078,0-1.828,0.156-2.469,0.313
                      c-0.594,0.172-1.063,0.344-1.469,0.516c-0.813,0.328-1.438,0.672-2.063,1.047c-1.156,0.688-2.219,1.453-3.391,2.344
                      c-2.016,1.563-4.25,3.5-6.609,5.781c-3.516,3.406-7.297,7.531-10.719,12.016c-1.719,2.234-3.344,4.594-4.797,7.016
                      c-1.453,2.438-2.734,4.922-3.734,7.531c-4.969,12.906-8.281,24.813-10.313,35.781c-2.031,11-2.828,21.063-2.828,30.297
                      c0,15.109,2.125,27.922,4.141,38.734c0,0.531,0.031,1.016,0.063,1.531c0.063,1,0.156,2.063,0.281,3.156
                      c0.156,1.625,0.375,3.313,0.5,4.703c0.063,0.703,0.125,1.328,0.172,1.828c0.031,0.219,0.047,0.438,0.047,0.594v0.156v0.031v0.016
                      v0.922l2,8.844c-2.859,3.125-5.344,6.625-7.266,10.469c-2.672,5.344-4.266,11.375-4.266,17.734c0,2.828,0.313,5.734,0.969,8.641
                      v-0.031c1.594,7,3.656,13.094,6.156,18.406c3.688,7.984,8.406,14.188,13.641,18.844c4.047,3.625,8.359,6.266,12.641,8.219
                      c3.813,10.344,7.594,19.063,11.609,26.531c4,7.469,8.219,13.656,12.844,18.875c1.516,7.438,2.797,16.5,2.797,25.703
                      c0,3.109-0.141,2.891-0.469,5.984l0,0c-0.313,3-0.969,5.609-1.938,7.938c-1.406,3.5-3.5,6.5-6.328,9.297
                      c-2.797,2.797-6.391,5.391-10.641,7.797l-3.328,1.781c-3.547,1.813-7.422,3.531-11.563,5.219
                      c-9.547,3.875-20.453,7.531-31.5,11.922c-8.281,3.313-16.672,7.063-24.656,11.781c-5.984,3.516-11.781,7.625-17.078,12.484
                      c-7.953,7.281-14.797,16.344-19.531,27.563c-4.75,11.25-7.391,24.531-7.391,40.25c0,2.188,0.484,4.344,1.25,6.281
                      c0.703,1.813,1.688,3.5,2.828,5.094c2.188,2.969,5,5.625,8.469,8.188c6.047,4.469,14.109,8.641,24.531,12.594
                      c15.625,5.891,36.563,11.188,63.641,15.031C182.797,509.594,216,512,256,512c34.688,0,64.25-1.813,89.125-4.781
                      c18.672-2.25,34.719-5.156,48.344-8.469c10.203-2.5,19.063-5.234,26.656-8.125c5.688-2.156,10.688-4.406,15.016-6.719
                      c3.266-1.719,6.141-3.5,8.703-5.344c3.813-2.75,6.875-5.594,9.188-8.813c1.156-1.625,2.125-3.344,2.844-5.219
                      c0.672-1.844,1.109-3.875,1.109-5.969C456.984,447.906,455.781,438.344,453.5,429.797z M239.453,430.656l-53.375-73.531
                      c0.688-0.5,1.359-1.031,2.031-1.547c2.938-2.344,5.641-4.984,8.016-7.953l43.031,19.969l-9.203,15.313l14.234,15.063
                      L239.453,430.656z M202.25,337.531c1.719-4.047,2.906-8.5,3.391-13.281c0.375-3.719,0.547-4.094,0.547-7.703
                      c-0.016-11.781-1.75-22.797-3.625-31.438l-0.5-2.266l-1.609-1.703c-4.063-4.297-7.969-9.766-11.891-17.078
                      c-3.938-7.313-7.844-16.406-11.844-27.75l-1.328-3.719l-3.703-1.344c-2.547-0.938-5.031-2.156-7.438-3.766
                      c-3.625-2.438-7.047-5.734-10.188-10.672c-3.125-4.938-5.953-11.563-7.969-20.531c-0.375-1.688-0.563-3.328-0.563-4.922
                      c0-3.563,0.875-6.969,2.5-10.234c1.344-2.688,3.234-5.25,5.5-7.563c3.859,5.813,7.031,10.422,8.188,11.578
                      c3.781,3.766,4.313-5.109,2.531-15.078c-3.313-18.344,16.781-40.125,18.844-82.906C228.313,97.203,316.719,78,316.719,78
                      s0.938,21.969,16.031,42.047c14.313,19.078,22.438,63.828,22.438,63.828s1.953-3.688,4.844-8.625c1.75,2.031,3.188,4.219,4.25,6.5
                      c1.422,3.063,2.188,6.25,2.188,9.594c0,1.609-0.172,3.25-0.563,4.938c-1.344,5.969-3.047,10.906-4.969,14.984
                      c-2.875,6.141-6.172,10.359-9.625,13.484c-3.469,3.063-7.156,5.094-11,6.5l-3.688,1.344l-1.313,3.703
                      c-4.031,11.359-7.938,20.453-11.859,27.781c-3.922,7.297-7.859,12.797-11.922,17.078l-1.594,1.703l-0.5,2.266
                      c-1.875,8.625-3.625,19.641-3.625,31.406c0,3.625,0.156,3.984,0.563,7.719c0.438,4.344,1.438,8.422,2.922,12.156
                      c0.078,0.188,0.141,0.375,0.234,0.531l-50.906,26.75L202.25,337.531z M276.781,434.156l-5.25-36.188l14.203-15.063l-9.219-15.375
                      l38.922-20.438c1.094,1.375,2.219,2.688,3.422,3.922c2.234,2.281,4.625,4.328,7.188,6.219L276.781,434.156z"
                      style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('kazoku')}}" class="radio-tile-label">{{ 'COMMON.KAZOKU' | translate }}</label>

            </div>
          </label>
        </div>

        <div class="input-container input-container-right">
          <label for="{{getId('dairi')}}">
            <input id="{{getId('dairi')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiKbn"
              (change)="onDairi()" [value]="3" name={{rdName}} hidden />
            <div class="radio-tile"
              [class.input-invalid]="pageForm.controls.dairiKbn.invalid && (pageForm.controls.dairiKbn.touched || pageForm.controls.dairiKbn.dirty)">
              <div class="icon dairi-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0{fill:#4B4B4B;}
                  </style>
                  <g>
                    <path class="st0" d="M316.761,327.239c-1.49-14.854,0.701-29.826,3.158-40.851c9.621-10.09,17.888-25.136,26.434-48.966
                      c12.758-4.626,25.74-15.365,31.846-41.93c2.812-12.234-1.951-23.573-10.228-31.761c6.096-20.18,30.144-113.704-40.967-132.955
                      C303.445,1.098,282.724,2.203,247.201,5.62c-17.67,1.699-20.196,5.444-33.746,3.424c-15.541-2.32-28.136-7.086-31.924-8.996
                      c-2.182-1.105-22.758,17.016-28.528,31.85c-18.279,46.991-13.539,79.284-9.025,103.714c-0.162,2.498-0.379,4.988-0.389,7.516
                      l4.15,17.898c0.012,0.184-0.002,0.36,0.004,0.544c-9.207,8.19-14.779,20.068-11.908,33.011
                      c5.906,26.618,18.815,37.451,31.545,42.17c8.363,23.882,16.504,38.984,26.045,49.146c2.379,11.036,4.459,26.037,2.856,40.888
                      c-6.697,62.214-137.533,28.938-137.946,138.217c-0.064,16.853,56.301,46.459,197.49,46.989
                      c141.19,0.531,197.776-28.648,197.838-45.501C454.076,357.21,322.992,389.502,316.761,327.239z M232.972,493.819l-35.295-145.722
                      l44.027,18.518l-2.453,21.296l7.006-0.125L232.972,493.819z M276.828,388.052l-2.291-21.314l44.164-18.186L282.31,494.005
                      l-12.486-106.131L276.828,388.052z" style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('dairi')}}" class="radio-tile-label">{{ 'COMMON.DAIRI' | translate }}</label>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="box-koumoku">
      <div [hidden]="!(pageForm.controls.dairiKbn.value==2 || pageForm.controls.dairiKbn.value==3)">

        <div class="box-koumoku">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <ng-container *ngIf="pageForm.controls.dairiKbn.value==2">
              <label>{{ 'COMMON.KAZOKU' | translate }}</label>
            </ng-container>
            <ng-container *ngIf="pageForm.controls.dairiKbn.value==3">
              <label>{{ 'COMMON.DAIRI' | translate }}</label>
            </ng-container>
          </div>

          <form [formGroup]="pageForm">
            <app-shimei [buttonVisible]="pageForm.controls.dairiKbn.value==2"
              buttonTitle="{{ 'JYUMINHYOU.SHINSEI.TAISYO_SEI_COPY' | translate }}" [copyShimei]="getUji()"
              fgName={{fgNameShimeiDairi}} ckName={{ckNameShimeiDairi}} defaltKbn="1">
            </app-shimei>
          </form>
        </div>

        <div [hidden]="!(pageForm.controls.dairiKbn.value==3)">

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <label>{{ 'COMMON.ADDR' | translate }}</label>
            </div>

            <form [formGroup]="pageForm">
              <app-zipcode fgName={{fgNameZipcodeDairi}} ckName={{ckNameZipcodeDairi}} defaltKbn="3"></app-zipcode>
            </form>
          </div>

          <div class="box-koumoku">
            <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
            <ng-container
              *ngIf="pageForm.controls.dairi_telno.hasError('numeric') && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.TEL_ERROR_TYPE' | translate }}
                </a>
              </div>
            </ng-container>
            <div class="box-control">
              <input
                [class.input-invalid]="pageForm.controls.dairi_telno.invalid && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)"
                class="input-text input-tel" type="tel" maxlength="11" name="dairi_telno"
                [formControl]="pageForm.controls.dairi_telno" autocomplete="tel">
            </div>
            <div class="box-setumei">
              <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
            </div>
          </div>

          <ng-container *ngIf="false">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <label>{{ 'COMMON.DAIRI_KANKEI' | translate }}</label>
            </div>

            <ng-container
              *ngIf="pageForm.controls.dairi_knki.hasError('required') && (pageForm.controls.dairi_knki.touched || pageForm.controls.dairi_knki.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.DAIRI_ERROR_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <div class="select box-dairi-knki"
                [class.input-invalid]="pageForm.controls.dairi_knki.invalid && (pageForm.controls.dairi_knki.touched || pageForm.controls.dairi_knki.dirty)">
                <select name="dairi_knki" [formControl]="pageForm.controls.dairi_knki">
                  <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                  <option *ngFor="let kankei_val of kankei" [ngValue]="kankei_val">{{kankei_val}}</option>
                </select>
              </div>
            </div>
          </ng-container>

          <div class="box-koumoku　no-margin-bottom">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.TITLE_ININJYOU' | translate }}</label></div>
            </div>

            <ng-container
              *ngIf="pageForm.controls.ininjyouFlg.hasError('required') && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
              <div class="err-msg" *ngIf="pageForm.controls.ininjyouFlg.value == null">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.ININJYOU_ERROR_REQUIRED' | translate }}
                </a>
              </div>
              <div class="err-msg" *ngIf="pageForm.controls.ininjyouFlg.value == false">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'INKANTOUROKU.SHINSEI.ININJYOU_REQUIRED_MESSAGE' | translate }}
                </a>
              </div>
            </ng-container>
            <div class="dairi-radio-tile-group">
              <div class="input-container input-container-left">
                <label for="ininjyouFlg-yes">
                  <input id="ininjyouFlg-yes" class="radio-button" type="radio" name="ininjyouFlg"
                    [formControl]="pageForm.controls.ininjyouFlg" [value]="true" hidden />
                  <div class="radio-tile"
                    [class.input-invalid]="pageForm.controls.ininjyouFlg.invalid && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
                    <div class="icon ininjyouFlg-yes-icon">
                      <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                        viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                        <style type="text/css">
                          .st0 {
                            fill: #4B4B4B;
                          }
                        </style>
                        <g>
                          <path class="st0" d="M208.971,443.563c2.203-2.219,3.281-5.109,3.234-8l8.047-8.031l65.25-14
                              c6.672-1.203,12.266-5.719,14.859-11.984c0,0,12.375-27.641,19.891-40.172l-20.781-20.781l-3.234-3.25l-20.797-20.797
                              c-12.531,7.516-40.188,19.891-40.188,19.891c-6.266,2.594-10.766,8.188-11.969,14.844l-14.016,65.266l-8.016,8.031
                              c-2.906-0.016-5.813,1.047-8.016,3.25c-4.344,4.359-4.344,11.391,0,15.734S204.627,447.906,208.971,443.563z M256.564,363.063
                              c4.734-4.75,12.422-4.75,17.188,0c4.734,4.734,4.734,12.438,0,17.172c-4.766,4.734-12.453,4.734-17.188,0
                              C251.814,375.5,251.814,367.797,256.564,363.063z" style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0"
                            d="M362.346,318.766l-44.313-44.328c0,0-15.531,15.531-20.531,20.531c-5.016,5-11.531,3.5-11.531,3.5l-6,6.031
                              l21.031,21.031l5.016,5l26.297,26.297l6-6.016c0,0-1.5-6.5,3.5-11.516C346.83,334.281,362.346,318.766,362.346,318.766z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M497.83,138.969c-20.5-20.484-42.844-18.625-66.141,4.656c-23.266,23.281-90.219,106.625-106,122.406
                              l45.078,45.063c15.766-15.766,99.109-82.719,122.391-106S518.314,159.453,497.83,138.969z M477.486,175.141l-70.156,70.141
                              c-1.719,1.734-4.484,1.734-6.203,0l-9.625-9.625c-1.703-1.688-1.703-4.469,0-6.188l70.141-70.156c1.719-1.719,4.516-1.719,6.234,0
                              l9.609,9.625C479.205,170.656,479.205,173.438,477.486,175.141z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <rect x="88.408" y="201.844" class="st0" width="194.5" height="22.109"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <rect x="88.408" y="279.219" class="st0" width="145.875" height="22.094"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <rect x="88.408" y="356.563" class="st0" width="103.891" height="22.109"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <path class="st0" d="M358.58,356.969l-8.063,6.656l-7.938,7.938v102.156H26.518V38.281h213.281v83.484
                              c0,5.906,2.438,11.359,6.313,15.203c3.859,3.875,9.297,6.313,15.219,6.313h81.25v76.5c4.297-5.125,8.813-10.469,13.391-15.922
                              c4.313-5.141,8.719-10.391,13.125-15.625v-72.578L265.221,11.766H11.049c-6.109,0-11.047,4.953-11.047,11.047v466.375
                              c0,6.094,4.938,11.047,11.047,11.047h347c6.109,0,11.047-4.953,11.047-11.047V337.031l-12.672,12.672L358.58,356.969z
                               M261.924,45.969l75.188,75.203h-75.188V45.969z" style="fill: rgb(255, 215, 0);"></path>
                        </g>
                      </svg>
                    </div>
                    <label for="ininjyouFlg-yes" class="radio-tile-label">{{ 'COMMON.ININJYOU_YES' | translate }}
                    </label>
                  </div>
                </label>
              </div>
              <div class="input-container  input-container-right">
                <label for="ininjyouFlg-no">
                  <input id="ininjyouFlg-no" class="radio-button" type="radio" name="ininjyouFlg"
                    [formControl]="pageForm.controls.ininjyouFlg" [value]="false" hidden />
                  <div class="radio-tile"
                    [class.input-invalid]="pageForm.controls.ininjyouFlg.invalid && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
                    <div class="icon ininjyouFlg-no-icon">
                      <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                        <style type="text/css">
                          .st0 {
                            fill: #4B4B4B;
                          }
                        </style>
                        <g>
                          <path class="st0" d="M256,0c-11.191,0-23.03,9.012-19.737,37.037c2.627,22.364,9.555,44.566,19.737,44.566
                              c10.164,0,17.091-22.202,19.718-44.566C279.03,9.012,267.192,0,256,0z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M256,430.397c-10.182,0-17.11,22.202-19.737,44.565C232.97,502.988,244.809,512,256,512
                              c11.192,0,23.03-9.012,19.718-37.038C273.091,452.599,266.164,430.397,256,430.397z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M127.435,34.116c-9.688,5.596-15.436,19.319,1.426,41.938c13.476,18.053,30.568,33.803,39.38,28.721
                              c8.812-5.082,3.711-27.778-5.196-48.457C151.891,30.395,137.122,28.51,127.435,34.116z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M343.761,407.224c-8.812,5.082-3.712,27.778,5.176,48.458c11.172,25.922,25.923,27.807,35.61,22.202
                              c9.688-5.586,15.436-19.318-1.428-41.939C369.664,417.892,352.573,402.142,343.761,407.224z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M76.045,128.871c-22.611-16.864-36.333-11.106-41.929-1.418c-5.596,9.687-3.73,24.447,22.192,35.601
                              c20.689,8.906,43.376,13.998,48.458,5.196C109.848,159.437,94.089,142.336,76.045,128.871z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M455.673,348.946c-20.669-8.908-43.357-13.999-48.438-5.197c-5.1,8.812,10.659,25.914,28.721,39.38
                              c22.611,16.862,36.333,11.105,41.929,1.418C483.48,374.869,481.595,360.1,455.673,348.946z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M81.584,256c0-10.164-22.192-17.101-44.556-19.727C9.012,232.97,0.01,244.818,0.01,256
                              c0,11.191,9.002,23.029,37.018,19.737C59.392,273.1,81.584,266.163,81.584,256z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M474.953,236.263c-22.364,2.636-44.556,9.574-44.556,19.737c0,10.163,22.211,17.1,44.556,19.727
                              c28.035,3.302,37.037-8.536,37.037-19.727C511.99,244.808,502.988,232.97,474.953,236.263z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M56.308,348.946c-25.922,11.153-27.787,25.922-22.192,35.601c5.596,9.687,19.318,15.444,41.929-1.418
                              c18.044-13.466,33.803-30.567,28.721-39.38C99.684,334.947,76.997,340.039,56.308,348.946z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0"
                            d="M455.673,163.054c25.923-11.154,27.808-25.914,22.211-35.601c-5.596-9.698-19.318-15.446-41.929,1.418
                              c-18.062,13.465-33.821,30.566-28.721,39.378C412.316,177.052,435.004,171.96,455.673,163.054z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0"
                            d="M128.861,435.945c-16.862,22.621-11.115,36.353-1.408,41.939c9.687,5.605,24.438,3.72,35.592-22.202
                              c8.907-20.679,14.008-43.376,5.196-48.458C159.429,402.142,142.337,417.892,128.861,435.945z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <path class="st0" d="M384.547,34.116c-9.687-5.605-24.438-3.721-35.61,22.202c-8.907,20.678-13.989,43.375-5.176,48.457
                              c8.793,5.082,25.904-10.668,39.359-28.721C399.983,53.434,394.254,39.712,384.547,34.116z"
                            style="fill: rgb(255, 215, 0);"></path>
                        </g>
                      </svg>
                    </div>
                    <label for="ininjyouFlg-no" class="radio-tile-label">{{ 'COMMON.ININJYOU_NO' | translate }}
                    </label>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="box-block" [hidden]="true">
  <div class="box-koumoku">
    <h4>{{ 'JYUMINHYOU.SHINSEI.TITLE_SEIKYU' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'JYUMINHYOU.SHINSEI.SETUMEI_SEIKYU' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-kakunin">
        <div class="box-kakunin-message">
          <div class="box-kakunin-message-col1">
            <div class="kakunin-area">
              <input type="checkbox" id="kakunin-check-siky" name="sikyFlg" [formControl]="pageForm.controls.sikyFlg"
                (change)="onSiky()">
              <label for="kakunin-check-siky">
              </label>
            </div>
          </div>
          <div class="box-kakunin-message-col2">
            {{ 'JYUMINHYOU.SHINSEI.SEIKYU' | translate }}
          </div>
        </div>
      </div>

      <div class="box-sub-block" [hidden]="!pageForm.controls.sikyFlg.value">
        <div class="box-block-dairi-inner">

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <label>{{ 'COMMON.SEIKYUSYA' | translate }}</label>
            </div>

            <form [formGroup]="pageForm">
              <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiSiky}} ckName={{ckNameShimeiSiky}} defaltKbn="1">
              </app-shimei>
            </form>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <label>{{ 'COMMON.ADDR' | translate }}</label>
            </div>

            <form [formGroup]="pageForm">
              <app-zipcode fgName={{fgNameZipcodeSiky}} ckName={{ckNameZipcodeSiky}} defaltKbn="3"></app-zipcode>
            </form>
          </div>

          <div class="box-koumoku no-margin-bottom">
            <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
            <ng-container
              *ngIf="pageForm.controls.siky_telno.hasError('numeric') && (pageForm.controls.siky_telno.touched || pageForm.controls.siky_telno.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.TEL_ERROR_TYPE' | translate }}
                </a>
              </div>
            </ng-container>
            <div class="box-control">
              <input
                [class.input-invalid]="pageForm.controls.siky_telno.invalid && (pageForm.controls.siky_telno.touched || pageForm.controls.siky_telno.dirty)"
                class="input-text input-tel" type="tel" maxlength="11" name="siky_telno"
                [formControl]="pageForm.controls.siky_telno" autocomplete="tel">
            </div>
            <div class="box-setumei">
              <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>