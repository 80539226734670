<form [formGroup]="pageForm">
    <div formArrayName="setaiins">
        <div class="box-block" *ngFor="let setaiin of setaiins.controls; let i = index">

            <div class="box-idosya-title">
                <div class="box-koumoku">
                    <h4>{{ 'IRYOKODOMO.TAISYOUJIDOU.TITLE_TAISYOUJIDOU' | translate }}（{{ i+1 }}/{{ pageForm.controls.setaiins.length }}）
                    </h4>
                </div>
                <ng-container *ngIf="i!=0">
                    <a class="btn-delete" (click)="removeSetaiin(i)">
                        <fa-icon [icon]="faUserSlash"></fa-icon>
                    </a>
                </ng-container>
            </div>

            <div class="box-block-inner">

                <div class="box-koumoku">
                    <div class="box-help">
                        <a>
                            {{ 'IRYOKODOMO.TAISYOUJIDOU.SETUMEI_TAISYOUJIDOU' | translate }}
                        </a>
                    </div>
                </div>

                <div [formGroupName]="i">

                    <form [formGroup]="setaiin">
                        <div class="box-koumoku">
                            <div class="box-required">
                                <img class="required" src="/assets/required.png">
                                <label>{{ 'COMMON.TAISYOUSYA' | translate }}</label>
                              </div>
                        
                            <app-shimei title="{{ 'COMMON.SETAIIN' | translate }}" [buttonVisible]="true"
                                buttonTitle="{{ 'IRYOKODOMO.TAISYOUJIDOU.SHINSEI_SEI_COPY' | translate }}"
                                [copyShimei]="uji" fgName={{fgNameShimei}} ckName="{{ckNameShimei + i}}" defaltKbn="1">
                            </app-shimei>
                        </div>
                    </form>

                    <div class="box-koumoku">
                        <div class="box-required">
                            <img class="required" src="/assets/required.png">
                            <div class="label-msg"><label>{{ 'COMMON.BIRTHDAY' | translate }}</label></div>
                        </div>
                        <form [formGroup]="setaiin">
                            <app-datepicker fgName={{fgNameDatepicker}}></app-datepicker>
                        </form>
                    </div>

                    <div class="box-koumoku">
                        <div class="box-required">
                            <img class="required" src="/assets/required.png">
                            <div class="label-msg"><label>{{ 'COMMON.SEX' | translate }}</label></div>
                        </div>
                        <form [formGroup]="setaiin">
                            <app-sex fgName={{fgNameSex}} rdName={{i}}></app-sex>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="pageForm.controls.setaiins.value.length < 3">
    <div class="box-add-setaiin">
        <a class="btn-add-setaiin" (click)="addSetaiin()">{{ 'IRYOKODOMO.TAISYOUJIDOU.ADD_MEMBER' | translate }}</a>
    </div>
</ng-container>

<!-- Debug用の表示 
  <h3>Debug</h3>
  <ng-container *ngIf="form.value">
    <pre>{{ form.value | json }}</pre>
  </ng-container>
  -->