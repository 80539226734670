<div class="box-kakunin-title">
    <h4>{{ 'COMMON.KAKUNIN.TITLE_KAKUNIN' | translate }}</h4>
</div>

<div class="box-kakunin">
    <div class="box-koumoku">
        <h4>{{ 'JIDOTEATE.SEIKYUSYA.TITLE_SEIKYUSYA' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.SEIKYUSYA' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.fk201_snsi_name_kj}}</a></div>
                    <div class="is-hidden"><a>/</a></div>
                    <div><a>{{item.fk201_snsi_name_kn}}</a></div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.ADDR' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{'' + item.fk201_snsi_addr_ken + item.fk201_snsi_addr_city + item.fk201_snsi_addr_town + item.fk201_snsi_addr_banti + ' ' + item.fk201_snsi_addr_ktgk}}
            </div>
        </div>
        <div class="meisai-row" *ngIf="has11Addr">
            <div class="meisai-col-title">
                {{ 'JIDOTEATE.SEIKYUSYA.11_ADDR' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{'' + item.fk201_snsi_11_addr_ken + item.fk201_snsi_11_addr_city + item.fk201_snsi_11_addr_town + item.fk201_snsi_11_addr_banti + ' ' + item.fk201_snsi_11_addr_ktgk}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.TEL' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_telno}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.SEX' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <ng-container *ngIf="item.fk201_snsi_sex === '1';else second">
                    <a>{{ 'SEX_WITH_JA.MALE' | translate }}</a>
                </ng-container>
                <ng-template #second>
                    <ng-container *ngIf="item.fk201_snsi_sex === '2';else third">
                        <a>{{ 'SEX_WITH_JA.FEMALE' | translate }}</a>
                    </ng-container>
                </ng-template>
                <ng-template #third>
                    <a>{{ 'SEX_WITH_JA.UNKNOWN' | translate }}</a>
                </ng-template>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.ZOKUGARA' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_zokugara}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.BIRTHDAY' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_umare_ymd}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.NENKIN' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_nenkin}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.JOB' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_job}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.HAIGUSYA' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_snsi_haigu}}
            </div>
        </div>
    </div>
    
    <div *ngIf="hasHaigu">
        <div class="box-koumoku">
            <h4> {{ 'JIDOTEATE.HAIGUSYA.TITLE_HAIGUSYA' | translate }} </h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    {{ 'COMMON.HAIGUSYA' | translate }}
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.fk201_haigu_name_kj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.fk201_haigu_name_kn}}</a></div>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    {{ 'COMMON.BIRTHDAY' | translate }}
                </div>
                <div class="meisai-col-naiyou">
                    {{item.fk201_haigu_umare_ymd}}
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    {{ 'COMMON.JOB' | translate }}
                </div>
                <div class="meisai-col-naiyou">
                    {{item.fk201_haigu_job}}
                </div>
            </div>
            <div class="meisai-row" *ngIf="isHaiguBekkyo">
                <div class="meisai-col-title">
                    {{ 'COMMON.ADDR' | translate }}
                </div>
                <div class="meisai-col-naiyou">
                    {{'' + item.fk201_haigu_addr_ken + item.fk201_haigu_addr_city + item.fk201_haigu_addr_town + item.fk201_haigu_addr_banti + ' ' + item.fk201_haigu_addr_ktgk}}
                </div>
            </div>
            <div class="meisai-row" *ngIf="haiguHas11Addr">
                <div class="meisai-col-title">
                    {{ 'JIDOTEATE.HAIGUSYA.11_ADDR' | translate }}
                </div>
                <div class="meisai-col-naiyou">
                    {{'' + item.fk201_haigu_11_addr_ken + item.fk201_haigu_11_addr_city + item.fk201_haigu_11_addr_town + item.fk201_haigu_11_addr_banti + ' ' + item.fk201_haigu_11_addr_ktgk}}
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let jido of item.fk202s; let tindex = index">
        <div class="box-koumoku">
            <h4>{{ 'JIDOTEATE.TAISYOUJIDOU.TITLE_TAISYOUJIDOU' | translate }}（{{tindex + 1}}/{{getJidoCount()}}）</h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.JIDO' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{jido.fk202_name_kj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{jido.fk202_name_kn}}</a></div>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.BIRTHDAY' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_umare_ymd}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.ZOKUGARA' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_zokugara}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JIDOTEATE.TAISYOUJIDOU.BEKKYO' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_dokyo}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JIDOTEATE.TAISYOUJIDOU.JOB' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_job}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JIDOTEATE.TAISYOUJIDOU.KANGO' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_kango}}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JIDOTEATE.TAISYOUJIDOU.SEIKEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{jido.fk202_seikei}}</a></div>
                </div>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <h4>{{ 'JIDOTEATE.KOZA.TITLE_KOZA' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.BANK' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_koza_bank_kj}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.BANK_SITEN' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_koza_siten_kj}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.KOZA_SYUBETU' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_koza_syubetu}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.KOZA_NO' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_koza_no}}
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                {{ 'COMMON.KOZA_MEIGI' | translate }}
            </div>
            <div class="meisai-col-naiyou">
                {{item.fk201_koza_meigi_kn}}
            </div>
        </div>
    </div>

</div>