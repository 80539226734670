import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { zenkaku2hankaku } from '@utility/string';

@Directive({
  selector: '[appZen2han]'
})
export class Zen2hanDirective {

  constructor(private elemRef: ElementRef<HTMLInputElement>,
    private control: NgControl) {}

  @HostListener("input") onInput(): void {
    const initialValue = this.elemRef.nativeElement.value;
    const hanValue = zenkaku2hankaku(initialValue);
    this.control.control.setValue(hanValue);
  }

}
