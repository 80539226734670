import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';
import * as JSEncryptModule from 'jsencrypt';

import { ConfigService } from '@services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  private publicKey= '';

  constructor(
    config: ConfigService
  ) {
    this.publicKey = config.get("PUBLIC_KEY")
   }

  //QRコード化する共通鍵、申請情報を暗号化
  getQREncrypt(qrText: string): any{
    //共通鍵の生成
    var key = this.getKey();
    //申請情報の暗号化
    var qrEncrypted = CryptoJS.AES.encrypt(qrText, key);
    //共通鍵の暗号化
    var cryptRSA = new JSEncryptModule.JSEncrypt();
    cryptRSA.setKey(this.publicKey); 
    var keyEncrypted = cryptRSA.encrypt(key);

    return qrEncrypted + "," + keyEncrypted;
  }

  //共通鍵作成
  getKey() {
    //パスワードの生成
    var pass = this.getRandomStr(16);
    //ソルトの生成
    var salt = CryptoJS.lib.WordArray.random(128 / 8);
    //共通鍵の生成
    var key = CryptoJS.PBKDF2(pass, salt, { keySize: 128 / 32 });
    //Base64にエンコード
    var key_b64 = CryptoJS.enc.Base64.stringify(key);

    return key_b64;
  }

  //長さlenの文字列をランダムに作成
  getRandomStr(len: number) {
    var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$%&=~/*-+";

    //ランダムな文字列の生成
    var result = "";
    for (var i = 0; i < len; i++) {
      result += str.charAt(Math.floor(Math.random() * str.length));
    }
    return result;
  }
}
