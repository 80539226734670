import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, ViewContainerRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Jyukiido, Menu, Shinsei, Zenjyusyo, Shinjyusyo, Idosya } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ModalService } from "@services/modal/modal.service";
import { CyouhyouModalComponent } from '@jyukiido/components/cyouhyou-modal/cyouhyou-modal.component';
import { JyukiidoMenuComponent } from '@jyukiido/components/jyukiido-menu/jyukiido-menu.component';
import { JyukiidoDouiComponent } from '@jyukiido/components/jyukiido-doui/jyukiido-doui.component';
import { JyukiidoShinseiComponent } from '@jyukiido/components/jyukiido-shinsei/jyukiido-shinsei.component';
import { JyukiidoZenjyusyoComponent } from '@jyukiido/components/jyukiido-zenjyusyo/jyukiido-zenjyusyo.component';
import { JyukiidoShinjyusyoComponent } from '@jyukiido/components/jyukiido-shinjyusyo/jyukiido-shinjyusyo.component';
import { JyukiidoIdosyaComponent } from '@jyukiido/components/jyukiido-idosya/jyukiido-idosya.component';
import { JyukiidoKakuninComponent } from '@jyukiido/components/jyukiido-kakunin/jyukiido-kakunin.component';
import { JyukiidoQrComponent } from '@jyukiido/components/jyukiido-qr/jyukiido-qr.component';
import { JyukiidoService } from '@jyukiido/services/jyukiido.service';
import { Step } from '@components/stepver/stepver.component';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-jyukiido',
  templateUrl: './jyukiido.component.html',
  styleUrls: ['./jyukiido.component.css']
})
export class JyukiidoComponent implements OnInit {
  @ViewChild(JyukiidoMenuComponent) menuComponent: JyukiidoMenuComponent;
  @ViewChild(JyukiidoDouiComponent) douiComponent: JyukiidoDouiComponent;
  @ViewChild(JyukiidoShinseiComponent) shinseiComponent: JyukiidoShinseiComponent;
  @ViewChild(JyukiidoZenjyusyoComponent) zenjyusyoComponent: JyukiidoZenjyusyoComponent;
  @ViewChild(JyukiidoShinjyusyoComponent) shinjyusyoComponent: JyukiidoShinjyusyoComponent;
  @ViewChild(JyukiidoIdosyaComponent) idosyaComponent: JyukiidoIdosyaComponent;
  @ViewChild(JyukiidoKakuninComponent) kakuninComponent: JyukiidoKakuninComponent;
  @ViewChild(JyukiidoQrComponent) qrComponent: JyukiidoQrComponent;

  item: Jyukiido;
  idosyas: Idosya[];
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;
  config: any;
  shinseiCyouhyou: any;

  subsucribe: any;

  constructor(
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private jyukiidoService: JyukiidoService,
    private modalService: ModalService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService,
    private viewContainerRef: ViewContainerRef,
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
    this.config = this.configService.getCyouhyouConfig('jyukiido');
    this.shinseiCyouhyou = this.configService.get('SHINSEI_CHOUHYOU');
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    this.item = {
      "sj201_snsi_bng": 0,
      "sj201_snsi_ymd": this.today,
      "sj201_snsi_syurui": "",
      "sj201_snsi_name_kj": "",
      "sj201_snsi_name_kn": "",
      "sj201_snsi_telno": "",
      "sj201_dairi_yubinno": "",
      "sj201_dairi_addr_ken": "",
      "sj201_dairi_addr_city": "",
      "sj201_dairi_addr_town": "",
      "sj201_dairi_addr_banti": "",
      "sj201_dairi_addr_ktgk": "",
      "sj201_dairi_knki": "",
      "sj201_ido_ymd": "",
      "sj201_cyouhyo_cd": "",
      "sj201_old_yubinno": "",
      "sj201_old_addr_ken": "",
      "sj201_old_addr_city": "",
      "sj201_old_addr_town": "",
      "sj201_old_addr_banti": "",
      "sj201_old_addr_ktgk": "",
      "sj201_old_setai_name_kj": "",
      "sj201_old_setai_name_kn": "",
      "sj201_new_yubinno": "",
      "sj201_new_addr_ken": "",
      "sj201_new_addr_city": "",
      "sj201_new_addr_town": "",
      "sj201_new_addr_banti": "",
      "sj201_new_addr_ktgk": "",
      "sj201_new_setai_name_kj": "",
      "sj201_new_setai_name_kn": "",
      "sj202s": [{
        "sj202_id": 1,
        "sj202_name_kj": "",
        "sj202_name_kn": "",
        "sj202_umare_ymd": "",
        "sj202_sex": "",
        "sj202_zokugara": "",
        "sj202_zokugara_sonota": "",
      }]
    };

    this.steps = this.jyukiidoService.steps;

    //ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.menuComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      //toutchしたことにする
      this.menuComponent.setTouched();
      if (environment.production && this.menuComponent.pageForm.invalid) return;
      const menu = this.menuComponent.getMenu();
      this.setMenu(menu);

      //次の画面のステータスをセット
      this.isChildFormValid = this.douiComponent.pageForm.valid;
    }

    if (this.currentSteper == 2) {
      //toutchしたことにする
      this.douiComponent.setTouched();
      if (environment.production && this.douiComponent.pageForm.invalid) return;

      //次の画面のステータスをセット
      this.isChildFormValid = this.idosyaComponent.pageForm.valid;
    }

    if (this.currentSteper == 3) {
      //toutchしたことにする
      this.idosyaComponent.setTouched();
      if (environment.production && this.idosyaComponent.pageForm.invalid) return;

      this.idosyas = this.idosyaComponent.getIdosyas();
      this.setIdosyas(this.idosyas);

      //戻った場合を考慮して先画面の世帯員をクリアする
      this.shinseiComponent.clearSetaiin();
      this.zenjyusyoComponent.clearSetaiin();
      this.shinjyusyoComponent.clearSetaiin();

      //申請書の表示制御
      this.shinseiComponent.getCyouhyo(this.item.sj201_snsi_syurui);

      //次の画面のステータスをセット
      this.isChildFormValid = this.shinseiComponent.pageForm.valid;
    }
    if (this.currentSteper == 4) {
      //toutchしたことにする
      this.shinseiComponent.setTouched();
      if (environment.production && this.shinseiComponent.pageForm.invalid) return;
      const shinsei = this.shinseiComponent.getShinsei();
      this.setShinsei(shinsei);

      //郵便番号の表示制御
      this.zenjyusyoComponent.filterAddrKbns(this.getZenShowKbn());
      this.zenjyusyoComponent.setDefaltKbn(this.getZenDefaltKbn());

      //戻った場合を考慮して先画面の世帯員をクリアする
      this.zenjyusyoComponent.clearSetaiin();

      //次の画面のステータスをセット
      this.isChildFormValid = this.zenjyusyoComponent.pageForm.valid;
    }
    if (this.currentSteper == 5) {
      //toutchしたことにする
      this.zenjyusyoComponent.setTouched();
      if (environment.production && this.zenjyusyoComponent.pageForm.invalid) return;

      const zenjyusyo = this.zenjyusyoComponent.getZenjyusyo();
      this.setZenjyusyo(zenjyusyo);

      //郵便番号の表示制御
      this.shinjyusyoComponent.filterAddrKbns(this.getShinShowKbn());
      this.shinjyusyoComponent.setDefaltKbn(this.getShinDefaltKbn());

      //戻った場合を考慮して先画面の世帯員をクリアする
      this.shinjyusyoComponent.clearSetaiin();

      //次の画面のステータスをセット
      this.isChildFormValid = this.shinjyusyoComponent.pageForm.valid;
    }
    if (this.currentSteper == 6) {
      //toutchしたことにする
      this.shinjyusyoComponent.setTouched();
      if (environment.production && this.shinjyusyoComponent.pageForm.invalid) return;
      
      const shinjyusyo = this.shinjyusyoComponent.getShinjyusyo();
      this.setShinjyusyo(shinjyusyo);

      //次の画面のステータスをセット
      this.kakuninComponent.getCyouhyoList(this.item.sj201_snsi_syurui);
      
      this.isChildFormValid = this.qrComponent.createQRText();
    }
    if (this.currentSteper == 7) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;
    this.scrollTop();
  }

  onBack() {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;

    //同意に戻ったらチェックを外す
    if (this.currentSteper == 2) {
      this.douiComponent.clearDouiFlg();
    }
  }

  hiddenBack() {
    if ([2, 3, 4, 5, 6, 7, 8].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3, 4, 5, 6, 7].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setMenu(data: Menu) {
    this.item.sj201_snsi_syurui = data.snsi_syurui;
  }

  setShinsei(data: Shinsei) {
    this.item.sj201_snsi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.snsi_smi));
    this.item.sj201_snsi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.snsi_smi));
    this.item.sj201_snsi_telno = this.replaceNullEmpty(data.snsi_telno);
    this.item.sj201_dairi_yubinno = this.replaceNullEmpty(data.dairi_address.zipcode);
    this.item.sj201_dairi_addr_ken = this.replaceNullEmpty(data.dairi_address.address.ken);
    this.item.sj201_dairi_addr_city = this.replaceNullEmpty(data.dairi_address.address.city);
    this.item.sj201_dairi_addr_town = this.replaceNullEmpty(data.dairi_address.address.town);
    this.item.sj201_dairi_addr_banti = this.replaceNullEmpty(data.dairi_address.banti);
    this.item.sj201_dairi_addr_ktgk = this.replaceNullEmpty(data.dairi_address.ktgk);
    this.item.sj201_dairi_knki = this.replaceNullEmpty(data.dairi_knki);
    this.item.sj201_ido_ymd = this.replaceNullEmpty(data.ido_ymd);
    this.item.sj201_cyouhyo_cd = data.cyouhyo_cd.join(",");
  }

  setZenjyusyo(data: Zenjyusyo) {
    this.item.sj201_old_setai_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.old_setai_smi));
    this.item.sj201_old_setai_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.old_setai_smi));

    this.item.sj201_old_yubinno = this.replaceNullEmpty(data.old_address.zipcode);
    this.item.sj201_old_addr_ken = this.replaceNullEmpty(data.old_address.address.ken);
    this.item.sj201_old_addr_city = this.replaceNullEmpty(data.old_address.address.city);
    this.item.sj201_old_addr_town = this.replaceNullEmpty(data.old_address.address.town);
    this.item.sj201_old_addr_banti = this.replaceNullEmpty(data.old_address.banti);
    this.item.sj201_old_addr_ktgk = this.replaceNullEmpty(data.old_address.ktgk);
  }

  setShinjyusyo(data: Shinjyusyo) {
    this.item.sj201_new_setai_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.new_setai_smi));
    this.item.sj201_new_setai_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.new_setai_smi));

    this.item.sj201_new_yubinno = this.replaceNullEmpty(data.new_address.zipcode);
    this.item.sj201_new_addr_ken = this.replaceNullEmpty(data.new_address.address.ken);
    this.item.sj201_new_addr_city = this.replaceNullEmpty(data.new_address.address.city);
    this.item.sj201_new_addr_town = this.replaceNullEmpty(data.new_address.address.town);
    this.item.sj201_new_addr_banti = this.replaceNullEmpty(data.new_address.banti);
    this.item.sj201_new_addr_ktgk = this.replaceNullEmpty(data.new_address.ktgk);
  }

  setIdosyas(datas: Idosya[]) {
    const transfers = [];
    datas.forEach((data) => {
      const transfer = {
        "sj202_id": this.replaceNullZero(data.id),
        "sj202_name_kj": this.replaceNullEmpty(getNameKjFromShimei(data.ido_smi)),
        "sj202_name_kn": this.replaceNullEmpty(getNameKnFromShimei(data.ido_smi)),
        "sj202_umare_ymd": this.replaceNullEmpty(data.umare_ymd),
        "sj202_sex": this.replaceNullEmpty(data.sex),
        "sj202_zokugara": this.replaceNullEmpty(data.zokugara),
        "sj202_zokugara_sonota": this.replaceNullEmpty(data.zokugara_sonota)
      };
      transfers.push(transfer);
    });
    this.item.sj202s = transfers;
  }

  replaceNullZero(value) {
    return value == null ? 0 : value;
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

  getZenShowKbn() {
    //1:管内　2：管外　3：国内　4：国外
    if (this.item.sj201_snsi_syurui == '01') {
      //転入の場合
      return []
    }
    else if (this.item.sj201_snsi_syurui == '02') {
      //転出の場合
      return []
    }
    else if (this.item.sj201_snsi_syurui == '03') {
      //転居の場合
      return []
    }
  }

  getZenDefaltKbn() {
    //1:管内　2：管外　3：国内　4：国外
    if (this.item.sj201_snsi_syurui == '01') {
      //転入の場合
      return 2
    }
    else if (this.item.sj201_snsi_syurui == '02') {
      //転出の場合
      return 1
    }
    else if (this.item.sj201_snsi_syurui == '03') {
      //転居の場合
      return 1
    }
  }

  getShinShowKbn() {
    //1:管内　2：管外　3：国内　4：国外
    if (this.item.sj201_snsi_syurui == '01') {
      //転入の場合
      return []
    }
    else if (this.item.sj201_snsi_syurui == '02') {
      //転出の場合
      return []
    }
    else if (this.item.sj201_snsi_syurui == '03') {
      //転居の場合
      return []
    }
  }

  getShinDefaltKbn() {
    //1:管内　2：管外　3：国内　4：国外
    if (this.item.sj201_snsi_syurui == '01') {
      //転入の場合
      return 1
    }
    else if (this.item.sj201_snsi_syurui == '02') {
      //転出の場合
      return 2
    }
    else if (this.item.sj201_snsi_syurui == '03') {
      //転居の場合
      return 1
    }
  }

}
