import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { isAfter, parseISO } from 'date-fns';

export const getFormattedCurrentDateJST = (formatString: string): string =>
    format(new Date(), formatString, { timeZone: 'Asia/Tokyo' });

export const getFormattedDateJST = (date: Date, formatString: string): string =>
    format(date, formatString, { timeZone: 'Asia/Tokyo' });

export const getGengo = (date: Date): string => {
    const reiwaStart = parseISO('2019-05-01T00+09:00');
    const heiseiStart = parseISO('1989-01-08T00+09:00');
    const showaStart = parseISO('1926-12-25T00+09:00');
    const taishoStart = parseISO('1912-07-30T00+09:00');
    const meijiStart = parseISO('1868-01-25T00+09:00');

    if (isAfter(date, reiwaStart)) {
        return '令和';
    } else if (isAfter(date, heiseiStart)) {
        return '平成';
    } else if (isAfter(date, showaStart)) {
        return '昭和';
    } else if (isAfter(date, taishoStart)) {
        return '大正';
    } else if (isAfter(date, meijiStart)) {
        return '明治';
    } else {
        throw new Error('argument out of range (before meiji)');
    }
};

export const getGengoStringFromYear = (year: number): string => {
    const reiwaStart = 2019;
    const heiseiStart = 1989;
    const showaStart = 1926;
    const taishoStart = 1912;
    const meijiStart = 1868;

    if (year > reiwaStart) {
        return `令和${year - reiwaStart + 1}`;
    } else if (year === reiwaStart) {
        return `令和元 / 平成${year - heiseiStart + 1}`;
    } else if (year > heiseiStart) {
        return `平成${year - heiseiStart + 1}`;
    } else if (year === heiseiStart) {
        return `平成元 / 昭和${year - showaStart + 1}`;
    } else if (year > showaStart) {
        return `昭和${year - showaStart + 1}`;
    } else if (year === showaStart) {
        return `昭和元 / 大正${year - taishoStart + 1}`;
    } else if (year > taishoStart) {
        return `大正${year - taishoStart + 1}`;
    } else if (year === taishoStart) {
        return `大正元 / 明治${year - meijiStart + 1}`;
    } else if (year > meijiStart) {
        return `明治${year - meijiStart + 1}`;
    } else if (year === meijiStart) {
        return '明治元';
    } else {
        throw new Error('argument out of range (before meiji)');
    }
};

export const toJstTime = (date: Date): Date =>
    utcToZonedTime(date, 'Asia/Tokyo');

const pad2zeros = (value: number | string) => value.toString().padStart(2, '0');
export const formatyyMMdd = (year: number | string, month: number | string, day: number | string) =>
    `${pad2zeros(year)}-${pad2zeros(month)}-${pad2zeros(day)}`;
