import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getShimeiFromShimeiForm, getFormattedyyMMddFromDatepicker, getZipcodeAddressFromZipcodeForm } from '@utility//format';
import { Koza } from '@jidoteate/services/item';

@Component({
  selector: 'app-jidoteate-koza',
  templateUrl: './jidoteate-koza.component.html',
  styleUrls: ['./jidoteate-koza.component.css', '../../jidoteate.component.css']
})
export class JidoteateKozaComponent implements OnInit, AfterViewInit {
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  fgNameBankcode = 'bankcode';
  faExclamationTriangle = faExclamationTriangle;

  constructor(private fb: FormBuilder, public translate: TranslateService) {
    this.pageForm = fb.group({
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // const ret = Object(event);
    });
  }

  ngOnInit(): void {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit(): void {
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  getKoza(): Koza {
    const koza = new Koza(
      this.pageForm.controls.bankcode.get('bankCode').value,
      this.pageForm.controls.bankcode.get('bankName').value,
      this.pageForm.controls.bankcode.get('branchCode').value,
      this.pageForm.controls.bankcode.get('branchName').value,
      this.pageForm.controls.bankcode.get('accountType').value,
      this.pageForm.controls.bankcode.get('accountNumber').value,
      this.pageForm.controls.bankcode.get('accountOwner').value,
    );

    return koza;
  }

}
