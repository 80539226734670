import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Inkantouroku, Shinsei, Hosho } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { InkantourokuDouiComponent } from '@inkantouroku/components/inkantouroku-doui/inkantouroku-doui.component';
import { InkantourokuShinseiComponent } from '@inkantouroku/components/inkantouroku-shinsei/inkantouroku-shinsei.component';
import { InkantourokuHoshoComponent } from '@inkantouroku/components/inkantouroku-hosho/inkantouroku-hosho.component';
import { InkantourokuKakuninComponent } from '@inkantouroku/components/inkantouroku-kakunin/inkantouroku-kakunin.component';
import { InkantourokuQrComponent } from '@inkantouroku/components/inkantouroku-qr/inkantouroku-qr.component';
import { InkantourokuService } from '@inkantouroku/services/inkantouroku.service';
import { Step } from '@components/stepver/stepver.component';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-inkantouroku',
  templateUrl: './inkantouroku.component.html',
  styleUrls: ['./inkantouroku.component.css']
})
export class InkantourokuComponent implements OnInit {
  @ViewChild(InkantourokuDouiComponent) douiComponent: InkantourokuDouiComponent;
  @ViewChild(InkantourokuShinseiComponent) shinseiComponent: InkantourokuShinseiComponent;
  @ViewChild(InkantourokuHoshoComponent) hoshoComponent: InkantourokuHoshoComponent;
  @ViewChild(InkantourokuKakuninComponent) kakuninComponent: InkantourokuKakuninComponent;
  @ViewChild(InkantourokuQrComponent) qrComponent: InkantourokuQrComponent;

  item: Inkantouroku;
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;
  
  constructor(
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private inkantourokuService: InkantourokuService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    //初期化
    this.item = {
      "sj121_snsi_bng": 0,
      "sj121_snsi_ymd": this.today,
      "sj121_snsi_yubinno": "",
      "sj121_snsi_addr_ken": "",
      "sj121_snsi_addr_city": "",
      "sj121_snsi_addr_town": "",
      "sj121_snsi_addr_banti": "",
      "sj121_snsi_addr_ktgk": "",
      "sj121_snsi_name_kj": "",
      "sj121_snsi_name_kn": "",
      "sj121_snsi_telno": "",
      "sj121_snsi_umare_ymd": "",
      "sj121_dairi_yubinno": "",
      "sj121_dairi_addr_ken": "",
      "sj121_dairi_addr_city": "",
      "sj121_dairi_addr_town": "",
      "sj121_dairi_addr_banti": "",
      "sj121_dairi_addr_ktgk": "",
      "sj121_dairi_name_kj": "",
      "sj121_dairi_name_kn": "",
      "sj121_dairi_telno": "",
      "sj121_hosho_yubinno": "",
      "sj121_hosho_addr_ken": "",
      "sj121_hosho_addr_city": "",
      "sj121_hosho_addr_town": "",
      "sj121_hosho_addr_banti": "",
      "sj121_hosho_addr_ktgk": "",
      "sj121_hosho_name_kj": "",
      "sj121_hosho_name_kn": "",
      "sj121_hosho_telno": "",
      "sj121_hosho_touroku_no": 0,
      "sj121_honnin_kakunin": "",
      "sj121_honnin_sonota": "",
      "sj121_inkansyoumei_maisuu": 0,
      "sj121_sign_img": "",
      "sj121_zumi_flg": false,
      "sj121_sys_user_id": "",
    };

    this.steps = this.inkantourokuService.steps;

    //ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.shinseiComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      //toutchしたことにする
      this.douiComponent.setTouched();
      if (environment.production && this.douiComponent.pageForm.invalid) return;

      //次の画面のステータスをセット
      this.isChildFormValid = this.shinseiComponent.pageForm.valid;
    }

    if (this.currentSteper == 2) {
      //toutchしたことにする
      this.shinseiComponent.setTouched();
      if (environment.production && this.shinseiComponent.pageForm.invalid) return;
      const shinsei = this.shinseiComponent.getShinsei();
      this.setShinsei(shinsei);

      const hosho = this.hoshoComponent.getHosho();
      this.setHosho(hosho);

      this.isChildFormValid = this.qrComponent.createQRText();
    }
    // if (this.currentSteper == 3) {
    //   // toutchしたことにする
    //   this.hoshoComponent.setTouched();
    //   if (environment.production && this.hoshoComponent.pageForm.invalid) return;
    //   const hosho = this.hoshoComponent.getHosho();
    //   this.setHosho(hosho);
    // }
    if (this.currentSteper == 3) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;

    this.scrollTop();
  }

  onBack() {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;

    //同意に戻ったらチェックを外す
    if (this.currentSteper == 1) {
      this.douiComponent.clearDouiFlg();
    }
  }

  hiddenBack() {
    if ([2, 3, 4].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setShinsei(data: Shinsei) {
    this.item.sj121_snsi_yubinno = this.replaceNullEmpty(data.snsi_address.zipcode);
    this.item.sj121_snsi_addr_ken = this.replaceNullEmpty(data.snsi_address.address.ken);
    this.item.sj121_snsi_addr_city = this.replaceNullEmpty(data.snsi_address.address.city);
    this.item.sj121_snsi_addr_town = this.replaceNullEmpty(data.snsi_address.address.town);
    this.item.sj121_snsi_addr_banti = this.replaceNullEmpty(data.snsi_address.banti);
    this.item.sj121_snsi_addr_ktgk = this.replaceNullEmpty(data.snsi_address.ktgk);
    this.item.sj121_snsi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.snsi_smi));
    this.item.sj121_snsi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.snsi_smi));
    this.item.sj121_snsi_telno = this.replaceNullEmpty(data.snsi_telno);
    this.item.sj121_snsi_umare_ymd = this.replaceNullEmpty(data.snsi_umare_ymd);
    this.item.sj121_dairi_yubinno = this.replaceNullEmpty(data.dairi_address.zipcode);
    this.item.sj121_dairi_addr_ken = this.replaceNullEmpty(data.dairi_address.address.ken);
    this.item.sj121_dairi_addr_city = this.replaceNullEmpty(data.dairi_address.address.city);
    this.item.sj121_dairi_addr_town = this.replaceNullEmpty(data.dairi_address.address.town);
    this.item.sj121_dairi_addr_banti = this.replaceNullEmpty(data.dairi_address.banti);
    this.item.sj121_dairi_addr_ktgk = this.replaceNullEmpty(data.dairi_address.ktgk);
    this.item.sj121_dairi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.dairi_smi));
    this.item.sj121_dairi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.dairi_smi));
    this.item.sj121_dairi_telno = this.replaceNullEmpty(data.dairi_telno);
    this.item.sj121_honnin_kakunin = this.replaceNullEmpty(data.honnin_kakunin);
    this.item.sj121_honnin_sonota = this.replaceNullEmpty(data.honnin_sonota);
    this.item.sj121_inkansyoumei_maisuu = this.replaceNullZero(data.inkansyoumei_maisuu);
  }

  setHosho(data: Hosho) {
    this.item.sj121_hosho_yubinno = this.replaceNullEmpty(data.hosho_address.zipcode);
    this.item.sj121_hosho_addr_ken = this.replaceNullEmpty(data.hosho_address.address.ken);
    this.item.sj121_hosho_addr_city = this.replaceNullEmpty(data.hosho_address.address.city);
    this.item.sj121_hosho_addr_town = this.replaceNullEmpty(data.hosho_address.address.town);
    this.item.sj121_hosho_addr_banti = this.replaceNullEmpty(data.hosho_address.banti);
    this.item.sj121_hosho_addr_ktgk = this.replaceNullEmpty(data.hosho_address.ktgk);
    this.item.sj121_hosho_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.hosho_smi));
    this.item.sj121_hosho_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.hosho_smi));
    this.item.sj121_hosho_telno = this.replaceNullEmpty(data.hosho_telno);
    this.item.sj121_hosho_touroku_no = this.replaceNullZero(data.hosho_touroku_no);
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  replaceNullZero(value) {
    return value == null ? 0 : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

}
