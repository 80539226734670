import { Component, OnInit, Input } from '@angular/core';
import { Iryokodomo} from '@iryokodomo/services/item';

@Component({
  selector: 'app-iryokodomo-kakunin',
  templateUrl: './iryokodomo-kakunin.component.html',
  styleUrls: ['./iryokodomo-kakunin.component.css']
})
export class IryokodomoKakuninComponent implements OnInit {
  @Input() item: Iryokodomo;

  constructor() { }

  ngOnInit(): void {
  }

  getMaxTransfer() {
    return this.item.fk102s.length;
  }

}
