import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { Shimei, ShimeiComponent } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getShimeiFromShimeiForm, getFormattedyyMMddFromDatepicker, getZipcodeAddressFromZipcodeForm } from '@utility/format';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { JidoMeisai } from '@jidoteate/services/item';
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'app-jidoteate-jidos',
  templateUrl: './jidoteate-jidos.component.html',
  styleUrls: ['./jidoteate-jidos.component.css', '../../jidoteate.component.css']
})
export class JidoteateJidosComponent implements OnInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(DatepickerComponent) datepickerComponents: QueryList<DatepickerComponent>;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  shimei: Shimei;
  uji: Shimei;
  zokugara: Array<{ value: string; label: string; }>;
  zokugaraMaster: any;
  bekkyo: string[];
  umu: string[];
  seikei: string[];
  fgNameShimei = 'shimei';
  ckNameShimei = 'jidos-shimei';
  fgNameDatepicker = 'datepicker';
  faExclamationTriangle = faExclamationTriangle;
  faUserSlash = faUserSlash;

  constructor(private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.zokugara = Object(config.get("ZOKUGARA"));
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.zokugaraMaster = ret.translations.MASTER.ZOKUGARA;
      this.umu = ret.translations.JIDOTEATE.TAISYOUJIDOU.KANGO_LIST;
      this.bekkyo = ret.translations.JIDOTEATE.TAISYOUJIDOU.BEKKYO_LIST;
      this.seikei = ret.translations.JIDOTEATE.TAISYOUJIDOU.SEIKEI_LIST;
    });
  }

  ngOnInit(): void {
    this.pageForm = this.createForm();
    // 最低一人は入力させる
    this.addSetaiin();

    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      setaiins: this.fb.array([])
    });
  }

  get setaiinForm(): FormGroup {
    return this.fb.group({
      //shimei（shimei コンポーネントで追加）
      //jidos-shimei（shimei コンポーネントで追加）
      //datepicker（datepicker コンポーネントで追加）
      zokugara: [null, [Validators.required]],
      zokugara_sonota: [null, []],
      doukyo: [null, [Validators.required]],
      job: [null, [Validators.required]],
      kango: [null, [Validators.required]],
      seikei: [null, [Validators.required]],
    });
  }

  get setaiins(): FormArray {
    return this.pageForm.get('setaiins') as FormArray;
  }

  addSetaiin() {
    this.setaiins.push(this.setaiinForm);
  }

  removeSetaiin(index: number) {
    this.setaiins.removeAt(index);
  }


  getJidos(): JidoMeisai[] {
    const jidos = [];

    this.pageForm.get('setaiins')['controls'].forEach((child, i) => {
      const shimeiData = new Shimei(child.controls.shimei.get('sei_kj').value, child.controls.shimei.get('mei_kj').value, child.controls.shimei.get('sei_kn').value, child.controls.shimei.get('mei_kn').value);
      const umare_ymd = String(child.controls.datepicker.get('year').value) + '-' + String(('0' + (child.controls.datepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (child.controls.datepicker.get('day').value)).slice(-2));
      const zokugara = this.pageForm.controls.setaiins.value[i].zokugara_sonota != "" && this.pageForm.controls.setaiins.value[i].zokugara_sonota != null ?  this.pageForm.controls.setaiins.value[i].zokugara_sonota
      : this.zokugaraMaster[this.pageForm.controls.setaiins.value[i].zokugara];
      const jido = new JidoMeisai(i + 1, 
        shimeiData, 
        String(umare_ymd), 
        zokugara,
         this.pageForm.controls.setaiins.value[i].doukyo,
         this.pageForm.controls.setaiins.value[i].job,
         this.pageForm.controls.setaiins.value[i].kango,
         this.pageForm.controls.setaiins.value[i].seikei
         );

      jidos.push(jido);
    });

    return jidos;
  }
  
  setCopyShimei(name_kj, name_kn) {
    const shimei_kj = name_kj.split("　");
    const shimei_kn = name_kn.split("　");
    this.shimei = new Shimei(shimei_kj[0], shimei_kj[1], shimei_kn[0], shimei_kn[1]);
    this.uji = new Shimei(shimei_kj[0], null, shimei_kn[0], null);
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  onZokugaraChange(index: number){
    if (this.pageForm.controls.setaiins.value[index].zokugara!=='99'){
      const formArray = this.pageForm.get('setaiins') as FormArray;
      formArray.controls[index].get('zokugara_sonota').setValue('');
    }
  }

}
