import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export class Inkantouroku {
    sj121_snsi_bng: number;
    sj121_snsi_ymd: string;
    sj121_snsi_yubinno: string;
    sj121_snsi_addr_ken: string;
    sj121_snsi_addr_city: string;
    sj121_snsi_addr_town: string;
    sj121_snsi_addr_banti: string;
    sj121_snsi_addr_ktgk: string;
    sj121_snsi_name_kj: string;
    sj121_snsi_name_kn: string;
    sj121_snsi_telno: string;
    sj121_snsi_umare_ymd: string;
    sj121_dairi_yubinno: string;
    sj121_dairi_addr_ken: string;
    sj121_dairi_addr_city: string;
    sj121_dairi_addr_town: string;
    sj121_dairi_addr_banti: string;
    sj121_dairi_addr_ktgk: string;
    sj121_dairi_name_kj: string;
    sj121_dairi_name_kn: string;
    sj121_dairi_telno: string;
    sj121_hosho_yubinno: string;
    sj121_hosho_addr_ken: string;
    sj121_hosho_addr_city: string;
    sj121_hosho_addr_town: string;
    sj121_hosho_addr_banti: string;
    sj121_hosho_addr_ktgk: string;
    sj121_hosho_name_kj: string;
    sj121_hosho_name_kn: string;
    sj121_hosho_telno: string;
    sj121_hosho_touroku_no: number;
    sj121_honnin_kakunin: string;
    sj121_honnin_sonota: string;
    sj121_inkansyoumei_maisuu: number;
    sj121_sign_img: String;
    sj121_zumi_flg: boolean;
    sj121_sys_user_id: String;
}

export class Shinsei {
    snsi_smi: Shimei;
    snsi_address: ZipcodeAddress;
    snsi_telno: string;
    snsi_umare_ymd: string;
    dairiFlg: boolean;
    dairi_smi: Shimei;
    dairi_address: ZipcodeAddress;
    dairi_telno: string;
    honnin_kakunin: string;
    honnin_sonota: string;
    inkansyoumei_maisuu: number;
    constructor(snsi_smi: Shimei, snsi_address: ZipcodeAddress, snsi_telno: string, snsi_umare_ymd: string, dairiFlg: boolean, dairi_smi: Shimei, dairi_address: ZipcodeAddress, dairi_telno: string, honnin_kakunin: string, honnin_sonota: string, inkansyoumei_maisuu: number) {
        this.snsi_smi = snsi_smi;
        this.snsi_address = snsi_address;
        this.snsi_telno = snsi_telno;
        this.snsi_umare_ymd = snsi_umare_ymd;
        this.dairiFlg = dairiFlg;
        this.dairi_smi = dairi_smi;
        this.dairi_address = dairi_address;
        this.dairi_telno = dairi_telno;
        this.honnin_kakunin = honnin_kakunin;
        this.honnin_sonota = honnin_sonota;
        this.inkansyoumei_maisuu = inkansyoumei_maisuu;
    }
}

export class Hosho {
    hosho_smi: Shimei;
    hosho_address: ZipcodeAddress;
    hosho_telno: string;
    hosho_touroku_no: number;
    constructor(hosho_smi: Shimei, hosho_address: ZipcodeAddress, hosho_telno: string, hosho_touroku_no: number) {
        this.hosho_smi = hosho_smi;
        this.hosho_address = hosho_address;
        this.hosho_telno = hosho_telno;
        this.hosho_touroku_no = hosho_touroku_no;
    }
}