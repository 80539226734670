import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config/config.service';
import { TranslateService } from '@ngx-translate/core';

class LanguageSetting {
  "display": boolean;
  "default": string;
  "language": string[]
};

@Injectable({
  providedIn: 'root'
})
export class TranslateSettingService {


  constructor(private config: ConfigService) {
  }

  setTranslate(trans: TranslateService, langSetting: LanguageSetting): TranslateService{

    trans.addLangs(langSetting.language);
    trans.setDefaultLang(langSetting.default);
    const name = '_' + this.config.get("NAME");
    const browserLang = trans.getBrowserLang() + name;
    const browserLangMatch = RegExp(langSetting.language.join('|'));
    trans.use(browserLang.match(browserLangMatch) ? browserLang : langSetting.default);
    return trans
  }


  getLanguage(val: string): string {
    const lang = this.config.get("LANGUAGE_NAME").find(lang => lang.code == val);
    return lang == null ? null : lang.name == '' ? null : lang.name;
  }

}
