<div class="box-address">
    <div class="box-address-title">
        <div *ngFor="let addr_kbn of addr_kbns|filterKbn:showKbns; let i=index">
            <input type="radio" name="{{getName()}}" id="{{getId(i)}}" [value]="addr_kbn.value"
                (change)="onCheckChange($event)" [checked]="i==0">
            <label for="{{getId(i)}}" [class.label-first]="i==0"
                [class.label-last]="i==showKbns.length-1">{{addr_kbn.label}}</label>
        </div>
    </div>

    <ng-container
        *ngIf="controlName('addr_kbn').value == 1 || controlName('addr_kbn').value == 2 || controlName('addr_kbn').value == 3">
        <div class="box-koumoku-nomargin">
            <div class="box-required">
                <img class="required" src="/assets/required.png">
                <div class="label-msg"><label>{{ 'ZIPCODE.TITLE_ZIPCODE' | translate }}</label></div>
            </div>
            <ng-container
                *ngIf="controlName('zipcode').hasError('required') && (controlName('zipcode').touched || controlName('zipcode').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_REQUIRED' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('zipcode').hasError('numeric') && (controlName('zipcode').touched || controlName('zipcode').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_TYPE' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('zipcode').hasError('minlength') && (controlName('zipcode').touched || controlName('zipcode').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_LENGTH' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('zipcode').hasError('kannai') && !(controlName('zipcode').hasError('required') && (controlName('zipcode').touched || controlName('zipcode').dirty))">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_KANNAI' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('zipcode').hasError('kangai') && !(controlName('zipcode').hasError('required') && (controlName('zipcode').touched || controlName('zipcode').dirty))">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_KANGAI' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('zipcode').hasError('notExists') && !(controlName('zipcode').hasError('required') && (controlName('zipcode').touched || controlName('zipcode').dirty))">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ZIPCODE_ERROR_EXISTS' | translate }}
                    </a>
                </div>
            </ng-container>

            <div class="box-control">
                <input
                    [class.input-invalid]="controlName('zipcode').invalid && (controlName('zipcode').touched || controlName('zipcode').dirty)"
                    type="tel" maxlength="7" class="input-text input-zipcode" placeholder="" name="zipcode"
                    [formControl]="controlName('zipcode')" (keyup)="onKeyZipcode($event)" autocomplete="postal-code">
            </div>
            <div class="box-setumei">
                <a>{{ 'ZIPCODE.ZIPCODE_SETUMEI' | translate }}</a>
            </div>
        </div>

        <div [hidden]="!showFlg">

            <div class="box-koumoku box-koumoku-topmargin">
                <div class="box-required">
                    <img class="required" src="/assets/required.png">
                    <div class="label-msg"><label>{{ 'ZIPCODE.ADDR_KEN' | translate }}</label></div>
                </div>
                <ng-container
                    *ngIf="controlName('dsp_address').hasError('required') && (controlName('dsp_address').touched || controlName('dsp_address').dirty)">
                    <div class="err-msg">
                        <a>
                            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                            {{ 'ZIPCODE.ADDR_ERROR_REQUIRED' | translate }}
                        </a>
                    </div>
                </ng-container>
                <div class="box-control">
                    <div class="select"
                        [class.input-invalid]="controlName('dsp_address').invalid && (controlName('dsp_address').touched || controlName('dsp_address').dirty)">
                        <select [formControl]="controlName('dsp_address')">
                            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                            <option *ngFor="let address of addresses" [ngValue]="address">
                                {{getFullAddress(address)}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="box-koumoku">
                <div class="box-required">
                    <img class="required" src="/assets/required.png">
                    <div class="label-msg"><label>{{ 'ZIPCODE.ADDR_BANCHI' | translate }}</label></div>
                </div>
                <ng-container
                    *ngIf="controlName('banti').hasError('required') && (controlName('banti').touched || controlName('banti').dirty)">
                    <div class="err-msg">
                        <a>
                            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                            {{ 'ZIPCODE.ADDR_BANCHI_REQUIRED' | translate }}
                        </a>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="controlName('banti').hasError('banti') && (controlName('banti').touched || controlName('banti').dirty)">
                    <div class="err-msg">
                        <a>
                            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                            {{ 'ZIPCODE.ADDR_BANCHI_TYPE' | translate }}
                        </a>
                    </div>
                </ng-container>
                <div class="box-control">
                    <input
                        [class.input-invalid]="controlName('banti').invalid && (controlName('banti').touched || controlName('banti').dirty)"
                        class="input-text" type="text" [formControl]="controlName('banti')"
                        autocomplete="street-address" maxlength="10">
                </div>
                <div class="box-setumei">
                    <a>{{ 'ZIPCODE.BANTI_SETUMEI' | translate }}</a>
                    <br>
                    <a>{{ 'ZIPCODE.BANTI_EXAMPLE' | translate }}</a>
                </div>
            </div>
            <div class="box-koumoku-nomargin">
                <div class="label-msg"><label>{{ 'ZIPCODE.ADDR_KATAGAKI' | translate }}</label></div>
                <ng-container
                    *ngIf="controlName('ktgk').hasError('zenkaku') && (controlName('ktgk').touched || controlName('ktgk').dirty)">
                    <div class="err-msg">
                        <a>
                            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                            {{ 'ZIPCODE.ADDR_KATAGAKI_TYPE' | translate }}
                        </a>
                    </div>
                </ng-container>
                <div class="box-control">
                    <input class="input-text" type="text" [formControl]="controlName('ktgk')" maxlength="20" >
                </div>
                <div class="box-setumei">
                    <a>{{ 'ZIPCODE.KTGK_SETUMEI' | translate }}</a>
                    <br>
                    <a>{{ 'ZIPCODE.KTGK_EXAMPLE' | translate }}</a>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="controlName('addr_kbn').value == 4">
        <div class="box-koumoku-nomargin">
            <div class="box-required">
                <img class="required" src="/assets/required.png">
                <div class="label-msg"><label>{{ 'ZIPCODE.ADDR_ALL' | translate }}</label></div>
            </div>
            <ng-container
                *ngIf="controlName('address_all').hasError('required') && (controlName('address_all').touched || controlName('address_all').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ADDR_ALL_REQUIRED' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('address_all').hasError('zenkaku') && (controlName('address_all').touched || controlName('address_all').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'ZIPCODE.ADDR_ALL_TYPE' | translate }}
                    </a>
                </div>
            </ng-container>
            <div class="box-control">
                <input
                    [class.input-invalid]="controlName('address_all').invalid && (controlName('address_all').touched || controlName('address_all').dirty)"
                    class="input-text" type="text" [formControl]="controlName('address_all')"
                    autocomplete="street-address" maxlength="20" >
            </div>
            <div class="box-setumei">
                <a>{{ 'ZIPCODE.ADDR_ALL_SETUMEI' | translate }}</a>
            </div>
        </div>
    </ng-container>

</div>