<div class="box-doui">
    <div class="box-doui-setumei">
        <h5>{{ 'COMMON.DOUI.DOUI_COMMON' | translate }}</h5>
        <ng-container *ngIf="item.sj201_snsi_syurui=='01'">
            <h5>{{ 'JYUKIIDO.DOUI.DOUI_01' | translate }}</h5>
        </ng-container>
        <ng-container *ngIf="item.sj201_snsi_syurui=='02'">
            <h5>{{ 'JYUKIIDO.DOUI.DOUI_02' | translate }}</h5>
        </ng-container>
        <ng-container *ngIf="item.sj201_snsi_syurui=='03'">
            <h5>{{ 'JYUKIIDO.DOUI.DOUI_03' | translate }}</h5>
        </ng-container>
        <h5>{{ 'JYUKIIDO.DOUI.DOUI_COMMON' | translate }}</h5>
    </div>

    <div class="box-doui-message">
        <div class="box-doui-message-col1">
            <div class="doui-area">
                <input type="checkbox" id="doui-check" name="douiFlg" [formControl]="pageForm.controls.douiFlg">
                <label for="doui-check">
                </label>
            </div>
        </div>
        <div class="box-doui-message-col2">
            {{ 'COMMON.DOUI.DOUI_KAKUNIN' | translate }}
        </div>
    </div>
</div>