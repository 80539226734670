<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'JYUKIIDO.ZENJYUSYO.TITLE_ZENJYUSYO' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <form [formGroup]="pageForm">
      <div class="box-koumoku">
        <div class="box-required">
          <img class="required" src="/assets/required.png">
          <label>{{ 'COMMON.ADDR' | translate }}</label>
        </div>
        <app-zipcode fgName={{fgNameZipcode}} ckName={{ckNameZipcode}}>
        </app-zipcode>
      </div>

      <div class="box-koumoku">
        <div class="box-setai">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'COMMON.SETAINUSHI' | translate }}</label>
          </div>

          <div class="box-input">
            <div class="toggle_switch">
              <input type="checkbox" id="{{getId('zen_switch')}}" name="editFlg" [formControl]="pageForm.controls.editFlg"
                (change)="onEdit()">
                <label for="{{getId('zen_switch')}}">
                <fa-icon [icon]="faEdit"></fa-icon>
              </label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="pageForm.controls.editFlg.value==false">
          <ng-container
            *ngIf="pageForm.controls.setainushi.hasError('required') && (pageForm.controls.setainushi.touched || pageForm.controls.setainushi.dirty)">
            <div class="err-msg">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'COMMON.SETAINUSHI_ERROR_REQUIRED' | translate }}
              </a>
            </div>
          </ng-container>
          <div class="box-control">
            <div class="select"
              [class.input-invalid]="pageForm.controls.setainushi.invalid && (pageForm.controls.setainushi.touched || pageForm.controls.setainushi.dirty)">
              <select [formControl]="pageForm.controls.setainushi" (change)="onChangeSetainushi()">
                <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                <option *ngFor="let item_val of idosyas" [ngValue]="item_val">
                  {{item_val.ido_smi.sei_kj}}　{{item_val.ido_smi.mei_kj}}</option>
              </select>
            </div>
          </div>
        </ng-container>

        <div [hidden]="!pageForm.controls.editFlg.value">
          <div class="box-koumoku">
            <app-shimei [buttonVisible]="false" fgName={{fgNameShimei}} ckName={{ckNameShimei}} defaltKbn="1">
            </app-shimei>
          </div>
        </div>

      </div>

    </form>
  </div>
</div>