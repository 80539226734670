<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'INKANSYOUMEI.SHINSEI.TITLE_KISAI' | translate }}</h4>
  </div>
  <div class="box-block-inner">
    <div class="box-koumoku">
      <div class="inf-msg">
        <a>
          <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
          {{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO_LOST' | translate }}
        </a>
      </div>
    </div>
    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO' | translate }}</label>
      </div>
      <ng-container
        *ngIf="pageForm.controls.touroku_no.hasError('numeric') && (pageForm.controls.touroku_no.touched || pageForm.controls.touroku_no.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <ng-container
        *ngIf="pageForm.controls.touroku_no.hasError('required') && (pageForm.controls.touroku_no.touched || pageForm.controls.touroku_no.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.touroku_no.invalid && (pageForm.controls.touroku_no.touched || pageForm.controls.touroku_no.dirty)"
          class="input-text input-inkanno" type="text" name="touroku_no" [formControl]="pageForm.controls.touroku_no" maxlength="7">
      </div>
      <div class="box-setumei">
        <a>{{ 'INKANSYOUMEI.SHINSEI.TOUROKUNO_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.MAISU' | translate }}</label>
      </div>

      <ng-container
        *ngIf="pageForm.controls.maisuu.hasError('required') && (pageForm.controls.maisuu.touched || pageForm.controls.maisuu.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'COMMON.MAISU_ERROR_REQUIRED' | translate }}
          </a>
        </div>
      </ng-container>

      <div class="box-control">
        <div class="select box-maisu"
          [class.input-invalid]="pageForm.controls.maisuu.invalid && (pageForm.controls.maisuu.touched || pageForm.controls.maisuu.dirty)">
          <select name="maisu" [formControl]="pageForm.controls.maisuu">
            <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
            <option *ngFor="let maisu_val of maisu" [ngValue]="maisu_val">{{maisu_val}}</option>
          </select>
        </div>
      </div>

    </div>
  </div>
  
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'INKANSYOUMEI.SHINSEI.SETUMEI_TAISYOUSYA' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.TAISYOUSYA' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiTouroku}} ckName={{ckNameShimeiTouroku}}
          defaltKbn="1"></app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcodeTouroku}} ckName={{ckNameZipcodeTouroku}}
          defaltKbn="1"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.snsi_telno.hasError('numeric') && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.snsi_telno.invalid && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)"
          class="input-text input-tel" type="text" maxlength="11" name="snsi_telno"
          [formControl]="pageForm.controls.snsi_telno" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerTouroku}}></app-datepicker>
      </form>
    </div>
  </div>
</div>

<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'COMMON.TITLE_DAIRI' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'INKANSYOUMEI.SHINSEI.SETUMEI_DAIRI' | translate }}
        </a>
      </div>
    </div>

    <div class="box-required">
      <img class="required" src="/assets/required.png">
      <label>{{ 'COMMON.RAICYOUSYA' | translate }}</label>
    </div>

    <ng-container
      *ngIf="pageForm.controls.dairiFlg.hasError('required') && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
      <div class="err-msg">
        <a>
          <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
          {{ 'COMMON.RAICYOUSYA_ERROR_REQUIRED' | translate }}
        </a>
      </div>
    </ng-container>

    <div class="box-koumoku">
      <div class="dairi-radio-tile-group">
        <div class="input-container input-container-left">
          <label for="{{getId('honnin')}}">
            <input id="{{getId('honnin')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiFlg"
              (change)="onDairi()" [value]="false" name={{rdName}} hidden />
            <div class="radio-tile" [class.input-invalid]="pageForm.controls.dairiFlg.invalid && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
              <div class="icon honnin-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                  viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #4B4B4B;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M449.688,422.344c-3.375-9.109-8.094-16.906-13.703-23.5c-4.906-5.766-10.484-10.625-16.391-14.781
                      c-10.344-7.297-21.719-12.484-32.875-16.922c-8.359-3.328-16.625-6.203-24.266-9.109c-5.734-2.172-11.109-4.344-15.891-6.688
                      c-3.594-1.734-6.844-3.563-9.688-5.5c-2.125-1.469-4.016-2.969-5.656-4.547c-2.469-2.391-4.406-4.891-5.813-7.703
                      c-1.438-2.828-2.406-5.984-2.813-9.844c-0.313-3.063-0.469-6.188-0.469-9.281c0-9.125,1.266-18.094,2.781-25.484
                      c4.594-5.188,8.797-11.359,12.766-18.75c3.984-7.438,7.766-16.109,11.547-26.391c2.422-1.094,4.844-2.422,7.25-4.031
                      c5.422-3.641,10.578-8.734,14.875-15.516c4.281-6.781,7.734-15.203,10.094-25.609c0.641-2.875,0.953-5.766,0.953-8.578
                      c0-5.906-1.391-11.531-3.734-16.563c-1.625-3.484-3.688-6.672-6.094-9.578c1.234-4.531,2.719-10.641,4.047-17.781
                      c1.828-9.938,3.359-21.844,3.359-34.375c0-8.078-0.641-16.438-2.281-24.672c-1.234-6.172-3.031-12.297-5.594-18.203
                      c-3.844-8.813-9.391-17.109-17.156-23.969c-7.156-6.359-16.156-11.422-27-14.672c-3.828-4.578-7.688-8.516-11.641-11.828
                      c-3.391-2.844-6.891-5.25-10.469-7.219c-5.359-2.969-10.922-4.969-16.625-6.156c-5.703-1.203-11.5-1.625-17.516-1.625
                      c-8.313,0-17.047,0.813-26.703,1.781c-3.641,0.359-6.703,0.813-9.359,1.281c-3.953,0.703-6.922,1.438-9.313,1.906
                      c-1.188,0.25-2.219,0.438-3.25,0.563c-1.016,0.109-2.016,0.188-3.156,0.188c-1.5,0-3.281-0.125-5.563-0.469
                      c-7.031-1-13.453-2.609-18.5-4.156c-2.531-0.766-4.688-1.516-6.406-2.156c-0.844-0.328-1.594-0.625-2.188-0.891
                      c-0.594-0.25-1.063-0.453-1.313-0.578l0,0c-0.766-0.391-1.5-0.625-2.188-0.766C183.094,0.031,182.5,0,182,0
                      c-1.078,0-1.828,0.172-2.438,0.313c-0.609,0.156-1.078,0.328-1.5,0.516c-0.797,0.328-1.438,0.672-2.047,1.047
                      c-1.141,0.656-2.203,1.438-3.359,2.313c-2.016,1.563-4.219,3.5-6.563,5.75c-3.5,3.391-7.25,7.469-10.672,11.938
                      c-1.703,2.25-3.313,4.563-4.75,6.969c-1.453,2.406-2.719,4.875-3.734,7.469c-4.922,12.844-8.188,24.656-10.219,35.578
                      s-2.813,20.922-2.813,30.078c0,15.016,2.109,27.75,4.125,38.5c0,0.516,0.031,1,0.047,1.5c0.078,1.016,0.172,2.063,0.266,3.141
                      c0.172,1.625,0.375,3.297,0.531,4.688c0.063,0.688,0.125,1.313,0.156,1.797c0.031,0.25,0.031,0.438,0.031,0.594l0.031,0.156v0.063
                      l0,0v0.922l1.969,8.781c-2.844,3.109-5.313,6.578-7.219,10.391c-2.656,5.313-4.234,11.297-4.234,17.625
                      c0,2.813,0.313,5.688,0.953,8.563c1.578,6.938,3.625,13,6.109,18.281c3.688,7.922,8.359,14.094,13.563,18.719
                      c4.016,3.594,8.313,6.219,12.563,8.156c3.766,10.281,7.547,18.938,11.547,26.375c3.969,7.391,8.156,13.563,12.75,18.75
                      c1.5,7.375,2.781,16.375,2.781,25.516c0,3.094-0.156,6.203-0.469,9.266c-0.313,3.031-0.984,5.625-1.938,7.984
                      c-0.906,2.203-2.078,4.172-3.516,6.047c-2.703,3.516-6.453,6.672-11.203,9.594c-4.156,2.563-9.063,4.938-14.469,7.188
                      c-9.438,3.984-20.406,7.641-31.578,12.016c-8.391,3.281-16.938,6.984-25.109,11.641c-6.125,3.5-12.031,7.547-17.469,12.375
                      c-8.172,7.25-15.25,16.313-20.156,27.625c-4.906,11.297-7.656,24.75-7.641,40.688c0,2.188,0.453,4.313,1.219,6.25
                      c0.719,1.813,1.672,3.484,2.828,5.063c2.188,2.938,4.969,5.594,8.406,8.141c6.016,4.422,14.031,8.594,24.375,12.5
                      c15.531,5.859,36.328,11.109,63.219,14.938C183.281,509.625,216.25,512,256,512c34.453,0,63.828-1.797,88.547-4.75
                      c18.547-2.234,34.484-5.125,48.016-8.422c10.156-2.484,18.938-5.188,26.5-8.047c5.656-2.156,10.609-4.375,14.922-6.688
                      c3.234-1.719,6.094-3.484,8.625-5.297c3.781-2.734,6.828-5.578,9.141-8.781c1.156-1.609,2.125-3.313,2.813-5.172
                      c0.688-1.844,1.109-3.844,1.109-5.938C455.672,444.875,453.563,432.766,449.688,422.344z M189,262.344
                      c-3.906-7.25-7.781-16.297-11.781-27.563l-1.313-3.688l-3.656-1.344c-2.547-0.938-5.031-2.141-7.406-3.734
                      c-3.578-2.422-7-5.703-10.109-10.609c-3.109-4.922-5.922-11.5-7.922-20.391c-0.391-1.688-0.563-3.297-0.563-4.891
                      c0-3.563,0.875-6.938,2.484-10.188c1.328-2.656,3.203-5.219,5.469-7.5c3.828,5.781,6.984,10.344,8.141,11.5
                      c3.75,3.75,4.281-5.063,2.5-14.969c-3.281-18.234,16.688-39.875,18.719-82.375C228.5,96.563,316.344,77.5,316.344,77.5
                      s0.938,21.813,15.906,41.766c14.219,18.953,22.297,63.422,22.297,63.422s1.953-3.672,4.828-8.578
                      c1.719,2.016,3.141,4.188,4.188,6.453c1.438,3.063,2.188,6.219,2.188,9.547c0,1.594-0.172,3.219-0.563,4.906
                      c-1.328,5.922-3.031,10.828-4.922,14.891c-2.859,6.094-6.141,10.281-9.578,13.359c-3.453,3.078-7.094,5.078-10.906,6.469
                      l-3.672,1.359l-1.313,3.688c-3.984,11.266-7.891,20.313-11.797,27.578c-3.891,7.266-7.781,12.703-11.813,16.984l-1.594,1.672
                      l-0.5,2.266c-1.875,8.563-3.594,19.5-3.594,31.188c0,3.609,0.156,7.297,0.531,11c0.469,4.5,1.531,8.703,3.109,12.578
                      c1.484,3.609,3.422,6.922,5.719,9.891c0.563,0.719,1.141,1.438,1.734,2.125c-0.625,1.156-1.297,2.266-2.047,3.375
                      c-2.891,4.188-6.75,8.031-11.297,11.406c-6.813,5.063-15.156,9.031-23.5,11.641c-4.188,1.328-8.344,2.297-12.313,2.953
                      s-7.719,0.969-11.031,0.969c-5.031,0-11.094-0.734-17.375-2.25c-9.438-2.281-19.359-6.344-27.453-11.906
                      c-4.063-2.75-7.656-5.891-10.578-9.313c-2.031-2.359-3.719-4.859-5.094-7.484c2.125-2.531,4-5.297,5.547-8.344
                      c2.375-4.656,3.922-9.922,4.516-15.641c0.375-3.703,0.531-7.375,0.531-10.984c0-11.703-1.719-22.641-3.594-31.234l-0.5-2.25
                      l-1.594-1.688C196.781,275.063,192.891,269.594,189,262.344z M438.594,459.75c-0.563,0.953-1.859,2.516-4.063,4.281
                      c-1.906,1.531-4.484,3.266-7.734,5.031c-5.688,3.125-13.391,6.375-23.188,9.438c-14.688,4.625-34.078,8.844-58.531,11.906
                      c-24.469,3.063-54,4.953-89.078,4.953c-33.875,0-62.594-1.766-86.563-4.641c-17.984-2.156-33.281-4.938-46.063-8.063
                      c-9.563-2.344-17.734-4.875-24.531-7.438c-5.094-1.938-9.422-3.891-12.984-5.797c-2.672-1.422-4.922-2.828-6.75-4.141
                      c-2.734-1.969-4.484-3.75-5.344-4.969c-0.438-0.594-0.656-1.047-0.734-1.281c-0.047-0.109-0.063-0.156-0.063-0.188
                      c0-12.359,1.859-22.375,4.938-30.719c2.719-7.281,6.375-13.281,10.781-18.5c3.875-4.547,8.344-8.469,13.297-11.969
                      c8.672-6.125,18.828-10.844,29.422-15.047c7.938-3.156,16.109-6,24.047-9.016c5.953-2.25,11.766-4.594,17.266-7.266
                      c3.594-1.766,7.063-3.672,10.344-5.797c0.484,0.781,1,1.563,1.516,2.328c4.109,6,9.328,11.078,15.094,15.359
                      c8.656,6.406,18.578,11.031,28.422,14.156c4.922,1.547,9.844,2.719,14.609,3.5c4.766,0.766,9.375,1.188,13.703,1.188
                      c6.625-0.016,13.875-0.938,21.313-2.75c11.156-2.703,22.781-7.375,32.938-14.313c5.063-3.469,9.781-7.516,13.813-12.219
                      c1.844-2.156,3.531-4.438,5.063-6.875c0.328,0.219,0.656,0.438,1,0.656c5.219,3.188,10.844,5.875,16.734,8.344
                      c10.266,4.313,21.297,7.984,31.969,12.172c8,3.125,15.797,6.547,22.922,10.594c5.313,3.047,10.266,6.453,14.672,10.375
                      c6.609,5.875,12.031,12.844,15.938,21.797c3.875,8.938,6.25,19.953,6.266,34C439,458.938,438.906,459.266,438.594,459.75z"
                      style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('honnin')}}" class="radio-tile-label">{{ 'COMMON.HONNIN' | translate }}</label>

            </div>
          </label>
        </div>
        <div class="input-container input-container-right">
          <label for="{{getId('dairi')}}">
            <input id="{{getId('dairi')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiFlg"
              (change)="onDairi()" [value]="true" name={{rdName}} hidden />
            <div class="radio-tile" [class.input-invalid]="pageForm.controls.dairiFlg.invalid && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
              <div class="icon dairi-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0{fill:#4B4B4B;}
                  </style>
                  <g>
                    <path class="st0" d="M316.761,327.239c-1.49-14.854,0.701-29.826,3.158-40.851c9.621-10.09,17.888-25.136,26.434-48.966
                      c12.758-4.626,25.74-15.365,31.846-41.93c2.812-12.234-1.951-23.573-10.228-31.761c6.096-20.18,30.144-113.704-40.967-132.955
                      C303.445,1.098,282.724,2.203,247.201,5.62c-17.67,1.699-20.196,5.444-33.746,3.424c-15.541-2.32-28.136-7.086-31.924-8.996
                      c-2.182-1.105-22.758,17.016-28.528,31.85c-18.279,46.991-13.539,79.284-9.025,103.714c-0.162,2.498-0.379,4.988-0.389,7.516
                      l4.15,17.898c0.012,0.184-0.002,0.36,0.004,0.544c-9.207,8.19-14.779,20.068-11.908,33.011
                      c5.906,26.618,18.815,37.451,31.545,42.17c8.363,23.882,16.504,38.984,26.045,49.146c2.379,11.036,4.459,26.037,2.856,40.888
                      c-6.697,62.214-137.533,28.938-137.946,138.217c-0.064,16.853,56.301,46.459,197.49,46.989
                      c141.19,0.531,197.776-28.648,197.838-45.501C454.076,357.21,322.992,389.502,316.761,327.239z M232.972,493.819l-35.295-145.722
                      l44.027,18.518l-2.453,21.296l7.006-0.125L232.972,493.819z M276.828,388.052l-2.291-21.314l44.164-18.186L282.31,494.005
                      l-12.486-106.131L276.828,388.052z" style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('dairi')}}" class="radio-tile-label">{{ 'COMMON.DAIRI' | translate }}</label>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="box-koumoku">
      <div [hidden]="!pageForm.controls.dairiFlg.value">

        <div class="box-koumoku">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'COMMON.DAIRI' | translate }}</label>
          </div>

          <form [formGroup]="pageForm">
            <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiDairi}} ckName={{ckNameShimeiDairi}}
              defaltKbn="1">
            </app-shimei>
          </form>
        </div>

        <div class="box-koumoku">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'COMMON.ADDR' | translate }}</label>
          </div>

          <form [formGroup]="pageForm">
            <app-zipcode fgName={{fgNameZipcodeDairi}} ckName={{ckNameZipcodeDairi}}
              defaltKbn="3"></app-zipcode>
          </form>
        </div>

        <div class="box-koumoku">
          <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
          <ng-container
            *ngIf="pageForm.controls.dairi_telno.hasError('numeric') && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)">
            <div class="err-msg">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'COMMON.TEL_ERROR_TYPE' | translate }}
              </a>
            </div>
          </ng-container>
          <div class="box-control">
            <input
              [class.input-invalid]="pageForm.controls.dairi_telno.invalid && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)"
              class="input-text input-tel" type="text" maxlength="11" name="dairi_telno"
              [formControl]="pageForm.controls.dairi_telno" autocomplete="tel">
          </div>
          <div class="box-setumei">
            <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>