<div class="box-top">
  <div class="box-title">
    <img class="img-title-contens" src="/assets/title-torikomi.png">
    <div class="lang" *ngIf="langFlg">
      <div class="lang-box">
        <p class="lang-label">
          <fa-icon [icon]="faGlobe"></fa-icon> {{translateSettingService.getLanguage(langSelect.value)}}
        </p>
      </div>
      <select #langSelect (change)="translate.use(langSelect.value)">
        <option disabled="disabled" [value]="null">Language</option>
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
          {{ translateSettingService.getLanguage(lang) }}</option>
      </select>
    </div>
  </div>
</div>


<div class="box-main">
  <div class="box-contents-background">
    <div class="box-contents">
      <h1>{{ 'JYUMINHYOU.TITLE_MAIN' | translate }}</h1>
      <app-stepver [currentStep]="currentSteper" [steps]="steps"></app-stepver>

      <app-jyuminhyou-doui (isPageFormValid)="enableNextButton($event)" [hidden]="getCurrentSteper(1)">
      </app-jyuminhyou-doui>
      <app-jyuminhyou-shinsei (isPageFormValid)="enableNextButton($event)" [hidden]="getCurrentSteper(2)">
      </app-jyuminhyou-shinsei>
      <app-jyuminhyou-cyouhyo (isPageFormValid)="enableNextButton($event)" [hidden]="getCurrentSteper(3)">
      </app-jyuminhyou-cyouhyo>
      <app-jyuminhyou-option [item]="item" (isPageFormValid)="enableNextButton($event)" [hidden]="getCurrentSteper(4)">
      </app-jyuminhyou-option>
      <app-jyuminhyou-kakunin [item]="item" [shinsei]="shinsei" [hidden]="getCurrentSteper(5)"></app-jyuminhyou-kakunin>
      <app-jyuminhyou-qr [item]="item" [hidden]="getCurrentSteper(6)"></app-jyuminhyou-qr>

      <div class="box-next" [hidden]="hiddenNext()">
        <a class="btn-next" (click)="onNext()" [class.btn-next-valid]="isChildFormValid">
          <div *ngIf="isChildFormValid; then enableButton; else disableButton"></div>
        </a>
      </div>
      <ng-template #enableButton>
        <a>{{ 'COMMON.NEXT_VALID' | translate }}</a>
      </ng-template>
      <ng-template #disableButton>
        <a>{{ 'COMMON.NEXT_INVALID' | translate }}</a>
      </ng-template>

      <div class="box-back" [hidden]="hiddenBack()">
        <a class="btn-back" (click)="onBack()">{{ 'COMMON.BACK' | translate }}</a>
      </div>

      <div class="box-move-top">
        <a class="btn-move-top" (click)="scrollTop()"></a>
      </div>

    </div>
  </div>
</div>