<div class="box-kakunin-title">
    <h4>{{ 'COMMON.KAKUNIN.TITLE_KAKUNIN' | translate }}</h4>
</div>

<div class="box-kakunin">
    <div class="box-koumoku">
        <h4>{{ 'INKANSYOUMEI.SHINSEI.TITLE_TAISYOUSYA' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div class="box-shinsei">
                    <div><a>{{item.sj101_tisy_name_kj}}</a></div>
                    <div class="is-hidden"><a>/</a></div>
                    <div><a>{{item.sj101_tisy_name_kn}}</a></div>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.BIRTHDAY' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div><a>{{item.sj101_tisy_umare_ymd}}</a></div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div>
                    <a>{{item.sj101_tisy_addr_ken}}{{item.sj101_tisy_addr_city}}{{item.sj101_tisy_addr_town}}{{item.sj101_tisy_addr_banti}}
                        {{item.sj101_tisy_addr_ktgk}}</a>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div><a>{{item.sj101_tisy_telno}}</a></div>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <!--来庁者-->
        <h4>{{ 'COMMON.RAICYOUSYA' | translate }}</h4>
    </div>
    <div class="meisai">
        <ng-container *ngIf="item.sj101_dairi_addr_ken==''">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj101_tisy_name_kj}}</a></div>
                        <ng-container *ngIf="item.sj101_tisy_name_kn">
                            <div class="is-hidden"><a>/</a></div>
                            <div><a>{{item.sj101_tisy_name_kn}}</a></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="item.sj101_dairi_addr_ken">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SHIMEI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj101_dairi_name_kj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.sj101_dairi_name_kn}}</a></div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="shinsei.dairiKbn==3">
                <div class="meisai-row">
                    <div class="meisai-col-title">
                        <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                    </div>
                    <div class="meisai-col-naiyou">
                        <div>
                            <a>{{item.sj101_dairi_addr_ken}}{{item.sj101_dairi_addr_city}}{{item.sj101_dairi_addr_town}}{{item.sj101_dairi_addr_banti}}
                                {{item.sj101_dairi_addr_ktgk}}</a>
                        </div>
                    </div>
                </div>
                <div class="meisai-row">
                    <div class="meisai-col-title">
                        <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
                    </div>
                    <div class="meisai-col-naiyou">
                        <div><a>{{item.sj101_dairi_telno}}</a></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="item.sj101_siky_addr_ken">
        <div class="box-koumoku">
            <h4>{{ 'COMMON.SEIKYUSYA' | translate }}</h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.SEIKYUSYA' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div class="box-shinsei">
                        <div><a>{{item.sj101_siky_name_kj}}</a></div>
                        <div class="is-hidden"><a>/</a></div>
                        <div><a>{{item.sj101_siky_name_kn}}</a></div>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.ADDR' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div>
                        <a>{{item.sj101_siky_addr_ken}}{{item.sj101_siky_addr_city}}{{item.sj101_siky_addr_town}}{{item.sj101_siky_addr_banti}}
                            {{item.sj101_siky_addr_ktgk}}</a>
                    </div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.TEL' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{item.sj101_siky_telno}}</a></div>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngFor="let cyouhyo of item.sj102s; let tindex = index">
        <div class="box-koumoku">
            <h4>{{ 'JYUMINHYOU.CYOUHYO.TITLE_CYOUHYO' | translate }}（{{tindex + 1}}/{{getMaxCyouhyo()}}）</h4>
        </div>
        <div class="meisai">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JYUMINHYOU.CYOUHYO.NAME' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{ getCyouhyoName(cyouhyo.sj102_cyouhyo_cd) | translate }}</a></div>
                </div>
            </div>
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'COMMON.MAISU' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <div><a>{{cyouhyo.sj102_maisuu}}</a></div>
                </div>
            </div>
        </div>
    </div>

    <div class="box-koumoku">
        <h4>{{ 'JYUMINHYOU.OPTION.TITLE_OPTION' | translate }}</h4>
    </div>
    <div class="meisai">
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'JYUMINHYOU.OPTION.YOUTO' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <div><a>{{getYouto()}}</a></div>
            </div>
        </div>
        <div [hidden]="isHide()">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JYUMINHYOU.OPTION.TITLE_ZOKUGARA' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <ng-container *ngIf="getZokugara() === 'true';else second_zokugara">
                        <a>{{ 'PRINT_WITH_JA.YES' | translate }}</a>
                    </ng-container>
                    <ng-template #second_zokugara>
                        <ng-container *ngIf="getZokugara() === 'false';else third_zokugara">
                            <a>{{ 'PRINT_WITH_JA.NO' | translate }}</a>
                        </ng-container>
                    </ng-template>
                    <ng-template #third_zokugara>
                        <a>{{ 'PRINT_WITH_JA.UNKNOWN' | translate }}</a>
                    </ng-template>
                </div>
            </div>
        </div>
        <div [hidden]="isHide()">
            <div class="meisai-row">
                <div class="meisai-col-title">
                    <div><a>{{ 'JYUMINHYOU.OPTION.TITLE_HONSEKI' | translate }}</a></div>
                </div>
                <div class="meisai-col-naiyou">
                    <ng-container *ngIf="getHonseki() === 'true';else second_honseki">
                        <a>{{ 'PRINT_WITH_JA.YES' | translate }}</a>
                    </ng-container>
                    <ng-template #second_honseki>
                        <ng-container *ngIf="getHonseki() === 'false';else third_honseki">
                            <a>{{ 'PRINT_WITH_JA.NO' | translate }}</a>
                        </ng-container>
                    </ng-template>
                    <ng-template #third_honseki>
                        <a>{{ 'PRINT_WITH_JA.UNKNOWN' | translate }}</a>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="meisai-row">
            <div class="meisai-col-title">
                <div><a>{{ 'JYUMINHYOU.OPTION.TITLE_MYNUMBER' | translate }}</a></div>
            </div>
            <div class="meisai-col-naiyou">
                <ng-container *ngIf="getMynumber() === 'all';else second_mynumber">
                    <a>{{ 'JYUMINHYOU.OPTION.MYNUMBER_ALL' | translate }}</a>
                </ng-container>
                <ng-template #second_mynumber>
                    <ng-container *ngIf="getMynumber() === 'some';else third_mynumber">
                        <a>{{ 'JYUMINHYOU.OPTION.MYNUMBER_SOME' | translate }}</a>
                    </ng-container>
                </ng-template>
                <ng-template #third_mynumber>
                    <ng-container *ngIf="getMynumber() === 'false';else fourth_mynumber">
                        <a>{{ 'PRINT_WITH_JA.NO' | translate }}</a>
                    </ng-container>
                </ng-template>
                <ng-template #fourth_mynumber>
                    <a>{{ 'PRINT_WITH_JA.UNKNOWN' | translate }}</a>
                </ng-template>
            </div>
        </div>
        <div [hidden]="isHide()">
            <ng-container *ngIf="displayMultiSelectedLabel()">
                <div class="meisai-row">
                    <div class="meisai-col-title">
                        <div><a>{{ 'JYUMINHYOU.OPTION.GAIJINKISAI' | translate }}</a></div>
                    </div>
                    <div class="meisai-col-naiyou">
                        <div><a>{{displayMultiSelectedLabel()}}</a></div>
                    </div>
                </div>
            </ng-container>
        </div>    
    </div>

</div>
