<div class="box-block">
    <div class="box-koumoku">
        <h4>{{ 'JYUKIIDO.MENU.TITLE_ADDR' | translate }}</h4>
    </div>
    <div class="box-block-inner">
        <form [formGroup]="pageForm">
            <div class="box-koumoku">
                <div class="box-required">
                    <img class="required" src="/assets/question.png">
                    <div class="label-msg"><label>{{ 'JYUKIIDO.MENU.TITLE_ZEN_SETUMEI' | translate }}</label></div>
                </div>

                <div class="dairi-radio-tile-group">
                    <div class="input-container input-container-left">
                        <label for="zenInFlg-yes">
                            <input id="zenInFlg-yes" class="radio-button" type="radio" name="zenInFlg"
                                (change)="onZenIn()" [formControl]="pageForm.controls.zenInFlg" [value]="true" hidden />
                            <div class="radio-tile">
                                <div class="icon zenInFlg-yes-icon">
                                    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                        style="width: 32px; height: 32px;" xml:space="preserve">
                                        <style type="text/css">
                                            .st0 {
                                                fill: #4B4B4B;
                                            }
                                        </style>
                                        <g>
                                            <path class="st0" d="M99.407,259.965C44.587,259.965,0,304.552,0,359.372c0,54.811,44.587,99.398,99.407,99.398
                                                c54.811,0,99.406-44.587,99.406-99.398C198.813,304.552,154.218,259.965,99.407,259.965z M99.407,431.538
                                                c-39.793,0-72.157-32.374-72.157-72.166c0-39.801,32.364-72.175,72.157-72.175c39.792,0,72.174,32.374,72.174,72.175
                                                C171.581,399.165,139.199,431.538,99.407,431.538z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0" d="M412.602,259.965c-54.82,0-99.407,44.587-99.407,99.407c0,54.811,44.587,99.398,99.407,99.398
                                                c54.811,0,99.398-44.587,99.398-99.398C512,304.552,467.413,259.965,412.602,259.965z M412.602,431.538
                                                c-39.801,0-72.175-32.374-72.175-72.166c0-39.801,32.374-72.175,72.175-72.175c39.792,0,72.166,32.374,72.166,72.175
                                                C484.768,399.165,452.394,431.538,412.602,431.538z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0" d="M267.288,63.74l-142.7,24.504c-11.179,1.486-19.552,8.747-19.552,19.553l2.38,54.134l3.17,72.174
                                                c0,7.731,6.272,13.994,14.002,13.994c7.74,0,14.002-6.263,14.002-13.994l3.301-77.083l68.605-18.537
                                                c0,0-25.243,29.96-27.336,32.574c-10.459,13.072-14.246,37.039,2.614,49.678l73.009,53.021l4.144,63.532
                                                c0,10.519,8.53,19.049,19.04,19.049c10.51,0,19.04-8.53,19.04-19.049c0-0.417,16.773-203.573,16.773-203.573
                                                C322.637,91.363,298.254,58.867,267.288,63.74z M228.825,204.755c-3.648-2.71-2.728-6.341,0-9.972
                                                c2.718-3.648,24.512-21.785,24.512-21.785l4.535,57.173L228.825,204.755z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0"
                                                d="M53.986,123.85c19.492,0,35.302-15.809,35.302-35.301c0-19.51-15.81-35.319-35.302-35.319
                                                c-19.501,0-35.31,15.809-35.31,35.319C18.676,108.041,34.484,123.85,53.986,123.85z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                        </g>
                                    </svg>
                                </div>
                                <label for="zenInFlg-yes" class="radio-tile-label">{{ 'COMMON.YES' | translate }}
                                </label>
                            </div>
                        </label>
                    </div>
                    <div class="input-container  input-container-right">
                        <label for="zenInFlg-no">
                            <input id="zenInFlg-no" class="radio-button" type="radio" name="zenInFlg"
                                (change)="onZenIn()" [formControl]="pageForm.controls.zenInFlg" [value]="false"
                                hidden />
                            <div class="radio-tile">
                                <div class="icon zenInFlg-no-icon">
                                    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                        style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                                        <style type="text/css">
                                            .st0 {
                                                fill: #4B4B4B;
                                            }
                                        </style>
                                        <g>
                                            <path class="st0"
                                                d="M499.29,82.175H214.519c-7.022,0-12.71,6.024-12.71,13.452v200.679c0,7.428,5.688,13.434,12.71,13.434H499.29
                                            c7.022,0,12.71-6.005,12.71-13.434V95.627C512,88.199,506.312,82.175,499.29,82.175z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0"
                                                d="M102.781,354.37c-19.705,0-35.692,16.878-35.692,37.723c0,20.836,15.987,37.732,35.692,37.732
                                            c19.697,0,35.665-16.896,35.665-37.732C138.446,371.248,122.478,354.37,102.781,354.37z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0" d="M501.41,325.55H197.455c-5.847,0-10.599-5.026-10.599-11.208v-167.32c0-6.192-4.734-11.208-10.599-11.208
                                            H79.711c-3.144,0-6.12,1.483-8.126,4.027L2.455,227.573C0.866,229.578,0,232.122,0,234.737v76.955v40.24
                                            c0,6.183,4.743,11.199,10.59,11.199h53.267c8.629-12.93,22.85-21.418,38.924-21.418c16.066,0,30.278,8.488,38.916,21.418h217.407
                                            c8.629-12.93,22.84-21.418,38.916-21.418c16.074,0,30.276,8.488,38.915,21.418h64.476c5.847,0,10.59-5.016,10.59-11.199v-15.174
                                            C512,330.567,507.257,325.55,501.41,325.55z M38.703,243.648v-7.754c0-2.65,0.856-5.22,2.438-7.296l44.126-57.463
                                            c2.085-2.702,5.193-4.265,8.479-4.265h43.234c6.041,0,10.934,5.166,10.934,11.543v65.236c0,6.377-4.894,11.544-10.934,11.544H49.62
                                            C43.596,255.192,38.703,250.025,38.703,243.648z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                            <path class="st0"
                                                d="M398.02,354.37c-19.705,0-35.674,16.878-35.674,37.723c0,20.836,15.97,37.732,35.674,37.732
                                            c19.696,0,35.673-16.896,35.673-37.732C433.693,371.248,417.715,354.37,398.02,354.37z"
                                                style="fill: rgb(255, 215, 0);"></path>
                                        </g>
                                    </svg>
                                </div>
                                <label for="zenInFlg-no" class="radio-tile-label">{{ 'COMMON.NO' | translate }}
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="pageForm.controls.zenInFlg.value">
                <div class="box-koumoku">
                    <div class="box-required">
                        <img class="required" src="/assets/question.png">
                        <div class="label-msg"><label>{{ 'JYUKIIDO.MENU.TITLE_SHIN_SETUMEI' | translate }}</label></div>
                    </div>

                    <div class="dairi-radio-tile-group">
                        <div class="input-container input-container-left">
                            <label for="shinInFlg-yes">
                                <input id="shinInFlg-yes" class="radio-button" type="radio" name="shinInFlg"
                                    [formControl]="pageForm.controls.shinInFlg" [value]="true" hidden />
                                <div class="radio-tile">
                                    <div class="icon shinInFlg-yes-icon">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 512 512" style="width: 32px; height: 32px;"
                                            xml:space="preserve">
                                            <style type="text/css">
                                                .st0 {
                                                    fill: #4B4B4B;
                                                }
                                            </style>
                                            <g>
                                                <path class="st0" d="M99.407,259.965C44.587,259.965,0,304.552,0,359.372c0,54.811,44.587,99.398,99.407,99.398
                                                    c54.811,0,99.406-44.587,99.406-99.398C198.813,304.552,154.218,259.965,99.407,259.965z M99.407,431.538
                                                    c-39.793,0-72.157-32.374-72.157-72.166c0-39.801,32.364-72.175,72.157-72.175c39.792,0,72.174,32.374,72.174,72.175
                                                    C171.581,399.165,139.199,431.538,99.407,431.538z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0" d="M412.602,259.965c-54.82,0-99.407,44.587-99.407,99.407c0,54.811,44.587,99.398,99.407,99.398
                                                    c54.811,0,99.398-44.587,99.398-99.398C512,304.552,467.413,259.965,412.602,259.965z M412.602,431.538
                                                    c-39.801,0-72.175-32.374-72.175-72.166c0-39.801,32.374-72.175,72.175-72.175c39.792,0,72.166,32.374,72.166,72.175
                                                    C484.768,399.165,452.394,431.538,412.602,431.538z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0"
                                                    d="M267.288,63.74l-142.7,24.504c-11.179,1.486-19.552,8.747-19.552,19.553l2.38,54.134l3.17,72.174
                                                    c0,7.731,6.272,13.994,14.002,13.994c7.74,0,14.002-6.263,14.002-13.994l3.301-77.083l68.605-18.537
                                                    c0,0-25.243,29.96-27.336,32.574c-10.459,13.072-14.246,37.039,2.614,49.678l73.009,53.021l4.144,63.532
                                                    c0,10.519,8.53,19.049,19.04,19.049c10.51,0,19.04-8.53,19.04-19.049c0-0.417,16.773-203.573,16.773-203.573
                                                    C322.637,91.363,298.254,58.867,267.288,63.74z M228.825,204.755c-3.648-2.71-2.728-6.341,0-9.972
                                                    c2.718-3.648,24.512-21.785,24.512-21.785l4.535,57.173L228.825,204.755z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0"
                                                    d="M53.986,123.85c19.492,0,35.302-15.809,35.302-35.301c0-19.51-15.81-35.319-35.302-35.319
                                                    c-19.501,0-35.31,15.809-35.31,35.319C18.676,108.041,34.484,123.85,53.986,123.85z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <label for="shinInFlg-yes" class="radio-tile-label">{{ 'COMMON.YES' | translate }}
                                    </label>
                                </div>
                            </label>
                        </div>
                        <div class="input-container  input-container-right">
                            <label for="shinInFlg-no">
                                <input id="shinInFlg-no" class="radio-button" type="radio" name="shinInFlg"
                                    [formControl]="pageForm.controls.shinInFlg" [value]="false" hidden />
                                <div class="radio-tile">
                                    <div class="icon shinInFlg-no-icon">
                                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;"
                                            xml:space="preserve">
                                            <style type="text/css">
                                                .st0 {
                                                    fill: #4B4B4B;
                                                }
                                            </style>
                                            <g>
                                                <path class="st0"
                                                    d="M499.29,82.175H214.519c-7.022,0-12.71,6.024-12.71,13.452v200.679c0,7.428,5.688,13.434,12.71,13.434H499.29
                                                c7.022,0,12.71-6.005,12.71-13.434V95.627C512,88.199,506.312,82.175,499.29,82.175z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0"
                                                    d="M102.781,354.37c-19.705,0-35.692,16.878-35.692,37.723c0,20.836,15.987,37.732,35.692,37.732
                                                c19.697,0,35.665-16.896,35.665-37.732C138.446,371.248,122.478,354.37,102.781,354.37z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0" d="M501.41,325.55H197.455c-5.847,0-10.599-5.026-10.599-11.208v-167.32c0-6.192-4.734-11.208-10.599-11.208
                                                H79.711c-3.144,0-6.12,1.483-8.126,4.027L2.455,227.573C0.866,229.578,0,232.122,0,234.737v76.955v40.24
                                                c0,6.183,4.743,11.199,10.59,11.199h53.267c8.629-12.93,22.85-21.418,38.924-21.418c16.066,0,30.278,8.488,38.916,21.418h217.407
                                                c8.629-12.93,22.84-21.418,38.916-21.418c16.074,0,30.276,8.488,38.915,21.418h64.476c5.847,0,10.59-5.016,10.59-11.199v-15.174
                                                C512,330.567,507.257,325.55,501.41,325.55z M38.703,243.648v-7.754c0-2.65,0.856-5.22,2.438-7.296l44.126-57.463
                                                c2.085-2.702,5.193-4.265,8.479-4.265h43.234c6.041,0,10.934,5.166,10.934,11.543v65.236c0,6.377-4.894,11.544-10.934,11.544H49.62
                                                C43.596,255.192,38.703,250.025,38.703,243.648z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                                <path class="st0"
                                                    d="M398.02,354.37c-19.705,0-35.674,16.878-35.674,37.723c0,20.836,15.97,37.732,35.674,37.732
                                                c19.696,0,35.673-16.896,35.673-37.732C433.693,371.248,417.715,354.37,398.02,354.37z"
                                                    style="fill: rgb(255, 215, 0);"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <label for="shinInFlg-no" class="radio-tile-label">{{ 'COMMON.NO' | translate }}
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>

        </form>
    </div>
</div>