<div class="box-sex">
    <ng-container
        *ngIf="controlName('cd').hasError('required') && (controlName('cd').touched || controlName('cd').dirty)">
        <div class="err-msg">
            <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'SEX.ERROR_REQUIRED' | translate }}
            </a>
        </div>
    </ng-container>
    <div class="radio-tile-group">
        <div class="input-container">
            <label for="{{getId('mail')}}">
                <input id="{{getId('mail')}}" class="radio-button" type="radio" [formControl]="controlName('cd')"
                    [value]="'1'" name={{rdName}} hidden />
                <div class="radio-tile" [class.input-invalid]="controlName('cd').invalid && (controlName('cd').touched || controlName('cd').dirty)">
                    <div class="icon male-icon">
                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                            <style type="text/css">
                                .st0{fill:#4B4B4B;}
                            </style>
                            <g>
                                <path class="st0" d="M256,85.549c23.636,0,42.779-19.158,42.779-42.77C298.778,19.142,279.636,0,256,0
                                    s-42.778,19.142-42.778,42.778C213.221,66.391,232.364,85.549,256,85.549z" style="fill: rgb(255, 215, 0);"></path>
                                <path class="st0" d="M312.196,97.623H199.804c-20.725,0-43.274,22.549-43.274,43.282v143.768c0,10.364,8.396,18.767,18.758,18.767
                                    c10.363,0,18.775-8.403,18.775-18.767V166.468h8.651v320.88c0,13.617,11.035,24.651,24.644,24.651
                                    c13.625,0,24.66-11.034,24.66-24.651V301.138h7.964v186.211c0,13.617,11.034,24.651,24.66,24.651
                                    c13.609,0,24.643-11.034,24.643-24.651v-320.88h8.668v118.205c0,10.364,8.396,18.767,18.758,18.767
                                    c10.379,0,18.759-8.403,18.759-18.767V140.905C355.47,120.172,332.922,97.623,312.196,97.623z" style="fill: rgb(255, 215, 0);"></path>
                            </g>
                        </svg>
                    </div>
                    <label for="{{getId('mail')}}" class="radio-tile-label">{{ 'SEX.MALE' | translate }}</label>
                </div>
            </label>
        </div>
        <div class="input-container">
            <label for="{{getId('femail')}}">
                <input id="{{getId('femail')}}" class="radio-button" type="radio" [formControl]="controlName('cd')"
                    [value]="'2'" name={{rdName}} hidden />
                <div class="radio-tile" [class.input-invalid]="controlName('cd').invalid && (controlName('cd').touched || controlName('cd').dirty)">
                    <div class="icon female-icon">
                        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                            <style type="text/css">
                                .st0{fill:#4B4B4B;}
                            </style>
                            <g>
                                <path class="st0" d="M256,85.376c23.579,0,42.693-19.114,42.693-42.7C298.693,19.105,279.579,0,256,0
                                    c-23.579,0-42.684,19.105-42.684,42.675C213.316,66.262,232.421,85.376,256,85.376z" style="fill: rgb(255, 215, 0);"></path>
                                <path class="st0" d="M370.667,257.78l-31.432-111.518c-8.654-29.213-28.043-48.577-49.858-48.577c-16.173,0-33.377,0-33.377,0
                                    s-17.196,0-33.359,0c-21.815,0-41.222,19.364-49.875,48.577L141.333,257.78c-2.305,9.393,3.424,18.985,12.808,21.282
                                    c9.402,2.323,18.822-3.174,21.136-12.568l31.33-111.174h9.247l-54.512,193.385h51.398v143.449c0,10.96,8.886,19.846,19.845,19.846
                                    c10.976,0,19.854-8.886,19.854-19.846V348.705h10.942v143.449c0,10.96,8.895,19.846,19.862,19.846s19.845-8.886,19.845-19.846
                                    V348.705h47.586l-54.52-193.385h9.238l31.33,111.174c2.322,9.394,11.733,14.89,21.127,12.568
                                    C367.243,276.765,372.972,267.174,370.667,257.78z" style="fill: rgb(255, 215, 0);"></path>
                            </g>
                        </svg>
                    </div>
                    <label for="{{getId('femail')}}" class="radio-tile-label">{{ 'SEX.FEMALE' | translate }}</label>
                </div>
            </label>
        </div>
    </div>
</div>