import { Component, OnInit, Input } from '@angular/core';
import { Iryokodomo } from '@iryokodomo/services/item';
import { EncryptService } from '@services/encrypt/encrypt.service';
import { ConfigService } from '@services/config/config.service';
import * as QRCode from '../../../../../node_modules/qrcode';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faPlusCircle, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-iryokodomo-qr',
  templateUrl: './iryokodomo-qr.component.html',
  styleUrls: ['./iryokodomo-qr.component.css']
})
export class IryokodomoQrComponent implements OnInit {
  @Input() item: Iryokodomo;
  mochimono: any;
  mochimono_file_header: string;
  title: string;
  image_url;
  original_qr_size = 200;
  font_size = 10;
  margin = 30;
  filename: string;
  filenameMochimono: string;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faDownload = faDownload;
  isWindows: boolean;
  qr_error: string;

  constructor(private encryptService: EncryptService, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.mochimono = ret.translations.IRYOKODOMO.QR.MOCHIMONO_LIST;
      this.title = ret.translations.IRYOKODOMO.TITLE_MAIN;
      this.mochimono_file_header = ret.translations.IRYOKODOMO.QR.MOCHIMONO_FILE_HEADER;
      this.qr_error = ret.translations.COMMON.QR_ERROR;
    });
  }

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    this.isWindows = userAgent.toLowerCase().includes('windows');
  }

  createQRText(): boolean {
    const qrStr =
      //帳票番号
      'fk101'
      //申請
      + "," + this.item.fk101_snsi_yubinno
      + "," + this.item.fk101_snsi_addr_ken
      + "," + this.item.fk101_snsi_addr_city
      + "," + this.item.fk101_snsi_addr_town
      + "," + this.item.fk101_snsi_addr_banti
      + "," + this.item.fk101_snsi_addr_ktgk
      + "," + this.item.fk101_snsi_home_tel_no
      + "," + this.item.fk101_snsi_cell_tel_no
      + "," + this.item.fk101_snsi_namekj

      + "," + this.item.fk101_snsi_namekn
      + "," + this.item.fk101_snsi_birthday
      + "," + this.item.fk101_snsi_zokugara
      + "," + this.item.fk101_snsi_name_tusho
      + "," + this.item.fk101_snsi_nenkin

      //保険
      + "," + this.item.fk101_hoken_hhs_kigo
      + "," + this.item.fk101_hoken_hhs_bango
      + "," + this.item.fk101_hoken_hhs_name
      + "," + this.item.fk101_hoken_kodomoskk_date
      + "," + this.item.fk101_hoken_hoken_name
      + "," + this.item.fk101_hoken_hoken_bango
      + "," + this.item.fk101_hoken_hoken_syurui

      //児童
      + "," + this.createQRJido();

    const qrText = this.encryptService.getQREncrypt(qrStr);
    if (qrText.length > 2000) {
      alert(this.qr_error);
      return false;
    }

    QRCode.toDataURL(qrText, { scale: 2, errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      var img = document.getElementById('qr-image');
      (img as any).src = url;
      (img as any).alt = "iryokodomo-" + getFormattedCurrentDateJST('yyyyMMddHHmmss');
    });

    this.filename = "iryokodomo-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".png";
    this.filenameMochimono = "iryokodomo-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".txt";
    return true;
  }

  downloadCanvas() {
    var img = document.getElementById('qr-image');
    let link = document.getElementById('hiddenLink');
    (link as any).href = (img as any).src;
    link.click();
  }

  createQRJido() {
    let textTaisyou = String(this.item.fk102s.length);
    for (let i = 0; i < this.item.fk102s.length; i++) {
      const taisyou = this.item.fk102s[i];
      textTaisyou = textTaisyou
        + "," + taisyou.fk102_jido_namekj
        + "," + taisyou.fk102_jido_namekn
        + "," + taisyou.fk102_jido_sex
        + "," + taisyou.fk102_jido_birthday
        + "," + taisyou.fk102_jido_seiho;
    }

    return textTaisyou;
  }

  downloadMochimono(): void {
    const lines: Array<string> = [];
    lines.push(this.mochimono_file_header);
    lines.push('');
    const mochimonoList: Array<{ title: string; meisai: Array<string>; }> = this.mochimono;
    mochimonoList.forEach(u => {
      lines.push(`・${u.title}`);
      u.meisai.forEach(v => lines.push(v));
      lines.push('');
    });

    const textdata = lines.join(this.isWindows ? '\r\n' : '\n');

    const link = document.getElementById('hiddenMochimonoLink') as HTMLAnchorElement;
    link.href = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(textdata)));
    link.click();
  }
}
