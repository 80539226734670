<div class="box-shimei">
    <ng-container *ngIf="buttonVisible">
        <div class="box-copy">
            <a class="btn-copy" (click)="onCopy()">{{buttonTitle}}</a>
        </div>
    </ng-container>

    <div class="box-shimei-title">
        <div *ngFor="let shimei_kbn of shimei_kbns|filterKbn:showKbns; let i=index">
            <input type="radio" name="{{getName()}}" id="{{getId(i)}}" [value]="shimei_kbn.value"
                [formControl]="controlName('shimei_kbn')" (change)="onCheckChange()">
            <label for="{{getId(i)}}" [class.label-first]="i==0"
                [class.label-last]="i==showKbns.length-1" [class]="fontCSS">{{shimei_kbn.label | translate}}</label>
        </div>
    </div>

    <ng-container *ngIf="controlName('shimei_kbn').value == 1">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'SHIMEI.TITLE_NAMEKJ' | translate }}</label></div>
        </div>

        <ng-container
            *ngIf="controlName('sei_kj').hasError('required') && (controlName('sei_kj').touched || controlName('sei_kj').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.SEIKJ_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="controlName('sei_kj').hasError('zenkaku') && (controlName('sei_kj').touched || controlName('sei_kj').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.SEIKJ_ERROR_TYPE' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="controlName('mei_kj').hasError('required') && (controlName('mei_kj').touched || controlName('mei_kj').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.MEIKJ_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="controlName('mei_kj').hasError('zenkaku') && (controlName('mei_kj').touched || controlName('mei_kj').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.MEIKJ_ERROR_TYPE' | translate }}
                </a>
            </div>
        </ng-container>

        <div class="box-seimei-kj">
            <div class="box-seimei-input">
                <div class="box-sei-input">
                    <div class="label-msg"><label>{{ 'SHIMEI.SEIKJ_TITLE' | translate }}</label></div>
                    <div class="box-control">
                        <input 
                            [class.input-invalid]="controlName('sei_kj').invalid && (controlName('sei_kj').touched || controlName('sei_kj').dirty)"
                            class="input-text" type="text" name="sei_kj" [formControl]="controlName('sei_kj')"
                            autocomplete="family-name" maxlength="10" >
                    </div>
                </div>
                <div class="box-mei-input">
                    <div class="label-msg"><label>{{ 'SHIMEI.MEIKJ_TITLE' | translate }}</label></div>
                    <div class="box-control">
                        <input 
                            [class.input-invalid]="controlName('mei_kj').invalid && (controlName('mei_kj').touched || controlName('mei_kj').dirty)"
                            class="input-text" type="text" name="mei_kj" [formControl]="controlName('mei_kj')"
                            autocomplete="given-name" maxlength="10" >
                    </div>
                </div>
            </div>
            <div class="box-setumei">
                <a>{{ 'SHIMEI.SEIMEIKJ_SETUMEI' | translate }}</a>
            </div>
        </div>
        
        <ng-container *ngIf="!hiddenKn">
            <div class="box-required">
                <img class="required" src="/assets/required.png">
                <div class="label-msg"><label>{{ 'SHIMEI.TITLE_NAMEKN' | translate }}</label></div>
            </div>
    
            <ng-container
                *ngIf="controlName('sei_kn').hasError('required') && (controlName('sei_kn').touched || controlName('sei_kn').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.SEIKN_ERROR_REQUIRED' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('sei_kn').hasError('katakana') && (controlName('sei_kn').touched || controlName('sei_kn').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.SEIKN_ERROR_TYPE' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('mei_kn').hasError('required') && (controlName('mei_kn').touched || controlName('mei_kn').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.MEIKN_ERROR_REQUIRED' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('mei_kn').hasError('katakana') && (controlName('mei_kn').touched || controlName('mei_kn').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.MEIKN_ERROR_TYPE' | translate }}
                    </a>
                </div>
            </ng-container>
            <div class="box-seimei-kn">
                <div class="box-seimei-input">
                    <div class="box-sei-input">
                        <div class="label-msg"><label>{{ 'SHIMEI.SEIKN_TITLE' | translate }}</label></div>
                        <div class="box-control">
                            <input
                                [class.input-invalid]="controlName('sei_kn').invalid && (controlName('sei_kn').touched || controlName('sei_kn').dirty)"
                                class="input-text" type="text" name="sei_kn" [formControl]="controlName('sei_kn')" maxlength="10" > 
                        </div>
                    </div>
                    <div class="box-mei-input">
                        <div class="label-msg"><label>{{ 'SHIMEI.MEIKN_TITLE' | translate }}</label></div>
                        <div class="box-control">
                            <input
                                [class.input-invalid]="controlName('mei_kn').invalid && (controlName('mei_kn').touched || controlName('mei_kn').dirty)"
                                class="input-text" type="text" name="mei_kn" [formControl]="controlName('mei_kn')" maxlength="10" >
                        </div>
                    </div>
                </div>
                <div class="box-setumei">
                    <a>{{ 'SHIMEI.SEIMEIKN_SETUMEI' | translate }}</a>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="controlName('shimei_kbn').value == 2">
        <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'SHIMEI.TITLE_SHIMEI_KJ_ALL' | translate }}</label></div>
        </div>

        <ng-container
            *ngIf="controlName('shimei_kj_all').hasError('required') && (controlName('shimei_kj_all').touched || controlName('shimei_kj_all').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.SHIMEI_KJ_ALL_ERROR_REQUIRED' | translate }}
                </a>
            </div>
        </ng-container>
        <ng-container
            *ngIf="controlName('shimei_kj_all').hasError('zenkaku') && (controlName('shimei_kj_all').touched || controlName('shimei_kj_all').dirty)">
            <div class="err-msg">
                <a>
                    <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                    {{ 'SHIMEI.SHIMEI_KJ_ALL_ERROR_TYPE' | translate }}
                </a>
            </div>
        </ng-container>

        <div class="box-seimei-kj">
            <div class="box-seimei-all-input">
                <div class="box-control">
                    <input
                        [class.input-invalid]="controlName('shimei_kj_all').invalid && (controlName('shimei_kj_all').touched || controlName('shimei_kj_all').dirty)"
                        class="input-text" type="text" name="shimei_kj_all" [formControl]="controlName('shimei_kj_all')"
                        autocomplete="family-name" maxlength="20" >
                </div>
            </div>
            <div class="box-setumei">
                <a>{{ 'SHIMEI.SHIMEI_KJ_ALL_SETUMEI' | translate }}</a>
            </div>
        </div>

        <ng-container *ngIf="!hiddenKn">

            <div class="label-msg"><label>{{ 'SHIMEI.TITLE_SHIMEI_KN_ALL' | translate }}</label></div>
            <ng-container
                *ngIf="controlName('shimei_kn_all').hasError('required') && (controlName('shimei_kn_all').touched || controlName('shimei_kn_all').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.SHIMEI_KN_ALL_ERROR_REQUIRED' | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-container
                *ngIf="controlName('shimei_kn_all').hasError('katakana') && (controlName('shimei_kn_all').touched || controlName('shimei_kn_all').dirty)">
                <div class="err-msg">
                    <a>
                        <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                        {{ 'SHIMEI.SHIMEI_KN_ALL_ERROR_TYPE' | translate }}
                    </a>
                </div>
            </ng-container>
    
            <div class="box-seimei-kn">
                <div class="box-seimei-all-input">
                    <div class="box-control">
                        <input
                            [class.input-invalid]="controlName('shimei_kn_all').invalid && (controlName('shimei_kn_all').touched || controlName('shimei_kn_all').dirty)"
                            class="input-text" type="text" name="shimei_kn_all"
                            [formControl]="controlName('shimei_kn_all')" maxlength="20" >
                    </div>
                </div>
                <div class="box-setumei">
                    <a>{{ 'SHIMEI.SHIMEI_KN_ALL_SETUMEI' | translate }}</a>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>