<form [formGroup]="pageForm">
  <div formArrayName="setaiins">
    <div class="box-block" *ngFor="let setaiin of setaiins.controls; let i = index">

      <div class="box-setaiin-title">
        <div class="box-koumoku">
          <h4>{{ 'JIDOTEATE.TAISYOUJIDOU.TITLE_TAISYOUJIDOU' | translate }}（{{ i + 1 }}/{{ setaiins.length }}）
          </h4>
        </div>
        <ng-container *ngIf="i!=0">
          <a class="btn-delete-setaiin" (click)="removeSetaiin(i)">
            <fa-icon [icon]="faUserSlash"></fa-icon>
          </a>
        </ng-container>
      </div>

      <div class="box-block-inner">

        <div class="box-koumoku">
          <div class="box-help">
            <a>
              {{ 'JIDOTEATE.TAISYOUJIDOU.SETUMEI' | translate }}
            </a>
          </div>
        </div>

        <div [formGroupName]="i">

          <form [formGroup]="setaiin">
            <div class="box-koumoku">
              <div class="box-required">
                <img class="required" src="/assets/required.png">
                <label>{{ 'COMMON.JIDO' | translate }}</label>
              </div>

              <app-shimei title="{{ 'COMMON.SETAIIN' | translate }}" [buttonVisible]="true"
                buttonTitle="{{ 'JIDOTEATE.SEI_COPY' | translate }}" [copyShimei]="uji" fgName={{fgNameShimei}}
                ckName="{{ckNameShimei + i}}" defaltKbn="1">
              </app-shimei>
            </div>
          </form>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.BIRTHDAY' | translate }}</label></div>
            </div>
            <form [formGroup]="setaiin">
              <app-datepicker fgName={{fgNameDatepicker}}></app-datepicker>
            </form>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'COMMON.ZOKUGARA' | translate }}</label></div>
            </div>
            <ng-container
              *ngIf="pageForm.controls.setaiins.controls[i].controls.zokugara.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.zokugara.touched || pageForm.controls.setaiins.controls[i].controls.zokugara.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'COMMON.ZOKUGARA_ERROR_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>
            <div class="box-control">
              <div class="select box-col-md"
                [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.zokugara.invalid && (pageForm.controls.setaiins.controls[i].controls.zokugara.touched || pageForm.controls.setaiins.controls[i].controls.zokugara.dirty)">
                <select formControlName="zokugara" (change)="onZokugaraChange(i)">
                  <option disabled="disabled" [value]="null">{{ 'COMMON.SELECT' | translate }}</option>
                  <option *ngFor="let zokugara_val of zokugara" [ngValue]="zokugara_val.value">{{zokugara_val.label | translate}}</option>
                </select>
              </div>
            </div>
          </div>

          <ng-container *ngIf="pageForm.controls.setaiins.controls[i].controls.zokugara.value=='99'">
            <div class="box-koumoku">
              <div class="label-msg"><label>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA' | translate }}</label></div>
              <div class="box-control">
                  <input class="input-text" type="text" formControlName="zokugara_sonota" maxlength="10">
              </div>
              <div class="box-setumei">
                  <a>{{ 'JYUKIIDO.IDOSYA.ZOKUGARA_SONOTA_SETUMEI' | translate }}</a>
              </div>
            </div>
          </ng-container>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'JIDOTEATE.TAISYOUJIDOU.BEKKYO' | translate }}</label></div>
            </div>
            <ng-container *ngIf="pageForm.controls.setaiins.controls[i].controls.doukyo.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.doukyo.touched || pageForm.controls.setaiins.controls[i].controls.doukyo.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'JIDOTEATE.TAISYOUJIDOU.BEKKYO_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <div class="select box-col-md"
              [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.doukyo.invalid && (pageForm.controls.setaiins.controls[i].controls.doukyo.touched || pageForm.controls.setaiins.controls[i].controls.doukyo.dirty)">
                <select formControlName="doukyo">
                  <option disabled="disabled" [value]="null" selected="true">{{ 'COMMON.SELECT' | translate }}</option>
                  <option *ngFor="let bekkyo_val of bekkyo" [ngValue]="bekkyo_val">{{bekkyo_val}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'JIDOTEATE.TAISYOUJIDOU.JOB' | translate }}</label></div>
            </div>
            <ng-container
              *ngIf="pageForm.controls.setaiins.controls[i].controls.job.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.job.touched || pageForm.controls.setaiins.controls[i].controls.job.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'JIDOTEATE.TAISYOUJIDOU.JOB_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <input
                [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.job.invalid && (pageForm.controls.setaiins.controls[i].controls.job.touched || pageForm.controls.setaiins.controls[i].controls.job.dirty)"
                class="input-text " type="text" formControlName="job" maxlength="10">
            </div>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label>{{ 'JIDOTEATE.TAISYOUJIDOU.KANGO' | translate }}</label></div>
            </div>
            <ng-container
              *ngIf="pageForm.controls.setaiins.controls[i].controls.kango.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.kango.touched || pageForm.controls.setaiins.controls[i].controls.kango.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'JIDOTEATE.TAISYOUJIDOU.KANGO_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <div class="select box-col-md"
                [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.kango.invalid && (pageForm.controls.setaiins.controls[i].controls.kango.touched || pageForm.controls.setaiins.controls[i].controls.kango.dirty)">
                <select formControlName="kango">
                  <option disabled="disabled" [value]="null" selected="true">{{ 'COMMON.SELECT' | translate }}
                  </option>
                  <option *ngFor="let umu_val of umu" [ngValue]="umu_val">{{umu_val}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="box-koumoku">
            <div class="box-required">
              <img class="required" src="/assets/required.png">
              <div class="label-msg"><label> {{ 'JIDOTEATE.TAISYOUJIDOU.SEIKEI' | translate }}</label></div>
            </div>
            <ng-container
              *ngIf="pageForm.controls.setaiins.controls[i].controls.seikei.hasError('required') && (pageForm.controls.setaiins.controls[i].controls.seikei.touched || pageForm.controls.setaiins.controls[i].controls.seikei.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'JIDOTEATE.TAISYOUJIDOU.SEIKEI_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

            <div class="box-control">
              <div class="select box-col-md"
                [class.input-invalid]="pageForm.controls.setaiins.controls[i].controls.seikei.invalid && (pageForm.controls.setaiins.controls[i].controls.seikei.touched || pageForm.controls.setaiins.controls[i].controls.seikei.dirty)">
                <select formControlName="seikei">
                  <option disabled="disabled" [value]="null" selected="true">{{ 'COMMON.SELECT' | translate }}
                  </option>
                  <option *ngFor="let seikei_val of seikei" [ngValue]="seikei_val">{{seikei_val}}</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="pageForm.controls.setaiins.value.length < 5">
  <div class="box-add-setaiin">
    <a class="btn-add-setaiin" (click)="addSetaiin()">{{ 'JIDOTEATE.TAISYOUJIDOU.ADD_MEMBER' | translate }}</a>
  </div>
</ng-container>

<!-- Debug用の表示 
<h3>Debug</h3>
<ng-container *ngIf="form.value">
  <pre>{{ form.value | json }}</pre>
</ng-container>
-->