import { Component, OnInit, Input } from '@angular/core';
import { Jyukiido, Transfer } from '@jyukiido/services/item';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-jyukiido-kakunin',
  templateUrl: './jyukiido-kakunin.component.html',
  styleUrls: ['./jyukiido-kakunin.component.css']
})
export class JyukiidoKakuninComponent implements OnInit {
  @Input() item: Jyukiido;
  zokugara: any;

  cyouhyoList = [];
  cyouhyoSyuruiList01;
  cyouhyoSyuruiList02;
  cyouhyoSyuruiList03;
  cyouhyoNameList01;
  cyouhyoNameList02;
  cyouhyoNameList03;

  constructor(public translate: TranslateService) { 
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.zokugara = Object(ret.translations.MASTER.ZOKUGARA);
      this.cyouhyoSyuruiList01 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_01);
      this.cyouhyoSyuruiList02 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_02);
      this.cyouhyoSyuruiList03 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_03);
      this.cyouhyoNameList01 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_01);
      this.cyouhyoNameList02 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_02);
      this.cyouhyoNameList03 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_03);
      this.getCyouhyoList(this.item.sj201_snsi_syurui);
    });
  }

  ngOnInit() {
  }

  getMaxTransfer() {
    return this.item.sj202s.length;
  }

  getZokugaraLabel(transfer: Transfer){
    return transfer.sj202_zokugara == "" ? ""
    : transfer.sj202_zokugara_sonota != "" ?  transfer.sj202_zokugara_sonota 
    : this.zokugara[transfer.sj202_zokugara];
  }


  getCyouhyoList(snsi_syurui){
    const cyouhyoCd = this.item.sj201_cyouhyo_cd.split(",");
    const cyouhyoSyuruiList = snsi_syurui == '01' ? this.cyouhyoSyuruiList01 :snsi_syurui == '02' ? this.cyouhyoSyuruiList02 : snsi_syurui == '03' ? this.cyouhyoSyuruiList03 : [];
    const cyouhyoNameList = snsi_syurui == '01' ? this.cyouhyoNameList01 :snsi_syurui == '02' ? this.cyouhyoNameList02 : snsi_syurui == '03' ? this.cyouhyoNameList03 : [];
    const cyouhyoList = [];
    Object.keys(cyouhyoSyuruiList).forEach(syurui =>{
      const cyouhyoSyurui = {
        label: cyouhyoSyuruiList[syurui],
        cyouhyo: []
      }
      Object.keys(cyouhyoNameList[syurui]).forEach(name => {
        if(cyouhyoCd.includes(name)) cyouhyoSyurui.cyouhyo.push(cyouhyoNameList[syurui][name]);
      });
      if(cyouhyoSyurui.cyouhyo.length > 0) cyouhyoList.push(cyouhyoSyurui);
    });
    this.cyouhyoList = cyouhyoList;
  }

}
