import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { Jyukiido, Shinsei } from '@jyukiido/services/item';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { Shimei, ShimeiComponent } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { toJstTime } from '@utility/date-functions';

@Component({
  selector: 'app-jyukiido-shinsei',
  templateUrl: './jyukiido-shinsei.component.html',
  styleUrls: ['./jyukiido-shinsei.component.css', '../../jyukiido.component.css']
})
export class JyukiidoShinseiComponent implements OnInit, AfterViewInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @Input() item: Jyukiido;
  @Input() config: any;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  snsiSyurui = "";
  cyouhyoList = [];
  fgNameShimeiTouroku = 'shimeiTouroku';
  ckNameShimeiTouroku = 'shinsei-shimeiTouroku';
  fgNameDatepickerTouroku = 'datepickerTouroku';
  fgNameShimeiDairi = 'shimeiDairi';
  ckNameShimeiDairi = 'shinsei-shimeiDairi';
  fgNameZipcodeDairi = 'zipcodeDairi';
  ckNameZipcodeDairi = 'shinsei-zipcodeDairi';
  faExclamationTriangle = faExclamationTriangle;
  rdName = 'honnin_dairi';


  idoMM;
  idoDD;
  after14MM;
  after14DD;

  idoKako1
  idoKako2
  isLongAgo;

  cyouhyoSyuruiList01;
  cyouhyoSyuruiList02;
  cyouhyoSyuruiList03;
  cyouhyoNameList01;
  cyouhyoNameList02;
  cyouhyoNameList03;

  constructor(private fb: FormBuilder, public translate: TranslateService) {
    this.pageForm = fb.group({
      honnin: [null, [Validators.required]],
      dairiFlg: [null, [Validators.required]],
      //shinsei-shimeiDairi（shimei コンポーネントで追加）
      //zipcodeDairi（zipcode コンポーネントで追加）
      dairi_telno: [null, [CustomValidator.numeric]],
      dairi_knki: [null, [Validators.required]],
      ininjyouFlg: [null, [Validators.required]],
      //datepickerTouroku（datepicker コンポーネントで追加）
      selectedCyouhyoArray: fb.group({})
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.cyouhyoSyuruiList01 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_01);
      this.cyouhyoSyuruiList02 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_02);
      this.cyouhyoSyuruiList03 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_SYURUI_03);
      this.cyouhyoNameList01 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_01);
      this.cyouhyoNameList02 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_02);
      this.cyouhyoNameList03 = Object(ret.translations.JYUKIIDO.SHINSEI.CYOUHYO_NAME_03);
      this.idoKako1 = ret.translations.JYUKIIDO.SHINSEI.IDO_KAKO1;
      this.idoKako2 = ret.translations.JYUKIIDO.SHINSEI.IDO_KAKO2;
      this.setCyouhyoList();
    });    
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });

    //初期表示
    const today = toJstTime(new Date());
    this.valueChange(today);
  }

  ngAfterViewInit() {
    //はじめは代理はチェックしない
    this.pageForm.controls.shimeiDairi.disable();
    this.pageForm.controls.zipcodeDairi.disable();
    this.pageForm.controls.ininjyouFlg.disable();
  }

  getShinsei(): Shinsei {

    var shimeiData
    if (this.pageForm.controls.dairiFlg.value) {
      //代理の場合
      shimeiData = new Shimei(this.pageForm.controls.shimeiDairi.get('sei_kj').value,
        this.pageForm.controls.shimeiDairi.get('mei_kj').value,
        this.pageForm.controls.shimeiDairi.get('sei_kn').value,
        this.pageForm.controls.shimeiDairi.get('mei_kn').value);
    }
    else {
      //本人の場合
      shimeiData = new Shimei(this.pageForm.controls.honnin.value.sj202_name_kj,
        null,
        this.pageForm.controls.honnin.value.sj202_name_kn,
        null);
    }

    const address = new Address(this.pageForm.controls.zipcodeDairi.get('address').value.ken, this.pageForm.controls.zipcodeDairi.get('address').value.city, this.pageForm.controls.zipcodeDairi.get('address').value.town);
    const zipcodeAddress = new ZipcodeAddress(this.pageForm.controls.zipcodeDairi.get('zipcode').value, address, this.pageForm.controls.zipcodeDairi.get('banti').value, this.pageForm.controls.zipcodeDairi.get('ktgk').value);
    const datepicker = String(this.pageForm.controls.datepickerTouroku.get('year').value) + '-' + String(('0' + (this.pageForm.controls.datepickerTouroku.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.datepickerTouroku.get('day').value)).slice(-2));

    const cyouhyouCd = [];
    this.cyouhyoList.forEach( (cyouhyouSyurui, index) =>{
      cyouhyouSyurui.cyouhyo.forEach( cyouhyouName => {
        const selectedCyouhyo = this.pageForm.controls['selectedCyouhyo'] as FormGroup;
        const isSelect = selectedCyouhyo.controls[cyouhyouName.value].value;
        if(isSelect){
          cyouhyouCd.push(cyouhyouName.value);
        }
      })
    });

    const shinsei = new Shinsei(shimeiData,
      this.pageForm.controls.dairi_telno.value,
      this.pageForm.controls.dairiFlg.value,
      zipcodeAddress,
      this.pageForm.controls.dairi_knki.value,
      datepicker,
      cyouhyouCd);

    return shinsei;
  }

  onDairi() {
    if (this.pageForm.controls.dairiFlg.value) {
      //代理の場合
      this.pageForm.controls.shimeiDairi.enable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setValidate();
      this.pageForm.controls.zipcodeDairi.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setValidate();
      this.pageForm.controls.dairi_knki.enable();
      this.pageForm.controls.ininjyouFlg.enable();

      this.pageForm.controls.honnin.disable();
    }
    else {
      //代理で無い場合
      this.pageForm.controls.shimeiDairi.disable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setDisable();
      this.pageForm.controls.zipcodeDairi.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setDisable();
      this.pageForm.controls.dairi_knki.disable();
      this.pageForm.controls.ininjyouFlg.disable();

      this.pageForm.controls.honnin.enable();
    }

    //値をクリア
    this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).clear();
    this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).clear();
    this.pageForm.controls.dairi_telno.setValue('');
    this.pageForm.controls.dairi_knki.setValue('');
    this.pageForm.controls.ininjyouFlg.setValue(null);

    this.pageForm.controls.honnin.setValue(null);
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix;
  }

  clearSetaiin() {
    this.pageForm.controls.honnin.setValue(null);
  }

  valueChange(value: Date) {
    const today = toJstTime(new Date());

    this.after14MM = '';
    this.after14DD = '';
    this.idoMM = '';
    this.idoDD = '';
    this.isLongAgo = null;

    if (this.lowerThanDateOnly(today, value)) {
      //引越し日が未来の場合
      const after14 = new Date(value.getFullYear(), value.getMonth(), value.getDate() + 14);
      this.after14MM = after14.getMonth() + 1;
      this.after14DD = after14.getDate();
      this.idoMM = value.getMonth() + 1;
      this.idoDD = value.getDate();
    }
    else {
      //過去の場合
      const longAgoDate = this.config["IDO_KAKO2_DATE"];
      const beforeLong = new Date(today.getFullYear()-longAgoDate["YEAR"], today.getMonth()-longAgoDate["MONTH"], today.getDate()-longAgoDate["DAY"]);
      if (this.lowerThanDateOnly(value, beforeLong)) {
        this.isLongAgo = true;
      }
      else {
        this.isLongAgo = false;
      }
    }

  }

  lowerThanDateOnly(date1, date2) {
    var year1 = date1.getFullYear();
    var month1 = date1.getMonth() + 1;
    var day1 = date1.getDate();

    var year2 = date2.getFullYear();
    var month2 = date2.getMonth() + 1;
    var day2 = date2.getDate();

    if (year1 == year2) {
      if (month1 == month2) {
        return day1 < day2;
      }
      else {
        return month1 < month2;
      }
    } else {
      return year1 < year2;
    }
  }

  getCyouhyo(snsi_syurui){
    this.snsiSyurui = snsi_syurui;
    delete this.pageForm.controls['selectedCyouhyo'];
    const selectedCyouhyoForm = this.fb.group({});
    const cyouhyoSyuruiList = snsi_syurui == '01' ? this.cyouhyoSyuruiList01 :snsi_syurui == '02' ? this.cyouhyoSyuruiList02 : snsi_syurui == '03' ? this.cyouhyoSyuruiList03 : [];
    const cyouhyoNameList = snsi_syurui == '01' ? this.cyouhyoNameList01 :snsi_syurui == '02' ? this.cyouhyoNameList02 : snsi_syurui == '03' ? this.cyouhyoNameList03 : [];
    const cyouhyoList = [];
    Object.keys(cyouhyoSyuruiList).forEach(syurui =>{
      const cyouhyoSyurui = {
        value: syurui,
        label: cyouhyoSyuruiList[syurui],
        cyouhyo: []
      }
      Object.keys(cyouhyoNameList[syurui]).forEach(name => {
        const cyouhyoName = {
          value: name,
          label: cyouhyoNameList[syurui][name]
        }
        cyouhyoSyurui.cyouhyo.push(cyouhyoName);
        selectedCyouhyoForm.addControl(name, new FormControl(false));
      });
      cyouhyoList.push(cyouhyoSyurui);
    });
    this.cyouhyoList = cyouhyoList;
    this.pageForm.addControl('selectedCyouhyo', selectedCyouhyoForm);
  }

  setCyouhyoList() {
    const cyouhyoSyuruiList = this.snsiSyurui == '01' ? this.cyouhyoSyuruiList01 :this.snsiSyurui == '02' ? this.cyouhyoSyuruiList02 : this.snsiSyurui == '03' ? this.cyouhyoSyuruiList03 : [];
    const cyouhyoNameList = this.snsiSyurui == '01' ? this.cyouhyoNameList01 :this.snsiSyurui == '02' ? this.cyouhyoNameList02 : this.snsiSyurui == '03' ? this.cyouhyoNameList03 : [];
    const cyouhyoList = [];
    Object.keys(cyouhyoSyuruiList).forEach(syurui =>{
      const cyouhyoSyurui = {
        value: syurui,
        label: cyouhyoSyuruiList[syurui],
        cyouhyo: []
      }
      Object.keys(cyouhyoNameList[syurui]).forEach(name => {
        const cyouhyoName = {
          value: name,
          label: cyouhyoNameList[syurui][name]
        }
        cyouhyoSyurui.cyouhyo.push(cyouhyoName);
      });
      cyouhyoList.push(cyouhyoSyurui);
    });
    this.cyouhyoList = cyouhyoList;
  }
}
