import { Component, OnInit, Input } from '@angular/core';
import { Inkantouroku} from '@inkantouroku/services/item';

@Component({
  selector: 'app-inkantouroku-kakunin',
  templateUrl: './inkantouroku-kakunin.component.html',
  styleUrls: ['./inkantouroku-kakunin.component.css']
})
export class InkantourokuKakuninComponent implements OnInit {
  @Input() item: Inkantouroku;

  constructor() { }

  ngOnInit(): void {
  }
}
