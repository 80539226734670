import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@services/config/config.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankCodeApiService {

  url: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.url = this.config.get('URL_SPUTNIK');
   }

  getBanks(): Promise<Array<Bank>> {
    const params = { method: "bank" };

    return lastValueFrom(this.http.get<Array<Bank>>(this.url, { params })).then(result =>{
      const res: any = result;
      return res.banks;
    });
  }

  getShitens(bankcode: number | string): Promise<Array<Bank>> {
    const params = { method: "bankSiten", bankCode: String(bankcode) };

    return lastValueFrom(this.http.get<Array<Bank>>(this.url, { params })).then(result =>{
      const res: any = result;
      return res.banks;
    });
  }
}

export type Bank = {
  code: string;
  kana: string;
  name: string;
  roma: string;
  hira: string;
  created_at: string; // ISO formatted date
  updated_at: string; // ISO formatted date
  url: string;
  branches_url: string;
};

export type BankWithoutUrl = {
  code: string;
  kana: string;
  name: string;
  roma: string;
  hira: string;
  created_at: string; // ISO formatted date
  updated_at: string; // ISO formatted date
};

export type Shiten = {
  code: string;
  kana: string;
  name: string;
  roma: string;
  hira: string;
  created_at: string; // ISO formatted date
  updated_at: string; // ISO formatted date
  url: string;
};

export type ShitenWithoutUrl = {
  code: string;
  kana: string;
  name: string;
  roma: string;
  hira: string;
  created_at: string; // ISO formatted date
  updated_at: string; // ISO formatted date
  url: string;
};
