import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { Iryokodomo, TaisyouJido, Jido } from '@iryokodomo/services/item';
import { Shimei } from '@components/shimei/shimei.component';
import { ShimeiComponent } from '@components/shimei/shimei.component';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { SexComponent } from '@components/sex/sex.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-iryokodomo-taisyoujidou',
  templateUrl: './iryokodomo-taisyoujidou.component.html',
  styleUrls: ['./iryokodomo-taisyoujidou.component.css']
})
export class IryokodomoTaisyoujidouComponent implements OnInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(DatepickerComponent) datepickerComponents: QueryList<DatepickerComponent>;
  @ViewChildren(SexComponent) sexComponents: QueryList<SexComponent>;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  // shimei: Shimei;
  uji: Shimei;
  fgNameShimei = 'shimei';
  ckNameShimei = 'taisyoujidou-shimei';
  fgNameDatepicker = 'datepicker';
  fgNameSex = 'sex';
  rdNameSex = 'taisyoujidou-sex';
  faUserSlash = faUserSlash;

  constructor(private fb: FormBuilder, public translate: TranslateService, private cd: ChangeDetectorRef ) {
  }

  ngOnInit(): void {
    this.pageForm = this.createForm();
    //一人は入力させる
    this.addSetaiin();

    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      setaiins: this.fb.array([])
    });
  }

  get setaiinForm(): FormGroup {
    return this.fb.group({
      //shimei（shimei コンポーネントで追加）
      //datepicker（datepicker コンポーネントで追加）
      //sex（sex コンポーネントで追加）
    });
  }

  get setaiins(): FormArray {
    return this.pageForm.get('setaiins') as FormArray;
  }

  addSetaiin() {
    this.setaiins.push(this.setaiinForm);
    this.cd.detectChanges();
  }

  removeSetaiin(index: number) {
    this.setaiins.removeAt(index);
  }

  getJidos(): TaisyouJido[] {
    const taisyouJidos = [];

    this.pageForm.get('setaiins')['controls'].forEach((child, i) => {
      const shimeiData = new Shimei(child.controls.shimei.get('sei_kj').value, child.controls.shimei.get('mei_kj').value, child.controls.shimei.get('sei_kn').value, child.controls.shimei.get('mei_kn').value);
      const umare_ymd = String(child.controls.datepicker.get('year').value) + '-' + String(('0' + (child.controls.datepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (child.controls.datepicker.get('day').value)).slice(-2));
      const sex = String(child.get(this.fgNameSex).get('cd').value);
      const taisyouJido = new TaisyouJido(i + 1, shimeiData, String(umare_ymd), String(sex), null);
      taisyouJidos.push(taisyouJido);
    });

    return taisyouJidos;
  }

  setCopyShimei(name_kj, name_kn) {
    const shimei_kj = name_kj.split("　");
    const shimei_kn = name_kn.split("　");
    // this.shimei = new Shimei(shimei_kj[0], shimei_kj[1], shimei_kn[0], shimei_kn[1]);
    this.uji = new Shimei(shimei_kj[0], null, shimei_kn[0], null);
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

}
