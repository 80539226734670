<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'INKANTOUROKU.SHINSEI.TITLE_SHINSEI' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'INKANTOUROKU.SHINSEI.SETUMEI_SHINSEI' | translate }}
        </a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'INKANTOUROKU.SHINSEI.TOUROKUSYA' | translate }}</label>
      </div>

      <form [formGroup]="pageForm">
        <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiTouroku}} ckName={{ckNameShimeiTouroku}}
          defaltKbn="1"></app-shimei>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.ADDR' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-zipcode fgName={{fgNameZipcodeTouroku}} ckName={{ckNameZipcodeTouroku}}
          defaltKbn="1"></app-zipcode>
      </form>
    </div>

    <div class="box-koumoku">
      <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
      <ng-container
        *ngIf="pageForm.controls.snsi_telno.hasError('numeric') && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)">
        <div class="err-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>{{ 'COMMON.TEL_ERROR_TYPE' | translate }}
          </a>
        </div>
      </ng-container>
      <div class="box-control">
        <input
          [class.input-invalid]="pageForm.controls.snsi_telno.invalid && (pageForm.controls.snsi_telno.touched || pageForm.controls.snsi_telno.dirty)"
          class="input-text input-tel" type="text" maxlength="11" name="snsi_telno"
          [formControl]="pageForm.controls.snsi_telno" autocomplete="tel">
      </div>
      <div class="box-setumei">
        <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
      </div>
    </div>

    <div class="box-koumoku">
      <div class="box-required">
        <img class="required" src="/assets/required.png">
        <label>{{ 'COMMON.BIRTHDAY' | translate }}</label>
      </div>
      <form [formGroup]="pageForm">
        <app-datepicker fgName={{fgNameDatepickerTouroku}}></app-datepicker>
      </form>
    </div>

    <div class="box-koumoku">
      <ng-container *ngIf="checkBirthday()">
        <div class="cau-msg">
          <a>
            <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
            {{ 'INKANTOUROKU.SHINSEI.BIRTHDAY_MESSAGE' | translate }}
          </a>
        </div>
      </ng-container>
    </div>

  </div>
</div>

<div class="box-block">
  <div class="box-koumoku">
    <h4>{{ 'COMMON.TITLE_DAIRI' | translate }}</h4>
  </div>
  <div class="box-block-inner">

    <div class="box-koumoku">
      <div class="box-help">
        <a>
          {{ 'INKANTOUROKU.SHINSEI.SETUMEI_DAIRI' | translate }}
        </a>
      </div>
    </div>

    <div class="box-required">
      <img class="required" src="/assets/required.png">
      <label>{{ 'COMMON.RAICYOUSYA' | translate }}</label>
    </div>

    <ng-container
      *ngIf="pageForm.controls.dairiFlg.hasError('required') && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
      <div class="err-msg">
        <a>
          <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
          {{ 'COMMON.RAICYOUSYA_ERROR_REQUIRED' | translate }}
        </a>
      </div>
    </ng-container>

    <div class="box-koumoku">
      <div class="dairi-radio-tile-group">
        <div class="input-container input-container-left">
          <label for="{{getId('honnin')}}">
            <input id="{{getId('honnin')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiFlg"
              (change)="onDairi()" [value]="false" name={{rdName}} hidden />
            <div class="radio-tile" [class.input-invalid]="pageForm.controls.dairiFlg.invalid && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
              <div class="icon honnin-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                  viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill: #4B4B4B;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M449.688,422.344c-3.375-9.109-8.094-16.906-13.703-23.5c-4.906-5.766-10.484-10.625-16.391-14.781
                      c-10.344-7.297-21.719-12.484-32.875-16.922c-8.359-3.328-16.625-6.203-24.266-9.109c-5.734-2.172-11.109-4.344-15.891-6.688
                      c-3.594-1.734-6.844-3.563-9.688-5.5c-2.125-1.469-4.016-2.969-5.656-4.547c-2.469-2.391-4.406-4.891-5.813-7.703
                      c-1.438-2.828-2.406-5.984-2.813-9.844c-0.313-3.063-0.469-6.188-0.469-9.281c0-9.125,1.266-18.094,2.781-25.484
                      c4.594-5.188,8.797-11.359,12.766-18.75c3.984-7.438,7.766-16.109,11.547-26.391c2.422-1.094,4.844-2.422,7.25-4.031
                      c5.422-3.641,10.578-8.734,14.875-15.516c4.281-6.781,7.734-15.203,10.094-25.609c0.641-2.875,0.953-5.766,0.953-8.578
                      c0-5.906-1.391-11.531-3.734-16.563c-1.625-3.484-3.688-6.672-6.094-9.578c1.234-4.531,2.719-10.641,4.047-17.781
                      c1.828-9.938,3.359-21.844,3.359-34.375c0-8.078-0.641-16.438-2.281-24.672c-1.234-6.172-3.031-12.297-5.594-18.203
                      c-3.844-8.813-9.391-17.109-17.156-23.969c-7.156-6.359-16.156-11.422-27-14.672c-3.828-4.578-7.688-8.516-11.641-11.828
                      c-3.391-2.844-6.891-5.25-10.469-7.219c-5.359-2.969-10.922-4.969-16.625-6.156c-5.703-1.203-11.5-1.625-17.516-1.625
                      c-8.313,0-17.047,0.813-26.703,1.781c-3.641,0.359-6.703,0.813-9.359,1.281c-3.953,0.703-6.922,1.438-9.313,1.906
                      c-1.188,0.25-2.219,0.438-3.25,0.563c-1.016,0.109-2.016,0.188-3.156,0.188c-1.5,0-3.281-0.125-5.563-0.469
                      c-7.031-1-13.453-2.609-18.5-4.156c-2.531-0.766-4.688-1.516-6.406-2.156c-0.844-0.328-1.594-0.625-2.188-0.891
                      c-0.594-0.25-1.063-0.453-1.313-0.578l0,0c-0.766-0.391-1.5-0.625-2.188-0.766C183.094,0.031,182.5,0,182,0
                      c-1.078,0-1.828,0.172-2.438,0.313c-0.609,0.156-1.078,0.328-1.5,0.516c-0.797,0.328-1.438,0.672-2.047,1.047
                      c-1.141,0.656-2.203,1.438-3.359,2.313c-2.016,1.563-4.219,3.5-6.563,5.75c-3.5,3.391-7.25,7.469-10.672,11.938
                      c-1.703,2.25-3.313,4.563-4.75,6.969c-1.453,2.406-2.719,4.875-3.734,7.469c-4.922,12.844-8.188,24.656-10.219,35.578
                      s-2.813,20.922-2.813,30.078c0,15.016,2.109,27.75,4.125,38.5c0,0.516,0.031,1,0.047,1.5c0.078,1.016,0.172,2.063,0.266,3.141
                      c0.172,1.625,0.375,3.297,0.531,4.688c0.063,0.688,0.125,1.313,0.156,1.797c0.031,0.25,0.031,0.438,0.031,0.594l0.031,0.156v0.063
                      l0,0v0.922l1.969,8.781c-2.844,3.109-5.313,6.578-7.219,10.391c-2.656,5.313-4.234,11.297-4.234,17.625
                      c0,2.813,0.313,5.688,0.953,8.563c1.578,6.938,3.625,13,6.109,18.281c3.688,7.922,8.359,14.094,13.563,18.719
                      c4.016,3.594,8.313,6.219,12.563,8.156c3.766,10.281,7.547,18.938,11.547,26.375c3.969,7.391,8.156,13.563,12.75,18.75
                      c1.5,7.375,2.781,16.375,2.781,25.516c0,3.094-0.156,6.203-0.469,9.266c-0.313,3.031-0.984,5.625-1.938,7.984
                      c-0.906,2.203-2.078,4.172-3.516,6.047c-2.703,3.516-6.453,6.672-11.203,9.594c-4.156,2.563-9.063,4.938-14.469,7.188
                      c-9.438,3.984-20.406,7.641-31.578,12.016c-8.391,3.281-16.938,6.984-25.109,11.641c-6.125,3.5-12.031,7.547-17.469,12.375
                      c-8.172,7.25-15.25,16.313-20.156,27.625c-4.906,11.297-7.656,24.75-7.641,40.688c0,2.188,0.453,4.313,1.219,6.25
                      c0.719,1.813,1.672,3.484,2.828,5.063c2.188,2.938,4.969,5.594,8.406,8.141c6.016,4.422,14.031,8.594,24.375,12.5
                      c15.531,5.859,36.328,11.109,63.219,14.938C183.281,509.625,216.25,512,256,512c34.453,0,63.828-1.797,88.547-4.75
                      c18.547-2.234,34.484-5.125,48.016-8.422c10.156-2.484,18.938-5.188,26.5-8.047c5.656-2.156,10.609-4.375,14.922-6.688
                      c3.234-1.719,6.094-3.484,8.625-5.297c3.781-2.734,6.828-5.578,9.141-8.781c1.156-1.609,2.125-3.313,2.813-5.172
                      c0.688-1.844,1.109-3.844,1.109-5.938C455.672,444.875,453.563,432.766,449.688,422.344z M189,262.344
                      c-3.906-7.25-7.781-16.297-11.781-27.563l-1.313-3.688l-3.656-1.344c-2.547-0.938-5.031-2.141-7.406-3.734
                      c-3.578-2.422-7-5.703-10.109-10.609c-3.109-4.922-5.922-11.5-7.922-20.391c-0.391-1.688-0.563-3.297-0.563-4.891
                      c0-3.563,0.875-6.938,2.484-10.188c1.328-2.656,3.203-5.219,5.469-7.5c3.828,5.781,6.984,10.344,8.141,11.5
                      c3.75,3.75,4.281-5.063,2.5-14.969c-3.281-18.234,16.688-39.875,18.719-82.375C228.5,96.563,316.344,77.5,316.344,77.5
                      s0.938,21.813,15.906,41.766c14.219,18.953,22.297,63.422,22.297,63.422s1.953-3.672,4.828-8.578
                      c1.719,2.016,3.141,4.188,4.188,6.453c1.438,3.063,2.188,6.219,2.188,9.547c0,1.594-0.172,3.219-0.563,4.906
                      c-1.328,5.922-3.031,10.828-4.922,14.891c-2.859,6.094-6.141,10.281-9.578,13.359c-3.453,3.078-7.094,5.078-10.906,6.469
                      l-3.672,1.359l-1.313,3.688c-3.984,11.266-7.891,20.313-11.797,27.578c-3.891,7.266-7.781,12.703-11.813,16.984l-1.594,1.672
                      l-0.5,2.266c-1.875,8.563-3.594,19.5-3.594,31.188c0,3.609,0.156,7.297,0.531,11c0.469,4.5,1.531,8.703,3.109,12.578
                      c1.484,3.609,3.422,6.922,5.719,9.891c0.563,0.719,1.141,1.438,1.734,2.125c-0.625,1.156-1.297,2.266-2.047,3.375
                      c-2.891,4.188-6.75,8.031-11.297,11.406c-6.813,5.063-15.156,9.031-23.5,11.641c-4.188,1.328-8.344,2.297-12.313,2.953
                      s-7.719,0.969-11.031,0.969c-5.031,0-11.094-0.734-17.375-2.25c-9.438-2.281-19.359-6.344-27.453-11.906
                      c-4.063-2.75-7.656-5.891-10.578-9.313c-2.031-2.359-3.719-4.859-5.094-7.484c2.125-2.531,4-5.297,5.547-8.344
                      c2.375-4.656,3.922-9.922,4.516-15.641c0.375-3.703,0.531-7.375,0.531-10.984c0-11.703-1.719-22.641-3.594-31.234l-0.5-2.25
                      l-1.594-1.688C196.781,275.063,192.891,269.594,189,262.344z M438.594,459.75c-0.563,0.953-1.859,2.516-4.063,4.281
                      c-1.906,1.531-4.484,3.266-7.734,5.031c-5.688,3.125-13.391,6.375-23.188,9.438c-14.688,4.625-34.078,8.844-58.531,11.906
                      c-24.469,3.063-54,4.953-89.078,4.953c-33.875,0-62.594-1.766-86.563-4.641c-17.984-2.156-33.281-4.938-46.063-8.063
                      c-9.563-2.344-17.734-4.875-24.531-7.438c-5.094-1.938-9.422-3.891-12.984-5.797c-2.672-1.422-4.922-2.828-6.75-4.141
                      c-2.734-1.969-4.484-3.75-5.344-4.969c-0.438-0.594-0.656-1.047-0.734-1.281c-0.047-0.109-0.063-0.156-0.063-0.188
                      c0-12.359,1.859-22.375,4.938-30.719c2.719-7.281,6.375-13.281,10.781-18.5c3.875-4.547,8.344-8.469,13.297-11.969
                      c8.672-6.125,18.828-10.844,29.422-15.047c7.938-3.156,16.109-6,24.047-9.016c5.953-2.25,11.766-4.594,17.266-7.266
                      c3.594-1.766,7.063-3.672,10.344-5.797c0.484,0.781,1,1.563,1.516,2.328c4.109,6,9.328,11.078,15.094,15.359
                      c8.656,6.406,18.578,11.031,28.422,14.156c4.922,1.547,9.844,2.719,14.609,3.5c4.766,0.766,9.375,1.188,13.703,1.188
                      c6.625-0.016,13.875-0.938,21.313-2.75c11.156-2.703,22.781-7.375,32.938-14.313c5.063-3.469,9.781-7.516,13.813-12.219
                      c1.844-2.156,3.531-4.438,5.063-6.875c0.328,0.219,0.656,0.438,1,0.656c5.219,3.188,10.844,5.875,16.734,8.344
                      c10.266,4.313,21.297,7.984,31.969,12.172c8,3.125,15.797,6.547,22.922,10.594c5.313,3.047,10.266,6.453,14.672,10.375
                      c6.609,5.875,12.031,12.844,15.938,21.797c3.875,8.938,6.25,19.953,6.266,34C439,458.938,438.906,459.266,438.594,459.75z"
                      style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('honnin')}}" class="radio-tile-label">{{ 'COMMON.HONNIN' | translate }}</label>

            </div>
          </label>
        </div>
        <div class="input-container input-container-right">
          <label for="{{getId('dairi')}}">
            <input id="{{getId('dairi')}}" class="radio-button" type="radio" [formControl]="pageForm.controls.dairiFlg"
              (change)="onDairi()" [value]="true" name={{rdName}} hidden />
            <div class="radio-tile" [class.input-invalid]="pageForm.controls.dairiFlg.invalid && (pageForm.controls.dairiFlg.touched || pageForm.controls.dairiFlg.dirty)">
              <div class="icon dairi-icon">

                <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                  <style type="text/css">
                    .st0{fill:#4B4B4B;}
                  </style>
                  <g>
                    <path class="st0" d="M316.761,327.239c-1.49-14.854,0.701-29.826,3.158-40.851c9.621-10.09,17.888-25.136,26.434-48.966
                      c12.758-4.626,25.74-15.365,31.846-41.93c2.812-12.234-1.951-23.573-10.228-31.761c6.096-20.18,30.144-113.704-40.967-132.955
                      C303.445,1.098,282.724,2.203,247.201,5.62c-17.67,1.699-20.196,5.444-33.746,3.424c-15.541-2.32-28.136-7.086-31.924-8.996
                      c-2.182-1.105-22.758,17.016-28.528,31.85c-18.279,46.991-13.539,79.284-9.025,103.714c-0.162,2.498-0.379,4.988-0.389,7.516
                      l4.15,17.898c0.012,0.184-0.002,0.36,0.004,0.544c-9.207,8.19-14.779,20.068-11.908,33.011
                      c5.906,26.618,18.815,37.451,31.545,42.17c8.363,23.882,16.504,38.984,26.045,49.146c2.379,11.036,4.459,26.037,2.856,40.888
                      c-6.697,62.214-137.533,28.938-137.946,138.217c-0.064,16.853,56.301,46.459,197.49,46.989
                      c141.19,0.531,197.776-28.648,197.838-45.501C454.076,357.21,322.992,389.502,316.761,327.239z M232.972,493.819l-35.295-145.722
                      l44.027,18.518l-2.453,21.296l7.006-0.125L232.972,493.819z M276.828,388.052l-2.291-21.314l44.164-18.186L282.31,494.005
                      l-12.486-106.131L276.828,388.052z" style="fill: rgb(255, 215, 0);"></path>
                  </g>
                </svg>

              </div>
              <label for="{{getId('dairi')}}" class="radio-tile-label">{{ 'COMMON.DAIRI' | translate }}</label>
            </div>
          </label>
        </div>
      </div>
    </div>

    <!-- 本人確認書類 -->
    <div class="box-koumoku">
      <div [hidden]="!(pageForm.controls.dairiFlg.value === false)">
        <div class="box-required">
          <img class="required" src="/assets/required.png">
          <label>{{ 'INKANTOUROKU.SHINSEI.HONNIN_KAKUNIN' | translate }}</label>
        </div>
        <form [formGroup]="pageForm">
          <div class="box-koumoku">
            <div class="box-question">
              <img class="required" src="/assets/question.png">
              <div class="label-msg"><label>{{ 'INKANTOUROKU.SHINSEI.HONNIN_KAKUNIN_SETUMEI' | translate }} <br>
                  {{ 'INKANTOUROKU.SHINSEI.HONNIN_KAKUNIN_SETUMEI2' | translate }}</label></div>
            </div>
            <ng-container *ngIf="pageForm.controls.honninKakuninFlg.hasError('required') && (pageForm.controls.honninKakuninFlg.touched || pageForm.controls.honninKakuninFlg.dirty)">
              <div class="err-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'INKANTOUROKU.SHINSEI.HONNIN_ERROR_REQUIRED' | translate }}
                </a>
              </div>
            </ng-container>

    
            <div class="dairi-radio-tile-group">
              <div class="input-container input-container-left">
                <label for="honninKakuninFlg-yes">
                  <input id="honninKakuninFlg-yes" class="radio-button" type="radio" name="honninKakuninFlg"
                    (change)="onHonninKakunin()" [formControl]="pageForm.controls.honninKakuninFlg" [value]="true" hidden />
                  <div class="radio-tile" [class.input-invalid]="pageForm.controls.honninKakuninFlg.invalid && (pageForm.controls.honninKakuninFlg.touched || pageForm.controls.honninKakuninFlg.dirty)">
                    <div class="icon honninKakuninFlg-yes-icon">
                      <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                        style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                        <style type="text/css">
                          .st0 {
                            fill: #4B4B4B;
                          }
                        </style>
                        <g>
                          <path class="st0"
                            d="M342.906,0H69.063c-6.578,0-11.906,5.344-11.906,11.906v488.188c0,6.578,5.328,11.906,11.906,11.906h373.875
                                  c6.563,0,11.906-5.328,11.906-11.906V111.938L342.906,0z M339.344,36.844l81.031,81.031h-81.031V36.844z M426.266,483.422H85.734
                                  V28.578h229.797v89.938c0,6.359,2.625,12.234,6.797,16.391c4.141,4.172,10.016,6.781,16.391,6.781h87.547V483.422z"
                            style="fill: rgb(255, 215, 0);"></path>
                          <rect x="145.281" y="185.75" class="st0" width="209.563" height="23.813"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <rect x="145.281" y="269.094" class="st0" width="208.359" height="23.813"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <rect x="145.281" y="352.438" class="st0" width="111.906" height="23.828"
                            style="fill: rgb(255, 215, 0);"></rect>
                          <path class="st0" d="M311.031,340.219c-8.594,8.563-13.938,20.563-13.938,33.656s5.344,25.094,13.938,33.672l0.125,0.125
                                  l-20.031,32.906l22.156,0.297l10.469,19.563l20.953-34.469l20.953,34.469l10.5-19.563l22.141-0.297l-20.047-32.906l0.141-0.125
                                  c8.578-8.578,13.938-20.578,13.938-33.672s-5.359-25.094-13.938-33.656c-8.578-8.609-20.578-13.969-33.688-13.969
                                  S319.594,331.609,311.031,340.219z M363.75,373.875c0,3.672-1,7.047-2.797,9.938c-0.766,1.281-1.703,2.453-2.766,3.547
                                  c-3.188,3.141-7.344,5.188-12.094,5.516c-0.453,0.031-0.906,0.063-1.391,0.063s-0.922-0.031-1.359-0.063
                                  c-4.75-0.328-8.938-2.375-12.125-5.516c-1.063-1.094-2-2.266-2.75-3.547c-1.813-2.891-2.813-6.266-2.813-9.938
                                  c0-5.313,2.094-9.969,5.563-13.469c3.5-3.469,8.188-5.563,13.484-5.563c5.313,0,9.984,2.094,13.484,5.563
                                  C361.641,363.906,363.75,368.563,363.75,373.875z" style="fill: rgb(255, 215, 0);"></path>
                        </g>
                      </svg>
                    </div>
                    <label for="honninKakuninFlg-yes" class="radio-tile-label">{{ 'COMMON.YES' | translate }}
                    </label>
                  </div>
                </label>
              </div>
              <div class="input-container  input-container-right">
                <label for="honninKakuninFlg-no">
                  <input id="honninKakuninFlg-no" class="radio-button" type="radio" name="honninKakuninFlg"
                    (change)="onHonninKakunin()" [formControl]="pageForm.controls.honninKakuninFlg" [value]="false"
                    hidden />
                  <div class="radio-tile" [class.input-invalid]="pageForm.controls.honninKakuninFlg.invalid && (pageForm.controls.honninKakuninFlg.touched || pageForm.controls.honninKakuninFlg.dirty)">
                    <div class="icon honninKakuninFlg-no-icon">
                      <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                      <style type="text/css">
                        .st0 {
                          fill: #4B4B4B;
                        }
                      </style>
                      <g>
                        <path class="st0" d="M256,0c-11.191,0-23.03,9.012-19.737,37.037c2.627,22.364,9.555,44.566,19.737,44.566
                            c10.164,0,17.091-22.202,19.718-44.566C279.03,9.012,267.192,0,256,0z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M256,430.397c-10.182,0-17.11,22.202-19.737,44.565C232.97,502.988,244.809,512,256,512
                            c11.192,0,23.03-9.012,19.718-37.038C273.091,452.599,266.164,430.397,256,430.397z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M127.435,34.116c-9.688,5.596-15.436,19.319,1.426,41.938c13.476,18.053,30.568,33.803,39.38,28.721
                            c8.812-5.082,3.711-27.778-5.196-48.457C151.891,30.395,137.122,28.51,127.435,34.116z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M343.761,407.224c-8.812,5.082-3.712,27.778,5.176,48.458c11.172,25.922,25.923,27.807,35.61,22.202
                            c9.688-5.586,15.436-19.318-1.428-41.939C369.664,417.892,352.573,402.142,343.761,407.224z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M76.045,128.871c-22.611-16.864-36.333-11.106-41.929-1.418c-5.596,9.687-3.73,24.447,22.192,35.601
                            c20.689,8.906,43.376,13.998,48.458,5.196C109.848,159.437,94.089,142.336,76.045,128.871z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M455.673,348.946c-20.669-8.908-43.357-13.999-48.438-5.197c-5.1,8.812,10.659,25.914,28.721,39.38
                            c22.611,16.862,36.333,11.105,41.929,1.418C483.48,374.869,481.595,360.1,455.673,348.946z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M81.584,256c0-10.164-22.192-17.101-44.556-19.727C9.012,232.97,0.01,244.818,0.01,256
                            c0,11.191,9.002,23.029,37.018,19.737C59.392,273.1,81.584,266.163,81.584,256z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M474.953,236.263c-22.364,2.636-44.556,9.574-44.556,19.737c0,10.163,22.211,17.1,44.556,19.727
                            c28.035,3.302,37.037-8.536,37.037-19.727C511.99,244.808,502.988,232.97,474.953,236.263z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M56.308,348.946c-25.922,11.153-27.787,25.922-22.192,35.601c5.596,9.687,19.318,15.444,41.929-1.418
                            c18.044-13.466,33.803-30.567,28.721-39.38C99.684,334.947,76.997,340.039,56.308,348.946z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0"
                          d="M455.673,163.054c25.923-11.154,27.808-25.914,22.211-35.601c-5.596-9.698-19.318-15.446-41.929,1.418
                            c-18.062,13.465-33.821,30.566-28.721,39.378C412.316,177.052,435.004,171.96,455.673,163.054z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M128.861,435.945c-16.862,22.621-11.115,36.353-1.408,41.939c9.687,5.605,24.438,3.72,35.592-22.202
                            c8.907-20.679,14.008-43.376,5.196-48.458C159.429,402.142,142.337,417.892,128.861,435.945z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M384.547,34.116c-9.687-5.605-24.438-3.721-35.61,22.202c-8.907,20.678-13.989,43.375-5.176,48.457
                            c8.793,5.082,25.904-10.668,39.359-28.721C399.983,53.434,394.254,39.712,384.547,34.116z"
                          style="fill: rgb(255, 215, 0);"></path>
                      </g>
                    </svg>

                    </div>
                    <label for="honninKakuninFlg-no" class="radio-tile-label">{{ 'COMMON.NO' | translate }}
                    </label>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <!-- 枚数 -->
          <div class="box-koumoku">
            <div [hidden]="!pageForm.controls.honninKakuninFlg.value">
              <div class="box-koumoku">
                <div class="inf-msg">
                  <a>
                    <fa-icon [icon]="faInfoCircle" class="inf-img"></fa-icon>
                    {{ 'INKANTOUROKU.SHINSEI.MAISUU_SETUMEI' | translate }}
                  </a>
                </div>
              </div>
              <div class="label-msg">
                <label>{{ 'INKANTOUROKU.SHINSEI.MAISUU' | translate }}</label>
              </div>
    
              <div class="box-control">
                <div class="select box-maisu">
                  <select name="maisu" [formControl]="pageForm.controls.maisuu">
                    <option disabled="disabled" [value]="0" selected>{{ 'COMMON.SELECT' | translate }}</option>
                    <option *ngFor="let maisu_val of maisu" [ngValue]="maisu_val">{{maisu_val}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- その他の本人確認書類 -->
          <div class="box-koumoku">
            <div [hidden]="!(pageForm.controls.honninKakuninFlg.value == false)">
              <div class="label-msg"><label>{{ 'INKANTOUROKU.SHINSEI.SONOTA' | translate }}</label></div>
              <div class="box-control">
                <input class="input-text" type="text" [formControl]="pageForm.controls.sonota" maxlength="10">
              </div>
              <div class="box-setumei">
                <a>{{ 'INKANTOUROKU.SHINSEI.SONOTA_SETUMEI' | translate }}</a>
              </div>
              <div class="cau-msg">
                <a>
                  <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                  {{ 'INKANTOUROKU.SHINSEI.SONOTA_MESSAGE' | translate }}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    


    <div class="box-koumoku">
      <div [hidden]="!pageForm.controls.dairiFlg.value">

        <div class="box-koumoku">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'COMMON.DAIRI' | translate }}</label>
          </div>
          
          <ng-container>
            <div class="cau-msg">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'INKANTOUROKU.SHINSEI.DAIRI_SOKUJITSU' | translate }}
              </a>
            </div>
          </ng-container>

          <form [formGroup]="pageForm">
            <app-shimei [buttonVisible]="false" fgName={{fgNameShimeiDairi}} ckName={{ckNameShimeiDairi}}
              defaltKbn="1">
            </app-shimei>
          </form>
        </div>

        <div class="box-koumoku">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <label>{{ 'COMMON.ADDR' | translate }}</label>
          </div>

          <form [formGroup]="pageForm">
            <app-zipcode fgName={{fgNameZipcodeDairi}} ckName={{ckNameZipcodeDairi}}
              defaltKbn="3"></app-zipcode>
          </form>
        </div>

        <div class="box-koumoku">
          <div class="label-msg"><label>{{ 'COMMON.TEL' | translate }}</label></div>
          <ng-container
            *ngIf="pageForm.controls.dairi_telno.hasError('numeric') && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)">
            <div class="err-msg">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'COMMON.TEL_ERROR_TYPE' | translate }}
              </a>
            </div>
          </ng-container>
          <div class="box-control">
            <input
              [class.input-invalid]="pageForm.controls.dairi_telno.invalid && (pageForm.controls.dairi_telno.touched || pageForm.controls.dairi_telno.dirty)"
              class="input-text input-tel" type="text" maxlength="11" name="dairi_telno"
              [formControl]="pageForm.controls.dairi_telno" autocomplete="tel">
          </div>
          <div class="box-setumei">
            <a>{{ 'COMMON.TEL_SETUMEI' | translate }}</a>
          </div>
        </div>

        <div class="box-koumoku　no-margin-bottom">
          <div class="box-required">
            <img class="required" src="/assets/required.png">
            <div class="label-msg"><label>{{ 'COMMON.TITLE_ININJYOU' | translate }}</label></div>
          </div>

          <ng-container
            *ngIf="pageForm.controls.ininjyouFlg.hasError('required') && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
            <div class="err-msg" *ngIf="pageForm.controls.ininjyouFlg.value == null">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'COMMON.ININJYOU_ERROR_REQUIRED' | translate }}
              </a>
            </div>
            <div class="err-msg" *ngIf="pageForm.controls.ininjyouFlg.value == false">
              <a>
                <fa-icon [icon]="faExclamationTriangle" class="err-img"></fa-icon>
                {{ 'INKANTOUROKU.SHINSEI.ININJYOU_REQUIRED_MESSAGE' | translate }}
              </a>
            </div>
          </ng-container>
          <div class="dairi-radio-tile-group">
            <div class="input-container input-container-left">
              <label for="ininjyouFlg-yes">
                <input id="ininjyouFlg-yes" class="radio-button" type="radio" name="ininjyouFlg"
                  [formControl]="pageForm.controls.ininjyouFlg" [value]="true" hidden />
                <div class="radio-tile" [class.input-invalid]="pageForm.controls.ininjyouFlg.invalid && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
                  <div class="icon ininjyouFlg-yes-icon">
                    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px"
                      viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                      <style type="text/css">
                        .st0 {
                          fill: #4B4B4B;
                        }
                      </style>
                      <g>
                        <path class="st0" d="M208.971,443.563c2.203-2.219,3.281-5.109,3.234-8l8.047-8.031l65.25-14
                            c6.672-1.203,12.266-5.719,14.859-11.984c0,0,12.375-27.641,19.891-40.172l-20.781-20.781l-3.234-3.25l-20.797-20.797
                            c-12.531,7.516-40.188,19.891-40.188,19.891c-6.266,2.594-10.766,8.188-11.969,14.844l-14.016,65.266l-8.016,8.031
                            c-2.906-0.016-5.813,1.047-8.016,3.25c-4.344,4.359-4.344,11.391,0,15.734S204.627,447.906,208.971,443.563z M256.564,363.063
                            c4.734-4.75,12.422-4.75,17.188,0c4.734,4.734,4.734,12.438,0,17.172c-4.766,4.734-12.453,4.734-17.188,0
                            C251.814,375.5,251.814,367.797,256.564,363.063z" style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0"
                          d="M362.346,318.766l-44.313-44.328c0,0-15.531,15.531-20.531,20.531c-5.016,5-11.531,3.5-11.531,3.5l-6,6.031
                            l21.031,21.031l5.016,5l26.297,26.297l6-6.016c0,0-1.5-6.5,3.5-11.516C346.83,334.281,362.346,318.766,362.346,318.766z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M497.83,138.969c-20.5-20.484-42.844-18.625-66.141,4.656c-23.266,23.281-90.219,106.625-106,122.406
                            l45.078,45.063c15.766-15.766,99.109-82.719,122.391-106S518.314,159.453,497.83,138.969z M477.486,175.141l-70.156,70.141
                            c-1.719,1.734-4.484,1.734-6.203,0l-9.625-9.625c-1.703-1.688-1.703-4.469,0-6.188l70.141-70.156c1.719-1.719,4.516-1.719,6.234,0
                            l9.609,9.625C479.205,170.656,479.205,173.438,477.486,175.141z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <rect x="88.408" y="201.844" class="st0" width="194.5" height="22.109"
                          style="fill: rgb(255, 215, 0);"></rect>
                        <rect x="88.408" y="279.219" class="st0" width="145.875" height="22.094"
                          style="fill: rgb(255, 215, 0);"></rect>
                        <rect x="88.408" y="356.563" class="st0" width="103.891" height="22.109"
                          style="fill: rgb(255, 215, 0);"></rect>
                        <path class="st0" d="M358.58,356.969l-8.063,6.656l-7.938,7.938v102.156H26.518V38.281h213.281v83.484
                            c0,5.906,2.438,11.359,6.313,15.203c3.859,3.875,9.297,6.313,15.219,6.313h81.25v76.5c4.297-5.125,8.813-10.469,13.391-15.922
                            c4.313-5.141,8.719-10.391,13.125-15.625v-72.578L265.221,11.766H11.049c-6.109,0-11.047,4.953-11.047,11.047v466.375
                            c0,6.094,4.938,11.047,11.047,11.047h347c6.109,0,11.047-4.953,11.047-11.047V337.031l-12.672,12.672L358.58,356.969z
                             M261.924,45.969l75.188,75.203h-75.188V45.969z" style="fill: rgb(255, 215, 0);"></path>
                      </g>
                    </svg>
                  </div>
                  <label for="ininjyouFlg-yes" class="radio-tile-label">{{ 'COMMON.ININJYOU_YES' | translate }}
                  </label>
                </div>
              </label>
            </div>
            <div class="input-container  input-container-right">
              <label for="ininjyouFlg-no">
                <input id="ininjyouFlg-no" class="radio-button" type="radio" name="ininjyouFlg"
                  [formControl]="pageForm.controls.ininjyouFlg" [value]="false" hidden />
                <div class="radio-tile" [class.input-invalid]="pageForm.controls.ininjyouFlg.invalid && (pageForm.controls.ininjyouFlg.touched || pageForm.controls.ininjyouFlg.dirty)">
                  <div class="icon ininjyouFlg-no-icon">
                    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                      style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">
                      <style type="text/css">
                        .st0 {
                          fill: #4B4B4B;
                        }
                      </style>
                      <g>
                        <path class="st0" d="M256,0c-11.191,0-23.03,9.012-19.737,37.037c2.627,22.364,9.555,44.566,19.737,44.566
                            c10.164,0,17.091-22.202,19.718-44.566C279.03,9.012,267.192,0,256,0z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M256,430.397c-10.182,0-17.11,22.202-19.737,44.565C232.97,502.988,244.809,512,256,512
                            c11.192,0,23.03-9.012,19.718-37.038C273.091,452.599,266.164,430.397,256,430.397z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M127.435,34.116c-9.688,5.596-15.436,19.319,1.426,41.938c13.476,18.053,30.568,33.803,39.38,28.721
                            c8.812-5.082,3.711-27.778-5.196-48.457C151.891,30.395,137.122,28.51,127.435,34.116z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M343.761,407.224c-8.812,5.082-3.712,27.778,5.176,48.458c11.172,25.922,25.923,27.807,35.61,22.202
                            c9.688-5.586,15.436-19.318-1.428-41.939C369.664,417.892,352.573,402.142,343.761,407.224z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M76.045,128.871c-22.611-16.864-36.333-11.106-41.929-1.418c-5.596,9.687-3.73,24.447,22.192,35.601
                            c20.689,8.906,43.376,13.998,48.458,5.196C109.848,159.437,94.089,142.336,76.045,128.871z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M455.673,348.946c-20.669-8.908-43.357-13.999-48.438-5.197c-5.1,8.812,10.659,25.914,28.721,39.38
                            c22.611,16.862,36.333,11.105,41.929,1.418C483.48,374.869,481.595,360.1,455.673,348.946z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M81.584,256c0-10.164-22.192-17.101-44.556-19.727C9.012,232.97,0.01,244.818,0.01,256
                            c0,11.191,9.002,23.029,37.018,19.737C59.392,273.1,81.584,266.163,81.584,256z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M474.953,236.263c-22.364,2.636-44.556,9.574-44.556,19.737c0,10.163,22.211,17.1,44.556,19.727
                            c28.035,3.302,37.037-8.536,37.037-19.727C511.99,244.808,502.988,232.97,474.953,236.263z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M56.308,348.946c-25.922,11.153-27.787,25.922-22.192,35.601c5.596,9.687,19.318,15.444,41.929-1.418
                            c18.044-13.466,33.803-30.567,28.721-39.38C99.684,334.947,76.997,340.039,56.308,348.946z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0"
                          d="M455.673,163.054c25.923-11.154,27.808-25.914,22.211-35.601c-5.596-9.698-19.318-15.446-41.929,1.418
                            c-18.062,13.465-33.821,30.566-28.721,39.378C412.316,177.052,435.004,171.96,455.673,163.054z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M128.861,435.945c-16.862,22.621-11.115,36.353-1.408,41.939c9.687,5.605,24.438,3.72,35.592-22.202
                            c8.907-20.679,14.008-43.376,5.196-48.458C159.429,402.142,142.337,417.892,128.861,435.945z"
                          style="fill: rgb(255, 215, 0);"></path>
                        <path class="st0" d="M384.547,34.116c-9.687-5.605-24.438-3.721-35.61,22.202c-8.907,20.678-13.989,43.375-5.176,48.457
                            c8.793,5.082,25.904-10.668,39.359-28.721C399.983,53.434,394.254,39.712,384.547,34.116z"
                          style="fill: rgb(255, 215, 0);"></path>
                      </g>
                    </svg>
                  </div>
                  <label for="ininjyouFlg-no" class="radio-tile-label">{{ 'COMMON.ININJYOU_NO' | translate }}
                  </label>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>