import { Shimei, ShimeiComponent } from '../../common/components/shimei/shimei.component';
import { AbstractControl } from '@angular/forms';
import { formatyyMMdd } from '../../common/utility/date-functions';
import { ZipcodeAddress, Address } from '../../common/components/zipcode/zipcode.component';

export const getShimeiFromShimeiForm = (shimeiForm: AbstractControl): Shimei =>
    new Shimei(
        shimeiForm.get('sei_kj').value ?? '',
        shimeiForm.get('mei_kj').value ?? '',
        shimeiForm.get('sei_kn').value ?? '',
        shimeiForm.get('mei_kn').value ?? ''
    );

export const getFormattedyyMMddFromDatepicker = (datepicker: AbstractControl): string => {
    return formatyyMMdd(
        datepicker.get('year').value,
        datepicker.get('month').value,
        datepicker.get('day').value
    );
};

export const getZipcodeAddressFromZipcodeForm = (zipcodeForm: AbstractControl): ZipcodeAddress => {
    const address = zipcodeForm.get('address').value as Address;
    const nonNullAddress = new Address(address.ken ?? '', address.city ?? '', address.town ?? '');
    return new ZipcodeAddress(
        zipcodeForm.get('zipcode').value ?? '',
        nonNullAddress,
        zipcodeForm.get('banti').value ?? '',
        zipcodeForm.get('ktgk').value ?? ''
    );
};

export const concatSeiMei = (sei: string, mei: string) => (sei ?? '') + '　' + (mei ?? '');
export const getNameKjFromShimei = (shimei: Shimei) => !shimei.mei_kj ? shimei.sei_kj : concatSeiMei(shimei.sei_kj, shimei.mei_kj);
export const getNameKnFromShimei = (shimei: Shimei) => !shimei.mei_kn ? shimei.sei_kn : concatSeiMei(shimei.sei_kn, shimei.mei_kn);
