import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Jyuminhyou, Shinsei } from '@jyuminhyou/services/item';
import { ZipcodeComponent, ZipcodeAddress, Address } from '@components/zipcode/zipcode.component';
import { Shimei, ShimeiComponent } from '@components/shimei/shimei.component';
import { CustomValidator } from '@utility/custom-validator';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-jyuminhyou-shinsei',
  templateUrl: './jyuminhyou-shinsei.component.html',
  styleUrls: ['./jyuminhyou-shinsei.component.css', '../../jyuminhyou.component.css']
})
export class JyuminhyouShinseiComponent implements OnInit, AfterViewInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(ZipcodeComponent) zipcodeComponents: QueryList<ZipcodeComponent>;
  @ViewChild(DatepickerComponent) datepickerComponent: DatepickerComponent;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  kankei: string[];
  fgNameShimeiTisy = 'shimeiTisy';
  ckNameShimeiTisy = 'shinsei-shimeiTisy';
  fgNameZipcodeTisy = 'zipcodeTisy';
  ckNameZipcodeTisy = 'shinsei-zipcodeTisy';
  fgNameShimeiDairi = 'shimeiDairi';
  ckNameShimeiDairi = 'shinsei-shimeiDairi';
  fgNameZipcodeDairi = 'zipcodeDairi';
  ckNameZipcodeDairi = 'shinsei-zipcodeDairi';
  fgNameShimeiSiky = 'shimeiSiky';
  ckNameShimeiSiky = 'shinsei-shimeiSiky';
  fgNameZipcodeSiky = 'zipcodeSiky';
  ckNameZipcodeSiky = 'shinsei-zipcodeSiky';
  fgNameDatepickerTisyUmare = 'datepickerTisyUmare';
  faExclamationTriangle = faExclamationTriangle;
  rdName = 'honnin_dairi';

  constructor(private fb: FormBuilder, public translate: TranslateService) {
    this.pageForm = fb.group({
      //shimeiTisy（shimei コンポーネントで追加）
      //datepickerTisyUmare（datepicker コンポーネントで追加）
      //zipcodeTisy（zipcode コンポーネントで追加）
      tisy_telno: [null, [CustomValidator.numeric]],
      dairiKbn: [null, [Validators.required]],
      //shimeiDairi（shimei コンポーネントで追加）
      //zipcodeDairi（zipcode コンポーネントで追加）
      dairi_telno: [null, [CustomValidator.numeric]],
      dairi_knki: [null, [Validators.required]],
      sikyFlg: false,
      //shimeiSiky（shimei コンポーネントで追加）
      //zipcodeSiky（zipcode コンポーネントで追加）
      siky_telno: [null, [CustomValidator.numeric]],
      ininjyouFlg: [null, [Validators.requiredTrue]],
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.kankei = ret.translations.MASTER.KANKEI;
    });
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  ngAfterViewInit() {
    //はじめは代理はチェックしない
    this.pageForm.controls.dairi_knki.disable();
    this.pageForm.controls.zipcodeDairi.disable();
    this.pageForm.controls.shimeiDairi.disable();
    this.pageForm.controls.zipcodeSiky.disable();
    this.pageForm.controls.shimeiSiky.disable();
    this.pageForm.controls.ininjyouFlg.disable();
  }

  getShinsei(): Shinsei {
    const shimeiTisyData = new Shimei(this.pageForm.controls.shimeiTisy.get('sei_kj').value, this.pageForm.controls.shimeiTisy.get('mei_kj').value, this.pageForm.controls.shimeiTisy.get('sei_kn').value, this.pageForm.controls.shimeiTisy.get('mei_kn').value);
    const datepickerTisyUmare = String(this.pageForm.controls.datepickerTisyUmare.get('year').value) + '-' + String(('0' + (this.pageForm.controls.datepickerTisyUmare.get('month').value)).slice(-2)) + '-' + String(('0' + (this.pageForm.controls.datepickerTisyUmare.get('day').value)).slice(-2));
    const addressTisy = new Address(this.pageForm.controls.zipcodeTisy.get('address').value.ken, this.pageForm.controls.zipcodeTisy.get('address').value.city, this.pageForm.controls.zipcodeTisy.get('address').value.town);
    const zipcodeTisyAddress = new ZipcodeAddress(this.pageForm.controls.zipcodeTisy.get('zipcode').value, addressTisy, this.pageForm.controls.zipcodeTisy.get('banti').value, this.pageForm.controls.zipcodeTisy.get('ktgk').value);
    const shimeiDairiData = new Shimei(this.pageForm.controls.shimeiDairi.get('sei_kj').value, this.pageForm.controls.shimeiDairi.get('mei_kj').value, this.pageForm.controls.shimeiDairi.get('sei_kn').value, this.pageForm.controls.shimeiDairi.get('mei_kn').value);
    const addressDairi = new Address(this.pageForm.controls.zipcodeDairi.get('address').value.ken, this.pageForm.controls.zipcodeDairi.get('address').value.city, this.pageForm.controls.zipcodeDairi.get('address').value.town);

    var zipcodeDairiAddress;
    if (this.pageForm.controls.dairiKbn.value == 2) {
      //同一世帯員の場合は対象者の住所をコピー
      zipcodeDairiAddress = zipcodeTisyAddress;
    }
    else
    {
      zipcodeDairiAddress = new ZipcodeAddress(this.pageForm.controls.zipcodeDairi.get('zipcode').value, addressDairi, this.pageForm.controls.zipcodeDairi.get('banti').value, this.pageForm.controls.zipcodeDairi.get('ktgk').value);
    }
    
    const shimeiSikyData = new Shimei(this.pageForm.controls.shimeiSiky.get('sei_kj').value, this.pageForm.controls.shimeiSiky.get('mei_kj').value, this.pageForm.controls.shimeiSiky.get('sei_kn').value, this.pageForm.controls.shimeiSiky.get('mei_kn').value);
    const addressSiky = new Address(this.pageForm.controls.zipcodeSiky.get('address').value.ken, this.pageForm.controls.zipcodeSiky.get('address').value.city, this.pageForm.controls.zipcodeSiky.get('address').value.town);
    const zipcodeSikyAddress = new ZipcodeAddress(this.pageForm.controls.zipcodeSiky.get('zipcode').value, addressSiky, this.pageForm.controls.zipcodeSiky.get('banti').value, this.pageForm.controls.zipcodeSiky.get('ktgk').value);

    const shinsei = new Shinsei(
      shimeiTisyData,
      datepickerTisyUmare,
      zipcodeTisyAddress,
      this.pageForm.controls.tisy_telno.value,
      this.pageForm.controls.dairiKbn.value,
      shimeiDairiData,
      zipcodeDairiAddress,
      this.pageForm.controls.dairi_knki.value,
      this.pageForm.controls.dairi_telno.value,
      this.pageForm.controls.sikyFlg.value,
      shimeiSikyData,
      zipcodeSikyAddress,
      this.pageForm.controls.siky_telno.value,
    );

    return shinsei;

  }

  onDairi() {
    if (this.pageForm.controls.dairiKbn.value == 1) {
      //本人の場合
      this.pageForm.controls.shimeiDairi.disable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setDisable();
      this.pageForm.controls.zipcodeDairi.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setDisable();
      this.pageForm.controls.dairi_telno.disable();
      // this.pageForm.controls.dairi_knki.disable();
      this.pageForm.controls.ininjyouFlg.disable();
    }
    else if (this.pageForm.controls.dairiKbn.value == 2) {
      //代理の場合
      this.pageForm.controls.shimeiDairi.enable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setValidate();
      this.pageForm.controls.zipcodeDairi.disable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setDisable();
      this.pageForm.controls.dairi_telno.disable();
      // this.pageForm.controls.dairi_knki.disable();
      this.pageForm.controls.ininjyouFlg.disable();
    }
    else if (this.pageForm.controls.dairiKbn.value == 3) {
      //代理の場合
      this.pageForm.controls.shimeiDairi.enable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).setValidate();
      this.pageForm.controls.zipcodeDairi.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).setValidate();
      this.pageForm.controls.dairi_telno.enable();
      // this.pageForm.controls.dairi_knki.enable();
      this.pageForm.controls.ininjyouFlg.enable();
    }

    //値をクリア
    this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiDairi).clear();
    this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeDairi).clear();
    this.pageForm.controls.dairi_telno.setValue('');
    // this.pageForm.controls.dairi_knki.setValue('');
    this.pageForm.controls.ininjyouFlg.setValue(null);
  }

  //未使用
  onSiky() {
    if (this.pageForm.controls.sikyFlg.value) {
      //代理の場合
      this.pageForm.controls.shimeiSiky.enable();
      this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiSiky).setValidate();
      this.pageForm.controls.zipcodeSiky.enable();
      this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeSiky).setValidate();
      this.pageForm.controls.siky_telno.enable();
    }
    else {
      //代理で無い場合
      this.pageForm.controls.shimeiSiky.disable();
      this.pageForm.controls.zipcodeSiky.disable();
      this.pageForm.controls.siky_telno.disable();
    }

    //値をクリア
    this.shimeiComponents.find((element) => element.fgName === this.fgNameShimeiSiky).clear();
    this.zipcodeComponents.find((element) => element.fgName === this.fgNameZipcodeSiky).clear();
    this.pageForm.controls.siky_telno.setValue('');
  }


  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix;
  }

  getUji(){
    return new Shimei(this.pageForm.controls.shimeiTisy.get('sei_kj').value, null, this.pageForm.controls.shimeiTisy.get('sei_kn').value, null);;
  }

}
