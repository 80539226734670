import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export class Iryokodomo {
    fk101_snsi_bng: number;
    fk101_snsi_ymd: string;
    fk101_snsi_yubinno: string;
    fk101_snsi_addr_ken: string;
    fk101_snsi_addr_city: string;
    fk101_snsi_addr_town: string;
    fk101_snsi_addr_banti: string;
    fk101_snsi_addr_ktgk: string;
    fk101_snsi_home_tel_no: string;
    fk101_snsi_cell_tel_no: string;
    fk101_snsi_namekj: string;
    fk101_snsi_namekn: string;
    fk101_snsi_birthday: string;
    fk101_snsi_zokugara: string;
    fk101_snsi_name_tusho: string;
    fk101_snsi_nenkin: string;
    fk101_hoken_hhs_kigo: string;
    fk101_hoken_hhs_bango: string;
    fk101_hoken_hhs_name: string;
    fk101_hoken_kodomoskk_date: string;
    fk101_hoken_hoken_name: string;
    fk101_hoken_hoken_bango: string;
    fk101_hoken_hoken_syurui: string;
    fk102s: Jido[];
}

export class Jido {
    fk102_id: number;
    fk102_jido_namekj: string;
    fk102_jido_namekn: string;
    fk102_jido_sex: string;
    fk102_jido_birthday: string;
    fk102_jido_seiho: string;
}

export class Shinsei {
    snsi_ymd: string;
    snsi_address: ZipcodeAddress;
    snsi_home_tel_no: string;
    snsi_cell_tel_no: string;
    snsi_smi: Shimei;
    snsi_birthday: string;
    snsi_zokugara: string;
    snsi_name_tusho: string;
    snsi_nenkin: string;

    constructor(snsi_ymd: string,
        snsi_address: ZipcodeAddress,
        snsi_home_tel_no: string,
        snsi_cell_tel_no: string,
        snsi_smi: Shimei,
        snsi_birthday: string,
        snsi_zokugara: string,
        snsi_name_tusho: string,
        snsi_nenkin: string) {
        this.snsi_ymd = snsi_ymd;
        this.snsi_address = snsi_address;
        this.snsi_home_tel_no = snsi_home_tel_no;
        this.snsi_cell_tel_no = snsi_cell_tel_no;
        this.snsi_smi = snsi_smi;
        this.snsi_birthday = snsi_birthday;
        this.snsi_zokugara = snsi_zokugara;
        this.snsi_name_tusho = snsi_name_tusho;
        this.snsi_nenkin = snsi_nenkin;
    }
}

export class Hoken {
    hoken_hhs_kigo: string;
    hoken_hhs_bango: string;
    hoken_hhs_name: string;
    hoken_kodomoskk_date: string;
    hoken_hoken_name: string;
    hoken_hoken_bango: string;
    hoken_hoken_syurui: string;

    constructor(
        hoken_hhs_kigo: string,
        hoken_hhs_bango: string,
        hoken_hhs_name: string,
        hoken_kodomoskk_date: string,
        hoken_hoken_name: string,
        hoken_hoken_bango: string,
        hoken_hoken_syurui: string
    ) {
        this.hoken_hhs_kigo = hoken_hhs_kigo;
        this.hoken_hhs_bango = hoken_hhs_bango;
        this.hoken_hhs_name = hoken_hhs_name;
        this.hoken_kodomoskk_date = hoken_kodomoskk_date;
        this.hoken_hoken_name = hoken_hoken_name;
        this.hoken_hoken_bango = hoken_hoken_bango;
        this.hoken_hoken_syurui = hoken_hoken_syurui;
    }
}

export class TaisyouJido {
    id: number;
    jido_smi: Shimei;
    umare_ymd: string;
    sex: string;
    seiho: string;
    constructor(id: number, jido_smi: Shimei, umare_ymd: string, sex: string, seiho: string) {
        this.id = id;
        this.jido_smi = jido_smi;
        this.umare_ymd = umare_ymd;
        this.sex = sex;
        this.seiho = seiho;
    }
}