<div class="box-doui">
    <div class="box-doui-setumei">
        <h5>{{ 'COMMON.DOUI.DOUI_COMMON' | translate }}</h5>
        <h5>{{ 'INKANTOUROKU.DOUI.DOUI_COMMON' | translate }}</h5>
    </div>

    <div class="box-doui-message">
        <div class="box-doui-message-col1">
            <div class="doui-area">
                <input type="checkbox" id="doui-check" name="douiFlg" [formControl]="pageForm.controls.douiFlg">
                <label for="doui-check">
                </label>
            </div>
        </div>
        <div class="box-doui-message-col2">
            {{ 'COMMON.DOUI.DOUI_KAKUNIN' | translate }}
        </div>
    </div>
</div>