import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '@utility/custom-validator';
import { Jyukiido, Transfer } from '@jyukiido/services/item';

@Component({
  selector: 'app-jyukiido-doui',
  templateUrl: './jyukiido-doui.component.html',
  styleUrls: ['./jyukiido-doui.component.css']
})
export class JyukiidoDouiComponent implements OnInit {
  @Input() item: Jyukiido;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  pageForm: FormGroup;

  constructor(fb: FormBuilder) {
    this.pageForm = fb.group({
      douiFlg: [null, [Validators.required, CustomValidator.doui]]
    });
  }

  ngOnInit() {
    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  clearDouiFlg(){
    this.pageForm.controls.douiFlg.setValue(null);
  }

}
