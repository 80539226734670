import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { CustomValidator } from '../../../common/utility/custom-validator';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sex',
  templateUrl: './sex.component.html',
  styleUrls: ['./sex.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SexComponent implements OnInit {
  parentForm: FormGroup;
  @Input() fgName: string;
  @Input() rdName: string;
  faExclamationTriangle = faExclamationTriangle;

  constructor(private fgd: FormGroupDirective, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.parentForm = this.fgd.form;
    this.parentForm.addControl(this.fgName, this.fb.group({
      cd: [null, [Validators.required]],
    }))
  }

  controlName(key) {
    return (this.parentForm.get(this.fgName).get(key));
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix + this.fgName + this.rdName;
  }

}
