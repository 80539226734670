import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JyukiidoComponent } from './jyukiido/jyukiido.component';
import { InkansyoumeiComponent } from './inkansyoumei/inkansyoumei.component';
import { IryokodomoComponent } from './iryokodomo/iryokodomo.component';
import { JyuminhyouComponent } from './jyuminhyou/jyuminhyou.component';
import { InkantourokuComponent } from './inkantouroku/inkantouroku.component';
import { JidoteateComponent } from '@jidoteate/jidoteate.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'jyukiido', component: JyukiidoComponent },
  { path: 'inkansyoumei', component: InkansyoumeiComponent },
  { path: 'iryokodomo', component: IryokodomoComponent },
  { path: 'jyuminhyou', component: JyuminhyouComponent },
  { path: 'inkantouroku', component: InkantourokuComponent },
  { path: 'jidoteate', component: JidoteateComponent },
  { path: '**', component: PageNotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
