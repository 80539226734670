<div class="box-top">
  <div class="box-title">
    <img class="img-title-contens" src="/assets/title-torikomi.png">
    <div class="lang" *ngIf="langFlg">
      <div class="lang-box">
        <p class="lang-label">
          <fa-icon [icon]="faGlobe"></fa-icon> {{translateSettingService.getLanguage(langSelect.value)}}
        </p>
      </div>
      <select #langSelect (change)="translate.use(langSelect.value)">
        <option disabled="disabled" [value]="null">Language</option>
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
          {{ translateSettingService.getLanguage(lang) }}</option>
      </select>
    </div>
  </div>
</div>

<div class="box-main">
  <div class="box-contents-background">
    <div class="box-contents">
      <h1>{{ 'JIDOTEATE.TITLE_MAIN' | translate }}</h1>
      <app-stepver [currentStep]="currentSteper" [steps]="steps"></app-stepver>

      <app-jidoteate-seikyusha (isPageFormValid)='enableNextButton($event)' [hidden]='getCurrentSteper(1)'>
      </app-jidoteate-seikyusha>
      <app-jidoteate-haigusha (isPageFormValid)='enableNextButton($event)' [hidden]='getCurrentSteper(2)'>
      </app-jidoteate-haigusha>
      <app-jidoteate-jidos (isPageFormValid)='enableNextButton($event)' [hidden]='getCurrentSteper(3)'>
      </app-jidoteate-jidos>
      <app-jidoteate-koza (isPageFormValid)='enableNextButton($event)' [hidden]='getCurrentSteper(4)'>
      </app-jidoteate-koza>
      <app-jidoteate-kakunin [item]="item" [hidden]='getCurrentSteper(5)'></app-jidoteate-kakunin>
      <app-jidoteate-qr [item]="item" [hidden]='getCurrentSteper(6)'></app-jidoteate-qr>

      <div class="box-next" [hidden]="hiddenNext()">
        <a class="btn-next" (click)="onNext()" [class.btn-next-valid]="isChildFormValid">
          <div *ngIf="isChildFormValid; then enableButton; else disableButton"></div>
        </a>
      </div>
      <ng-template #enableButton>
        <a>{{ 'COMMON.NEXT_VALID' | translate }}</a>
      </ng-template>
      <ng-template #disableButton>
        <a>{{ 'COMMON.NEXT_INVALID' | translate }}</a>
      </ng-template>

      <div class="box-back" [hidden]="hiddenBack()">
        <a class="btn-back" (click)="onBack()">{{ 'COMMON.BACK' | translate }}</a>
      </div>

      <div class="box-move-top">
        <a class="btn-move-top" (click)="scrollTop()"></a>
      </div>

    </div>
  </div>
</div>


<!-- デバッグ用
<div>
  <h3>Debug</h3>
  <ng-container>
    <pre>{{ item | json }}</pre>
  </ng-container>
</div>
-->