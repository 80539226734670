import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { JidoTeate, Seikyusha, Haigusha, JidoMeisai, Jido, Koza } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { JidoteateService } from '@jidoteate/services/jidoteate.service';
import { JidoteateSeikyushaComponent } from '@jidoteate/components/jidoteate-seikyusha/jidoteate-seikyusha.component';
import { JidoteateHaigushaComponent } from '@jidoteate/components/jidoteate-haigusha/jidoteate-haigusha.component';
import { JidoteateJidosComponent } from '@jidoteate/components/jidoteate-jidos/jidoteate-jidos.component';
import { JidoteateKozaComponent } from '@jidoteate/components/jidoteate-koza/jidoteate-koza.component';
import { JidoteateKakuninComponent } from '@jidoteate/components/jidoteate-kakunin/jidoteate-kakunin.component';
import { JidoteateQrComponent } from '@jidoteate/components/jidoteate-qr/jidoteate-qr.component';
import { Step } from '@components/stepver/stepver.component';

import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Shimei } from '@components/shimei/shimei.component';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-jidoteate',
  templateUrl: './jidoteate.component.html',
  styleUrls: ['./jidoteate.component.css']
})
export class JidoteateComponent implements OnInit {
  @ViewChild(JidoteateSeikyushaComponent) seikyushaComponent: JidoteateSeikyushaComponent;
  @ViewChild(JidoteateHaigushaComponent) haigushaComponent: JidoteateHaigushaComponent;
  @ViewChild(JidoteateJidosComponent) jidosComponent: JidoteateJidosComponent;
  @ViewChild(JidoteateKozaComponent) kozaComponent: JidoteateKozaComponent;
  @ViewChild(JidoteateKakuninComponent) kakuninComponent: JidoteateKakuninComponent;
  @ViewChild(JidoteateQrComponent) qrComponent: JidoteateQrComponent;

  item: JidoTeate;
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;

  constructor(
    fb: FormBuilder, 
    private cd: ChangeDetectorRef,
    private jidoteateService: JidoteateService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    // 初期化
    this.item = {
      "fk201_snsi_bng": 0,
      "fk201_snsi_ymd": this.today,
      "fk201_snsi_name_kj": "",
      "fk201_snsi_name_kn": "",
      "fk201_snsi_umare_ymd": "",
      "fk201_snsi_yubinno": "",
      "fk201_snsi_addr_ken": "",
      "fk201_snsi_addr_city": "",
      "fk201_snsi_addr_town": "",
      "fk201_snsi_addr_banti": "",
      "fk201_snsi_addr_ktgk": "",
      "fk201_snsi_11_yubinno": "",
      "fk201_snsi_11_addr_ken": "",
      "fk201_snsi_11_addr_city": "",
      "fk201_snsi_11_addr_town": "",
      "fk201_snsi_11_addr_banti": "",
      "fk201_snsi_11_addr_ktgk": "",
      "fk201_snsi_job": "",
      "fk201_snsi_telno": "",
      "fk201_snsi_sex": "",
      "fk201_snsi_zokugara": "",
      "fk201_snsi_nenkin": "",
      "fk201_snsi_haigu": "",
      "fk201_haigu_name_kj": "",
      "fk201_haigu_name_kn": "",
      "fk201_haigu_umare_ymd": "",
      "fk201_haigu_yubinno": "",
      "fk201_haigu_addr_ken": "",
      "fk201_haigu_addr_city": "",
      "fk201_haigu_addr_town": "",
      "fk201_haigu_addr_banti": "",
      "fk201_haigu_addr_ktgk": "",
      "fk201_haigu_11_yubinno": "",
      "fk201_haigu_11_addr_ken": "",
      "fk201_haigu_11_addr_city": "",
      "fk201_haigu_11_addr_town": "",
      "fk201_haigu_11_addr_banti": "",
      "fk201_haigu_11_addr_ktgk": "",
      "fk201_haigu_job": "",
      "fk201_haigu_dokyo": "",
      "fk201_koza_bank_cd": "",
      "fk201_koza_bank_kj": "",
      "fk201_koza_siten_cd": "",
      "fk201_koza_siten_kj": "",
      "fk201_koza_syubetu": "",
      "fk201_koza_no": "",
      "fk201_koza_meigi_kn": "",
      "fk202s": [{
        "fk202_id": 0,
        "fk202_name_kj": "",
        "fk202_name_kn": "",
        "fk202_umare_ymd": "",
        "fk202_zokugara": "",
        "fk202_dokyo": "",
        "fk202_job": "",
        "fk202_kango": "",
        "fk202_seikei": "",
      }],
    };

    this.steps = this.jidoteateService.steps;

    // ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.seikyushaComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      // touchしたことにする
      this.seikyushaComponent.setTouched();
      if (environment.production && this.seikyushaComponent.pageForm.invalid) return;
      const seikyusha = this.seikyushaComponent.getSeikyusha();
      this.setSeikyusha(seikyusha);
      //請求者氏名をコピー元にセット
      this.haigushaComponent.setCopyShimei(this.item.fk201_snsi_name_kj, this.item.fk201_snsi_name_kn);
      //次の画面のステータスをセット
      this.isChildFormValid = this.haigushaComponent.pageForm.valid;
    }
    if (this.currentSteper == 2) {
      // touchしたことにする
      this.haigushaComponent.setTouched();
      if (environment.production && this.haigushaComponent.pageForm.invalid) return;
      const haigusha = this.haigushaComponent.getHaigusha();
      this.setHaigusha(haigusha);
      //請求者氏名をコピー元にセット
      this.jidosComponent.setCopyShimei(this.item.fk201_snsi_name_kj, this.item.fk201_snsi_name_kn);
      //次の画面のステータスをセット
      this.isChildFormValid = this.jidosComponent.pageForm.valid;
    }
    if (this.currentSteper == 3) {
      this.jidosComponent.setTouched();
      if (environment.production && this.jidosComponent.pageForm.invalid) return;

      const jidos = this.jidosComponent.getJidos();
      this.setJidos(jidos);
      //次の画面のステータスをセット
      this.isChildFormValid = this.kozaComponent.pageForm.valid;
    }
    if (this.currentSteper == 4) {
      this.kozaComponent.setTouched();
      if (environment.production && this.kozaComponent.pageForm.invalid) return;

      this.setKoza(this.kozaComponent.getKoza());

      this.isChildFormValid = this.qrComponent.createQRText();
    }
    if (this.currentSteper == 5) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;
    this.scrollTop();
  }

  onBack(): void {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;
  }

  hiddenBack() {
    if ([2, 3, 4, 5, 6].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3, 4, 5].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setSeikyusha(data: Seikyusha) {
    this.item.fk201_snsi_ymd = this.replaceNullEmpty(data.snsi_ymd);
    this.item.fk201_snsi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.snsi_smi));
    this.item.fk201_snsi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.snsi_smi));
    this.item.fk201_snsi_umare_ymd = this.replaceNullEmpty(data.snsi_umare_ymd);
    this.item.fk201_snsi_yubinno = this.replaceNullEmpty(data.snsi_address.zipcode);
    this.item.fk201_snsi_addr_ken = this.replaceNullEmpty(data.snsi_address.address.ken);
    this.item.fk201_snsi_addr_city = this.replaceNullEmpty(data.snsi_address.address.city);
    this.item.fk201_snsi_addr_town = this.replaceNullEmpty(data.snsi_address.address.town);
    this.item.fk201_snsi_addr_banti = this.replaceNullEmpty(data.snsi_address.banti);
    this.item.fk201_snsi_addr_ktgk = this.replaceNullEmpty(data.snsi_address.ktgk);

    this.item.fk201_snsi_11_yubinno = this.replaceNullEmpty(data.snsi_11_address.zipcode);
    this.item.fk201_snsi_11_addr_ken = this.replaceNullEmpty(data.snsi_11_address.address.ken);
    this.item.fk201_snsi_11_addr_city = this.replaceNullEmpty(data.snsi_11_address.address.city);
    this.item.fk201_snsi_11_addr_town = this.replaceNullEmpty(data.snsi_11_address.address.town);
    this.item.fk201_snsi_11_addr_banti = this.replaceNullEmpty(data.snsi_11_address.banti);
    this.item.fk201_snsi_11_addr_ktgk = this.replaceNullEmpty(data.snsi_11_address.ktgk);

    this.item.fk201_snsi_job = this.replaceNullEmpty(data.snsi_job);
    this.item.fk201_snsi_telno = this.replaceNullEmpty(data.snsi_telno);
    this.item.fk201_snsi_sex = this.replaceNullEmpty(data.snsi_sex);
    this.item.fk201_snsi_zokugara = this.replaceNullEmpty(data.snsi_zokugara);
    this.item.fk201_snsi_nenkin = this.replaceNullEmpty(data.snsi_nenkin);
  }

  setHaigusha(data: Haigusha) {
    this.item.fk201_snsi_haigu = this.replaceNullEmpty(data.snsi_haigu);

    this.item.fk201_haigu_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.haigu_smi));
    this.item.fk201_haigu_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.haigu_smi));
    this.item.fk201_haigu_umare_ymd = this.replaceNullEmpty(data.haigu_umare_ymd);

    this.item.fk201_haigu_yubinno = this.replaceNullEmpty(data.haigu_address.zipcode);
    this.item.fk201_haigu_addr_ken = this.replaceNullEmpty(data.haigu_address.address.ken);
    this.item.fk201_haigu_addr_city = this.replaceNullEmpty(data.haigu_address.address.city);
    this.item.fk201_haigu_addr_town = this.replaceNullEmpty(data.haigu_address.address.town);
    this.item.fk201_haigu_addr_banti = this.replaceNullEmpty(data.haigu_address.banti);
    this.item.fk201_haigu_addr_ktgk = this.replaceNullEmpty(data.haigu_address.ktgk);

    this.item.fk201_haigu_11_yubinno = this.replaceNullEmpty(data.haigu_11_address.zipcode);
    this.item.fk201_haigu_11_addr_ken = this.replaceNullEmpty(data.haigu_11_address.address.ken);
    this.item.fk201_haigu_11_addr_city = this.replaceNullEmpty(data.haigu_11_address.address.city);
    this.item.fk201_haigu_11_addr_town = this.replaceNullEmpty(data.haigu_11_address.address.town);
    this.item.fk201_haigu_11_addr_banti = this.replaceNullEmpty(data.haigu_11_address.banti);
    this.item.fk201_haigu_11_addr_ktgk = this.replaceNullEmpty(data.haigu_11_address.ktgk);

    this.item.fk201_haigu_dokyo = this.replaceNullEmpty(data.haigu_dokyo);
    this.item.fk201_haigu_job = this.replaceNullEmpty(data.haigu_job);
  }

  setJidos(datas: JidoMeisai[]) {
    const jidos = [];
    datas.forEach((data) => {
      const jido = {
        "fk202_id": this.replaceNullZero(data.id),
        "fk202_name_kj": this.replaceNullEmpty(getNameKjFromShimei(data.shimei)),
        "fk202_name_kn": this.replaceNullEmpty(getNameKnFromShimei(data.shimei)),
        "fk202_umare_ymd": this.replaceNullEmpty(data.umare_ymd),
        "fk202_zokugara": this.replaceNullEmpty(data.zokugara),
        "fk202_dokyo": this.replaceNullEmpty(data.doukyo),
        "fk202_job": this.replaceNullEmpty(data.job),
        "fk202_kango": this.replaceNullEmpty(data.kango),
        "fk202_seikei": this.replaceNullEmpty(data.seikei)
      };
      jidos.push(jido);
    });
    this.item.fk202s = jidos;
  }

  setKoza(data: Koza) {
    this.item.fk201_koza_bank_cd = this.replaceNullEmpty(data.koza_bank_cd);
    this.item.fk201_koza_bank_kj = this.replaceNullEmpty(data.koza_bank_kj);
    this.item.fk201_koza_siten_cd = this.replaceNullEmpty(data.koza_siten_cd);
    this.item.fk201_koza_siten_kj = this.replaceNullEmpty(data.koza_siten_kj);
    this.item.fk201_koza_syubetu = this.replaceNullEmpty(data.koza_syubetu);
    this.item.fk201_koza_no = this.replaceNullEmpty(data.koza_no);
    this.item.fk201_koza_meigi_kn = this.replaceNullEmpty(data.koza_meigi_kn);
  }

  replaceNullZero(value) {
    return value == null ? 0 : value;
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

}
