import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export class Jyukiido {
    sj201_snsi_bng: number;
    sj201_snsi_ymd: string;
    sj201_snsi_syurui: string;
    sj201_snsi_name_kj: string;
    sj201_snsi_name_kn: string;
    sj201_snsi_telno: string;
    sj201_dairi_yubinno: string;
    sj201_dairi_addr_ken: string;
    sj201_dairi_addr_city: string;
    sj201_dairi_addr_town: string;
    sj201_dairi_addr_banti: string;
    sj201_dairi_addr_ktgk: string;
    sj201_dairi_knki: string;
    sj201_ido_ymd: string;
    sj201_cyouhyo_cd: string;
    sj201_old_yubinno: string;
    sj201_old_addr_ken: string;
    sj201_old_addr_city: string;
    sj201_old_addr_town: string;
    sj201_old_addr_banti: string;
    sj201_old_addr_ktgk: string;
    sj201_old_setai_name_kj: string;
    sj201_old_setai_name_kn: string;
    sj201_new_yubinno: string;
    sj201_new_addr_ken: string;
    sj201_new_addr_city: string;
    sj201_new_addr_town: string;
    sj201_new_addr_banti: string;
    sj201_new_addr_ktgk: string;
    sj201_new_setai_name_kj: string;
    sj201_new_setai_name_kn: string;
    sj202s: Transfer[];
}

export class Transfer {
    sj202_id: number;
    sj202_name_kj: string;
    sj202_name_kn: string;
    sj202_umare_ymd: string;
    sj202_sex: string;
    sj202_zokugara: string;
    sj202_zokugara_sonota: string
}

export class Menu {
    snsi_syurui: string;
    constructor(snsi_syurui: string) {
        this.snsi_syurui = snsi_syurui;
    }
}

export class Shinsei {
    snsi_smi: Shimei;
    snsi_telno: string;
    dairiFlg: boolean;
    dairi_address: ZipcodeAddress;
    dairi_knki: string;
    ido_ymd: string;
    cyouhyo_cd: string[];
    constructor(snsi_smi: Shimei, snsi_telno: string, dairiFlg: boolean, dairi_address: ZipcodeAddress, dairi_knki: string, ido_ymd: string, cyouhyo_cd: string[]) {
        this.snsi_smi = snsi_smi;
        this.snsi_telno = snsi_telno;
        this.dairiFlg = dairiFlg;
        this.dairi_address = dairi_address;
        this.dairi_knki = dairi_knki;
        this.ido_ymd = ido_ymd;
        this.cyouhyo_cd = cyouhyo_cd;
    }
}

export class Zenjyusyo {
    old_address: ZipcodeAddress;
    old_setai_smi: Shimei;
    constructor(old_address: ZipcodeAddress, old_setai_smi: Shimei) {
        this.old_address = old_address;
        this.old_setai_smi = old_setai_smi;
    }
}

export class Shinjyusyo {
    new_address: ZipcodeAddress;
    new_setai_smi: Shimei;
    constructor(new_address: ZipcodeAddress, new_setai_smi: Shimei) {
        this.new_address = new_address;
        this.new_setai_smi = new_setai_smi;
    }
}

export class Idosya {
    id: number;
    ido_smi: Shimei;
    umare_ymd: string;
    sex: string;
    zokugara: string;
    zokugara_sonota: string;
    constructor(id: number, ido_smi: Shimei, umare_ymd: string, sex: string, zokugara: string, zokugara_sonota: string) {
        this.id = id;
        this.ido_smi = ido_smi;
        this.umare_ymd = umare_ymd;
        this.sex = sex;
        this.zokugara = zokugara;
        this.zokugara_sonota = zokugara_sonota;
    }
}

