import { ZipcodeAddress } from '@components/zipcode/zipcode.component';
import { Shimei } from '@components/shimei/shimei.component';

export class Jyuminhyou {
    sj101_snsi_bng: number;
    sj101_snsi_ymd: string;
    sj101_tisy_name_kj: string;
    sj101_tisy_name_kn: string;
    sj101_tisy_umare_ymd: string;
    sj101_tisy_yubinno: string;
    sj101_tisy_addr_ken: string;
    sj101_tisy_addr_city: string;
    sj101_tisy_addr_town: string;
    sj101_tisy_addr_banti: string;
    sj101_tisy_addr_ktgk: string;
    sj101_tisy_telno: string;
    sj101_dairi_name_kj: string;
    sj101_dairi_name_kn: string;
    sj101_dairi_yubinno: string;
    sj101_dairi_addr_ken: string;
    sj101_dairi_addr_city: string;
    sj101_dairi_addr_town: string;
    sj101_dairi_addr_banti: string;
    sj101_dairi_addr_ktgk: string;
    sj101_dairi_knki: string;
    sj101_dairi_telno: string;
    sj101_siky_name_kj: string;
    sj101_siky_name_kn: string;
    sj101_siky_yubinno: string;
    sj101_siky_addr_ken: string;
    sj101_siky_addr_city: string;
    sj101_siky_addr_town: string;
    sj101_siky_addr_banti: string;
    sj101_siky_addr_ktgk: string;
    sj101_siky_knki: string;
    sj101_siky_telno: string;
    sj101_siky_riyu: string;
    sj101_ichibu_name: string;
    sj101_youto: string;
    sj101_zokusei: string;
    sj101_mynumber_taisyosya: string
    sj102s: Cyouhyo[];
}

export class Cyouhyo {
    sj102_snsi_bng: number;
    sj102_cyouhyo_cd: string;
    sj102_maisuu: string;
    sj102_ichibu_name: string;
}

export class Shinsei {
    tisy_smi: Shimei;
    tisy_umare_ymd: string;
    tisy_address: ZipcodeAddress;
    tisy_telno: string;
    dairiKbn: string;
    dairi_smi: Shimei;
    dairi_address: ZipcodeAddress;
    dairi_knki: string;
    dairi_telno: string;
    sikyFlg: boolean;
    siky_smi: Shimei;
    siky_address: ZipcodeAddress;
    siky_telno: string;
    constructor(tisy_smi: Shimei,
        tisy_umare_ymd: string,
        tisy_address: ZipcodeAddress,
        tisy_telno: string,
        dairiKbn: string,
        dairi_smi: Shimei,
        dairi_address: ZipcodeAddress,
        dairi_knki: string,
        dairi_telno: string,
        sikyFlg: boolean,
        siky_smi: Shimei,
        siky_address: ZipcodeAddress,
        siky_telno: string
    ) {
        this.tisy_smi = tisy_smi;
        this.tisy_umare_ymd = tisy_umare_ymd;
        this.tisy_address = tisy_address;
        this.tisy_telno = tisy_telno;
        this.dairiKbn = dairiKbn;
        this.dairi_smi = dairi_smi;
        this.dairi_address = dairi_address;
        this.dairi_knki = dairi_knki;
        this.dairi_telno = dairi_telno;
        this.sikyFlg = sikyFlg;
        this.siky_smi = siky_smi;
        this.siky_address = siky_address;
        this.siky_telno = siky_telno;
    }
}

export class Syoumeisyo {
    id: number;
    cyouhyo_cd: string;
    maisuu: string;
    ichibu_name: string;
    constructor(id: number, cyouhyo_cd: string, maisuu: string, ichibu_name: string) {
        this.id = id;
        this.cyouhyo_cd = cyouhyo_cd;
        this.maisuu = maisuu;
        this.ichibu_name = ichibu_name;
    }
}

export class SeikyuOption {
    youto: string[];
    zokugara: string;
    mynumber: string;
    honseki: string;
    gaijinkisaiObjects: object[];
    constructor(youto: string[], zokugara: string, mynumber: string, honseki: string, gaijinkisaiObjects: object[]) {
        this.youto = youto;
        this.zokugara = zokugara;
        this.mynumber = mynumber;
        this.honseki = honseki;
        this.gaijinkisaiObjects = gaijinkisaiObjects;
    }
}