import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormGroupDirective, ControlContainer, AbstractControl } from '@angular/forms';
import { CustomValidator } from '../../../common/utility/custom-validator';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigService } from '@services/config/config.service';

export class Shimei {
  sei_kj: string;
  mei_kj: string;
  sei_kn: string;
  mei_kn: string;

  constructor(sei_kj: string, mei_kj: string, sei_kn: string, mei_kn: string) {
    this.sei_kj = sei_kj;
    this.mei_kj = mei_kj;
    this.sei_kn = sei_kn;
    this.mei_kn = mei_kn;
  }
}

@Component({
  selector: 'app-shimei',
  templateUrl: './shimei.component.html',
  styleUrls: ['./shimei.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ShimeiComponent implements OnInit {
  @Input() title: string;
  @Input() buttonVisible: boolean;
  @Input() buttonTitle: string;
  @Input() copyShimei: Shimei;
  @Input() fgName: string;
  @Input() ckName: string;
  @Input() showKbns: String[]=['1', '2'];
  @Input() defaltKbn: String;
  parentForm: FormGroup;
  faExclamationTriangle = faExclamationTriangle;
  shimei_kbns: Array<{ value: string; label: string; }>;
  subscriptions;
  fontCSS= 'kbnfont-big';

  @Input() hiddenKn: boolean | null;

  constructor(private fgd: FormGroupDirective, private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.shimei_kbns = Object(config.get("SHIMEI_KBN"));
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);

      if (ret.lang=='en' || ret.lang=='vi'){
        this.fontCSS = 'kbnfont-small';
      };
    });
  }

  ngOnInit(): void {
    this.parentForm = this.fgd.form;

    this.parentForm.addControl(this.fgName, this.fb.group({
      sei_kj: [null, [Validators.required, CustomValidator.zenkaku]],
      mei_kj: [null, [Validators.required, CustomValidator.zenkaku]],
      sei_kn: [null, [Validators.required, CustomValidator.katakana]],
      mei_kn: [null, [Validators.required, CustomValidator.katakana]],
      shimei_kj_all: [null, [Validators.required, CustomValidator.zenkaku]],
      shimei_kn_all: [null, [CustomValidator.katakana]],
      shimei_kbn: [this.defaltKbn],
    }));

    //shimei_kj_allをsei_kjに同期させる
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.controlName('shimei_kj_all').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.controlName('sei_kj').setValue(data);
      }));

    //shimei_kn_allをsei_knに同期させる
    this.subscriptions.add(this.controlName('shimei_kn_all').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.controlName('sei_kn').setValue(data);
      }));

    this.setValidate();
  }

  onCopy(): void {
    this.controlName('sei_kj').setValue(this.copyShimei.sei_kj);
    this.controlName('mei_kj').setValue(this.copyShimei.mei_kj);
    this.controlName('sei_kn').setValue(this.copyShimei.sei_kn);
    this.controlName('mei_kn').setValue(this.copyShimei.mei_kn);
    this.controlName('shimei_kj_all').setValue(this.copyShimei.sei_kj);
    this.controlName('shimei_kn_all').setValue(this.copyShimei.sei_kn);
  }

  controlName(key: 'sei_kj' | 'mei_kj' | 'sei_kn' | 'mei_kn' | 'shimei_kj_all' | 'shimei_kn_all' | 'shimei_kbn'): AbstractControl {
    return (this.parentForm.get(this.fgName).get(key));
  }

  clear(): void {
    this.controlName('sei_kj').setValue('');
    this.controlName('mei_kj').setValue('');
    this.controlName('sei_kn').setValue('');
    this.controlName('mei_kn').setValue('');
    this.controlName('shimei_kj_all').setValue('');
    this.controlName('shimei_kn_all').setValue('');
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix + this.fgName + this.ckName;
  }

  getName() {
    //必ずフォーム内で一意になるようにする
    return this.fgName + this.ckName;
  }

  onCheckChange() {
    //値をクリア
    this.clear();
    //バリデーション
    this.setValidate();
  }

  setValidate() {
    if (this.controlName('shimei_kbn').value == '1') {
      //日本人
      this.controlName('sei_kj').enable();
      this.controlName('mei_kj').enable();
      this.controlName('sei_kn').enable();
      this.controlName('mei_kn').enable();
      this.controlName('shimei_kj_all').disable();
      this.controlName('shimei_kn_all').disable();
    }
    else if (this.controlName('shimei_kbn').value == '2') {
      //外国人
      this.controlName('sei_kj').disable();
      this.controlName('mei_kj').disable();
      this.controlName('sei_kn').disable();
      this.controlName('mei_kn').disable();
      this.controlName('shimei_kj_all').enable();
      this.controlName('shimei_kn_all').enable();
    }

    if(this.hiddenKn) {
      this.controlName('sei_kn').disable();
      this.controlName('mei_kn').disable();
      this.controlName('shimei_kn_all').disable();
    }
  }

  setDisable() {
    this.controlName('sei_kj').disable();
    this.controlName('mei_kj').disable();
    this.controlName('sei_kn').disable();
    this.controlName('mei_kn').disable();
    this.controlName('shimei_kj_all').disable();
    this.controlName('shimei_kn_all').disable();
  }

}
