import { Component, OnInit, Input } from '@angular/core';
import { Inkantouroku } from '@inkantouroku/services/item';
import { EncryptService } from '@services/encrypt/encrypt.service';
import { ConfigService } from '@services/config/config.service';
import * as QRCode from '../../../../../node_modules/qrcode';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faPlusCircle, faMinusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inkantouroku-qr',
  templateUrl: './inkantouroku-qr.component.html',
  styleUrls: ['./inkantouroku-qr.component.css']
})
export class InkantourokuQrComponent implements OnInit {
  @Input() item: Inkantouroku;
  mochimono: any;
  mochimono_file_header: string;
  title: string;
  uketuke: any;
  image_url;
  original_qr_size = 200;
  font_size = 10;
  margin = 30;
  filename: string;
  filenameMochimono: string;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faDownload = faDownload;
  isWindows: boolean;
  qr_error: string;

  constructor(private encryptService: EncryptService, public translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      const isDairi = !!this.item?.sj121_dairi_addr_ken;
      this.title = ret.translations.INKANTOUROKU.TITLE_MAIN;
      this.mochimono = isDairi
        ? ret.translations.INKANTOUROKU.QR.MOCHIMONO_LIST_DAIRI
        : ret.translations.INKANTOUROKU.QR.MOCHIMONO_LIST;
      this.mochimono_file_header = isDairi
        ? ret.translations.INKANTOUROKU.QR.MOCHIMONO_FILE_HEADER_DAIRI
        : ret.translations.INKANTOUROKU.QR.MOCHIMONO_FILE_HEADER;
      this.uketuke = ret.translations.INKANTOUROKU.QR.UKETUKE_LIST;
      this.qr_error = ret.translations.COMMON.QR_ERROR;
    });
  }

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    this.isWindows = userAgent.toLowerCase().includes('windows');
  }

  createQRText(): boolean {
    const isDairi = !!this.item?.sj121_dairi_addr_ken;
    this.translate
      .get(
        isDairi
          ? "INKANTOUROKU.QR.MOCHIMONO_LIST_DAIRI"
          : "INKANTOUROKU.QR.MOCHIMONO_LIST"
      )
      .subscribe((mochimono: any) =>
        this.mochimono = mochimono
      );
    this.translate
      .get(
        isDairi
          ? "INKANTOUROKU.QR.MOCHIMONO_FILE_HEADER_DAIRI"
          : "INKANTOUROKU.QR.MOCHIMONO_FILE_HEADER"
      )
      .subscribe(
        (mochimono_file_header: string) =>
          (this.mochimono_file_header = mochimono_file_header)
      );

    const qrStr =
      //帳票番号
      'sj121'
      //申請
      + "," + this.item.sj121_snsi_yubinno
      + "," + this.item.sj121_snsi_addr_ken
      + "," + this.item.sj121_snsi_addr_city
      + "," + this.item.sj121_snsi_addr_town
      + "," + this.item.sj121_snsi_addr_banti
      + "," + this.item.sj121_snsi_addr_ktgk
      + "," + this.item.sj121_snsi_name_kj
      + "," + this.item.sj121_snsi_name_kn
      + "," + this.item.sj121_snsi_telno
      + "," + this.item.sj121_snsi_umare_ymd
      + "," + this.item.sj121_dairi_yubinno
      + "," + this.item.sj121_dairi_addr_ken
      + "," + this.item.sj121_dairi_addr_city
      + "," + this.item.sj121_dairi_addr_town
      + "," + this.item.sj121_dairi_addr_banti
      + "," + this.item.sj121_dairi_addr_ktgk
      + "," + this.item.sj121_dairi_name_kj
      + "," + this.item.sj121_dairi_name_kn
      + "," + this.item.sj121_dairi_telno
      + "," + this.item.sj121_hosho_yubinno
      + "," + this.item.sj121_hosho_addr_ken
      + "," + this.item.sj121_hosho_addr_city
      + "," + this.item.sj121_hosho_addr_town
      + "," + this.item.sj121_hosho_addr_banti
      + "," + this.item.sj121_hosho_addr_ktgk
      + "," + this.item.sj121_hosho_name_kj
      + "," + this.item.sj121_hosho_name_kn
      + "," + this.item.sj121_hosho_telno
      + "," + this.item.sj121_hosho_touroku_no
      + "," + this.item.sj121_honnin_kakunin
      + "," + this.item.sj121_honnin_sonota
      + "," + this.item.sj121_inkansyoumei_maisuu;

    const qrText = this.encryptService.getQREncrypt(qrStr);
    if (qrText.length > 2000) {
      alert(this.qr_error);
      return false;
    }

    QRCode.toDataURL(qrText, { scale: 2, errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      var img = document.getElementById('qr-image');
      (img as any).src = url;
      (img as any).alt = "inkantouroku-" + getFormattedCurrentDateJST('yyyyMMddHHmmss');
    });

    this.filename = "inkantouroku-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".png";
    this.filenameMochimono = "inkantouroku-" + getFormattedCurrentDateJST('yyyyMMddHHmmss') + ".txt";
    return true;
  }

  downloadCanvas() {
    var img = document.getElementById('qr-image');
    let link = document.getElementById('hiddenLink');
    (link as any).href = (img as any).src;
    link.click();
  }

  downloadMochimono(): void {
    const lines: Array<string> = [];
    lines.push(this.mochimono_file_header);
    lines.push('');
    const mochimonoList: Array<{ title: string; meisai: Array<string>; }> = this.mochimono;
    mochimonoList.forEach(u => {
      lines.push(`・${u.title}`);
      u.meisai.forEach(v => lines.push(v));
      lines.push('');
    });

    const textdata = lines.join(this.isWindows ? '\r\n' : '\n');

    const link = document.getElementById('hiddenMochimonoLink') as HTMLAnchorElement;
    link.href = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(textdata)));
    link.click();
  }

}
