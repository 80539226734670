import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Iryokodomo, Shinsei, TaisyouJido, Hoken } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IryokodomoShinseiComponent } from '@iryokodomo/components/iryokodomo-shinsei/iryokodomo-shinsei.component';
import { IryokodomoTaisyoujidouComponent } from '@iryokodomo/components/iryokodomo-taisyoujidou/iryokodomo-taisyoujidou.component';
import { IryokodomoHokenComponent } from '@iryokodomo/components/iryokodomo-hoken/iryokodomo-hoken.component';
import { IryokodomoKakuninComponent } from '@iryokodomo/components/iryokodomo-kakunin/iryokodomo-kakunin.component';
import { IryokodomoQrComponent } from '@iryokodomo/components/iryokodomo-qr/iryokodomo-qr.component';
import { IryokodomoService } from '@iryokodomo/services/iryokodomo.service';
import { Step } from '@components/stepver/stepver.component';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-iryokodomo',
  templateUrl: './iryokodomo.component.html',
  styleUrls: ['./iryokodomo.component.css']
})
export class IryokodomoComponent implements OnInit {
  @ViewChild(IryokodomoShinseiComponent) shinseiComponent: IryokodomoShinseiComponent;
  @ViewChild(IryokodomoTaisyoujidouComponent) taisyoujidouComponent: IryokodomoTaisyoujidouComponent;
  @ViewChild(IryokodomoHokenComponent) hokenComponent: IryokodomoHokenComponent;
  @ViewChild(IryokodomoQrComponent) qrComponent: IryokodomoQrComponent;

  item: Iryokodomo;
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;

  constructor(
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private iryokodomoService: IryokodomoService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    //初期化
    this.item = {
      "fk101_snsi_bng": 0,
      "fk101_snsi_ymd": this.today,
      "fk101_snsi_yubinno": "",
      "fk101_snsi_addr_ken": "",
      "fk101_snsi_addr_city": "",
      "fk101_snsi_addr_town": "",
      "fk101_snsi_addr_banti": "",
      "fk101_snsi_addr_ktgk": "",
      "fk101_snsi_home_tel_no": "",
      "fk101_snsi_cell_tel_no": "",
      "fk101_snsi_namekj": "",
      "fk101_snsi_namekn": "",
      "fk101_snsi_birthday": "",
      "fk101_snsi_zokugara": "",
      "fk101_snsi_name_tusho": "",
      "fk101_snsi_nenkin": "",
      "fk101_hoken_hhs_kigo": "",
      "fk101_hoken_hhs_bango": "",
      "fk101_hoken_hhs_name": "",
      "fk101_hoken_kodomoskk_date": "",
      "fk101_hoken_hoken_name": "",
      "fk101_hoken_hoken_bango": "",
      "fk101_hoken_hoken_syurui": "",
      "fk102s": [{
        "fk102_id": 1,
        "fk102_jido_namekj": "",
        "fk102_jido_namekn": "",
        "fk102_jido_sex": "",
        "fk102_jido_birthday": "",
        "fk102_jido_seiho": "",
      }]
    };

    this.steps = this.iryokodomoService.steps;

    //ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.shinseiComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      //toutchしたことにする
      this.shinseiComponent.setTouched();
      if (environment.production && this.shinseiComponent.pageForm.invalid) return;
      const shinsei = this.shinseiComponent.getShinsei();
      this.setShinsei(shinsei);
      //申請者氏名をコピー元にセット
      this.taisyoujidouComponent.setCopyShimei(this.item.fk101_snsi_namekj, this.item.fk101_snsi_namekn);
      //次の画面のステータスをセット
      this.isChildFormValid = this.taisyoujidouComponent.pageForm.valid;
    }
    if (this.currentSteper == 2) {
      //toutchしたことにする
      this.taisyoujidouComponent.setTouched();
      if (environment.production && this.taisyoujidouComponent.pageForm.invalid) return;

      const jidos = this.taisyoujidouComponent.getJidos();
      this.setJidos(jidos);
      //次の画面のステータスをセット
      this.isChildFormValid = this.hokenComponent.pageForm.valid;
    }
    if (this.currentSteper == 3) {
      //toutchしたことにする
      this.hokenComponent.setTouched();
      if (environment.production && this.hokenComponent.pageForm.invalid) return;
      const hoken = this.hokenComponent.getHoken();
      this.setHoken(hoken);

      this.isChildFormValid = this.qrComponent.createQRText();
    }
    if (this.currentSteper == 4) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;
    this.scrollTop();
  }

  onBack() {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;
  }

  hiddenBack() {
    if ([2, 3, 4, 5].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3, 4].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setShinsei(data: Shinsei) {
    //this.item.fk101_snsi_ymd = data.snsi_ymd;
    this.item.fk101_snsi_yubinno = this.replaceNullEmpty(data.snsi_address.zipcode);
    this.item.fk101_snsi_addr_ken = this.replaceNullEmpty(data.snsi_address.address.ken);
    this.item.fk101_snsi_addr_city = this.replaceNullEmpty(data.snsi_address.address.city);
    this.item.fk101_snsi_addr_town = this.replaceNullEmpty(data.snsi_address.address.town);
    this.item.fk101_snsi_addr_banti = this.replaceNullEmpty(data.snsi_address.banti);
    this.item.fk101_snsi_addr_ktgk = this.replaceNullEmpty(data.snsi_address.ktgk);
    this.item.fk101_snsi_home_tel_no = this.replaceNullEmpty(data.snsi_home_tel_no);
    this.item.fk101_snsi_cell_tel_no = this.replaceNullEmpty(data.snsi_cell_tel_no);
    this.item.fk101_snsi_namekj = this.replaceNullEmpty(getNameKjFromShimei(data.snsi_smi));
    this.item.fk101_snsi_namekn = this.replaceNullEmpty(getNameKnFromShimei(data.snsi_smi));
    this.item.fk101_snsi_birthday = this.replaceNullEmpty(data.snsi_birthday);
    this.item.fk101_snsi_zokugara = this.replaceNullEmpty(data.snsi_zokugara);
    //this.item.fk101_snsi_name_tusho= this.replaceNullEmpty(data.snsi_name_tusho);
    this.item.fk101_snsi_nenkin = this.replaceNullEmpty(data.snsi_nenkin);
  }

  setJidos(datas: TaisyouJido[]) {
    const transfers = [];
    datas.forEach((data) => {
      const transfer = {
        "fk102_id": this.replaceNullZero(data.id),
        "fk102_jido_namekj": this.replaceNullEmpty(getNameKjFromShimei(data.jido_smi)),
        "fk102_jido_namekn": this.replaceNullEmpty(getNameKnFromShimei(data.jido_smi)),
        "fk102_jido_sex": this.replaceNullEmpty(data.sex),
        "fk102_jido_birthday": this.replaceNullEmpty(data.umare_ymd),
        "fk102_jido_seiho": this.replaceNullEmpty(data.seiho),
      };
      transfers.push(transfer);
    });
    this.item.fk102s = transfers;
  }

  setHoken(data: Hoken) {
    this.item.fk101_hoken_hhs_kigo = this.replaceNullEmpty(data.hoken_hhs_kigo);
    this.item.fk101_hoken_hhs_bango = this.replaceNullEmpty(data.hoken_hhs_bango);
    this.item.fk101_hoken_hhs_name = this.replaceNullEmpty(data.hoken_hhs_name);
    this.item.fk101_hoken_kodomoskk_date = this.replaceNullEmpty(data.hoken_kodomoskk_date);
    this.item.fk101_hoken_hoken_name = this.replaceNullEmpty(data.hoken_hoken_name);
    this.item.fk101_hoken_hoken_bango = this.replaceNullEmpty(data.hoken_hhs_bango);
    this.item.fk101_hoken_hoken_syurui = this.replaceNullEmpty(data.hoken_hoken_syurui);
  }
  
  replaceNullZero(value) {
    return value == null ? 0 : value;
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

}
