import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: any;
  cyouhyouConfig: any = [];
  constructor(private http: HttpClient) { }
  configPath: string = "/config/"

  get(key: any) {
    return this.config[key];
  }

  getCyouhyouConfig(name:string){
    return this.cyouhyouConfig.find(conf => conf["NAME"] === name)["CONFIG"];
  }

  async load() {
    const configJsonPath = this.configPath + 'config.json';
    await lastValueFrom(this.http.get( configJsonPath))
      .then(async (res: any) => {
        const jititai_name = location.hostname.split(".")[0];
        const config = res;
        const configFolder = config.find(j => j.HOST == jititai_name)["CONFIG"];
        const configFolderPath = this.configPath + configFolder;
        const configCommonPath = configFolderPath + '/config-common.json';

        await lastValueFrom(this.http.get(configCommonPath)).then( async (commonConfig:any) => {
          this.config = commonConfig;
          const configList = this.config["CONFIG"];
          for(let i = 0; configList.length > i; i++) {
            const configCyouhyouPath = configFolderPath + '/' + configList[i]["FILE"] + '.json'
            await lastValueFrom(this.http.get(configCyouhyouPath)).then( async (cyouhyouConfig:any) => {
              const cyouhyouConfigObj = {
                "NAME": configList[i]["NAME"],
                "CONFIG": cyouhyouConfig
              }
              this.cyouhyouConfig.push(cyouhyouConfigObj);
            });
          };
        });
      })
      .catch(this.errorHandler);
  }

  private errorHandler(err) {
    console.log('Error occured.', err);
    return Promise.reject(err.message || err);
  }

}