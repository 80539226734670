import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { CustomValidator, ValidZipcode } from '@utility/custom-validator';
import { GeoApiService } from '@services/geo-api/geo-api.service';
import { faExclamationTriangle, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import { ConfigService } from '@services/config/config.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


export class ZipcodeAddress {
  zipcode: string;
  address: Address;
  banti: string;
  ktgk: string;
  faUserSlash = faUserSlash;

  constructor(zipcode: string, address: Address, banti: string, ktgk: string) {
    this.zipcode = zipcode;
    this.address = address;
    this.banti = banti;
    this.ktgk = ktgk;
  }
}

export class Address {
  ken: string;
  city: string;
  town: string;

  constructor(ken: string, city: string, town: string) {
    this.ken = ken;
    this.city = city;
    this.town = town;
  }
}

@Component({
  selector: 'app-zipcode',
  templateUrl: './zipcode.component.html',
  styleUrls: ['./zipcode.component.css']
})
export class ZipcodeComponent implements OnInit {
  @Input() fgName: string;
  @Input() ckName: string;
  @Input() showKbns: String[] = [];
  @Input() defaltKbn: String;

  parentForm: FormGroup;
  addresses: Address[] = [];
  showFlg: boolean = false;
  defaultAddress: string;
  faExclamationTriangle = faExclamationTriangle;
  addr_kbns;
  subscriptions;
  pre_zipcode: string;

  constructor(private fgd: FormGroupDirective, private geoApiService: GeoApiService, private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
      this.addr_kbns = ret.translations.ZIPCODE.ADDR_KBN;
    });
  }

  ngOnInit(): void {
    this.parentForm = this.fgd.form;

    this.parentForm.addControl(this.fgName, this.fb.group({
      zipcode: [null, [Validators.required, CustomValidator.numeric, Validators.minLength(7)]],
      address: [this.fb.group({
        ken: null,
        city: null,
        town: null,
      })],
      banti: [null, [Validators.required, CustomValidator.banti]],
      ktgk: [null, [CustomValidator.zenkaku]],
      address_all: [null, [Validators.required, CustomValidator.zenkaku]],
      addr_kbn: [this.defaltKbn],
      dsp_address: [null, [Validators.required]]
    },
      {
        validator: ValidZipcode(this.geoApiService, this.config)
      }
    ));

    //address_allをaddress.kenに同期させる
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.controlName('address_all').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        this.controlName('address').setValue(new Address(data, null, null));
      }));

    //dsp_addressをaddressに同期させる
    this.subscriptions.add(this.controlName('dsp_address').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(data => {
        if (data) {
          this.controlName('address').setValue(new Address(data.ken, data.city, data.town));
        }
        else {
          this.controlName('address').setValue(new Address(null, null, null));
        };
      }));

    this.setValidate();
  }

  onKeyZipcode(event: any): void {
    if ((String(this.controlName('zipcode').value).length === 7) && (this.pre_zipcode != String(this.controlName('zipcode').value))) {
      //同じは検索しない
      this.onSearch();
    }
    else if (String(this.controlName('zipcode').value).length != 7)
    {
      //7桁以下にしたら次回は強制検索
      this.pre_zipcode = null;
      this.showFlg = false;
    }

    if (!this.showFlg) {
      this.defaultAddress = null;
      this.addresses = [];
      this.controlName('dsp_address').patchValue(null);
    }
  }

  onSearch(): void {
    if (this.controlName('zipcode').invalid) return;

    //クリア
    this.addresses = [];
    this.controlName('dsp_address').patchValue(null);
    this.controlName('banti').setValue('');
    this.controlName('ktgk').setValue('');
    this.controlName('address_all').setValue('');

    this.pre_zipcode = this.controlName('zipcode').value;
    this.geoApiService.getAddressByPostal(this.pre_zipcode).then(result => {
      if (result == null) {
        this.controlName('zipcode').setErrors({ 'notExists': true });
        //見つからない場合は、設定しない
        this.pre_zipcode = null;
        this.showFlg = false;
        return;
      }

      for (let i = 0; result.length > i; i++) {
        const location = {
          ken: result[i].prefecture,
          city: result[i].city,
          town: result[i].town
        };

        this.addresses.push(location);
      }
      this.showFlg = true;
    });
  }

  getFullAddress(address: Address): string {
    return address.ken + address.city + address.town;
  }

  controlName(key: 'zipcode' | 'address' | 'banti' | 'ktgk' | 'addr_kbn' | 'address_all' | 'dsp_address'): AbstractControl {
    return (this.parentForm.get(this.fgName).get(key));
  }

  clear(): void {
    this.controlName('zipcode').setValue('');
    this.controlName('dsp_address').patchValue(null);
    this.controlName('banti').setValue('');
    this.controlName('ktgk').setValue('');
    this.controlName('address_all').setValue('');
    this.addresses = [];
    this.pre_zipcode = null

    this.showFlg = false;
  }

  getId(prefix: string) {
    //必ず一意になるようにする
    return prefix + this.fgName + this.ckName;
  }

  getName() {
    //必ずフォーム内で一意になるようにする
    return this.fgName + this.ckName;
  }

  onCheckChange(e) {
    this.controlName('addr_kbn').setValue(e.target.value);

    //値をクリア
    this.clear();
    //バリデーション
    this.setValidate();
  }

  setValidate() {
    if (this.controlName('addr_kbn').value == '1') {
      //管内
      this.controlName('zipcode').enable();
      this.controlName('dsp_address').enable();
      this.controlName('banti').enable();
      this.controlName('ktgk').enable();
      this.controlName('address_all').disable();
    }
    else if (this.controlName('addr_kbn').value == '2') {
      //管外
      this.controlName('zipcode').enable();
      this.controlName('dsp_address').enable();
      this.controlName('banti').enable();
      this.controlName('ktgk').enable();
      this.controlName('address_all').disable();
    }
    else if (this.controlName('addr_kbn').value == '3') {
      //国内
      this.controlName('zipcode').enable();
      this.controlName('dsp_address').enable();
      this.controlName('banti').enable();
      this.controlName('ktgk').enable();
      this.controlName('address_all').disable();
    }
    else if (this.controlName('addr_kbn').value == '4') {
      //国外
      this.controlName('zipcode').disable();
      this.controlName('dsp_address').disable();
      this.controlName('banti').disable();
      this.controlName('ktgk').disable();
      this.controlName('address_all').enable();
    }
  }

  setDisable() {
    this.controlName('zipcode').disable();
    this.controlName('dsp_address').disable();
    this.controlName('banti').disable();
    this.controlName('ktgk').disable();
    this.controlName('address_all').disable();
  }

  setDefaltKbn(addr_kbn) {
    this.controlName('addr_kbn').setValue(addr_kbn);
    //値をクリア
    this.clear();
    this.setValidate();
  }

}