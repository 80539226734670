<div class="box-qr">
    <div class="img-qr">
        <img id="qr-image" (click)="downloadCanvas()">
    </div>
    <div class="box-koumoku">
        <h6>{{ 'JYUMINHYOU.TITLE_MAIN' | translate }}</h6>
    </div>
    <div>
        <div class="box-koumoku">
            <button class="c-button" (click)="downloadCanvas()">
                <fa-icon [icon]="faDownload"></fa-icon>
                {{ 'COMMON.QR.BUTTON_DOWNLOAD' | translate }}
            </button>

        </div>
    </div>
    <div class="box-mochimono">
        <a>{{ 'COMMON.QR.DOWNLOAD_MESSAGE' | translate }}</a>
    </div>
    <a id="hiddenLink" download="{{filename}}" hidden="hidden">link</a>
</div>

<div class="box-block">
    <div class="box-koumoku">
        <h4>{{ 'COMMON.QR.MOCHIMONO' | translate }}</h4>
    </div>

    <div class="box-koumoku box-mochimono">
        <div *ngFor="let mochimono_val of mochimono; let i = index">
            <input type="checkbox" id="label{{i}}" class="cssacc" checked />
            <label for="label{{i}}">
                <fa-icon [icon]="faPlusCircle" class="mochimono-unchecked"></fa-icon>
                <fa-icon [icon]="faMinusCircle" class="mochimono-checked"></fa-icon>
                {{mochimono_val.title}}
            </label>
            <div class="accshow">
                <p *ngFor="let meisai_val of mochimono_val.meisai">{{meisai_val}}</p>
            </div>
        </div>
    </div>
    <div class="box-qr">
        <button class="c-button" (click)="downloadMochimono()">
            <fa-icon [icon]="faDownload"></fa-icon>
            {{ 'COMMON.QR.BUTTON_MOCHIMONO_DOWNLOAD' | translate }}
        </button>
    </div>
    <a id="hiddenMochimonoLink" download="{{filenameMochimono}}" hidden="hidden">link</a>
</div>

<div class="box-block">
    <div class="box-koumoku">
        <h4>{{ 'COMMON.QR.UKETUKE' | translate }}</h4>
    </div>

    <div class="box-koumoku box-mochimono">
        <div *ngFor="let uketuke_val of uketuke; let i = index">
            <input type="checkbox" id="uketuke{{i}}" class="cssacc" />
            <label for="uketuke{{i}}">
                <fa-icon [icon]="faPlusCircle" class="mochimono-unchecked"></fa-icon>
                <fa-icon [icon]="faMinusCircle" class="mochimono-checked"></fa-icon>
                {{uketuke_val.title}}
            </label>
            <div class="accshow">
                <p *ngFor="let meisai_val of uketuke_val.meisai">{{meisai_val}}</p>
            </div>
        </div>
    </div>
</div>
<!--
<div class="box-mail">
    <div class="box-mail-message">
        持ち物リストをメールで送信する場合はこちら
    </div>
    <div class="box-mail-image">
        <img class="img-right" src="/assets/right.png">
    </div>
</div>
-->
<div class="box-complete">
    <h5>{{ 'COMMON.QR.COMPLETE_MESSAGE' | translate }}</h5>
</div>
<div class="box-syouhyou">
    <span>※QRコードは(株)デンソーウェーブの登録商標です</span>
</div>