import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, ViewChildren, QueryList } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { Jyukiido, Idosya } from '@jyukiido/services/item';
import { Shimei } from '@components/shimei/shimei.component';
import { ShimeiComponent } from '@components/shimei/shimei.component';
import { DatepickerComponent } from '@components/datepicker/datepicker.component';
import { SexComponent } from '@components/sex/sex.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faExclamationTriangle, faUserSlash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'app-jyukiido-idosya',
  templateUrl: './jyukiido-idosya.component.html',
  styleUrls: ['./jyukiido-idosya.component.css', '../../jyukiido.component.css']
})
export class JyukiidoIdosyaComponent implements OnInit {
  @ViewChildren(ShimeiComponent) shimeiComponents: QueryList<ShimeiComponent>;
  @ViewChildren(DatepickerComponent) datepickerComponents: QueryList<DatepickerComponent>;
  @ViewChildren(SexComponent) sexComponents: QueryList<SexComponent>;
  @Input() item: Jyukiido;
  @Output() isPageFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  pageForm: FormGroup;
  zokugara: Array<{ value: string; label: string; }>;
  fgNameShimei = 'shimei';
  ckNameShimei = 'idosya-shimei';
  fgNameDatepicker = 'datepicker';
  fgNameSex = 'sex';
  ckNameSex = 'idosya-sex';
  faExclamationTriangle = faExclamationTriangle;
  faInfoCircle = faInfoCircle;
  faUserSlash = faUserSlash;
  hideSonota = true;

  constructor(private fb: FormBuilder, public translate: TranslateService, private config: ConfigService) {
    this.zokugara = Object(config.get("ZOKUGARA"));
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const ret = Object(event);
    });
  }

  ngOnInit(): void {
    this.pageForm = this.createForm();
    //一人は入力させる
    this.addSetaiin();

    this.pageForm.statusChanges.subscribe(value => {
      if (value === 'VALID') {
        this.isPageFormValid.emit(true);
      }
      else {
        this.isPageFormValid.emit(false);
      }
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      setaiins: this.fb.array([])
    });
  }

  get setaiinForm(): FormGroup {
    return this.fb.group({
      //shimei（shimei コンポーネントで追加）
      //datepicker（datepicker コンポーネントで追加）
      //sex（sex コンポーネントで追加）
      zokugara: [null, [Validators.required]],
      zokugara_sonota: [null, []],
    });
  }

  get setaiins(): FormArray {
    return this.pageForm.get('setaiins') as FormArray;
  }

  addSetaiin() {
    this.setaiins.push(this.setaiinForm);
  }

  removeSetaiin(index: number) {
    this.setaiins.removeAt(index);
  }

  getIdosyas(): Idosya[] {
    const idosyas = [];

    this.pageForm.get('setaiins')['controls'].forEach((child, i) => {
      const shimeiData = new Shimei(child.controls.shimei.get('sei_kj').value, child.controls.shimei.get('mei_kj').value, child.controls.shimei.get('sei_kn').value, child.controls.shimei.get('mei_kn').value);
      const umare_ymd = String(child.controls.datepicker.get('year').value) + '-' + String(('0' + (child.controls.datepicker.get('month').value)).slice(-2)) + '-' + String(('0' + (child.controls.datepicker.get('day').value)).slice(-2));
      const sex = String(child.get(this.fgNameSex).get('cd').value);
      const idosya = new Idosya(i + 1, shimeiData, String(umare_ymd), String(sex), this.pageForm.controls.setaiins.value[i].zokugara, this.pageForm.controls.setaiins.value[i].zokugara_sonota);
      idosyas.push(idosya);
    });

    return idosyas;
  }

  setTouched(): void {
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  getRadioName(i: number): string {
    return this.ckNameSex + i;
  }

  getUji(i) {
    const ctl = this.pageForm.get('setaiins')['controls'][i];
    return new Shimei(ctl.controls.shimei.get('sei_kj').value, null, ctl.controls.shimei.get('sei_kn').value, null);
  }

  onZokugaraChange(index: number){
    if (this.pageForm.controls.setaiins.value[index].zokugara!=='99'){
      const formArray = this.pageForm.get('setaiins') as FormArray;
      formArray.controls[index].get('zokugara_sonota').setValue('');
    }
  }

}
