import { Component, OnInit, Input } from '@angular/core';
import { Inkansyoumei} from '@inkansyoumei/services/item';

@Component({
  selector: 'app-inkansyoumei-kakunin',
  templateUrl: './inkansyoumei-kakunin.component.html',
  styleUrls: ['./inkansyoumei-kakunin.component.css']
})
export class InkansyoumeiKakuninComponent implements OnInit {
  @Input() item: Inkansyoumei;

  constructor() { }

  ngOnInit(): void {
  }
}
