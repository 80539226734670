import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Jyuminhyou, Shinsei, Syoumeisyo, SeikyuOption } from './services/item';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { JyuminhyouDouiComponent } from '@jyuminhyou/components/jyuminhyou-doui/jyuminhyou-doui.component';
import { JyuminhyouShinseiComponent } from '@jyuminhyou/components/jyuminhyou-shinsei/jyuminhyou-shinsei.component';
import { JyuminhyouCyouhyoComponent } from '@jyuminhyou/components/jyuminhyou-cyouhyo/jyuminhyou-cyouhyo.component';
import { JyuminhyouOptionComponent } from '@jyuminhyou/components/jyuminhyou-option/jyuminhyou-option.component';
import { JyuminhyouKakuninComponent } from '@jyuminhyou/components/jyuminhyou-kakunin/jyuminhyou-kakunin.component';
import { JyuminhyouQrComponent } from '@jyuminhyou/components/jyuminhyou-qr/jyuminhyou-qr.component';
import { JyuminhyouService } from '@jyuminhyou/services/jyuminhyou.service';
import { Step } from '@components/stepver/stepver.component';
import { getFormattedCurrentDateJST } from '@utility/date-functions';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { getNameKjFromShimei, getNameKnFromShimei } from '@utility/format';
import { ConfigService } from '@services/config/config.service'
import { TranslateSettingService } from '@services/translate-setting/translate-setting.service';

@Component({
  selector: 'app-jyuminhyou',
  templateUrl: './jyuminhyou.component.html',
  styleUrls: ['./jyuminhyou.component.css']
})
export class JyuminhyouComponent implements OnInit {
  @ViewChild(JyuminhyouDouiComponent) douiComponent: JyuminhyouDouiComponent;
  @ViewChild(JyuminhyouShinseiComponent) shinseiComponent: JyuminhyouShinseiComponent;
  @ViewChild(JyuminhyouCyouhyoComponent) cyouhyoComponent: JyuminhyouCyouhyoComponent;
  @ViewChild(JyuminhyouOptionComponent) optionComponent: JyuminhyouOptionComponent;
  @ViewChild(JyuminhyouKakuninComponent) kakuninComponent: JyuminhyouKakuninComponent;
  @ViewChild(JyuminhyouQrComponent) qrComponent: JyuminhyouQrComponent;

  item: Jyuminhyou;
  shinsei: Shinsei;
  steps: Step[];
  today: string;
  currentSteper: number = 1;
  isChildFormValid: boolean;
  faGlobe = faGlobe;
  langFlg: boolean;

  constructor(
    fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private jyuminhyouService: JyuminhyouService,
    public translate: TranslateService,
    private configService: ConfigService,
    private translateSettingService: TranslateSettingService
  ) {
    const lang = this.configService.get('LANGUAGE_SETTING')
    //多言語化対応
    this.langFlg = lang.display
    translate = translateSettingService.setTranslate(translate, lang);
  }

  ngOnInit(): void {
    this.today = getFormattedCurrentDateJST('yyyy-MM-dd');

    this.item = {
      "sj101_snsi_bng": 0,
      "sj101_snsi_ymd": this.today,
      "sj101_tisy_name_kj": "",
      "sj101_tisy_name_kn": "",
      "sj101_tisy_umare_ymd": "",
      "sj101_tisy_yubinno": "",
      "sj101_tisy_addr_ken": "",
      "sj101_tisy_addr_city": "",
      "sj101_tisy_addr_town": "",
      "sj101_tisy_addr_banti": "",
      "sj101_tisy_addr_ktgk": "",
      "sj101_tisy_telno": "",
      "sj101_dairi_name_kj": "",
      "sj101_dairi_name_kn": "",
      "sj101_dairi_yubinno": "",
      "sj101_dairi_addr_ken": "",
      "sj101_dairi_addr_city": "",
      "sj101_dairi_addr_town": "",
      "sj101_dairi_addr_banti": "",
      "sj101_dairi_addr_ktgk": "",
      "sj101_dairi_knki": "",
      "sj101_dairi_telno": "",
      "sj101_siky_name_kj": "",
      "sj101_siky_name_kn": "",
      "sj101_siky_yubinno": "",
      "sj101_siky_addr_ken": "",
      "sj101_siky_addr_city": "",
      "sj101_siky_addr_town": "",
      "sj101_siky_addr_banti": "",
      "sj101_siky_addr_ktgk": "",
      "sj101_siky_knki": "",
      "sj101_siky_telno": "",
      "sj101_siky_riyu": "",
      "sj101_ichibu_name": "",
      "sj101_youto": "",
      "sj101_zokusei": "",
      "sj101_mynumber_taisyosya": "",
      "sj102s": [{
        "sj102_snsi_bng": 1,
        "sj102_cyouhyo_cd": "",
        "sj102_maisuu": "",
        "sj102_ichibu_name": "",
      }]
    };

    this.steps = this.jyuminhyouService.steps;

    //ExpressionChangedAfterItHasBeenCheckedError対応
    this.cd.detectChanges();

    //初めのページのバリデーション情報をセット
    this.isChildFormValid = this.shinseiComponent.pageForm.valid;
  }

  getCurrentSteper(steper: number) {
    return steper != this.currentSteper;
  }

  onNext() {
    if (this.currentSteper == 1) {
      //toutchしたことにする
      this.douiComponent.setTouched();
      if (environment.production && this.douiComponent.pageForm.invalid) return;
      //次の画面のステータスをセット
      this.isChildFormValid = this.shinseiComponent.pageForm.valid;
    }
    if (this.currentSteper == 2) {
      //toutchしたことにする
      this.shinseiComponent.setTouched();
      if (environment.production && this.shinseiComponent.pageForm.invalid) return;
      this.shinsei = this.shinseiComponent.getShinsei();
      this.setShinsei(this.shinsei);
      //次の画面のステータスをセット
      this.isChildFormValid = this.cyouhyoComponent.pageForm.valid;
    }
    if (this.currentSteper == 3) {
      //toutchしたことにする
      this.cyouhyoComponent.setTouched();
      if (environment.production && this.cyouhyoComponent.pageForm.invalid) return;

      const cyouhyo = this.cyouhyoComponent.getCyouhyo();
      this.setCyouhyo(cyouhyo);
      //次の画面のステータスをセット
      this.isChildFormValid = this.optionComponent.pageForm.valid;
    }
    if (this.currentSteper == 4) {
      //toutchしたことにする
      this.optionComponent.setTouched();
      if (environment.production && this.optionComponent.pageForm.invalid) return;

      const option = this.optionComponent.getOption();
      this.setOption(option);

      this.isChildFormValid = this.qrComponent.createQRText();
    }
    if (this.currentSteper == 5) {
      if (!this.qrComponent.createQRText()) return;
    }

    this.currentSteper++;
    this.scrollTop();
  }

  onBack() {
    this.currentSteper--;
    this.scrollTop();
    //戻った場合は必ずOK
    this.isChildFormValid = true;

    //同意に戻ったらチェックを外す
    if (this.currentSteper == 1) {
      this.douiComponent.clearDouiFlg();
    }
  }

  hiddenBack() {
    if ([2, 3, 4, 5, 6].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  hiddenNext() {
    if ([1, 2, 3, 4, 5].includes(this.currentSteper)) {
      return false;
    }
    return true;
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  setShinsei(data: Shinsei) {
    this.item.sj101_tisy_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.tisy_smi));
    this.item.sj101_tisy_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.tisy_smi));
    this.item.sj101_tisy_umare_ymd = this.replaceNullEmpty(data.tisy_umare_ymd);
    this.item.sj101_tisy_yubinno = this.replaceNullEmpty(data.tisy_address.zipcode);
    this.item.sj101_tisy_addr_ken = this.replaceNullEmpty(data.tisy_address.address.ken);
    this.item.sj101_tisy_addr_city = this.replaceNullEmpty(data.tisy_address.address.city);
    this.item.sj101_tisy_addr_town = this.replaceNullEmpty(data.tisy_address.address.town);
    this.item.sj101_tisy_addr_banti = this.replaceNullEmpty(data.tisy_address.banti);
    this.item.sj101_tisy_addr_ktgk = this.replaceNullEmpty(data.tisy_address.ktgk);
    this.item.sj101_tisy_telno = this.replaceNullEmpty(data.tisy_telno);
    this.item.sj101_dairi_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.dairi_smi));
    this.item.sj101_dairi_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.dairi_smi));
    this.item.sj101_dairi_yubinno = this.replaceNullEmpty(data.dairi_address.zipcode);
    this.item.sj101_dairi_addr_ken = this.replaceNullEmpty(data.dairi_address.address.ken);
    this.item.sj101_dairi_addr_city = this.replaceNullEmpty(data.dairi_address.address.city);
    this.item.sj101_dairi_addr_town = this.replaceNullEmpty(data.dairi_address.address.town);
    this.item.sj101_dairi_addr_banti = this.replaceNullEmpty(data.dairi_address.banti);
    this.item.sj101_dairi_addr_ktgk = this.replaceNullEmpty(data.dairi_address.ktgk);
    this.item.sj101_dairi_knki = this.replaceNullEmpty(data.dairi_knki);
    this.item.sj101_dairi_telno = this.replaceNullEmpty(data.dairi_telno);
    this.item.sj101_siky_name_kj = this.replaceNullEmpty(getNameKjFromShimei(data.siky_smi));
    this.item.sj101_siky_name_kn = this.replaceNullEmpty(getNameKnFromShimei(data.siky_smi));
    this.item.sj101_siky_yubinno = this.replaceNullEmpty(data.siky_address.zipcode);
    this.item.sj101_siky_addr_ken = this.replaceNullEmpty(data.siky_address.address.ken);
    this.item.sj101_siky_addr_city = this.replaceNullEmpty(data.siky_address.address.city);
    this.item.sj101_siky_addr_town = this.replaceNullEmpty(data.siky_address.address.town);
    this.item.sj101_siky_addr_banti = this.replaceNullEmpty(data.siky_address.banti);
    this.item.sj101_siky_addr_ktgk = this.replaceNullEmpty(data.siky_address.ktgk);
    this.item.sj101_siky_telno = this.replaceNullEmpty(data.siky_telno);
  }

  setCyouhyo(datas: Syoumeisyo[]) {
    const cyouhyos = [];
    datas.forEach((data) => {
      const cyouhyo = {
        "sj102_id": this.replaceNullEmpty(data.id),
        "sj102_cyouhyo_cd": this.replaceNullEmpty(data.cyouhyo_cd),
        "sj102_maisuu": this.replaceNullEmpty(data.maisuu),
        "sj102_ichibu_name": this.replaceNullEmpty(data.ichibu_name),
      };
      cyouhyos.push(cyouhyo);
    });
    this.item.sj102s = cyouhyos;
  }

  setOption(data: SeikyuOption) {
    const zokuseiData = {
      zokugara: this.replaceNullEmpty(data.zokugara),
      honseki: this.replaceNullEmpty(data.honseki),
      kojinno: this.replaceNullEmpty(data.mynumber),
    };

    const gaijinkisaiObjects = this.replaceNullEmpty(data.gaijinkisaiObjects);

    if (gaijinkisaiObjects != "") {
      data.gaijinkisaiObjects.forEach((item, index) => {
        zokuseiData[item["value"]] = "true";
      });
    }
    const zokuseiDataJSON = JSON.stringify(zokuseiData);

    this.item.sj101_youto = JSON.stringify(data.youto);
    this.item.sj101_zokusei = zokuseiDataJSON;
  }

  replaceNullEmpty(value) {
    return value == null ? "" : value;
  }

  enableNextButton(isValid: boolean) {
    this.isChildFormValid = isValid;
  }

}
